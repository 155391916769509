import {  AppointmentTimeSlotDatabase } from './appointments';

export class ChatMessage {
    uid: string;
    url: string;
    send_at: number | string;
    from_user: string;
    to_user: string;
    send_from_user_name: string;
    send_to_user_name: string;
    send_from_user_photo: string;
    message: any;
    message_picture: string;
    eventId: string;
    appointment?: AppointmentTimeSlotDatabase;

    constructor() {
        this.url = null;
    }
}
