import { Injectable } from '@angular/core';
import { Session } from 'src/app/models/ceu-session';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap, auditTime, take, combineLatestWith, takeUntil } from 'rxjs/operators';
import { Observable, of, combineLatest, Subscription, forkJoin, firstValueFrom } from 'rxjs';
import * as _ from 'lodash';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { Firestore, collection, collectionData, doc, docData, getDoc, limit, orderBy, query, startAfter, updateDoc } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})

export class DaoScheduleService {
    private refGetNextPageSessionsGlobalVision = null
    private refGetSessionsGroupGlobalVision = null
    private refGetSessionsGroupsVision: Subscription[] = [];
    private refGetNextPageSessionsLimitedAccessByGroups = null
    private refGetSessionModule = null

    public headers;
    public requestOptions;

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        public http: HttpClient,
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    /**
     * @description get the Document data of a module
     * @param eventId 
     * @param moduleId 
     * @returns 
     */
    getModule(eventId: string, moduleId: string) {
        const ref = doc(this.firestore, `events/${eventId}/modules/${moduleId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * Get session module
     * @param moduleId 
     * @param sessionId 
     */
    getSessionModule(eventId: string, moduleId: string, sessionId: string): Observable<Session> {
        const ref1 = doc(this.firestore, `modules/${moduleId}/sessions/${sessionId}`);
        return docData(ref1).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((doc) => {
                return (doc as Session);
            }),
            switchMap((session) => {
                let speakersIds = Object.keys(session.speakers);
                let speakersObsArray: Observable<any>[] = [];

                if (!speakersIds || speakersIds.length == 0) {
                    session.speakers = [];
                    return (of(session));
                }

                speakersIds.forEach((speakerId) => {
                    const ref2 = doc(this.firestore, `events/${eventId}/speakers/${speakerId}`);
                    speakersObsArray.push(docData(ref2).pipe(takeUntil(this.Slogout.logoutSubject)));
                })

                return (combineLatest(speakersObsArray).pipe(
                    switchMap((speakers: any) => {
                        if (speakers && speakers.length > 0) {
                            session.speakers = speakers;
                        } else {
                            session.speakers = [];
                        }
                        return (of(session));
                    })
                ));
            })
        );
    }

    /**
     * @param eventId 
     * @param moduleId 
     * @param sessionId 
     * @returns all data related to a session (i.e. the session and schedule module firestore documents)
     */
    public getSessionData(eventId: string, moduleId: string, sessionId: string) {
        const scheduleRef = doc(this.firestore, `events/${eventId}/modules/${moduleId}`);
        const sessionRef = doc(this.firestore, `events/${eventId}/sessions/${sessionId}`);

        const arr$ = [
            docData(sessionRef).pipe(takeUntil(this.Slogout.logoutSubject)),
            docData(scheduleRef).pipe(takeUntil(this.Slogout.logoutSubject))
        ];

        return combineLatest(arr$);
    }

    closeRefGetSessionModule() {
        if (this.refGetSessionModule)
            this.refGetSessionModule.unsubscribe()
    }

    /**
     * @description get schedule sessions
     * @param moduleId 
     * @param startAfter 
     * @returns an observable of all documents (sessions of a schedule) present in the 'sessions' collection
     */
    public getSchedule(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/sessions`);
        const refQ = query(ref, orderBy("startTime"));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * @description get personal schedule sessions
     * @param eventId 
     * @param attendeeId 
     * @returns an observable of all firestore documents of sessions in the current attendee (user) personal 
     * schedule, i.e. documents present in the 'sessions' collection
     */
    public getPersonalSchedule(eventId: string, attendeeId: string) {
        const ref = collection(this.firestore, `events/${eventId}/attendees/${attendeeId}/sessions`);
        const refQ = query(ref, orderBy('startTime'));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * 
     * @param eventId 
     * @returns a list of firestore documents of all locations in a event
     */
    public getLocations(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/locations`);
        return firstValueFrom(collectionData(ref));
    }


    /******************************************************************************** methods of global vision *************************************************************************************** */

    // get the first 100 sessions of the module. (global vision)
    getFirstPageSessionsGlobalVision(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, ...[orderBy('startTime'), limit(100)]);

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                if (docs.length <= 0) {
                    return (of(false));
                } else {
                    let nextPage = docs[docs.length - 1];

                    return (of({
                        sessions: docs,
                        nextPage: nextPage
                    }));
                }
            })
        );
    }

    getAllSessionsVisionGlobal(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, orderBy('startTime'));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // get plus 100 module sessions using the start time as a filter.
    getNextPageSessionsGlobalVision(moduleId, lastStartTime, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, ...[orderBy('startTime'), startAfter(lastStartTime), limit(100)]);

        this.refGetNextPageSessionsGlobalVision = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((snapshot) => {
                let nextPage = snapshot[snapshot.length - 1];

                onResolve({
                    sessions: snapshot,
                    nextPage: nextPage
                });
            })
    }

    closeRefGetNextPageSessionsGlobalVision() {
        if (this.refGetNextPageSessionsGlobalVision)
            this.refGetNextPageSessionsGlobalVision.unsubscribe()
    }

    /******************************************************************************** methods of divided by groups *************************************************************************************** */
    // loads the group sessions.
    getSessionsGroupGlobalVision(groupId: string, moduleId: string) {
        const group = `groups.${groupId}`;

        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, orderBy(group));

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((sessions: Session[]) => {
                if (!sessions || sessions.length <= 0) {
                    return (of(null));
                } else {
                    return (of({
                        sessions: sessions.sort(function (a, b) {
                            return ((a.startTime < b.startTime) ? -1 : (a.startTime > b.startTime) ? 1 : 0);
                        }),
                        nextPage: sessions[sessions.length - 1]
                    }));
                }
            })
        );
    }

    closeRefGetSessionsGroupGlobalVision() {
        if (this.refGetSessionsGroupGlobalVision)
            this.refGetSessionsGroupGlobalVision.unsubscribe()
    }

    /******************************************************************************** groups vision *************************************************************************************** */
    // loads the participant group sessions.
    getSessionsGroupsVision(groups: Array<any>, moduleId: string) {
        if (groups.length > 0) {
            let obsArray: Observable<any>[] = [];
            const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
            let refQ;

            // get the sessions of each group
            for (const group of groups) {
                const groupId = `groups.${group.uid}`;
                refQ = query(ref, orderBy(groupId));

                obsArray.push(collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)));
            }

            return (combineLatest(obsArray).pipe(
                switchMap((sessions: any[]) => {
                    if (!sessions || sessions.length <= 0 || sessions.flat().length <= 0) {
                        return (of(null));
                    } else {
                        let filteredSessions = [];
                        sessions.flat()
                            .forEach((session) => {
                                if (filteredSessions.filter((sessionF) => sessionF.uid === session.uid).length === 0) {
                                    filteredSessions.push(session);
                                }
                            })
                        filteredSessions.sort((a, b) => {
                            return ((a.startTime < b.startTime) ? -1 : (a.startTime > b.startTime) ? 1 : 0);
                        });
                        return (of({
                            sessions: filteredSessions,
                            nextPage: filteredSessions[filteredSessions.length - 1]
                        }));
                    }
                })
            ));
        } else {
            return (of(null));
        }
    }

    closeRefGetSessionsGroupsVision() {
        if (this.refGetSessionsGroupsVision && this.refGetSessionsGroupsVision.length > 0) {
            this.refGetSessionsGroupsVision.forEach(sub => sub.unsubscribe());
        }
    }

    /******************************************************************************** methods of limited access by groups *************************************************************************************** */

    // get plus 100 module sessions using the start time as a filter. (limited access by groups)
    getNextPageSessionsLimitedAccessByGroups(moduleId, lastStartTime, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, orderBy('startTime'), startAfter(lastStartTime), limit(100));

        this.refGetNextPageSessionsLimitedAccessByGroups = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((snapshot) => {
                let nextPage = snapshot[snapshot.length - 1];

                onResolve({
                    sessions: snapshot,
                    nextPage: nextPage
                });
            })
    }

    getAllSessionsLimitedAccessByGroup(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions`);
        const refQ = query(ref, orderBy('startTime'));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    closeRefGetNextPageSessionsLimitedAccessByGroups() {
        if (this.refGetNextPageSessionsLimitedAccessByGroups)
            this.refGetNextPageSessionsLimitedAccessByGroups.unsubscribe()
    }

    /**
     * Checks whether the session is part of the user's personal calendar
     * @param moduleScheduleId 
     * @param sessionId 
     * @param attendeeId 
     */
    checkAttendeeOfTheSession(moduleScheduleId, sessionId, attendeeId) {
        const ref = doc(this.firestore, `modules/${moduleScheduleId}/sessions/${sessionId}/attendees/${attendeeId}`);
        return docData(ref).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((doc) => {
                if (typeof doc !== 'undefined') {
                    return (of(true));
                } else {
                    return (of(false));
                }
            })
        );
    }

    // loads session participants
    getSessionAttendees(moduleScheduleId, sessionId, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleScheduleId}/sessions/${sessionId}/attendees`);

        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    convertLowerCaseUpperCase(name) {
        var words = name.trim().toLowerCase().split(" ");
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
            words[a] = w[0].toUpperCase() + w.slice(1);
        }
        words.join(" ");
        return words[0];
    }

    // updateReactions({ eventId, sessionId, reactions }: updateReactionsProps) {
    /**
     * 
     * @param eventId 
     * @param moduleId 
     * @param sessionId 
     * @param reactions 
     * @returns 
     */
    updateReactions(
        eventId: string, 
        moduleId: string, 
        sessionId: string,
        videoId: string,
        reactions
    ) {
        const ref = doc(
            this.firestore, 
            `events/${eventId}/modules/${moduleId}/reactions/${sessionId}`
        );

        const obj = {};
        obj[videoId] = reactions;
        
        return updateDoc(ref, obj);
    }

    /**
     * @param eventId 
     * @param moduleId 
     * @param sessionId 
     * @returns an observable to a schedule session reactions data
     */
    public getReactionsListener(
        eventId: string, 
        moduleId: string, 
        sessionId: string
    ) {
        const ref = doc(
            this.firestore, 
            `events/${eventId}/modules/${moduleId}/reactions/${sessionId}`
        );

        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}
