import { Injectable } from '@angular/core';
import { TypeModule } from '../../models/type-module'
import { NameGroup } from 'src/app/enums/name-group';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { Firestore, collection, collectionData, orderBy, query, where } from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DaoGroupsService {
    private refGetGroups = null
    private refContSessionsOfGroup = null
    private refContAttendeesOfGroup = null
    private refContSpeakersOfGroup = null

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    // loads the event groups.
    getGroups(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.MANAGER_GROUP));

        this.refGetGroups = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((modules) => {
                if (modules.length > 0) {
                    const module = modules[0]
                    const moduleId = module.uid

                    const ref1 = collection(this.firestore, `modules/${moduleId}/groups`);
                    collectionData(ref1).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((groups) => {
                        onResolve(groups)
                    })
                } else {
                    onResolve([])
                }
            })
    }

    closeRefGetGroups() {
        if (this.refGetGroups)
            this.refGetGroups.unsubscribe()
    }

    // account the number of sessions that the group has from the schedule module, passed in the parameter.
    contSessionsOfGroup(groupId: string, moduleScheduleId: string, onResolve) {
        const group = `groups.${groupId}`
        const ref = collection(this.firestore, `modules/${moduleScheduleId}/sessions`);
        const refQ = query(ref, orderBy(group));

        this.refContSessionsOfGroup = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data) => {
            const size = data.length;
            onResolve(size);
        });
    }

    closeRefContSessionsOfGroup() {
        if (this.refContSessionsOfGroup)
            this.refContSessionsOfGroup.unsubscribe()
    }


    // count the number of participants the group has in the module.
    contAttendeesOfGroup(groupId: string, moduleAttendeeId: string, onResolve) {
        const group = `groups.${groupId}`
        const ref = collection(this.firestore, `modules/${moduleAttendeeId}/attendees`);
        const refQ = query(ref, orderBy(group));

        this.refContAttendeesOfGroup = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((data) => {
                const size = data.length;
                onResolve(size);
            })
    }

    closeRefContAttendeesOfGroup() {
        if (this.refContAttendeesOfGroup)
            this.refContAttendeesOfGroup.unsubscribe()
    }

     // count the number of speakers the group has in the module.
     contSpeakersOfGroup(groupId: string, moduleSpeakerId: string, onResolve) {
        const group = `groups.${groupId}`
        const ref = collection(this.firestore, `modules/${moduleSpeakerId}/speakers`);
        const refQ = query(ref, orderBy(group));

        this.refContSpeakersOfGroup = collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
            .subscribe((data) => {
                const size = data.length
                onResolve(size)
            })
    }

    closeRefContSpeakersOfGroup() {
        if (this.refContSpeakersOfGroup)
            this.refContSpeakersOfGroup.unsubscribe()
    }



    /**
   * 
get the group with language using identifier.
   * 
   *  @param identifier group identifier
   *  @param eventId uid of the group event.

   */

    groupLanguage(identifier: string, eventId: string) {
        return new Promise(async (resolve) => {
            switch (identifier) {
                case 'de_DE':
                    identifier = NameGroup.DeDE
                    break;
                case 'en_US':
                    identifier = NameGroup.EnUS
                    break;
                case 'es_ES':
                    identifier = NameGroup.EsES
                    break;
                case 'fr_FR':
                    identifier = NameGroup.FrFR
                    break;
                default:
                    identifier = NameGroup.PtBR
                    break;
            }

            const ref = collection(this.firestore, `events/${eventId}/modules`);
            const refQ = query(ref, where('type', '==', TypeModule.MANAGER_GROUP));

            collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject))
                .subscribe((modules) => {
                    if (modules.length > 0) {
                        const module = modules[0]
                        const moduleId = module.uid

                        // search the groups in the module.
                        const ref1 = collection(this.firestore, `modules/${moduleId}/groups`);
                        const refQ1 = query(ref1, where('identifier', '==', identifier))
                        collectionData(refQ1).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((groups) => {
                            let group = null

                            for (const g of groups) {
                                group = g
                            }

                            resolve(group)
                        })
                    } else {
                        resolve([])
                    }
                })
        })
    }

    // transforma o array de grupos em json 
    public transformArrayToJson(groups: Array<any>) {
        let json = {}

        for (const group of groups) {
            const groupId = group.uid
            json[groupId] = group
        }

        return json
    }

}