import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService } from 'src/app/shared/services';
import { DaoAttendeesService } from 'src/app/providers/db/dao-attendees.service';
import { GroupDiscussionsService } from 'src/app/shared/services/group-discussions/group-discussions.service';
import { Router } from '@angular/router';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy {
    eventId: string = null;
    userId: string = null;
    userPrimaryId: string = null;
    userSecondaryId: string = null;
    eventColors: EventColors = new EventColors();
    attendees: Array<any> = [];
    allAttendees: Array<any> = [];
    loader: boolean = true;
    searchOpen: boolean = false;
    subscriptions: Subscription[] = [];
    
    constructor(
        private events: Events,
        public global: GlobalService,
        private modalCtrl: ModalController,
        private router: Router,
        private navParams: NavParams,
        private dbAttendees: DaoAttendeesService,
        private gdService: GroupDiscussionsService,
        private zone: NgZone,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.eventId = this.navParams.get('eventId');
        this.userId = this.navParams.get('userId');
        this.loadColors();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        this.getAvailablesAttendees();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    getAvailablesAttendees() {
        this.dbAttendees.getAvailableAttendeesChat(
            this.eventId,
            (attendees) => {
                this.zone.run(() => {
                    this.attendees = [];
                    this.attendees = attendees;
                    this.allAttendees = attendees;
                    this.loader = false;
                });
            }
        );
    }

    attendeeHeader(attendee, i, attendees) {
        if (i == 0) {
            return attendee.letter;
        } else if (i != 0 && attendee.letter != attendees[i - 1].letter) {
            return attendee.letter;
        }
    }

    searchBar(ev) {
        if (ev.target.value.length >= 1) {
            let value = ev.target.value.toLowerCase();
            this.attendees = [];
            this.allAttendees.filter((item) => {
                if (item.name.toLowerCase().includes(value)) {
                    this.attendees.push(item);
                }
            });
        } else {
            this.attendees = this.allAttendees;
        }
    }

    async close(uid?: string) {
        if (uid) {
            try {
                const chatId = await this.gdService.getOrCreateChat(
                    this.eventId,
                    this.SUserData.userId,
                    uid
                );
                this.router.navigate([`/event/${this.eventId}/chat/${chatId}`]);
            } catch (e) {
                console.log(e);
            }
        }

        this.modalCtrl.dismiss();
    }
}
