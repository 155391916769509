import { customField } from './../../models/customField';
import { Injectable } from '@angular/core';
import { 
    Firestore, 
    DocumentData, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    updateDoc, 
    doc, 
    docData,
    getDoc,
    limit,
    startAfter,
    writeBatch,
    startAt,
    endAt,
    where,
    QueryConstraint
} from '@angular/fire/firestore';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { StorageService } from '../storage/storage.service';
import { TypeModule } from 'src/app/models/type-module';
import { GlobalService } from 'src/app/shared/services';
import { map, take, takeUntil } from 'rxjs/operators';
import { IAccessModule } from 'src/app/shared/interfaces/access-module.interface';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DaoAttendeesService {
    refGetModule = null

    constructor(
        private firestore: Firestore,
        private storage: StorageService,
        public global: GlobalService,
        private Slogout: LogoutService,
        private SEventData: EventDataService
    ) { }


    // get the id module passed in the parameter.
    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((module) => {
            onResolve(module);
        })
    }

    getFirstAttendeeFromModule(moduleId: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((attendees) => {
            onResolve(attendees)
        });
    }
    

    // close get module
    closeRefGetModule() {
        if (this.refGetModule)
            this.refGetModule.unsubscribe()
    }

    checkeditProfileActive(moduleId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(take(1)).subscribe((data) => {
            if (data.allowedEditProfile) {
                onResolve(true);
            }
            else {
                onResolve(false);
            }
        });
    }

    getChatStatus(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(take(1)).subscribe((snapshot) => {
            onResolve(snapshot['allow_chat'])
        });
    }

    getFirstAttendeesPageByModule(moduleId: string, typeOrder: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let refQ;

        switch (typeOrder) {
            case 'asc': //a-z
                refQ = query(ref, ...[orderBy('name', 'asc'), limit(100)]);
                break;

            case 'desc': //z-a
                refQ = query(ref, ...[orderBy('name', 'desc'), limit(100)]);
                break;

            case 'oldest'://antiho-recente
                refQ = query(ref, ...[orderBy('createdAt', 'asc'), limit(100)]);
                break;

            case 'recent': //recente-antigo
                refQ = query(ref, ...[orderBy('createdAt', 'desc'), limit(100)]);
                break;

            case 'id': //ID
                refQ = query(ref, ...[orderBy('identifier'), limit(100)]);
                break;
            default:
                return;
        }

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            if (docs.length > 0) {
                let nextPage = docs[docs.length - 1];

                onResolve({
                    attendees: docs,
                    next: nextPage
                });
            } else {
                onResolve({
                    attendees: [],
                    next: null
                });
            }
        })
    }

    getNextAttendeesPageByModule(moduleId: string, nextPage, typeOrder: string, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let refQ;

        switch (typeOrder) {
            case 'asc': //a-z
                refQ = query(ref, ...[startAfter(nextPage['name']), limit(100), orderBy('name', 'asc')]);
                break;

            case 'desc': //z-a
                refQ = query(ref, ...[startAfter(nextPage['name']), limit(100), orderBy('name', 'desc')]);
                break;

            case 'oldest'://antiho-recente
                refQ = query(ref, ...[startAfter(nextPage['createdAt']), limit(100), orderBy('createdAt', 'asc')]);
                break;

            case 'recent': //recente-antigo
                refQ = query(ref, ...[startAfter(nextPage['createdAt']), limit(100), orderBy('createdAt', 'desc')]);
                break;

            case 'id': //ID
                refQ = query(ref, ...[startAfter(nextPage['createdAt']), limit(100), orderBy('identifier')]);
                break;
            default:
                return;
        }

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            if (docs.length == 0) {
                onResolve(false);
            } else {
                const morePage = docs[docs.length - 1];
                onResolve({
                    attendees: docs,
                    next: morePage
                });
            }
        });
    }

    getAttendeeByEvent(eventId: string, attendeeId: string) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    getOrder(moduleId, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(take(1)).subscribe((doc) => {
            onResolve(doc.orderUsers);
        });
    }

    getCustomFields(eventId: string, moduleId: string) {
        const ref = collection(
            this.firestore, 
            `events/${eventId}/modules/${moduleId}/customFields`
        );
        const refQ = query(ref, orderBy("order"));
        return firstValueFrom(collectionData(refQ));
    }

    getCFAnswers(eventId: string, attendeeId: string) {
        const ref = collection(
            this.firestore, 
            `events/${eventId}/attendees/${attendeeId}/customFields`
        );
        return firstValueFrom(collectionData(ref));
    }

    // getCustomFieldOptions(moduleId: string, attendeeId: string, customId: string, onResolve) {
    //     const ref = collection(this.firestore, `modules/${moduleId}/customFields/${customId}/options`);
    //     collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
    //         onResolve(docs)
    //     });
    // }

    async updateAttendee(eventId: string, moduleId: string, attendee, file: any) {
        const batch = writeBatch(this.firestore);

        const ref1 = doc(
            this.firestore, 
            `events/${eventId}/attendees/${attendee.uid}`
        );
        const ref2 = doc(
            this.firestore, 
            `modules/${moduleId}/attendees/${attendee.uid}`
        );
        
        const obj = Object.assign({}, attendee)

        if (file) {
            const promise = new Promise<any>((res) => {
                this.storage.uploadAttendeeProfile(
                    eventId, 
                    attendee.uid, 
                    file, 
                    (url) => { res(url); }
                );
            });
            const url = await promise;
            if (!url) { return Promise.reject(); }

            obj.photoUrl = url;
        }
        batch.update(ref1, obj);
        batch.update(ref2, obj);

        return batch.commit();
    }

    public updateCustomFields(
        eventId: string, 
        attendeeId: string, // userId 
        cfAnswers: Array<any>
    ) {
        const batch = writeBatch(this.firestore);
        const path = `events/${eventId}/attendees/${attendeeId}/customFields`;
        for (const cfAnswer of cfAnswers) {
            const ref = doc(this.firestore, `${path}/${cfAnswer.id}`);
            batch.update(
                ref, 
                { "answer": (!cfAnswer.answer) ? null : cfAnswer.answer }
            );
        }
        return batch.commit();
    }

    getFieldOptions(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        getDoc(ref).then((value) => {
            const aux = value.data();
            onResolve(aux['fields']);
        });
    }

    // getFieldOptionsCustom(moduleId: string, onResolve) {
    //     const ref = doc(this.firestore, `modules/${moduleId}`);
    //     getDoc(ref).then((value) => {
    //         const aux = value.data();
    //         onResolve(aux['fieldsCustom']);
    //     });
    // }

    searchAttendeesByName(moduleId: string, name: string, onResolve) {
        name = this.convertLowerCaseUpperCase(name);

        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        const refQ = query(ref, ...[startAt(name), endAt(name + '\uf8ff'), orderBy('name')]);

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    convertLowerCaseUpperCase(name) {
        var words = name.trim().toLowerCase().split(" ");
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
            words[a] = w[0].toUpperCase() + w.slice(1);
        }
        words.join(" ");
        return words[0];
    }

    getAvailableAttendeesChat(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.ATTENDEE));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            docs.forEach((doc) => {
                if (!doc.allow_chat) { return; }

                const refModule = collection(this.firestore, `modules/${doc.uid}/attendees`);
                const refModuleQ = query(refModule, orderBy('queryName', 'asc'));

                collectionData(refModuleQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((documents) => {
                    const attendees = [];
                    documents.forEach((document) => {
                        const letters = document.name.split('');
                        const letter = letters[0].toUpperCase();
                        document.letter = letter;
                        document.principal_title = this.getAttendeePrincipalTitle(document.title);
                        attendees.push(document);
                    })
                    onResolve(attendees);
                })
            })
        })
    }

    addPoints(eventId: string, moduleId: string, userId: string, points: number, onResolve) {
        const ref1 = doc(this.firestore, `events/${eventId}/attendees/${userId}`);
        const ref2 = doc(this.firestore, `modules/${moduleId}/attendees/${userId}`);
        getDoc(ref1).then((doc) => {
            const attendee = doc.data();
            const batch = writeBatch(this.firestore);

            batch.update(ref1, { points: attendee.points + points });
            batch.update(ref2, { points: attendee.points + points });

            batch.commit().then(() => { onResolve(true); });
        });
    }

    /******************************************************************************** methods of global vision *************************************************************************************** */
    // loads the first 100 participants of the module. global vision
    getFirstAttendeesGlobalVision(typeOrder, moduleId, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), limit(100)];
                break;

            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), limit(100)];
                break;

            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), limit(100)];
                break;

            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), limit(100)];
                break;

            case 'id': //ID
                qc = [orderBy('identifier'), limit(100)];
                break;
            default:
                onResolve([]);
                return;
        }

        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }


    // get all attendees of the module
    getAttendeesAllGlobalVision(moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // get plus 100 attendees module.
    getNextPageAttendeesGlobalVision(moduleId, lastAttendee, typeOrder, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), startAfter(lastAttendee.queryName), limit(100)];
                break;

            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), startAfter(lastAttendee.queryName), limit(100)];
                break;

            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), startAfter(lastAttendee.createdAt), limit(100)];
                break;

            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), startAfter(lastAttendee.createdAt), limit(100)];
                break;

            case 'id': //ID
                qc = [orderBy('identifier'), startAfter(lastAttendee.identifier), limit(100)];
                break;
            default:
                return;
        }

        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    /******************************************************************************** methods of divided by groups *************************************************************************************** */
    // loads the group attendees.
    getAttendeesGroupGlobalVision(groupId: string, typeOrder: string, moduleId: string, onResolve) {
        const group = `groups.${groupId}`;

        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        const refQ = query(ref, orderBy(group));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            switch (typeOrder) {
                case 'asc': //a-z
                    docs.sort(function (a, b) {
                        return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
                    });
                    break;

                case 'desc': //z-a
                    docs.sort(function (a, b) {
                        return a.queryName > b.queryName ? -1 : a.queryName < b.queryName ? 1 : 0;
                    });
                    break;

                case 'oldest'://antiho-recente
                    docs.sort(function (a, b) {
                        return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
                    });
                    break;

                case 'recent': //recente-antigo
                    docs.sort(function (a, b) {
                        return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                    });
                    break;

                case 'id': //ID
                    docs.sort(function (a, b) {
                        return a.identifier < b.identifier ? -1 : a.identifier > b.identifier ? 1 : 0;
                    });
                    break;
            }
            onResolve(docs);
        });
    }

    /******************************************************************************** groups vision *************************************************************************************** */
    // loads the participant group attendees.
    getAttendeesGroupsVision(groupsIds: string[], typeOrder: string, moduleId: string, onResolve) {
        if (!(groupsIds.length > 0)) {
            onResolve([]);
            return;
        }

        const total = groupsIds.length;
        let i = 0;
        const list = []

        for (const groupId of groupsIds) {
            const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
            const refQ = query(ref, orderBy(groupId));

            collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
                i++

                // withdraws replay of attendees
                docs.forEach((attendee) => {
                    const pos = list.map(function (e) { return e.uid; }).indexOf(attendee.uid);
                    if (pos === -1) { list.push(attendee) }
                })

                // if all groups have already been processed.
                if (i >= total) {
                    if (list.length <= 0) { onResolve([]); return; }
                    // checks the order that participants are to be loaded.
                    switch (typeOrder) {
                        case 'asc': //a-z
                            list.sort(function (a, b) {
                                return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
                            });
                            break;
                        case 'desc': //z-a
                            list.sort(function (a, b) {
                                return a.queryName > b.queryName ? -1 : a.queryName < b.queryName ? 1 : 0;
                            });
                            break;
                        case 'oldest'://antiho-recente
                            list.sort(function (a, b) {
                                return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
                            });
                            break;
                        case 'recent': //recente-antigo
                            list.sort(function (a, b) {
                                return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                            });
                            break;
                        case 'id': //ID
                            list.sort(function (a, b) {
                                return a.identifier < b.identifier ? -1 : a.identifier > b.identifier ? 1 : 0;
                            });
                            break;
                    }
                    onResolve(list);
                }
            })
        }
    }

    /******************************************************************************** methods of limited access by groups *************************************************************************************** */
    // loads the first 100 participants of the module. 
    getFirstAttendeesLimitedAccessByGroup(typeOrder, moduleId, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), limit(100)];
                break;
            case 'id': //ID
                qc = [orderBy('identifier'), limit(100)];
                break;
            default:
                onResolve([]);
                return;
        }

        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    // get all attendees of the module
    getAttendeesAllLimitedAccessByGroup(moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // get plus 100 attendees module.
    getNextPageAttendeesLimitedAccessByGroup(moduleId, lastAttendee, typeOrder, onResolve) {
        const ref = collection(this.firestore, `modules/${moduleId}/attendees`);
        let qc: QueryConstraint[];

        // checks the order that participants are to be loaded.
        switch (typeOrder) {
            case 'asc': //a-z
                qc = [orderBy('queryName', 'asc'), startAfter(lastAttendee.queryName), limit(100)];
                break;
            case 'desc': //z-a
                qc = [orderBy('queryName', 'desc'), startAfter(lastAttendee.queryName), limit(100)];
                break;
            case 'oldest'://antiho-recente
                qc = [orderBy('createdAt', 'asc'), startAfter(lastAttendee.createdAt), limit(100)];
                break;
            case 'recent': //recente-antigo
                qc = [orderBy('createdAt', 'desc'), startAfter(lastAttendee.createdAt), limit(100)];
                break;
            case 'id': //ID
                qc = [orderBy('identifier'), startAfter(lastAttendee.identifier), limit(100)];
                break;
            default:
                return;
        }

        const refQ = query(ref, ...qc);
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }


    getAttendeePrincipalTitle(title) {
        const language = this.SEventData.getLanguage();
        return title[language];
    }

    /**
     * Init new access for attendee
     */
    initAccessModule() {
        let access: IAccessModule = {
            feedNews: null
        }
        return (access);
    }

    /**
     * Update access to module for attendee
     * @param eventId 
     * @param attendeeId 
     * @param newAccess 
     */
    updateAccessModule(eventId: string, attendeeId: string, newAccess: IAccessModule) {
        const ref = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}`);
        return updateDoc(ref, { access: newAccess });
    }

    /**
     * Fetches all attendees associated with a specific event from Firestore.
     * 
     * @param eventId - The unique identifier of the event.
     * @returns A collection of attendee documents associated with the event.
     * 
     */
    fetchAttendeesByEvent(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/attendees`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}
