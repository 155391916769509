import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService } from 'src/app/shared/services';
import { 
    Firestore, 
    collection,
    query,
    QueryConstraint,
    where,
    orderBy,
    collectionData, 
} from '@angular/fire/firestore';
import { TypeUser } from 'src/app/models/type-user';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDateService } from 'src/app/providers/date/notification-date.service';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';
import { TypeModule } from 'src/app/enums/type-module';
import { EventColors } from 'src/app/models/event-colors';
import { Subscription, takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { ActiveMenuIconsService } from 'src/app/shared/services/active-menu-icons/active-menu-icons.service';
import { LanguageService } from 'src/app/providers/language/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { NotificationsService } from 'src/app/providers/notifications.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    eventColors: EventColors = new EventColors();
    eventId: string = null;

    viewAllNotifications: boolean = true;
    viewNotificationDetail: boolean = false;
    notifications: Array<any> = [];
    notificationDetail: any = null;

    dateNow;
    languageUser: string = null;
    userType: number;
    subscriptions: Subscription[] = [];
    lang: string;
    
    constructor(
        public global: GlobalService,
        private modalCtrl: ModalController,
        private firestore: Firestore,
        private events: Events,
        // private navParams: NavParams,
        private translateService: TranslateService,
        private notificationDate: NotificationDateService,
        private daoGeral: DaoGeralService,
        private Slogout: LogoutService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private SActiveMenuIcons: ActiveMenuIconsService,
        private languageService: LanguageService,
        private route: ActivatedRoute,
        private SDateTime: DateTimeService,
        public SNotif: NotificationsService
    ) {
        this.eventId = this.route.snapshot.params.eventId;
        this.loadColors();
        
        this.SNotif.getListener(this.eventId).subscribe((notifs) => {
            this.notifications = notifs;
        });
        this.lang = this.SEventData.getLanguage(true);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        const popUpParams = {
            closeHandler: () => { 
                this.modalCtrl.dismiss();
                this.SActiveMenuIcons.previousActiveIcon();
            }, 
            redirectHandler: () => { this.modalCtrl.dismiss(); }
        };

        this.languageUser = this.languageService.userDeviceLanguage.tag;
        // this.startAllNotifications();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    // async startAllNotifications() {
    //     const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
    //     if (!eventData) return;
        
    //     const ref = collection(this.firestore, `events/${this.eventId}/notifications`);
    //     collectionData(ref).
    //     // // const qc: QueryConstraint[] = [
    //     // //     where('delivery_date', '<=', this.), 
    //     // //     orderBy('delivery_date', 'desc')
    //     // // ];
    //     // const refQ = query(ref, ...qc);

    //     this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
    //         this.dateNow = this.notificationDate.getTimeStampFromDateNow(new Date(), eventData.timezone);
        
    //         const ref = collection(this.firestore, `events/${this.eventId}/notifications`);
    //         const qc: QueryConstraint[] = [where('delivery_date', '<=', this.dateNow), orderBy('delivery_date', 'desc')];
    //         const refQ = query(ref, ...qc);

    //         collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((data: any) => {
    //             if (data.length <= this.notifications.length || data.length >= this.notifications.length) {
    //                 this.notifications = [];
    //                 const popUpParams = {
    //                     closeHandler: () => { 
    //                         this.modalCtrl.dismiss();
    //                         this.SActiveMenuIcons.previousActiveIcon();
    //                     }, 
    //                     redirectHandler: () => { this.modalCtrl.dismiss(); }
    //                 };

    //                 this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
    //                     if (userData.type >= TypeUser.SUPERGOD && userData.type <= TypeUser.EMPLOYEE) {
    //                         data.forEach(notification => {
    //                             if (notification.delivery_date > userData.notification_last_time) {
    //                                 notification.header = this.translateService.instant('pages.notifications.not_read');
    //                             } else if (notification.delivery_date <= userData.notification_last_time) {
    //                                 notification.header = this.translateService.instant('pages.notifications.read');
    //                             }
    //                             notification.userLastAccess = userData.notification_last_time;
    //                             // notification.delivery_date = this.date.convertTimestampToDateWithoutZone(notification.delivery_date);
    //                             this.notifications.push(this.instantiateNotification(notification));
    //                         });
    //                     } else {
    //                         data.forEach(notification => {
    //                             if (notification.send_to == 'all') {
    //                                 if (notification.delivery_date > userData.notification_last_time) {
    //                                     notification.header = this.translateService.instant('pages.notifications.not_read');
    //                                 } else if (notification.delivery_date <= userData.notification_last_time) {
    //                                     notification.header = this.translateService.instant('pages.notifications.read');
    //                                 }
    //                                 notification.userLastAccess = userData.notification_last_time;
    //                                 // notification.delivery_date = this.date.convertTimestampToDateWithoutZone(notification.delivery_date);
    //                                 const userId = this.SUserData.userId;
    //                                 if(notification.forModule === -1 || notification.forModule === undefined){
    //                                     this.notifications.push(this.instantiateNotification(notification));
    //                                 } else if(notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(userId)){
    //                                     this.notifications.push(this.instantiateNotification(notification));
    //                                 } else if(notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(userId)){
    //                                     this.notifications.push(this.instantiateNotification(notification));
    //                                 } 
                                        
    //                                 // this.notifications.push(this.instantiateNotification(notification));
    //                             } else {
    //                                 /** verify group and verifiy if user have allowed view group
    //                                  * case have, display notification, else not display
    //                                 */
    //                                 for (let index in notification.groups_ids) {
    //                                     const pos = this.global.groupsAttendees.map(function (e) { return e.uid; }).indexOf(notification.groups_ids[index]);
    //                                     if (pos >= 0) {
    //                                         if (notification.delivery_date > userData.notification_last_time) {
    //                                             notification.header = this.translateService.instant('pages.notifications.not_read');
    //                                         } else if (notification.delivery_date <= userData.notification_last_time) {
    //                                             notification.header = this.translateService.instant('pages.notifications.read');
    //                                         }
    //                                         notification.userLastAccess = userData.notification_last_time;
    //                                         // notification.delivery_date = this.date.convertTimestampToDateWithoutZone(notification.delivery_date);
    //                                         this.notifications.push(this.instantiateNotification(notification));
    //                                         break;
    //                                     }
    //                                 }
    //                             }
    //                         });
    //                     }
    //                 }).catch((error) => {
    //                     let noUserNotificationDate = localStorage.getItem('notificationTime');
    //                     data.forEach(notification => {
    //                         if (notification.send_to == 'all') {
    //                             if (notification.delivery_date > noUserNotificationDate || (noUserNotificationDate == null || noUserNotificationDate == undefined)) {
    //                                 notification.header = this.translateService.instant('pages.notifications.not_read');
    //                             } else if (notification.delivery_date <= noUserNotificationDate) {
    //                                 notification.header = this.translateService.instant('pages.notifications.read');
    //                             }
    //                             notification.userLastAccess = noUserNotificationDate;
    //                             const userId = this.SUserData.userId;
    //                             if(notification.forModule === -1 || notification.forModule === undefined){
    //                                 this.notifications.push(this.instantiateNotification(notification));
    //                             } else if(notification.forModule === TypeModule.PERSONALSCHEDULE && notification.users_ids.includes(userId)){
    //                                 this.notifications.push(this.instantiateNotification(notification));
    //                             } else if(notification.forModule === TypeModule.APPOINTMENTS && notification.users_ids.includes(userId)){
    //                                 this.notifications.push(this.instantiateNotification(notification));
    //                             } 
    //                             // notification.delivery_date = this.date.convertTimestampToDateWithoutZone(notification.delivery_date);
    //                             // this.notifications.push(this.instantiateNotification(notification));
    //                         }
    //                     });
    //                 });
    //             }
    //         });
    //     });
    // }

    instantiateNotification(notification) {
        notification["principal_title"] = "";
        notification["principal_msg"] = "";
        notification["principal_description"] = "";

        switch (this.languageUser) {
            case 'pt-BR':
                notification["principal_title"] = notification.headings["pt"] !== "" ? notification.headings["pt"] : this.getFirstNotificationContent(notification.headings);
                notification["principal_msg"] = notification.contents["pt"] !== "" ? notification.contents["pt"] : this.getFirstNotificationContent(notification.contents);
                break;
            case 'en-US':
                notification["principal_title"] = notification.headings["en"] !== "" ? notification.headings["en"] : this.getFirstNotificationContent(notification.headings);
                notification["principal_msg"] = notification.contents["en"] !== "" ? notification.contents["en"] : this.getFirstNotificationContent(notification.contents);
                break;
            case 'es-ES':
                notification["principal_title"] = notification.headings["es"] !== "" ? notification.headings["es"] : this.getFirstNotificationContent(notification.headings);
                notification["principal_msg"] = notification.contents["es"] !== "" ? notification.contents["es"] : this.getFirstNotificationContent(notification.contents);
                break;
            case 'fr-FR':
                notification["principal_title"] = notification.headings["fr"] !== "" ? notification.headings["fr"] : this.getFirstNotificationContent(notification.headings);
                notification["principal_msg"] = notification.contents["fr"] !== "" ? notification.contents["fr"] : this.getFirstNotificationContent(notification.contents);
                break;
            case 'de-DE':
                notification["principal_title"] = notification.headings["de"] !== "" ? notification.headings["de"] : this.getFirstNotificationContent(notification.headings);
                notification["principal_msg"] = notification.contents["de"] !== "" ? notification.contents["de"] : this.getFirstNotificationContent(notification.contents);
                break;
        }

        if (notification.descriptions) {
            notification["principal_description"] = notification.descriptions[this.languageUser] !== "" ? notification.descriptions[this.languageUser] : this.getFirstNotificationContent(notification.descriptions);
        }

        return notification;
    }

    getFirstNotificationContent(content: Object) {
        for (const key in content) {
            if (content[key] !== '') {
                return content[key];
            }
        }
    }

    notificationHeader(notification, index, notifications) {
        if (index == 0) {
            return notification.header;
        } else if (index != 0 && notification.header != notifications[index - 1].header) {
            return notification.header;
        }
    }

    

    notificationOpenDetail(notification) {
        this.SNotif.addViewedNotif(notification.uid);
        this.notificationDetail = notification;
        this.viewAllNotifications = false;
        this.viewNotificationDetail = true;
    }

    backAllNotifications() {
        this.viewNotificationDetail = false;
        this.viewAllNotifications = true;
        this.notificationDetail = null;
    }


    unsubscribe() {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            localStorage.setItem('notificationTime', this.dateNow);
            this.daoGeral.updateUserGeneral(
                { notification_last_time: this.dateNow }, 
                userData.uid, 
                this.eventId, 
                userData.type
            );
        });

        this.global.updateNotificationBadge(0, 'notify');
        this.events.publish('clearNotificationBadge');
        this.events.publish('updateChatBadge');
        this.events.publish('clearBadgePropertie');
        this.backAllNotifications();
    }

    ionViewWillLeave() {
        // this.unsubscribe();
    }
}
