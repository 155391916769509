export const environment = {
	production: true,

	firebase: {
		apiKey: "AIzaSyAcX-jtB_3xTy5fJtNXlfSeP9LUGVrcfvs",
        authDomain: "high5event-a48b5.firebaseapp.com",
        projectId: "high5event-a48b5",
        storageBucket: "high5event-a48b5.appspot.com",
        messagingSenderId: "735681106251",
        appId: "1:735681106251:web:60989e94db77e7eb6b58ce"
	},
	platform: {
		appName: "Fivents",
		apiBaseUrl: "https://us-central1-high5event-a48b5.cloudfunctions.net/",
		oneSignalAppId: "",
		defaultLanguage: "EnUS",
		oldDefaultLanguage: "en_US",
		defaultTimezone: "Europe/Paris",
		appBaseUrl: "https://app.high5event.com/",
		loginLogo: "logo.png",
		loginLogoClass: "", // options is 'banner-logo' or 'invent-logo'
		showProfileText: false,
		publicEvent: false,
		eventId: "", // case public event
		containerId: "defaultContainer", // case app is event manager with multiple events
		isClientApp: false, // case client app, set true to remove public events button
		bambuserApplicationId: "",
		bambuserApiKey: ""
	}
}