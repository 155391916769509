import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { 
    Firestore, 
    doc,
    docData,
    collection,
    query,
    orderBy,
    collectionData, 
} from '@angular/fire/firestore';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { LightboxImgComponent } from 'src/app/components/lightbox-img/lightbox-img.component';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { EventColors } from 'src/app/models/event-colors';
import { Subscription } from 'rxjs';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-maps-list',
    templateUrl: './maps-list.page.html',
    styleUrls: ['./maps-list.page.scss'],
})
export class MapsListPage implements OnInit, OnDestroy {
    public module = null
    loader: boolean = true;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    eventId: string = null;
    moduleId: string = null;
    mapsList: Array<any> = [];
    typeOrder: string = null;
    backBtn: boolean = true;
    allowButtonsHeader: boolean = false;
    searchOpen: boolean = false;
    subscriptions: Subscription[] = [];
    language: string;
    isMobile: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public global: GlobalService,
        private firestore: Firestore,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private router: Router,
        private modalCtrl: ModalController,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        // Analytics tracking
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_PLANS_MODULE);

        this.loadColors();

        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;

        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });

        const ref = doc(this.firestore, `modules/${this.moduleId}`);
        const subscription = docData(ref).subscribe((data: any) => {
            this.module = data;
            this.typeOrder = data.orderMaps;
            this.start();
        });

        this.subscriptions.push(subscription);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    // list maps
    start() {
        const ref = collection(this.firestore, `modules/${this.moduleId}/maps`);
        let refQ;

        switch (this.typeOrder) {
            case 'asc': //a-z
                refQ = query(ref, orderBy('name', 'asc'));
                break;

            case 'desc': //z-a
                refQ = query(ref, orderBy('name', 'desc'));
                break;

            case 'oldest'://antiho-recente
                refQ = query(ref, orderBy('createdAt', 'asc'));
                break;

            case 'recent': //recente-antigo
                refQ = query(ref, orderBy('createdAt', 'desc'));
                break;
            default:
                refQ = ref;
                break;
        }
        
        const subscription = collectionData(refQ).subscribe((data: any) => {
            this.mapsList = [];
            this.mapsList = data;
            this.loader = false;
        });

        this.subscriptions.push(subscription);
    }

    // zoom profile picture image
    zoomImg(url: string) {
        // this.photoViewer.show(url, '', { share: false });
        this.modalCtrl.create({
            component: LightboxImgComponent,
            componentProps: {
                img: url
            }
        }).then(modal => {
            modal.present();
        });
    }
}
