import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Post } from '../../models/ceu-post';
import { map, takeUntil, tap } from 'rxjs/operators';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { Firestore, collection, collectionData, doc, docData, limit, orderBy, query, where, writeBatch } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root'
})
export class DaoFeedNewsService {

    constructor(
        private storage: StorageService,
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    moduleNewsFeed(moduleId) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * Create an id for a post
     */
    createIdPost() {
        return doc(collection(this.firestore, "_")).id
    }

    getPostsNewsFeed(moduleId: string, feedLimit: number) {
        const ref = collection(this.firestore, `modules/${moduleId}/posts`);
        const refQ = query(ref, ...[orderBy('fixedTop'), orderBy('date', 'desc'), limit(feedLimit)]);

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    getPost(moduleId: string, postId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}/posts/${postId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    updatePost(post, eventId: string) {
        const moduleId = post.moduleId;
        const postId = post.uid;

        const batch = writeBatch(this.firestore);

        const ref1 = doc(this.firestore, `modules/${moduleId}/posts/${postId}`);
        const ref2 = doc(this.firestore, `events/${eventId}/feed-posts/${postId}`);

        batch.update(ref1, Object.assign({}, post));
        batch.update(ref2, Object.assign({}, post));

        return batch.commit();
    }

    createPost(post, eventId: string) {
        const batch = writeBatch(this.firestore);

        const moduleId = post.moduleId;
        const postId = post.uid;

        const ref1 = doc(this.firestore, `modules/${moduleId}/posts/${postId}`);
        const ref2 = doc(this.firestore, `events/${eventId}/feed-posts/${postId}`);

        batch.set(ref1, Object.assign({}, post));
        batch.set(ref2, Object.assign({}, post));

        return batch.commit();
    }

    deletePost(post: Post, eventId: string) {
        const batch = writeBatch(this.firestore);

        const moduleId = post.moduleId;
        const postId = post.uid;

        const ref1 = doc(this.firestore, `modules/${moduleId}/posts/${postId}`);
        const ref2 = doc(this.firestore, `events/${eventId}/feed-posts/${postId}`);

        batch.delete(ref1);
        batch.delete(ref2);

        // delete img storarge
        if (post.img.url.length > 0) {
            this.storage.deletePost(post.uid, eventId, post.moduleId)
        }

        return batch.commit();
    }

    /**
     * Get posts on a feed
     * @param eventId 
     * @param moduleId 
     */
    getNewsFeedsPost(eventId: string, moduleId: string) {
        const ref = collection(this.firestore, `events/${eventId}/feed-posts`);
        const refQ = query(ref, where('moduleId', '==', moduleId));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }
}
