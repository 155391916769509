import {
	Component,
	ViewEncapsulation,
	HostListener,
	OnInit,
	ViewChild,
    OnDestroy
} from "@angular/core";
import { DaoWidgetsService } from "src/app/providers/db/dao-widgets.service";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { LoadingController, MenuController, ToastController, ModalController, Platform } from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService, AnalyticsService } from "src/app/shared/services";
import { 
    Firestore,
    collection,
    query,
    orderBy,
    collectionData, 
} from '@angular/fire/firestore';
import { TypeUser } from "src/app/models/type-user";
import { TypeVisionGroup } from "src/app/enums/type-vision-group";
import { ModuleWidget } from "src/app/models/module-widget";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/reducers";
import { of, Subscription } from "rxjs";
import { getConnectionStatus } from "src/app/shared/selectors/utility.selectors";
import { map, mergeMap, take } from "rxjs/operators";
import { getUserData } from "src/app/shared/selectors/user.selectors";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import { DaoScheduleService } from "src/app/providers/db/dao-schedule.service";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { TypeVisionModule } from "src/app/models/type-vision-module";
import { TypeModule } from "src/app/enums/type-module";
import { DaoSessionFeedbackService } from "src/app/providers/db/dao-session-feedback.service";
import { DaoModulesService } from "src/app/providers/db/dao-modules.service";
import { TypeTracking } from "src/app/enums/type-tracking";
import { DomSanitizer } from "@angular/platform-browser";
import { PathComponent } from "src/app/models/path/path-components";
import { InAppBrowserService } from 'src/app/providers/in-app-browser/in-app-browser.service';
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { ActiveMenuIconsService } from "src/app/shared/services/active-menu-icons/active-menu-icons.service";

@Component({
	selector: "app-widgets",
	templateUrl: "./widgets.page.html",
	styleUrls: ["./widgets.page.scss"],
	encapsulation: ViewEncapsulation.None
})
export class WidgetsPage implements OnInit, OnDestroy {
	public module: ModuleWidget;
	subscriptions: Subscription[] = [];

	eventId: string = null;
	moduleId: string = null;
	mobileWidgets: any[] = [];
	desktopWidgets: any[] = [];
	loader: boolean = true;
	container: any;
	event = null;
	backBtn: boolean = true;
	allowButtonsHeader: boolean = false;
	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;
	flexerWidth: any = null;
	interval: any;
	allowLoad: boolean = true;
	userId: any = null; //Saves the uid of the logged in user.
	// typeUser: any = null; //Saves the type of logged in user.
	intervalWidgets;
	language: string;

	moduleBackgroundColor: string = "#F6F6F6";
	moduleBackgroundImage: string = "";
	moduleBackgroundImageDesktop: string = "";
	moduleBackgroundTypeImage: boolean = false;

	connectionStatus: string = "PENDING";
	isMobile: boolean = false;
	activated = true;
	desktopIframes = [];
	mobileIframes = [];

	@ViewChild("grid") grid: any;
	constructor(
		private dbWidgets: DaoWidgetsService,
		private route: ActivatedRoute,
		public loadingCtrl: LoadingController,
		public global: GlobalService,
		private firestore: Firestore,
		private events: Events,
		private menuCtrl: MenuController,
		private SAnalytics: AnalyticsService,
		private router: Router,
		private toastCtrl: ToastController,
		private store: Store<AppState>,
		private dbSpeakers: DaoSpeakersService,
		private dbSchedule: DaoScheduleService,
		private dbAttendees: DaoAttendeesService,
		private daoFeedback: DaoSessionFeedbackService,
		private dbModules: DaoModulesService,
		public sanitizer: DomSanitizer,
		public modalCtrl: ModalController,
        private inAppBrowser: InAppBrowserService,
        private eventData: EventDataService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
		private platform: Platform,
		private SActiveMenuIcons: ActiveMenuIconsService
	) {
		// Subscription on connection status
		this.store.select(getConnectionStatus).subscribe((networkStatus) => {
			if (this.connectionStatus == "OFFLINE" && networkStatus == "ONLINE") {
				this.initWidget();
			}

			this.connectionStatus = networkStatus;
		});

		// this.events.subscribe("languageUpdate", () => {
		// 	this.initWidget();
		// });

		this.events.subscribe("menuBadge", () => {
			this.menuBadge = this.global.notificationBadge;
		});

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        this.language = this.SEventData.getLanguage();

        // Analytics tracking
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_WIDGET_MODULE);

        this.loadColors();
        this.menuBadge = this.global.notificationBadge;
        this.initWidget();

		// this.subscriptions.push(
		//     this.gridWidth.subscribe(value => {
		//         console.log('gridWidth: ', value)
		//         document.body.style.setProperty('--height025', `${value / 8 - 18}px`)
		//         document.body.style.setProperty('--height05', `${value / 4 - 18}px`)
		//         document.body.style.setProperty('--height1', `${value / 1 - 18}px`)
		//     })
		// )
		this.subscriptions.push(
			this.global.hamburgerActivated.subscribe((activated) => {
				this.activated = activated;
				// console.log(this.grid.el.clientWidth)
				// this.gridWidth.next(this.grid.el.clientWidth)
				// const height05 = this.grid.el.clientWidth / 4 - 18
			})
		);
	}
	// gridWidth: BehaviorSubject<number> = new BehaviorSubject(0)
	
    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

	@HostListener("window:resize", ["$event"])
	onResize() {
		setTimeout(() => {
			// this.isMobile = window.innerWidth < 768;
			let element = document.getElementsByClassName("flexer");
			let size = element.length - 1;
			if (element[size].clientWidth > 0 && element[size].clientWidth !== this.flexerWidth) {
				this.loadWidgets();
			}
		}, 200);
	}

	ngOnInit() {
		if (this.platform.is('ios') || this.platform.is('android')) {
			this.isMobile = true;
		}

		this.menuCtrl.enable(true);
        const els = document.getElementsByClassName("col-025row-expand");
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	initWidget() {
		if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}
		this.userId = this.SUserData.userId;
		// get widget module
		this.dbWidgets.getModule(this.moduleId, (module: ModuleWidget) => {
			this.module = module;
			if (this.module) {
				this.module.moduleBackgroundTypeImage = this.module.moduleBackgroundTypeImage
					? this.module.moduleBackgroundTypeImage
					: this.moduleBackgroundTypeImage;
				this.module.moduleBackgroundImage = this.module.moduleBackgroundImage
					? this.module.moduleBackgroundImage
					: this.moduleBackgroundImage;
				this.module.moduleBackgroundImageDesktop = this.module.moduleBackgroundImageDesktop
					? this.module.moduleBackgroundImageDesktop
					: this.moduleBackgroundImageDesktop;
				this.module.moduleBackgroundColor = this.module.moduleBackgroundColor
					? this.module.moduleBackgroundColor
					: this.moduleBackgroundColor;
			}
		});

		// if (this.router.url == this.SEventData.eventData.homePage) {
		// 	this.allowButtonsHeader = true;
		// }
		this.loadEvent();
		this.loadWidgets();
	}

	// get background color of this module
	getBackground() {
		let style = {};
		style = { "background-color": "#F6F6F6" };
		if (this.module !== undefined) {
			const image =
				window.innerWidth < 768
					? this.module["moduleBackgroundImage"]
					: this.module["moduleBackgroundImageDesktop"];
			if (this.module["moduleBackgroundTypeImage"]) {
				style = {
					"background-image": "url('" + image + "')",
					"background-size": "cover",
					"min-height": "100vh",
					"background-repeat": "no-repeat"
				};
			} else {
				if (!this.module["moduleBackgroundIsDegrade"]) {
					style = {
						"background-color": this.module["moduleBackgroundColor"],
						"min-height": "100vh"
					};
				} else {
					style = {
						background:
							"linear-gradient(to right, " +
							this.module["moduleBackgroundColor"] +
							"," +
							this.module["moduleBackgroundColorSecondary"] +
							")",
						"min-height": "100vh"
					};
				}
			}
		}
		return style;
	}

	async presentLanguage(a) {
		const toast = await this.toastCtrl.create({
			message: a,
			duration: 5000
		});
		toast.present();
	}

	loadEvent() {
        const subscription = this.eventData.getEventDataListener(this.eventId).subscribe((data: any) => {
			this.event = data;
		});

        this.subscriptions.push(subscription);
	}

	// load all widgets
	async loadWidgets() {
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
        this.userId = userData.uid;

        const ref = collection(this.firestore, `modules/${this.moduleId}/widgets`);
        const refQ = query(ref, orderBy("order", "asc"));

        const subscription = collectionData(refQ).pipe(map((widgets) => {
            const element = document.getElementsByClassName("flexer");
            const size = element.length - 1;
            this.flexerWidth = (
                element && 
                element[size] && 
                (element[size].clientWidth > 0) ? 
                    element[size].clientWidth : this.flexerWidth
            );
            return widgets.filter((w) => {
                if (
                    w.typeVision == TypeVisionGroup.GLOBAL_VISION ||
                    w.typeVision == TypeVisionGroup.GROUP_ACCESS_PERMISSION &&
                    w.groups &&
                    userData &&
                    userData.groups &&
                    Object.keys(w.groups).some(g => userData.groups[g])
                ) {
                    if (this.flexerWidth <= 0) { return false; }

                    if (!w.gridArea) {
                        if (w.proportion.includes("col-1row")) {
                            w.finalHeight = this.flexerWidth / 2;
                        } else if (w.proportion.includes("col-2row")) {
                            w.finalHeight = this.flexerWidth;
                        } else if (w.proportion.includes("col-05row")) {
                            w.finalHeight = this.flexerWidth / 4;
                        }
                    } else {
                        const proportion = this.scaleProportion(w.proportion);
                        if (proportion.includes("col-05row")) {
                            w.finalHeight = this.flexerWidth / 4;
                        } else if (proportion.includes("col-1row")) {
                            w.finalHeight = this.flexerWidth / 2;
                        } else if (proportion.includes("col-025row")) {
                            w.finalHeight = this.flexerWidth / 8;
                        }
                    }

                    w.finalHeight -= 17;    // Substract 17px in any case

                    if (w.route.includes("personal-page")) {
                        // case route is personal page, redirect to profile 
                        // (SUPERGOD/GOD/CLIENT DOESN'T HAVE A PROFILE)
                        if (userData.type && userData.type >= 4) {
                            w.route = `/event/${this.eventId}/personal-page/${userData.moduleId}/${userData.type}/${userData.uid}`;
                        } else {
                            w.route = `/event/${this.eventId}/widget/${this.moduleId}`;
                        }
                    } else if (w.isExtern) {
                        const pattern = new RegExp("^(https?|ftp)://");
                        if (!pattern.test(w.route)) w.route = "https://" + w.route;
                    }
                    return true;
                }
                return false;
            });
        })).subscribe((val) => {
            this.desktopWidgets = [...val].filter((w) => w.gridArea !== undefined && w.gridArea !== null);
            this.mobileWidgets = [...val].filter((w) => w.gridArea == undefined || w.gridArea == null);
            this.desktopWidgets.forEach((w) => (this.desktopIframes[w.uid] = w.iframeSrc));
            this.mobileWidgets.forEach((w) => (this.mobileIframes[w.uid] = w.iframeSrc));
        });
        this.subscriptions.push(subscription);
	}

	// open external link
	openLink(url: string) {
        this.inAppBrowser.openLink(url);
	}

	ionViewWillLeave() {
		this.allowLoad = true;
	}

	openWidget(widget) {
		let splitedRoute = widget.route.split("/");
		if (
			splitedRoute.includes("attendees") ||
			splitedRoute.includes("schedule") ||
			splitedRoute.includes("speakers")
		) {
			this.dbModules
				.getModule(splitedRoute[4])
				.pipe(take(1))
				.subscribe((module) => {
					this.checkNumberOfSub(module, widget);
				});
		} else {
			if (widget.route === "notification") {
				this.openNotifications();
			} 
            // else if (widget.route === "PublicRegister") {
				// this.openPublicRegister();
			// } 
            // else if (widget.route === "PublicLogin") {
			// 	this.openPublicLogin();
			// }
            else {
				this.basicModuleRouting(widget);
			}
		}
	}
	// // login evento público
	// async openPublicLogin() {
	// 	const modal = await this.modalCtrl.create({
	// 		component: PathComponent.public_login,
	// 		componentProps: {
	// 			eventId: this.eventId,
	// 			logoUrl: this.SEventData.eventData.logo.url,
	// 			menuColor: this.eventColors.menu_color,
	// 			menuTxtColor: this.eventColors.menu_text_color
	// 		}
	// 	});
	// 	return await modal.present();
	// }

	// registro evento público
	// async openPublicRegister() {
	// 	const modal = await this.modalCtrl.create({
	// 		component: PathComponent.public_register,
	// 		componentProps: {
	// 			eventId: this.eventId,
	// 			logoUrl: this.SEventData.eventData.logo.url,
	// 			moduleId: this.moduleId,
	// 			typeUser: this.typeUser,
	// 			registerFirstTime: this.SEventData.eventData.firstRegistration
	// 		}
	// 	});
	// 	return await modal.present();
	// }

	async openNotifications() {
		this.router.navigate([`event/${this.eventId}/notifications`]);
        this.SActiveMenuIcons.changeActiveIcon('notif');
		// const modal = await this.modalCtrl.create({
		// 	component: NotificationsComponent,
		// 	componentProps: {
		// 		eventId: this.eventId
		// 	}
		// });
		// return await modal.present();
	}

	basicModuleRouting(widget) {
		this.router.navigate([widget.route]);
	}

	routingToSessionDirectly(moduleId, sessionId, sessionFeedbackModule) {
		let navigationExtras: NavigationExtras = {
			state: {
				sessionFeedbackModule: sessionFeedbackModule
			}
		};
		this.router.navigate([`/event/${this.eventId}/schedule-detail/${moduleId}/${sessionId}`], navigationExtras);
	}

	routingToSpeakerDirectly(moduleId, speaker) {
		let navigationExtras: NavigationExtras = {
			state: {
				user: speaker
			}
		};
		this.router.navigate([`/event/${this.eventId}/profile/${moduleId}/4/${speaker.uid}`], navigationExtras);
	}

	routingToAttendeeDirectly(moduleId, attendee) {
		let navigationExtras: NavigationExtras = {
			state: {
				user: attendee
			}
		};
		this.router.navigate(
			[`/event/${this.eventId}/profile/${moduleId}/${attendee.type}/${attendee.uid}`],
			navigationExtras
		);
	}

	/**
	 * Check number of sesions/speaker/orators
	 * @param module
	 */
	async checkNumberOfSub(module, widget) {
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);

		if (module.type == TypeModule.SCHEDULE) {
			this.dbSchedule.closeRefGetSessionsGroupsVision();

			this.daoFeedback.getFeedbackModule(this.SEventData.eventId, async (modules) => {
				let sessionFeedbackModule = null;
				if (modules.length >= 1) {
					sessionFeedbackModule = modules[0].uid;
				}

				// verifies the type of vision of the module. global vision
				if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
					this.dbSchedule
						.getAllSessionsVisionGlobal(module.uid)
						.pipe(take(1))
						.subscribe((sessions) => {
							if (sessions.length == 1) {
								this.routingToSessionDirectly(module.uid, sessions[0].uid, sessionFeedbackModule);
							} else {
								this.basicModuleRouting(widget);
							}
						});
				}

				// group vision
				if (module.typeVision === TypeVisionModule.GROUP_VISION) {
					let groups = this.global.groupsAttendees
						? this.global.groupsAttendees
						: await this.global.loadGroups(userData.uid, userData.type, this.eventId);
					this.dbSchedule
						.getSessionsGroupsVision(groups, module.uid)
						.pipe(take(1))
						.subscribe((data) => {
							if (data["sessions"].length === 1) {
								this.routingToSessionDirectly(
									module.uid,
									data["sessions"][0].uid,
									sessionFeedbackModule
								);
							} else {
								this.basicModuleRouting(widget);
							}
						});
				}

				//limited access by groups
				if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
					this.dbSchedule
						.getAllSessionsLimitedAccessByGroup(module.uid)
						.pipe(take(1))
						.subscribe((sessions) => {
							if (sessions.length == 1) {
								this.routingToSessionDirectly(module.uid, sessions[0].uid, sessionFeedbackModule);
							} else {
								this.basicModuleRouting(widget);
							}
						});
				}
			});
		} else if (module.type == TypeModule.SPEAKER) {
			// verifies the type of vision of the module. global vision
			if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
				this.dbSpeakers
					.getSpeakersAllGlobalVision(module.uid)
					.pipe(take(1))
					.subscribe((speakers) => {
						if (speakers.length == 1) {
							this.routingToSpeakerDirectly(module.uid, speakers[0]);
						} else {
							this.basicModuleRouting(widget);
						}
					});
			}

			// group vision
			if (module.typeVision === TypeVisionModule.GROUP_VISION) {
				let groups = this.global.groupsAttendees
					? this.global.groupsAttendees
					: await this.global.loadGroups(userData.uid, userData.type, this.eventId);
				this.dbSpeakers.getSpeakersGroupsVision(groups, "asc", module.uid, (speakers) => {
					if (speakers.length == 1) {
						this.routingToSpeakerDirectly(module.uid, speakers[0]);
					} else {
						this.basicModuleRouting(widget);
					}
				});
			}

			//limited access by groups
			if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
				this.dbSpeakers
					.getSpeakersAllLimitedAccessByGroup(module.uid)
					.pipe(take(1))
					.subscribe((speakers) => {
						if (speakers.length == 1) {
							this.routingToSpeakerDirectly(module.uid, speakers[0]);
						} else {
							this.basicModuleRouting(widget);
						}
					});
			}
		} else if (module.type == TypeModule.ATTENDEE) {
			// verifies the type of vision of the module. global vision
			if (module.typeVision === TypeVisionModule.GLOBAL_VISION) {
				this.dbAttendees
					.getAttendeesAllGlobalVision(module.uid)
					.pipe(take(1))
					.subscribe((attendees) => {
						if (attendees.length == 1) {
							this.routingToAttendeeDirectly(module.uid, attendees[0]);
						} else {
							this.basicModuleRouting(widget);
						}
					});
			}

			// group vision
			if (module.typeVision === TypeVisionModule.GROUP_VISION) {
				let groups = this.global.groupsAttendees
					? this.global.groupsAttendees
					: await this.global.loadGroups(userData.uid, userData.type, this.eventId);
				this.dbAttendees.getAttendeesGroupsVision(groups, "asc", module.uid, (attendees) => {
					if (attendees.length == 1) {
						this.routingToAttendeeDirectly(module.uid, attendees[0]);
					} else {
						this.basicModuleRouting(widget);
					}
				});
			}

			//limited access by groups
			if (module.typeVision === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
				this.dbAttendees
					.getAttendeesAllLimitedAccessByGroup(module.uid)
					.pipe(take(1))
					.subscribe((attendees) => {
						if (attendees.length == 1) {
							this.routingToAttendeeDirectly(module.uid, attendees[0]);
						} else {
							this.basicModuleRouting(widget);
						}
					});
			}
		} else {
			this.basicModuleRouting(widget);
		}
	}

	scaleProportion(proportion: string) {
		switch (proportion) {
			case "col-12 col-1row":
				return this.activated ? "col-6 col-05row" : "col-6 col-05row-expand";
			case "col-6 col-1row":
				return this.activated ? "col-3 col-05row" : "col-3 col-05row-expand";
			case "col-6 col-2row":
				return this.activated ? "col-3 col-1row" : "col-3 col-1row-expand";
			case "col-12 col-2row":
				return this.activated ? "col-6 col-1row" : "col-6 col-1row-expand";
			case "col-6 col-1row col-rounded":
				return this.activated ? "col-3 col-05row col-rounded" : "col-3 col-05row-expand col-rounded";
			case "col-6 col-05row":
				return this.activated ? "col-3 col-025row" : "col-3 col-025row-expand";
			case "col-12 col-05row":
				return this.activated ? "col-6 col-025row" : "col-6 col-025row-expand";
			case "col-12 col-1row desktop":
				return this.activated ? "col-12 col-05row" : "col-12 col-05row-expand";
			case "col-12 col-05row desktop":
				return this.activated ? "col-12 col-025row" : "col-12 col-025row-expand";
		}
	}
}
