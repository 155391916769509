import { Component, OnInit, NgZone, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import {
	NavController,
	MenuController,
	ModalController,
	AlertController,
	Platform,
	LoadingController
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { AnalyticsService, GlobalService, UtilityService } from "src/app/shared/services";
import { LightboxImgComponent } from "src/app/components/lightbox-img/lightbox-img.component";
import { TranslateService } from "@ngx-translate/core";
import { MarketService } from "src/app/providers/market/market.service";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/providers/authentication/auth.service";
import { PersonalSettingsPage } from "./personal-settings/personal-settings.page";
import { PersonalQRcodePage } from "./personal-qrcode/personal-qrcode.page";
import { DaoPersonalAgendaService } from "src/app/providers/db/dao-personal-agenda.service";
import { DocumentService } from "src/app/providers/document/document.service";
import { InAppBrowserService } from "src/app/providers/in-app-browser/in-app-browser.service";
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";
import { customField } from "src/app/models/customField";

@Component({
	selector: "app-personal-page",
	templateUrl: "./personal-page.page.html",
	styleUrls: ["./personal-page.page.scss"],
	providers: [DaoAttendeesService]
})
export class PersonalPagePage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	public eventId: string = null;
	public userId: string = null;
	public moduleId: string = null;
	public typeUser: number = null;
	public loader: boolean = true;
	public company: string = null;
	public title: string = null;
	public name: string = null;
	public site: string = null;
	public facebook: string = null;
	public instagram: string = null;
	public linkedin: string = null;
	public twitter: string = null;
	public identifier: string = null;
	public phone: string = null;
	public description: string = null;
	public photoUrl: string = null;
	public type: number = null;

	public editProfileActive: boolean = false;
	
	private personalScheduleId;
	public personalScheduleActive: boolean;

	public listCustomFields: Array<any> = [];
    cfAnswers = {};
	public listCustomFieldOptions: Array<any> = [];
	public fieldsCustom: any;
	showCardCustomField: boolean = false;

	public documents = [];
    doc: any;
	allowQR: boolean = false;
	backBtn: boolean = true;

	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;

	language: string;

	loaderToast;
	isMobile: boolean = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private dbAttendees: DaoAttendeesService,
		private dbSpeakers: DaoSpeakersService,
		private dbPersonalAgenda: DaoPersonalAgendaService,
		private navCtrl: NavController,
		public global: GlobalService,
        private SUtility: UtilityService,
		private modalCtrlWriteMessages: ModalController,
		private events: Events,
		private menuCtrl: MenuController,
		private zone: NgZone,
		private modalCtrl: ModalController,
		private platform: Platform,
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController,
		private translateService: TranslateService,
		private market: MarketService,
		private SAnalytics: AnalyticsService,
		private auth: AuthService,
		private docService: DocumentService,
        private inAppBrowser: InAppBrowserService,
        private SEventData: EventDataService
	) {
		this.menuCtrl.enable(true);
        
        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
		this.userId = params.userId;
		this.typeUser = params.type;
		this.moduleId = params.moduleId;

		this.loadColors();
		this.language = this.SEventData.getLanguage();
		this.menuBadge = this.global.notificationBadge;
		this.events.subscribe("menuBadge", () => {
			this.zone.run(() => {
				this.menuBadge = this.global.notificationBadge;
			});
		});
	}

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;

            // if (this.menu_color.includes("linear-gradient")) {
            //     const colors = this.menu_color.split(",");
            //     this.menu_color1 = colors[1];
            //     this.menu_color2 = colors[2].substr(0, colors[2].length - 1);
            // } else {
            //     this.menu_color1 = this.menu_color2 = this.menu_color;
            // }
        });
        this.subscriptions.push(subscription);
    }

	ngOnInit() {
		if (this.platform.is('ios') || this.platform.is('android')) {
			this.isMobile = true;
		}
		
		this.language = this.SEventData.getLanguage();
        
        const subscription = this.SEventData.getEventDataListener(this.eventId).subscribe((eventData) => {
            this.allowQR = eventData.allowProfileQR;
        });

        this.subscriptions.push(subscription);

        if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}

		if (this.typeUser == 5) {
			this.loadAttendee();
			this.checkeditProfileActiveAttendee();
		} else if (this.typeUser == 4) {
			this.loadSpeaker();
			this.checkeditProfileActiveSpeaker();
		} else {
			// invalid view profile
		}

		this.newIdSchedule(this.eventId, (data)=>{
			if(!data.habilitedApp){
				this.personalScheduleActive = data.habilitedApp
				
			}else{
				this.personalScheduleActive = data.habilitedApp
				this.personalScheduleId = data.uid;
			}
		});
		
    }         

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	checkeditProfileActiveAttendee() {
		this.dbAttendees.checkeditProfileActive(this.moduleId, (result) => {
			this.editProfileActive = result;
		});
	}

	checkeditProfileActiveSpeaker() {
		this.dbSpeakers.checkeditProfileActive(this.moduleId, (result) => {
			this.editProfileActive = result;
		});
	}

	// load attendee
	loadAttendee() {
		this.subscriptions.push(
			this.dbAttendees.getAttendeeByEvent(this.eventId, this.userId).subscribe((attendee) => {
				this.zone.run(() => {
					let aux = this.getSpeakerAttendeePrincipalTitle(attendee["title"], attendee["description"]);
					this.type = attendee["type"];
					this.name = attendee["name"];
					this.company = attendee["company"];
					this.title = aux.title;
					this.description = aux.description;
					this.photoUrl = attendee["photoUrl"];
					this.phone = attendee["phone"];
					this.site = attendee["website"];
					this.facebook = attendee["facebook"];
					this.instagram = attendee["instagram"];
					this.linkedin = attendee["linkedin"];
					this.twitter = attendee["twitter"];
					this.identifier = attendee["identifier"];

					this.documents = [];
					for (let uid in attendee["documents"]) {
						this.documents.push(attendee["documents"][uid]);
					}
					this.documents = [...this.documents].map((doc) => {
						if (doc.icon === "jpg_ico.png" || doc.icon === "png_ico.png") {
							doc.icon = "photo_ico.svg";
						}
						if (doc.icon.substr(doc.icon.length - 3) === "png") {
							doc.icon = doc.icon.substr(0, doc.icon.length - 3) + "svg";
						}
						return doc;
					});

					this.loader = false;
					this.getCustomFieldsAttendee();
				});
			})
		);
	}
    
	getCustomFieldsAttendee() {
        Promise.all([
            this.dbAttendees.getCustomFields(this.eventId, this.moduleId),
            this.dbAttendees.getCFAnswers(this.eventId, this.userId)    
        ]).then((result) => {
            result[1].forEach((e) => { this.cfAnswers[e.id] = e.answer; });
            this.listCustomFields = result[0].filter(cf => {
                if (cf.exibicao && cf.exibicao < 3 && this.cfAnswers[cf.uid]) {
                    if (cf.type == 's') {
                        this.cfAnswers[cf.uid] = (cf.answerOptions && this.cfAnswers[cf.uid]) ? 
                            (cf.answerOptions[this.cfAnswers[cf.uid]]) ? 
                                cf.answerOptions[this.cfAnswers[cf.uid]][this.language] : 
                                null :
                            null;
                    }
                    return (this.cfAnswers[cf.uid]) ? true : false;
                }
                return false;
            });

        }).catch(error => console.error(error));
    }

	// load speaker
	loadSpeaker() {
		this.subscriptions.push(
			this.dbSpeakers.getSpeakerByEvent(this.eventId, this.userId).subscribe((speaker) => {
				this.zone.run(() => {
					let aux = this.getSpeakerAttendeePrincipalTitle(speaker["title"], speaker["description"]);
					this.type = speaker["type"];
					this.name = speaker["name"];
					this.company = speaker["company"];
					this.title = aux.title;
					this.description = aux.description;
					this.photoUrl = speaker["photoUrl"];
					this.phone = speaker["phone"];
					this.site = speaker["website"];
					this.facebook = speaker["facebook"];
					this.instagram = speaker["instagram"];
					this.linkedin = speaker["linkedin"];
					this.twitter = speaker["twitter"];
					this.loader = false;

					// this.getFieldsCustomSpeaker();
				});
			})
		);
	}

	// getFieldsCustomSpeaker() {
	// 	this.dbSpeakers.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.zone.run(() => {
	// 			this.fieldsCustom = fields;
	// 			this.getCustomFieldsSpeakers();
	// 		});
	// 	});
	// }

	// getCustomFieldsSpeakers() {
	// 	this.dbSpeakers.getCustomFields(this.eventId, this.userId, (customFields) => {
	// 		this.zone.run(() => {
	// 			let displayCardCustom = false;
	// 			for (let aux of customFields) {
	// 				if (displayCardCustom == false) {
	// 					if (aux.type == "select") {
	// 						if (
	// 							aux.value[this.language] !== null &&
	// 							aux.value[this.language] !== undefined &&
	// 							aux.value[this.language] !== ""
	// 						) {
	// 							displayCardCustom = true;
	// 							this.showCardCustomField = true;
	// 						}
	// 					} else {
	// 						if (
	// 							aux.textValue[this.language] !== null &&
	// 							aux.textValue[this.language] !== undefined &&
	// 							aux.textValue[this.language] !== ""
	// 						) {
	// 							displayCardCustom = true;
	// 							this.showCardCustomField = true;
	// 						}
	// 					}
	// 				}

	// 				let position = this.fieldsCustom[aux.uid].order;
	// 				this.listCustomFields[position] = aux;
	// 			}

	// 			for (let i = 0; i < this.listCustomFields.length; i++) {
	// 				let custom = this.listCustomFields[i];

	// 				if (custom.type == "select") {
	// 					this.dbAttendees.getCustomFieldOptions(
	// 						this.moduleId,
	// 						this.userId,
	// 						custom.uid,
	// 						(listOptions) => {
	// 							for (let option of listOptions) {
	// 								if (custom.value == option.uid) {
	// 									custom.value = option.answer;
	// 								}
	// 							}
	// 						}
	// 					);
	// 				}
	// 			}
	// 		});
	// 	});
	// }

	openSessionDetail(sessionId) {
		this.router.navigate([`/event/${this.eventId}/schedule-detail/${this.moduleId}/${sessionId}`]);
	}

	// zoom profile picture image
	openImage(url: string) {
		// this.photoViewer.show(url, '', { share: false });
		this.modalCtrl
			.create({
				component: LightboxImgComponent,
				componentProps: {
					img: url
				}
			})
			.then((modal) => {
				modal.present();
			});
	}

	openLink(url: string) {
        this.inAppBrowser.openLink(url);
	}

	popPage() {
		this.navCtrl.pop();
	}

	editProfile() {
		this.global.previousPage = null;
		this.router.navigate([`/event/${this.eventId}/edit-profile/${this.moduleId}/${this.typeUser}/${this.userId}`]);
	}

	schedule() {
		this.global.previousPage = null;
		this.router.navigate([`/event/${this.eventId}/personal-schedule/${this.personalScheduleId}`]);
	}

	newIdSchedule(eventID : string, onResolve){
		this.dbPersonalAgenda.getId(eventID, onResolve);
	}

	logout() {
        // Update connected analytics
        this.SAnalytics.updateConnectedStatusUser(this.eventId, false);

        this.auth.logout();
        this.global.resetService();
        this.eventId = null;
        this.userId = null;
        this.typeUser = null;
    }
	
	async showModalSettings() {
		const modal = await this.modalCtrlWriteMessages.create({
			component: PersonalSettingsPage,
			componentProps:{ 
				eventId: this.eventId,
				moduleId: this.moduleId
				
			}
		});
		modal.present();
	}

	async showModalQR() {
		const modal = await this.modalCtrlWriteMessages.create({
			component: PersonalQRcodePage,
			componentProps:{ 
				userId: this.userId,
				eventId: this.eventId,
				moduleId: this.moduleId,
				identifier: this.identifier,
				name: this.name
			}
		});
		modal.present();
	}


	getSpeakerAttendeePrincipalTitle(title, description) {
		return {
			title: title[this.language],
			description: description[this.language]
		};
	}

	async presentAlertConfirm() {
		const alert = await this.alertCtrl.create({
			header: this.translateService.instant("global.alerts.not_found_title"),
			message: this.translateService.instant("global.alerts.app_open_doc_not_found_txt"),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.no"),
					role: "cancel",
					cssClass: "secondary",
					handler: (blah) => {}
				},
				{
					text: this.translateService.instant("global.buttons.yes"),
					handler: () => {
						this.openMarketApp();
					}
				}
			]
		});

		await alert.present();
	}

	openMarketApp() {
		let platforms = this.platform.platforms().join();
		if (platforms.includes("android")) {
			let androidPackage = this.getPackageNameAndroid(this.doc.type);
			this.market.open(androidPackage);
		} else if (platforms.includes("ios")) {
			let iosPackage = this.getPackageNameIos(this.doc.type);
			this.market.open(iosPackage);
		}
	}

	getPackageNameAndroid(extn) {
		let ext = extn.toLowerCase();
		let packageNames = {
			// texts
			docx: "com.google.android.apps.docs.editors.docs",
			doc: "com.google.android.apps.docs.editors.docs",
			docm: "com.google.android.apps.docs.editors.docs",
			// pdf
			pdf: "com.adobe.reader",
			// sheets
			xls: "com.google.android.apps.docs.editors.sheets",
			xlsx: "com.google.android.apps.docs.editors.sheets",
			xlsm: "com.google.android.apps.docs.editors.sheets",
			xlt: "com.google.android.apps.docs.editors.sheets",
			xlsb: "com.google.android.apps.docs.editors.sheets",
			xltx: "com.google.android.apps.docs.editors.sheets",
			xltm: "com.google.android.apps.docs.editors.sheets",
			// presentations (ppt)
			ppt: "com.google.android.apps.docs.editors.slides",
			pptx: "com.google.android.apps.docs.editors.slides",
			ppsx: "com.google.android.apps.docs.editors.slides",
			pps: "com.google.android.apps.docs.editors.slides",
			ppsm: "com.google.android.apps.docs.editors.slides"
		};
		return packageNames[ext];
	}

	getPackageNameIos(extn) {
		let ext = extn.toLowerCase();
		let packageNames = {
			// texts
			docx: "documentos-google/id842842640",
			doc: "documentos-google/id842842640",
			docm: "documentos-google/id842842640",
			// pdf
			pdf: "adobe-acrobat-reader/id469337564",
			// sheets
			xls: "planilhas-google/id842849113",
			xlsx: "planilhas-google/id842849113",
			xlsm: "planilhas-google/id842849113",
			xlt: "planilhas-google/id842849113",
			xlsb: "planilhas-google/id842849113",
			xltx: "planilhas-google/id842849113",
			xltm: "planilhas-google/id842849113",
			// presentations (ppt)
			ppt: "apresentações-google/id879478102",
			pptx: "apresentações-google/id879478102",
			ppsx: "apresentações-google/id879478102",
			pps: "apresentações-google/id879478102",
			ppsm: "apresentações-google/id879478102"
		};
		return packageNames[ext];
	}

	/**
     * Open a document
     * @param document
     */
    openDocument(document) {
        this.doc = document;
        this.docService.openDocument(document, this.eventId);

    }

	checkType(type: string): string {
		switch (type) {
			case "doc":
			case "docx":
			case "docm":
			case "DOC":
			case "DOCX":
			case "DOCM":
				return "Word";

			case "xls":
			case "xlt":
			case "xls":
			case "xml":
			case "xlsx":
			case "xlsm":
			case "xlsb":
			case "xltx":
			case "xltm":
			case "XLS":
			case "XLT":
			case "XLS":
			case "XML":
			case "XLSX":
			case "XLSM":
			case "XLSB":
			case "XLTX":
			case "XLTM":
				return "Excel";

			case "pdf":
			case "PDF":
				return "PDF";

			case "png":
			case "PNG":
				return "PNG";

			case "jpg":
			case "JPG":
				return "JPG";
			case "jpeg":
			case "JPEG":
				return "JPEG";

			case "ppt":
			case "pptx":
			case "thmx":
			case "ppsx":
			case "pps":
			case "ppsm":
			case "PPT":
			case "PPTX":
			case "THMX":
			case "PPSX":
			case "PPS":
			case "PPSM":
				return "PowerPoint";

			case "mp4":
			case "wmv":
			case "3gp":
			case "avi":
			case "mp3":
			case "wav":
			case "MP4":
			case "WMV":
			case "3GP":
			case "AVI":
			case "MP3":
			case "WAV":
				return "Media";

			default:
				return "Generic File ";
		}
	}
}
