import { Injectable } from '@angular/core';
import { TypeUser } from 'src/app/models/type-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Observable, firstValueFrom } from 'rxjs';
import { Firestore, doc, docData, updateDoc } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoGeralService {
    public headers;
    public requestOptions;
    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        public http: HttpClient,
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    loadUser(userId: string, typeUser: number, eventId: string): Observable<any> {
        let ref;

        switch (typeUser) {
            case TypeUser.SPEAKER:
                ref = doc(this.firestore, `events/${eventId}/speakers/${userId}`);
                break;
            case TypeUser.ATTENDEE:
                ref = doc(this.firestore, `events/${eventId}/attendees/${userId}`);
                break;
            default:
                ref = doc(this.firestore, `users/${userId}`);
                break;
        }

        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    getTermsOfUse(onResolve) {
        const ref = doc(this.firestore, `general-legal-terms/terms`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc['termOfUse']);
        })
    }

    getPrivacy(onResolve) {
        const ref = doc(this.firestore, `general-legal-terms/terms`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc['privacy']);
        });
    }

    /**
     * update user information in firestore database
     * @param obj
     * @param userId 
     * @param eventId 
     * @param typeUser 
     */
    updateUserGeneral(obj, userId: string, eventId: string, typeUser: number): Promise<void> {
        let ref;

        if (typeUser == TypeUser.SPEAKER || typeUser == TypeUser.ATTENDEE) {
            const auxTypeUser: string = (typeUser == TypeUser.SPEAKER) ? "speaker" : "attendees";
            ref = doc(this.firestore, `events/${eventId}/${auxTypeUser}/${userId}`);
        }
        else {
            ref = doc(this.firestore, `users/${userId}`);
        }

        return updateDoc(ref, obj);
    }

    getEvent(eventId: string) {
        const ref = doc(this.firestore, `events/${eventId}`);
        return firstValueFrom(docData(ref));
    }

}
