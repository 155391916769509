import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    // remove console log in production mode
    if (window && environment.firebase.projectId !== "high5event-a48b5") {
        window.console.log = function () { };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
