import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService, GlobalService, UtilityService } from 'src/app/shared/services';
import { MenuController, Platform } from '@ionic/angular';
import { DaoSurveyService } from 'src/app/providers/db/dao-survey.service';
import { Survey } from 'src/app/models/survey';
import { NotificationDateService } from 'src/app/providers/date/notification-date.service';
import { DaoAttendeesService } from 'src/app/providers/db/dao-attendees.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DaoInteractivityService } from 'src/app/providers/db/dao-interactivity.service';
import { NameModule } from 'src/app/models/name-module';
import { Subscription } from 'rxjs';
import { StorageService } from 'src/app/providers/storage/storage.service';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.page.html',
    styleUrls: ['./survey.page.scss'],
})
export class SurveyPage implements OnInit, OnDestroy {
    @Input() componentMode: boolean;
    @Input() backToList: boolean = false;
    subscriptions: Subscription[] = [];

    @Input() eventId: string = null;
    @Input() moduleId: string = null;
    @Input() surveyId: string = null;
    @Input() sessionId: string = null;
    @Input() userId: string = null;
    @Input() scheduleModuleId: string = null;
    @Output() stopLoaderEvent: EventEmitter<boolean> = new EventEmitter();
    @Output() goBackEvent: EventEmitter<any> = new EventEmitter();

    view_only: boolean = true;

    moduleInteractivity = null;

    survey: Survey;
    result: Array<any> = [];

    rate: number;
    ratingValue;

    previousPage;
    loadingSend: boolean = false;
    loader: boolean = true;

    eventColors: EventColors = new EventColors();
    surveyName: NameModule = new NameModule('', '', '', '', '');
    totalUserAnswers: boolean = true;
    allowButtonsHeader: boolean = false;
    language: string;

    isMobile: boolean = false;

    constructor(
        private platform: Platform,
        private route: ActivatedRoute,
        private router: Router,
        public SGlobal: GlobalService,
        private menuCtrl: MenuController,
        private daoSurvey: DaoSurveyService,
        private daoAttendee: DaoAttendeesService,
        private daoInteractivity: DaoInteractivityService,
        private notificationDate: NotificationDateService,
        private location: Location,
        private translateService: TranslateService,
        private SUtility: UtilityService,
        private storage: StorageService,
        private SAnalytics: AnalyticsService,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) { }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        this.menuCtrl.enable(true);
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
        this.loadColors();

        this.language = this.SEventData.getLanguage();

        if (!this.componentMode) {
            const params = this.route.snapshot.params;
            this.eventId = params.eventId;
            this.moduleId = params.moduleId;
            this.surveyId = params.surveyId;

            this.trackAnalytics();

            this.subscriptions.push(this.route.queryParams.subscribe(params => {
                if (this.router.getCurrentNavigation().extras.state) {
                    this.previousPage = this.router.getCurrentNavigation().extras.state.previousPage;
                    this.scheduleModuleId = this.router.getCurrentNavigation().extras.state.scheduleModuleId;
                    this.sessionId = this.router.getCurrentNavigation().extras.state.sessionId;
                }
            }));
        } else {
            this.daoInteractivity.getInteractivityModule(this.eventId, (module) => {
                this.moduleInteractivity = module;
                this.getSurvey();
            })

            this.trackAnalytics();
        }
    }

    /**
     * Tracks analytics
     */
    trackAnalytics() {
        console.log("Track an alytics: ", this.eventId, this.moduleId, this.surveyId);
        this.SAnalytics.accessToSurvey(this.eventId, this.moduleId, this.surveyId);
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ionViewWillEnter() {
        this.daoInteractivity.getInteractivityModule(this.eventId, (module) => {
            this.moduleInteractivity = module;
            this.getSurvey();
        })
    }

    /**
     * Go back to list surveys on component mode
     */
    goBackToList() {
        this.goBackEvent.emit(true);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * Getting survey
     */
    getSurvey() {
        if (this.SUserData.userLogged() || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
            this.subscriptions.push(this.daoSurvey.getSurvey(this.moduleId, this.surveyId).subscribe((survey) => {
                this.survey = survey;
                this.surveyName = new NameModule(survey.title['PtBR'], survey.title['EnUS'], survey.title['EsES'],
                    survey.title['FrFR'], survey.title['DeDE']);
                this.userId = this.SUserData.userId;

                this.stopLoaderEvent.emit(false);

                if (this.userId !== null && this.userId !== undefined) {
                    this.daoSurvey.getResponseUsers(this.moduleId, survey.uid, this.userId, (responses) => {
                        let totalQuestionsAnswered = 0;

                        if (responses != null && responses != undefined) {
                            totalQuestionsAnswered = Object.keys(responses).length;
                        }

                        if (totalQuestionsAnswered >= survey.questions.length) {
                            if (!survey.change_answer && survey.view_answered) {
                                this.view_only = true;
                            } else {
                                this.view_only = false;
                            }
                        } else {
                            this.view_only = false;
                        }

                    })
                } else {
                    this.view_only = false;
                }

                this.setResult();
            }));
        } else {
            if (this.componentMode) {
                this.goBackToList();
            } else {
                this.location.back();
            }
        }
    }

    /**
     * Setting result
     */
    setResult() {
        this.result = [];
        for (let i = 0; i < this.survey.questions.length; i++) {
            if (this.survey.questions[i].type == 'multipleSelect') {
                let arrayMulti = [];
                for (let t = 0; t < this.survey.questions[i].answers.length; t++) {
                    arrayMulti.push({
                        type: 'multipleSelect',
                        answer: null
                    })
                }
                this.result[i] = arrayMulti;
            } else {
                this.result[i] = {
                    type: this.survey.questions[i].type,
                    answer: null
                };
            }
        }

        if (this.userId !== null && this.userId !== undefined) {
            this.daoSurvey.getResponseUsers(this.moduleId, this.surveyId, this.userId, (responses) => {
                let cont = 0;
                for (let question of this.survey.questions) {
                    if (responses && responses[question.uid] !== undefined) {
                        question.answered = true;

                        if (question.type == 'dissertative' || question.type == 'date' || question.type == 'document') {
                            // this.result[cont] = responses[question.uid].answer;
                            this.result[cont] = (question.type == 'document') ? {
                                type: question.type,
                                answer: responses[question.uid].answer,
                                title: (responses[question.uid].title) ? responses[question.uid].title : "",
                                docType: (question.type == 'document') ? responses[question.uid].docType : ""
                            } : {
                                    type: question.type,
                                    answer: responses[question.uid].answer
                                };
                        } else if (question.type == 'evaluation') {
                            question.answered = false;
                            // this.result[cont] = responses[question.uid].answer;
                            this.result[cont] = {
                                type: question.type,
                                answer: responses[question.uid].answer
                            }
                            setTimeout(() => {
                                question.answered = true;
                            }, 300);

                        } else if (question.type == 'multipleSelect') {
                            for (let answerId of responses[question.uid].answer) {
                                let contAnswer = 0;
                                for (let answer of this.survey.questions[cont].answers) {
                                    if (answerId == answer.uid) {
                                        // this.result[cont][contAnswer] = true;
                                        this.result[cont][contAnswer] = {
                                            type: question.type,
                                            answer: true
                                        }
                                    }

                                    contAnswer++;
                                }
                            }
                        } else {
                            for (let answer of this.survey.questions[cont].answers) {
                                if (answer.uid == responses[question.uid].answer) {
                                    // this.result[cont] = answer.uid;
                                    this.result[cont] = {
                                        type: question.type,
                                        answer: answer.uid
                                    }
                                }
                            }
                        }

                    } else {
                        if (question.type == 'multipleSelect') {
                            let contAnswer = 0;
                            this.survey.questions[cont].answers.forEach(() => {
                                this.result[cont][contAnswer] = {
                                    type: question.type,
                                    answer: false
                                }
                                contAnswer++;
                            })
                        }
                    }
                    cont++;
                }

                this.loader = false;
            })
        } else {
            this.loader = false;
        }
    }

    /**
     * Set evaluation
     * @param $event 
     * @param index 
     */
    setEvaluation($event, index) {
        this.result[index].answer = $event.rating
    }

    /**
     * Selected date
     * @param $event 
     * @param index 
     */
    async dateSelected($event, index) {
        if (this.survey.questions[index].answered == false) {
            const date = new Date($event);
            const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
            const timezone = (eventData) ? eventData.timezone : localStorage.getItem("timezone");
            const timestamp = this.notificationDate.getTimeStampFromDateNow(date, timezone);
            
            this.result[index].answer = timestamp;
        } else {
            await this.SUtility.presentToast(this.translateService.instant('global.alerts.date_already_selected'), 2000, 'bottom', 'blue-default');
        }
    }

    /**
     * Send a survey
     */
    async sendSurvey() {
        this.totalUserAnswers = true;

        if (this.SUserData.userLogged() || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
            for (let i = 0; i <= this.survey.questions.length - 1; i++) {
                if (this.survey.questions[i].type == 'multipleSelect') {

                    let findAnswer = false;
                    for (let result of this.result[i]) {
                        if (result && result.answer) {
                            findAnswer = true;
                        }
                    }
                    if (!findAnswer) {
                        this.totalUserAnswers = false;
                    }
                } else if (this.survey.questions[i].type == 'dissertative') {
                    if (this.result[i].answer == null || this.result[i].answer == '') {
                        this.totalUserAnswers = false;
                    }
                } else {
                    if (this.result[i].answer == null) {
                        this.totalUserAnswers = false;
                    }
                }
            }
            if (this.survey.forceComplete && !this.totalUserAnswers) {
                let alert = await this.SUtility.presentAlert(
                    this.translateService.instant("pages.survey.force_complete_title_alert"),
                    this.translateService.instant("pages.survey.force_complete_message_alert"),
                    [{
                        text: this.translateService.instant('global.buttons.ok'),
                        handler: () => { }
                    }],
                    []
                )
                alert.present();
                return;
            }

            this.loadingSend = true;
            let questions = this.survey.questions;
            let timestamp = Date.now() / 1000 | 0;

            let totalPoints: any = 0;
            let contQuestion = 0;
            for (let question of questions) {
                if (question.type == 'multipleSelect') {
                    let answersSelected: Array<string> = [];
                    let answersSelectedWeight: Array<Number> = [];

                    for (let j = 0; j < this.result[contQuestion].length; j++) {
                        if (this.result[contQuestion][j].answer == true) {
                            answersSelected.push(question.answers[j].uid);

                            let weight = Number(question.answers[j].weight);
                            if (weight !== undefined && weight !== null) {
                                answersSelectedWeight.push(weight);
                            }
                        }
                    }

                    if (answersSelected.length > 0) {
                        await this.createResultQuestion(questions[contQuestion].uid, questions[contQuestion].type, answersSelected, null, timestamp)
                            .then((status) => {
                                if (status) {
                                    for (let weight of answersSelectedWeight) {
                                        totalPoints = totalPoints + weight;
                                    }
                                }

                                if (contQuestion == questions.length - 1) {
                                    this.loadingSend = false;
                                    this.addUserPoints(totalPoints);
                                }

                                contQuestion++;

                            })
                    } else {
                        if (contQuestion == questions.length - 1) {
                            this.loadingSend = false;
                            this.addUserPoints(totalPoints);
                        }

                        contQuestion++;
                    }

                } else if (question.type == 'oneSelect') {
                    if (this.result[contQuestion].answer != null && this.result[contQuestion].answer != undefined && this.result[contQuestion].answer != "") {
                        let ArrayAnswer: Array<String> = [this.result[contQuestion].answer];

                        await this.createResultQuestion(questions[contQuestion].uid, questions[contQuestion].type, ArrayAnswer, null, timestamp)
                            .then((status) => {
                                if (status) {
                                    let weight;

                                    for (let aux of questions[contQuestion].answers) {
                                        if (aux.uid == this.result[contQuestion].answer) {
                                            weight = aux.weight;
                                        }
                                    }

                                    totalPoints = totalPoints + weight;
                                }

                                if (contQuestion == questions.length - 1) {
                                    this.loadingSend = false;
                                    this.addUserPoints(totalPoints);
                                }

                                contQuestion++;

                            })
                    } else {
                        if (contQuestion == questions.length - 1) {
                            this.loadingSend = false;
                            this.addUserPoints(totalPoints);
                        }

                        contQuestion++;
                    }
                } else if (question.type == 'date' || question.type == "document") {
                    if (!question.answered) {
                        if (this.result[contQuestion].answer != null && this.result[contQuestion].answer != undefined && this.result[contQuestion].answer != "") {

                            await this.createResultQuestion(questions[contQuestion].uid, questions[contQuestion].type, this.result[contQuestion].answer, (question.type == 'document') ? {
                                title: this.result[contQuestion].title,
                                docType: this.result[contQuestion].docType
                            } : null, timestamp)
                                .then((status) => {

                                    totalPoints = totalPoints + questions[contQuestion].points;

                                    if (contQuestion == questions.length - 1) {
                                        this.loadingSend = false;
                                        this.addUserPoints(totalPoints);
                                    }

                                    contQuestion++;
                                })
                        } else {
                            if (contQuestion == questions.length - 1) {
                                this.loadingSend = false;
                                this.addUserPoints(totalPoints);
                            }

                            contQuestion++;
                        }
                    } else {
                        if (contQuestion == questions.length - 1) {
                            this.loadingSend = false;
                            this.addUserPoints(totalPoints);
                        }

                        contQuestion++;
                    }
                } else {
                    if (this.result[contQuestion].answer != null && this.result[contQuestion].answer != undefined && this.result[contQuestion].answer != "") {

                        await this.createResultQuestion(questions[contQuestion].uid, questions[contQuestion].type, this.result[contQuestion].answer, null, timestamp)
                            .then((status) => {

                                totalPoints = totalPoints + questions[contQuestion].points;

                                if (contQuestion == questions.length - 1) {
                                    this.loadingSend = false;
                                    this.addUserPoints(totalPoints);
                                }

                                contQuestion++;

                            })
                    } else {
                        if (contQuestion == questions.length - 1) {
                            this.loadingSend = false;
                            this.addUserPoints(totalPoints);
                        }

                        contQuestion++;
                    }
                }
            }
        }
    }

    /**
     * Create a result to a question
     * @param questionId 
     * @param typeQuestion 
     * @param answeredSelected 
     * @param timestamp 
     */
    createResultQuestion(questionId, typeQuestion, answeredSelected, documentResponse, timestamp) {
        return new Promise((resolve, reject) => {
            this.daoSurvey.createResult(this.eventId, this.moduleId, this.userId, this.surveyId, questionId, typeQuestion, answeredSelected, timestamp, documentResponse, (status) => {

                // Track analytics
                this.SAnalytics.sendSurvey(this.eventId, this.moduleId, this.surveyId);

                resolve(status);
            });
        });
    }

    /**
     * Add points to user
     * @param points 
     */
    addUserPoints(points) {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            if (this.totalUserAnswers) {
                this.daoSurvey.setUserSurveyAnswered(this.eventId, this.userId, this.surveyId);
            }

            if (points > 0) {
                this.daoAttendee.addPoints(
                    this.eventId, 
                    userData.moduleId, 
                    this.userId, 
                    points, 
                    (result) => { this.presentAlertConfirm(); }
                );
            } else {
                this.presentAlertConfirm();
            }
        }).catch((error) => {
            console.error(error);
            this.presentAlertConfirm();
        });
    }

    /**
     * Present a confirmation alert
     */
    async presentAlertConfirm() {
        let alert = await this.SUtility.presentAlert(this.translateService.instant('global.alerts.thankyou_answer'), this.translateService.instant('global.alerts.thankyou_answer'), [
            {
                text: this.translateService.instant('global.buttons.ok'),
                handler: () => {
                    if (this.componentMode) {
                        this.goBackToList();
                    } else {
                        this.location.back();
                    }
                }
            }
        ], []);
        await alert.present();
    }

    /**
     * Selected img
     */
    async selectPictureForDocType(ev, index) {
        if (this.SUserData.userLogged()) {
            if (this.isMobile) {
                this.selectPictureForMobile(index);
            } else {
                this.selectPictureForWeb(ev, index);
            }
            // } else {
        }
    }

    /**
     * Select an img for mobile
     */
    async selectPictureForMobile(index) {
        // let alert = await this.SUtility.presentAlert(
        //     this.translateService.instant('pages.editprofile.profile_pic_select'),
        //     this.translateService.instant('pages.editprofile.gallery_or_camera'),
        //     [
        //         {
        //             text: this.translateService.instant('pages.editprofile.gallery'),
        //             handler: () => {
        //                 this.storage.openGallery((data) => {
        //                     // Upload file on question doc
        //                     this.uploadDocImg(data, "image/jpeg", "", index, true);
        //                 })
        //             }
        //         },
        //         {
        //             text: this.translateService.instant('pages.editprofile.camera'),
        //             handler: () => {
        //                 this.storage.takePicture((data) => {
        //                     // Upload file on question doc
        //                     this.uploadDocImg(data, "image/jpeg", "", index, true);
        //                 });
        //             }
        //         }
        //     ]
        // )

        // await alert.present();
    
        this.storage.takePicture((data) => {
            // Upload file on question doc
            this.uploadDocImg(data, "image/jpeg", "", index, true);
        });
    }

    /**
     * Select doc for web
     * @param ev 
     */
    selectPictureForWeb(ev, index) {
        const fileWeb: File = ev.target.files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(fileWeb);
        // reader.onload = () => {
        // Upload file on question doc url
        this.uploadDocImg(fileWeb, fileWeb.type, fileWeb.name, index, false);
        // };
        // reader.onerror = error => console.log(error);
    }

    /**
     * Upload file
     * @param file 
     */
    async uploadDocImg(file: any, fileType: string = null, fileName: string = null, index, mobile: boolean) {
        try {
            const extension = fileName.split('.');
            const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
            const name = 
                this.survey.questions[index].title[this.language] + 
                "-" + 
                userData.identifier + 
                "." + 
                extension[extension.length - 1];
            
            const downloadUrl = await this.storage.uploadSurveyDocImg(
                file, 
                this.eventId, 
                this.moduleId, 
                this.surveyId, 
                this.survey.questions[index].uid, 
                name, 
                mobile
            );

            this.result[index].answer = downloadUrl;
            this.result[index].title = fileName;
            this.result[index].docType = fileType;
        } catch (error) { console.error(error); }
    }

    checkImg(result: any) {
        if (result && result.docType.includes('image')) {
            return (true);
        } else {
            return (false);
        }
    }

    async presentToast(answered) {
        if (answered && this.survey.change_answer == false) {
            this.SUtility.presentToast(this.translateService.instant('global.alerts.not_change_answer'), 2000, 'bottom', 'primary');
        }
    }
}
