// Type tracking enum
export enum TypeTracking {
    ACCESS_TO_PRINCIPAL_MODULE = 1, // OK
    ACCESS_TO_WIDGET_MODULE = 0, // OK

    ACCESS_TO_ATTENDEES_MODULE = 10, // OK
    ACCESS_TO_SPEAKERS_MODULE = 11, // OK
    ACCESS_TO_PROFILE = 12, // OK
    EDIT_PROFILE = 13, // OK

    ACCESS_TO_SCHEDULES_MODULE = 20, // OK
    ACCESS_TO_SCHEDULE_SESSION = 21, // OK

    ACCESS_TO_FEED_MODULE = 30, // OK
    SEND_LIKE_ON_FEED = 31, // OK
    REMOVE_LIKE_ON_FEED = 32, // OK
    SEND_COMMENT_ON_FEED = 33, // OK
    DELETE_COMMENT_ON_FEED = 34, // OK

    ACCESS_TO_SURVEYS_MODULE = 40, // OK
    ACCESS_TO_SURVEY = 41, // OK
    SEND_SURVEY = 42, // OK

    ACCESS_TO_QUIZS_MODULE = 50, // OK
    ACCESS_TO_QUIZ = 51, // OK
    SEND_QUIZ = 52, // OK

    ACCESS_TO_ASK_QUESTION_MODULE = 60, // OK
    ACCESS_TO_ASK_QUESTION = 61, // OK
    SEND_QUESTION = 62, // OK

    ACCESS_TO_DOCUMENTS_MODULE = 70, // OK
    ACCESS_TO_DOCUMENTS_FOLDER = 71, // OK
    OPEN_DOCUMENT = 72, // OK

    ACCESS_TO_IMAGES_MODULE = 80, // OK
    ACCESS_TO_IMAGES_FOLDER = 81, // OK
    OPEN_IMAGE = 82, // OK

    ACCESS_TO_INFOBOOTH_MODULE = 90, // OK

    ACCESS_TO_CHECKIN_MODULE = 100, // OK

    ACCESS_TO_RANKING_MODULE = 110, // OK

    ACCESS_TO_PLANS_MODULE = 120, // OK

    ACCESS_TO_DIFFUSION_MODULE = 130,

    ACCESS_TO_CHATS_MODULE = 140, // OK
    ACCESS_TO_CHAT = 141, // OK
    SEND_MESSAGE_ON_CHAT = 142, // OK
    SEND_VISIO_MESSAGE_ON_CHAT = 143, // OK

    ACCESS_TO_DISCUSSION_GROUP_MODULE = 150, // OK
    ACCESS_TO_DISCUSSION_GROUP = 151, // OK
    SEND_MESSAGE_ON_GROUP = 152, // OK
    SEND_VISIO_MESSAGE_ON_GROUP = 143, // OK

    GO_OUT_OF_APP = 300, // OK
    GO_BACK_ON_APP = 301, // OK
}

// Type tracking name
export const TypeNameTracking: any = {};
TypeNameTracking[TypeTracking.ACCESS_TO_PRINCIPAL_MODULE] = "Access to principal module";
TypeNameTracking[TypeTracking.ACCESS_TO_WIDGET_MODULE] = "Access to widget module";

TypeNameTracking[TypeTracking.ACCESS_TO_ATTENDEES_MODULE] = "Access to attendees module";
TypeNameTracking[TypeTracking.ACCESS_TO_SPEAKERS_MODULE] = "Access to speakers module";
TypeNameTracking[TypeTracking.ACCESS_TO_PROFILE] = "Access to profile";
TypeNameTracking[TypeTracking.EDIT_PROFILE] = "Edit profile";

TypeNameTracking[TypeTracking.ACCESS_TO_SCHEDULES_MODULE] = "Access to schedules module";
TypeNameTracking[TypeTracking.ACCESS_TO_SCHEDULE_SESSION] = "Access to schedule session";

TypeNameTracking[TypeTracking.ACCESS_TO_FEED_MODULE] = "Access to feed module";
TypeNameTracking[TypeTracking.SEND_LIKE_ON_FEED] = "Send like on feed";
TypeNameTracking[TypeTracking.REMOVE_LIKE_ON_FEED] = "Remove like on feed";
TypeNameTracking[TypeTracking.SEND_COMMENT_ON_FEED] = "Send comment on feed";
TypeNameTracking[TypeTracking.DELETE_COMMENT_ON_FEED] = "Delete comment on feed";

TypeNameTracking[TypeTracking.ACCESS_TO_SURVEYS_MODULE] = "Access to surveys module";
TypeNameTracking[TypeTracking.ACCESS_TO_SURVEY] = "Access to survey";
TypeNameTracking[TypeTracking.SEND_SURVEY] = "Send survey";

TypeNameTracking[TypeTracking.ACCESS_TO_QUIZS_MODULE] = "Access to quizs module";
TypeNameTracking[TypeTracking.ACCESS_TO_QUIZ] = "Access to quiz";
TypeNameTracking[TypeTracking.SEND_QUIZ] = "Send quiz";

TypeNameTracking[TypeTracking.ACCESS_TO_ASK_QUESTION_MODULE] = "Access to ask question module";
TypeNameTracking[TypeTracking.ACCESS_TO_ASK_QUESTION] = "Access to ask question";
TypeNameTracking[TypeTracking.SEND_QUESTION] = "Send question";

TypeNameTracking[TypeTracking.ACCESS_TO_DOCUMENTS_MODULE] = "Access to documents module";
TypeNameTracking[TypeTracking.ACCESS_TO_DOCUMENTS_FOLDER] = "Access to documents folder";
TypeNameTracking[TypeTracking.OPEN_DOCUMENT] = "Open document";

TypeNameTracking[TypeTracking.ACCESS_TO_IMAGES_MODULE] = "Access to images module";
TypeNameTracking[TypeTracking.ACCESS_TO_IMAGES_FOLDER] = "Access to images folder";
TypeNameTracking[TypeTracking.OPEN_IMAGE] = "Open image";

TypeNameTracking[TypeTracking.ACCESS_TO_INFOBOOTH_MODULE] = "Access to infobooth module";

TypeNameTracking[TypeTracking.ACCESS_TO_CHECKIN_MODULE] = "Access to checkin module";

TypeNameTracking[TypeTracking.ACCESS_TO_RANKING_MODULE] = "Access to ranking module";

TypeNameTracking[TypeTracking.ACCESS_TO_PLANS_MODULE] = "Access to plans module";

TypeNameTracking[TypeTracking.ACCESS_TO_DIFFUSION_MODULE] = "Access to diffusion module";

TypeNameTracking[TypeTracking.ACCESS_TO_CHATS_MODULE] = "Access to chats module";
TypeNameTracking[TypeTracking.ACCESS_TO_CHAT] = "Access to chat";
TypeNameTracking[TypeTracking.SEND_MESSAGE_ON_CHAT] = "Send message on chat";
TypeNameTracking[TypeTracking.SEND_VISIO_MESSAGE_ON_CHAT] = "Send visio message on chat";

TypeNameTracking[TypeTracking.ACCESS_TO_DISCUSSION_GROUP_MODULE] = "Access to group discussion module";
TypeNameTracking[TypeTracking.ACCESS_TO_DISCUSSION_GROUP] = "Access to group discussion";
TypeNameTracking[TypeTracking.SEND_MESSAGE_ON_GROUP] = "Send message on discussion group";
TypeNameTracking[TypeTracking.SEND_VISIO_MESSAGE_ON_GROUP] = "Send visio message on discussion group";

TypeNameTracking[TypeTracking.GO_OUT_OF_APP] = "Leaving application";
TypeNameTracking[TypeTracking.GO_BACK_ON_APP] = "Reentering application";
