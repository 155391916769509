import { IntegrationsActionsTypes, IntegrationsActionsUnion } from '../actions/integrations.actions';
import { IIntegration } from '../interfaces/integrations.interface';

export const initialState: IIntegration = null;

export function integrationsReducer(state = initialState, action: IntegrationsActionsUnion): IIntegration {
    switch (action.type) {
        case IntegrationsActionsTypes.GetIntegrations: {
            return (action.payload)
        }

        default: {
            return (state);
        }
    }
}