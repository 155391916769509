import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { UtilityService } from "src/app/shared/services";
import { DocumentService } from "src/app/providers/document/document.service";
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
    selector: "app-documents",
    templateUrl: "./documents.component.html",
    styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    @Input() eventId: string;
    @Input() showTitle: boolean = false;
    @Input() widthImg: number = 32;
    @Input() showNoResultImage: boolean = false;

    doc: any;

    @Input() documents: any[] = [];

    eventColors: EventColors = new EventColors();

    language: string;
    constructor(
        private SUtility: UtilityService,
        private translate: TranslateService,
        private docService: DocumentService,
        private SEventData: EventDataService
    ) { }

    ngOnInit() {
        this.language = this.SEventData.getLanguage();
        this.loadColors();
    }

    checkType(type: string): string {
        switch (type) {
            case "doc":
            case "docx":
            case "docm":
            case "DOC":
            case "DOCX":
            case "DOCM":
                return "Word";

            case "xls":
            case "xlt":
            case "xls":
            case "xml":
            case "xlsx":
            case "xlsm":
            case "xlsb":
            case "xltx":
            case "xltm":
            case "XLS":
            case "XLT":
            case "XLS":
            case "XML":
            case "XLSX":
            case "XLSM":
            case "XLSB":
            case "XLTX":
            case "XLTM":
                return "Excel";

            case "pdf":
            case "PDF":
                return "PDF";

            case "png":
            case "PNG":
                return "PNG";

            case "jpg":
            case "JPG":
                return "JPG";
            case "jpeg":
            case "JPEG":
                return "JPEG";

            case "ppt":
            case "pptx":
            case "thmx":
            case "ppsx":
            case "pps":
            case "ppsm":
            case "PPT":
            case "PPTX":
            case "THMX":
            case "PPSX":
            case "PPS":
            case "PPSM":
                return "PowerPoint";

            case "mp4":
            case "wmv":
            case "3gp":
            case "avi":
            case "mp3":
            case "wav":
            case "MP4":
            case "WMV":
            case "3GP":
            case "AVI":
            case "MP3":
            case "WAV":
                return "Media";

            default:
                return "Generic File";
        }
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * Open a document
     * @param document
     */
    openDocument(document) {
        this.docService.openDocument(document, this.eventId);
    }

    /**
     * Present a confirmation alert
     */
    async presentAlertConfirm() {
        const alert = await this.SUtility.presentAlert(
            this.translate.instant("global.alerts.thankyou_answer"),
            this.translate.instant("global.alerts.answer_saved_successfully"),
            [
                {
                    text: this.translate.instant("global.buttons.ok"),
                    handler: () => { },
                },
            ],
            []
        );

        await alert.present();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }
}
