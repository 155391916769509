import { Injectable } from '@angular/core';
import { AlertController, Platform } from "@ionic/angular";
import { FileService } from '../file/file.service';
import { AnalyticsService, UtilityService } from "src/app/shared/services";
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Share } from '@capacitor/share';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private loader: HTMLIonLoadingElement;

    constructor(
        private fileService: FileService,
        private platform: Platform,
        private SAnalytics: AnalyticsService,
        private SUtility: UtilityService,
        private SEventData: EventDataService,
        private alertCtrl: AlertController,
        private translateService: TranslateService
    ) { }

    /**
     * @param document 
     * @param eventId
     * @description Open the document provided as "document" param and on 
     * mobile devices it creates a directory (EzbizEventDoc) and download the 
     * documents before open them 
     */
    public openDocument(document: any, eventId: string) {
        this.SAnalytics.documentAccess(eventId, document);
        const platforms = this.platform.platforms().join();
        if (platforms.includes("android") || platforms.includes("ios")) {
            if (this.fileService.fileFormats.includes(document.type)) {
                this.openDocMobile(document);
            } else {
                this.openDocWeb(document);
            }
        } else {
            this.openDocWeb(document);
        }
    }

    /**
     * Download a document to the "Documents" directory and open it if possible
     * i.e. so far exel files are not possible to open
     */
    private openDocMobile(document: any) {
        this.presentLoading();
        const language = this.SEventData.getLanguage();

        // if the directory already exits the promise is rejected, either way 
        // the img is downloaded
        this.fileService.downloadFile(
            document.name[language], 
            document.type, 
            document.url/*, 
            "EzbizEventDoc"*/
        ).then((response) => {
            this.fileService.openFile(response.path, document.type)
                .catch(() => this.docOpeningErr(`file://${response.path}`))
                .finally(() => this.closeLoading())
        }).catch((error) => {
            console.error(error);
            this.genericErr()
            this.closeLoading();
        });
    }

    /**
     * Open docuemnt in a new tab of a web browser
     * @param document 
     */
    private openDocWeb(document: any): void {
        window.open(document.url, "_blank");
    }

    private async docOpeningErr(uri: string) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant("global.alerts.error"),
            message: this.translateService.instant("global.alerts.no_app_found_to_open_doc"),
            buttons: [
                {
                    text: this.translateService.instant("global.buttons.share"),
                    handler: () => { 
                        Share.share({ url: uri }).catch(() => this.genericErr())
                    }
                },
                { text: this.translateService.instant("global.buttons.back") }
            ]
        });
        alert.present();
    }

    private async genericErr() {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant("global.alerts.error"),
            message: this.translateService.instant("global.alerts.generic_error"),
            buttons: [{
                text: this.translateService.instant("global.buttons.back")
            }]
        });
        alert.present();
    }

    /**
     * Present loading
     */
    private async presentLoading() {
        this.loader = await this.SUtility.presentLoading("Loading...", 0, "crescent");
        await this.loader.present();
    }

    /**
     * Close loading
     */
    private async closeLoading() {
        await this.loader.dismiss();
    }
}
