export class CeuVisio {
    uid: string;
    members: any;
    startDate: string;
    endDate: string;
    url: string;

    constructor() {
        this.uid = null;
        this.members = {};
        this.startDate = null;
        this.endDate = null;
        this.url = null;
    }
}
