import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { 
    Firestore, 
    doc,
    docData,
    DocumentData, 
} from '@angular/fire/firestore';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import {
	IonContent,
	MenuController,
	ToastController,
    IonSelect,
		Platform
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TypeTracking } from "src/app/enums/type-tracking";
import { CeuGroup } from "src/app/models/ceu-group";
import { CeuTrack } from "src/app/models/ceu-track";
import { DateService } from "src/app/providers/date/date.service";
import { DaoScheduleService } from "src/app/providers/db/dao-schedule.service";
import { DaoSessionFeedbackService } from "src/app/providers/db/dao-session-feedback.service";
import { DaoTrackService } from "src/app/providers/db/dao-tracks.service";
import { LuxonService } from "src/app/providers/luxon/luxon.service";
import { GetScheduleSessions } from "src/app/shared/actions/schedules.actions";
import { AppState } from "src/app/shared/reducers";
import { AnalyticsService, GlobalService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { TypeVisionModule } from "../../models/type-vision-module";
import { DaoGroupsService } from "../../providers/db/dao-groups.service";
import { PathApi } from "./../../paths/path-api";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-schedule",
	templateUrl: "./schedule.page.html",
	styleUrls: ["./schedule.page.scss"]
})
export class SchedulePage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	public module = null;
	cancelTxt: string = null;
	isMobile: boolean = false;

	// @ViewChild(IonInfiniteScroll)
	// infiniteScroll: IonInfiniteScroll;
	// @ViewChild(IonVirtualScroll)
	// virtualScroll: IonVirtualScroll;
	@ViewChild("content") content: IonContent;
    @ViewChild('filter') filterRef: IonSelect;
	@ViewChild('filterTracks') filterTracksRef: IonSelect;
	@ViewChild('filterLocations') filterLocationsRef: IonSelect;

	eventId: string = null;
	moduleId: string = null;
	sessionFeedbackModule: string = null;

	//https options
	public requestOptions: any;
	public headers: any;

	lastSession = null;

	loader: boolean;
	loaderByTrack: boolean = false;

	habilitedLimit: boolean = false;
	custom = "na lobi hein";

	selectedDate = "all";
	sessions: any[] = [];
	sessionsAll: any[] = [];
	// dates: Array<IDate> = [];
	dates: Array<string> = [];
	allSessionDates: any[] = [];
	tracks: Array<CeuTrack> = [];
	selectedTrackId = null;
	locations: Array<any> = [];

	groups = []; //groups of the event.
	userGroups: any = []; //user groups

	// tabs filters
	viewHour: boolean = false; // html filter by date
	viewTrack: boolean = false; //html filter by track
	viewGroup: boolean = false; //html of the event groups

	// btns
	backBtn: boolean = true;

	searchBtn: boolean = false; //controls on the html button that activates the searchOpen ion-searchbar.
	searchOpen: boolean = false; //controls in HTML the searchOpen's Ion-searchbar.

	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;

	// module views
	typeVisionModule = null; //type of module views.
	GLOBAL_VISION = TypeVisionModule.GLOBAL_VISION; // visão global
	DIVIDED_BY_GROUPS = TypeVisionModule.DIVIDED_BY_GROUPS; // visão dividida por grupo
	GROUP_VISION = TypeVisionModule.GROUP_VISION; // visão por grupos
	GROUP_ACCESS_PERMISSION = TypeVisionModule.GROUP_ACCESS_PERMISSION; //acessp limitado por grupo

    language: string;

	allowCloseReferences: boolean = true;

	sessionIndex: number = -1;

	showHeader: boolean;
	showSpeakers: boolean;
	showDescription: boolean;
	showCustomFields: boolean;
	icsDownloadable: boolean;

	customFields: any[] = [];
	eventFormatedLang: any;
	arraryCustoms: any[][] = [];
	customs: any;

	// Test
	filterButton: boolean = true;
	sessionsByTracks: any[] = [];

	filteredSessions: Array<any> = [];
	filterTracks: string = "all";
	filterLocations: string = "all";
	filterDates: string;

    moduleBehavior: string;
    userId: string;

	constructor(
		private route: ActivatedRoute,
		private luxon: LuxonService,
		public http: HttpClient,
		public global: GlobalService,
		private dbSchedule: DaoScheduleService,
		public toastController: ToastController,
		private router: Router,
		private events: Events,
		private menuCtrl: MenuController,
		private translateService: TranslateService,
		private SAnalytics: AnalyticsService,
		private dbTracks: DaoTrackService,
		private dbGroups: DaoGroupsService,
		private daoFeedback: DaoSessionFeedbackService,
		private store: Store<AppState>,
		private SDate: DateService,
		private firestore: Firestore,
        private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
				private platform: Platform,
	) {
		this.headers = new HttpHeaders();
		this.headers.append("Accept", "application/json");
		this.headers.append("Content-Type", "application/json");
		this.requestOptions = { headers: this.headers };
		this.cancelTxt = this.translateService.instant("global.buttons.cancel");
		//this.menuCtrl.enable(true);

		this.events.subscribe("menuBadge", () => {
			this.menuBadge = this.global.notificationBadge;
		});
	}

	loadModuleFeedback() {
		this.daoFeedback.getFeedbackModule(this.eventId, (modules) => {
			if (modules.length >= 1) {
				this.sessionFeedbackModule = modules[0].uid;
			}
		});
	}

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const sub = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;

            const el = document.getElementById("header-agenda");
            if (el !== undefined && el !== null) el.style.setProperty("--box-shadow-color", this.eventColors.link_color + "40");

            document.body.style.setProperty('--menu-color', this.eventColors.menu_color);
            document.body.style.setProperty('--menu-text-color', this.eventColors.menu_text_color);
        });
        this.subscriptions.push(sub);
    }

	scheduleTrackName: string = this.translateService.instant("pages.schedule.filter_track");

	ngOnInit() {
        this.moduleBehavior = this.route.snapshot.parent.url[0].path;   // as schedule or personal-schedule

				if (this.platform.is('ios') || this.platform.is('android')) {
					this.isMobile = true;
				}

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        this.loadColors();
        this.language = this.SEventData.getLanguage();
        this.menuBadge = this.global.notificationBadge;
        this.loadModuleFeedback();

        // Track analytics
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);

        if (this.moduleBehavior == "schedule") {
            this.SAnalytics.createTrackRoadToModule(
                this.eventId,
                this.moduleId,
                TypeTracking.ACCESS_TO_SCHEDULES_MODULE
            );
            this.getLocations();
            this.getTracksModule();
        }

        this.loaderByTrack = false;

        if (this.global.previousPage == "container") {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        this.getModuleSettings();
        this.getSessionsData(this.typeVisionModule);
	}

	ionViewWillEnter() {
		if (this.sessions.length > 0) {
			//this.virtualScroll.checkEnd();
			if (this.sessionsAll.length == 1) {
				this.openSession(this.sessionsAll[0].uid);
			}
		}
	}

	headerHeightFn(item, index) {
		return 30;
	}

	itemHeightFn(item, index) {
		if (item.tracks.length > 0) return 118;
		if (item.locations.length === 0) return 61;
		return 75;
	}

    /**
     * @description fetch the schedule data (from module schedule or personal-schedule), and 
     * assign some of its contentsd to properties, responsible for the behavior of this component 
     */
	getModuleSettings(): void {
		//this.sessions = [];
		// this.sessionsAll = [];
		// this.selectedDate = "all";
		// this.selectedTrackId = null;

		// get the module information
		this.subscriptions.push(
			this.dbSchedule.getModule(this.eventId, this.moduleId).subscribe(async (module) => {
				this.module = module;

                this.showHeader = (module.showHeader !== undefined) ? module.showHeader : true;
                this.showSpeakers = (module.showSpeakers !== undefined) ? module.showSpeakers : false;
                this.showDescription = (module.showDescription !== undefined) ? module.showDescription : false;
                this.showCustomFields = (module.showCustomFields !== undefined) ? module.showCustomFields : false;
                this.icsDownloadable = (module.icsDownloadable !== undefined) ? module.icsDownloadable : false;
                this.filterButton = (module.enableTrackFilter !== undefined) ? module.enableTrackFilter : false;

                const el = document.getElementById(`${this.moduleId}-filtring-header`);
                if (el !== undefined && el !== null) el.style.display = this.showHeader ? "block" : "none";

                this.typeVisionModule = module.typeVision;
                if (module.trackName) { this.scheduleTrackName = module.trackName[this.language]; }

				// // verifies the type of vision of the module. global vision
				// if (this.typeVisionModule === this.GLOBAL_VISION) {
				// 	// this.getGlobalVision();
				// }

				// //divided by groups
				// if (this.typeVisionModule === this.DIVIDED_BY_GROUPS) {
				// 	this.getDividedByGroupsVision();
				// }

				// // group vision
				// if (this.typeVisionModule === this.GROUP_VISION) {
				// 	this.getGroupVision();
				// }

				// //limited access by groups
				// if (this.typeVisionModule === this.GROUP_ACCESS_PERMISSION) {
				// 	this.getLimitedAccessByGroupsVision();
				// }
			})
		);
	}

	ionViewWillLeave() {}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.selectedDate = "all";
		this.selectedTrackId = null;
		this.viewHour = false;
		this.viewTrack = false;
		this.viewGroup = false;

		this.searchBtn = false;
		this.searchOpen = false;

		this.groups = [];
		this.tracks = [];
		this.sessions = [];
		this.sessionsAll = [];

		// close refs
		this.dbSchedule.closeRefGetNextPageSessionsGlobalVision();
		this.dbSchedule.closeRefGetSessionsGroupGlobalVision();
		this.dbSchedule.closeRefGetSessionsGroupsVision();
		this.dbSchedule.closeRefGetNextPageSessionsLimitedAccessByGroups();

		this.dbGroups.closeRefGetGroups();
		this.dbGroups.closeRefContSessionsOfGroup();

		this.dbTracks.closeGetTracksModule();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	// /**
	//  * Reload date for sessions
	//  */
	// reloadDateForSessions() {
	// 	this.virtualScroll.checkEnd();
	// }

	/**
	 * Puts the session date in front
	 * @param session
	 * @param index
	 * @param sessionsList
	 */
	dateHeader(session, index, sessionsList) {
		if (index == 0 || (index != 0 && session.date != sessionsList[index - 1].date)) {
			return session.dateFormated;
		}
	}

	// clear the session fields for front view
	setTheSession(session) {
		// dates
		// if (typeof session.date === "number") {
		// 	if (this.SEventData.eventData.language && this.SEventData.eventData.language == "en_US") {
		// 		if (session.newDate) {
		// 			// let day = (session.newDate.day.toString().length == 1) ? '0' + session.newDate.day.toString() : session.newDate.day.toString();
		// 			// let month = (session.newDate.month.toString().length == 1) ? '0' + session.newDate.month.toString() : session.newDate.month.toString();
		// 			// let year = (session.newDate.year.toString().length == 1) ? '0' + session.newDate.year.toString() : session.newDate.year.toString();
		// 			// session.dateFormated = month + '/' + day + '/' + year;
		// 			session.dateFormated = this.luxon.getStringDateFromDate(session.newDate, 0);
		// 		} else {
		// 			session.dateFormated = moment.unix(session.date).format("MM/DD/YYYY");
		// 		}
		// 	} else {
		// 		if (session.newDate) {
		// 			// let day = (session.newDate.day.toString().length == 1) ? '0' + session.newDate.day.toString() : session.newDate.day.toString();
		// 			// let month = (session.newDate.month.toString().length == 1) ? '0' + session.newDate.month.toString() : session.newDate.month.toString();
		// 			// let year = (session.newDate.year.toString().length == 1) ? '0' + session.newDate.year.toString() : session.newDate.year.toString();
		// 			// session.dateFormated = day + '/' + month + '/' + year;
		// 			session.dateFormated = this.luxon.getStringDateFromDate(session.newDate, 1);
		// 		} else {
		// 			session.dateFormated = moment.unix(session.date).format("DD/MM/YYYY");
		// 		}
		// 	}
		// }

        // start and end time
        // session.startTime = this.dt.getConvertedTime(session.startTime, this.module.timezoneType);
		// session.endTime = (typeof session.endTime == "string" || session.endTime.length > 0) ?
		// 	this.dt.getConvertedTime(session.endTime, this.module.timezoneType) :
		// 	"--:--";

        const timezone = (this.moduleBehavior == "schedule") ? this.module.timezoneType : "event";
        session.startTime = this.dt.getConvertedTime(session.startTime, timezone);
		session.endTime = (typeof session.endTime == "string" || session.endTime.length > 0) ?
			this.dt.getConvertedTime(session.endTime, timezone) :
			"--:--";

		// locations of session
		const locations = [];

		for (const uid in session.locations) {
			locations.push(session.locations[uid]);
		}

		// sort by the order field.
		locations.sort(function (a, b) {
			return a.order - b.order;
		});

		session.locations = locations;

		// tracks of session
		const tracks = [];

		for (const uid in session.tracks) {
			tracks.push(session.tracks[uid]);
		}

		tracks.sort((a, b) => (a.identifier < b.identifier ? -1 : a.identifier > b.identifier ? 1 : 0));

		session.tracks = tracks;

		// groups of session
		const groups = [];

		for (const uid in session.groups) {
			groups.push(session.groups[uid]);
		}

		session.groups = groups;

		// -------- Custom fields ------- //
		let attendeeCustomFields: any[] = [];

		// tslint:disable-next-line: forin
		for (const uid in session.customFields) {
			attendeeCustomFields.push(session.customFields[uid]);
		}

		if (this.customs) {
			let arrayTemp = [];
			this.customs.forEach((e) => {
				if (
					attendeeCustomFields.some(
						(a) =>
							a.uid === e.uid &&
							e.textValue[this.eventFormatedLang] &&
							e.textValue[this.eventFormatedLang].length > 0
					)
				) {
					this.customFields.push(e);
					arrayTemp.push(e);
				}
			});
			this.arraryCustoms.push(arrayTemp);
		}

		return session;
	}

	async gettingAttendeeCustomFields(): Promise<any> {
		let userId = this.SUserData.userId;
		// userId = await this.global.loadUserId();

		if (userId) {
			return new Promise((res, rej) => {
                const ref = doc(this.firestore, `events/${this.eventId}/attendees/${this.SUserData.userId}`);
                docData(ref).pipe(take(1)).subscribe((attendee) => {
					// this.customFields = [];
					if (attendee) {
						const customs = attendee.customFields;
						if (customs && customs.length > 0) {
							res(customs);
						} else {
							rej("No custom fields for the actual attendee !");
						}
					}
				});
			});
		} else {
			return Promise.reject("No custom fields for the actual attendee !");
		}

		// this.arraryCustoms = [[]]
	}

	// loads the module tracks.
	allowFilterTracks: boolean = false;
	getTracksModule() {
		this.dbTracks.getTracksModule(this.moduleId, this.language, (data) => {
			if (data.length >= 1) {
				this.allowFilterTracks = true;
			} else {
				this.allowFilterTracks = false;
			}
			this.tracks = [];
			this.tracks = data;
		});
	}

	// load the event groups.
	getGroupsEvent() {
		this.dbGroups.getGroups(this.eventId, (groups: Array<CeuGroup>) => {
			// Sort the groups alphabetically.
			groups.sort(function (a, b) {
				return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
			});

			// checks the number of sessions in the module that belongs to each group.
			for (const group of groups) {
				const groupId = group.uid;
				const moduleScheduleId = this.moduleId;

				group.qtdSessions = 0;

				// account the number of sessions that the group has from the schedule module.
				this.dbGroups.contSessionsOfGroup(groupId, moduleScheduleId, (qtd: number) => {
					group.qtdSessions = qtd;
				});
			}

			this.groups = groups;
			this.loader = false;
		});
	}

	async presentToast(time) {
		const toast = await this.toastController.create({
			message: time,
			duration: 3000
		});

		toast.present();
	}

	// filter sessions by name
	filterSessionsByName(ev) {
		const letters = ev.target.value;

		// this.sessions = [];
		this.lastSession = null;
		this.loader = true;
		this.loaderByTrack = true;
		this.selectedDate = null;

		if (letters.trim().length === 0) {
			this.sessions = this.sessionsAll;
			this.activeDate !== "all" && this.filterByDate(this.activeDate);
		} else {
			// makes the session filter by name.
			const filteredArr = this.activeDate !== "all" ? [...this.sessions] : this.sessionsAll;
			this.sessions = filteredArr.filter((s) =>
				s.name[this.language].toLowerCase().includes(letters.toLowerCase())
			);
			// for (const session of this.sessionsAll) {
			// 	if (session.name[this.userFormatedLang].toLowerCase().indexOf(letters.toLowerCase()) > -1) {
			// 		this.sessions.push(session);
			// 	}
			// }
		}

		this.filterByTrackSpecialOperation();

		this.loader = false;
		this.loaderByTrack = false;
	}

	// // filter session by date
	 activeDate: string = "all";
	// filterByDate(date: string) {
	// 	this.sessions = [];
	// 	this.loader = true;
	// 	this.loaderByTrack = true;
	// 	this.lastSession = null;
	// 	this.selectedDate = date;
	// 	this.activeDate = date;

	// 	// Filter by dates
	// 	if (this.selectedDate == "all") {
	// 		this.sessions = [...this.sessionsAll];
	// 	} else {
	// 		if (!this.filterByTrackSpecial) {
	// 			for (const session of this.sessionsAll) {
	// 				let sessionDate = session.newDate.day + "/" + session.newDate.month + "/" + session.newDate.year;
	// 				if (date === sessionDate) {
	// 					this.sessions.push(session);
	// 				}
	// 			}
	// 		} else {
	// 			for (const session of this.sessionsAll) {
	// 				let sessionDate = session.newDate.day + "/" + session.newDate.month + "/" + session.newDate.year;

	// 				if (date === sessionDate) {
	// 					this.sessions.push(session);
	// 				}
	// 			}

	// 			this.filterByTrackSpecialOperation();
	// 		}
	// 		this.allSessionDates = Array.from(new Set([...this.sessionsAll].map((item) => item.dateFormated)));
	// 	}

	// 	this.scrollToTop();
	// 	this.loader = false;
	// 	this.loaderByTrack = false;
	// }

	/**
	 * Organize and filter datas by tracks
	 */
	filterByTrackSpecialOperation() {
		if (this.filterButton) {
			this.sessionsByTracks = [];
			this.sessions.forEach((session) => {
				if (session.tracks.length > 0) {
					session.tracks.forEach((track) => {
						let groupTrack = this.sessionsByTracks.find(
							(group) => group.track && group.track.uid === track.uid
						);
						if (!groupTrack) {
							this.sessionsByTracks.push({
								beginDate: "",
								endDate: "",
								mainLocation: null,
								sessions: [session],
								track: track
							});
						} else {
							groupTrack.sessions.push(session);
						}
					});
				} else {
					this.sessionsByTracks.push({
						beginDate: DateTime.fromObject(session.newStartTime).toISO(),
						endDate: session.newEndTime ? DateTime.fromObject(session.newEndTime).toISO() : null,
						mainLocation: null,
						sessions: [session],
						track: null
					});
				}
			});
			this.sessionsByTracks
				.map((groupTrack) => {
					groupTrack.sessions.sort((a, b) =>
						DateTime.fromObject(a.newStartTime) > DateTime.fromObject(b.newStartTime)
							? 1
							: DateTime.fromObject(a.newStartTime) < DateTime.fromObject(b.newStartTime)
							? -1
							: 0
					);
					groupTrack.beginDate =
						groupTrack.sessions.length > 0
							? DateTime.fromObject(groupTrack.sessions[0].newStartTime).toISO()
							: "";
					groupTrack.endDate =
						groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].newEndTime
							? DateTime.fromObject(
									groupTrack.sessions[groupTrack.sessions.length - 1].newEndTime
							  ).toISO()
							: "";
					let locations = [];
					groupTrack.sessions.forEach((session) => {
						if (session.locations.length > 0) {
							session.locations.forEach((location) => {
								if (!locations.find((loc) => loc.uid === location.uid)) {
									locations.push(location);
								}
							});
						}
					});

					if (locations.length === 1) {
						groupTrack.mainLocation = locations[0];
					}

					return groupTrack;
				})
				.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
		}
	}

	// filter session by track
	// filterByTrack($ev) {
	// 	this.sessions = [];
	// 	this.loaderByTrack = true;
	// 	this.loader = false;
	// 	this.lastSession = null;
	// 	this.selectedDate = "all";

	// 	if ($ev.target.value === "all") {
	// 		this.sessions = this.sessionsAll;
    //         this.selectedTrackId = null;
    //         this.viewTrack = false;
	// 	} else {
	// 		let track = $ev.target.value;
	// 		this.selectedTrackId = track.uid;

	// 		// filter the sessions by track
	// 		for (const session of this.sessionsAll) {
	// 			const index = session.tracks.map(e => e.uid).indexOf(this.selectedTrackId);

	// 			if (index > -1) {
	// 				this.sessions.push(session);
	// 			}
	// 		}
    //         this.viewTrack = true;
	// 	}
	// 	this.allSessionDates = Array.from(new Set([...this.sessions].map((item) => item.dateFormated)));
	// 	this.loaderByTrack = false;
	// }


    /**
	 * @description Apply filters to the list of sessions presented in the schedule module
	 */
	async filterSessions() {
		this.filteredSessions = (await this.filterGroupAccess(this.sessions));

		// by date
		this.filteredSessions = this.filteredSessions.filter((session) => {
			return (session.date == this.filterDates);
		});

		// by tracks
		if (this.filterTracks != "all") {
			this.filteredSessions = this.filteredSessions.filter((session) => {
				for (let track in session.tracks) {
					if (session.tracks[track].identifier == this.filterTracks) {
						return true;
					}
				}
				return false;
			});
		}

		// by locations
		if (this.filterLocations != "all") {
			this.filteredSessions = this.filteredSessions.filter((session) => {
				for (let location in session.locations) {
					if (session.locations[location].identifier == this.filterLocations) {
						return true;
					}
				}
				return false;
			});
		}

		this.loader = false;
		this.scrollToTop();
	}

	/**
	 * @description filter sessions by group (i.e. check if the user has group access to the sessions)
	 * @param sessions 
	 * @returns the sessions available to the user
	 */
	async filterGroupAccess(sessions: any[]) {
        const groups: Object = (await this.SUserData.getUserDataSnapshot()).groups
		const groupIds: Array<string> = (groups) ? Object.keys(groups) : [];

		let auxDate: string = "";
		let filteredSessions = sessions.filter((session) => {
			let sessionGroupIds: Array<string> = [];
			if (session.groups) {
				session.groups.forEach((group) => {
					sessionGroupIds.push(group.uid)
				});
			}
			else { return true; }

			// if session isnt restricted by groups 
			if (sessionGroupIds.length == 0) { return true; }

			// check if the any of the user groups match with any of the session groups
			for (let i = 0; i < groupIds.length; i++) {
				if (sessionGroupIds.includes(groupIds[i])) {
					return true;
				}
			}

			auxDate = session.date;
			return false;
		});

		if (
			auxDate != "" && filteredSessions.find((session) => { return (session.date == auxDate) ? true : false; }).length
		) { 
			const index = this.dates.indexOf(auxDate);
			delete this.dates[index];
		}

		return filteredSessions;
	}

	filterByDate(date: string) {
		this.filterDates = date;
		this.filterSessions();
	}
	
	filterByTrack(filter: any): void {
		this.filterTracks = filter.target.value;
		this.filterSessions();
	}
	
	filterByLocation(filter: any): void {
		this.filterLocations = filter.target.value;
		this.filterSessions();
	}

	/**
	 * @description get schedule module data
	 * @param typeVisio 
	 */
	async getSessionsData(typeVisio?: number) {
		// this.custom = await this.gettingAttendeeCustomFields().catch((error) => { console.log(error); });
		// this.userId = await this.global.loadUserId();
        this.userId = this.SUserData.userId;
        
        /**
         * @description callback responsible to handle the data of sessions 
         */
        const handleSessionData = (sessions: Array<DocumentData>) => {
            this.sessions = sessions.map((session) => { return this.setTheSession(session) });

            this.dates = [];
			sessions.forEach((session) => {
				session.date = (typeof session.date == "string") ? `${session.date.substring(0, 11)}00:00:00.000` : session.date;
				if (!this.dates.includes(session.date)) {
					this.dates.push(session.date);
				}
			});

            this.filterDates = this.dates[0];
            this.filterSessions();
        }

        if (this.moduleBehavior == "personal-schedule") {
            this.subscriptions.push(
                this.dbSchedule.getPersonalSchedule(this.eventId, this.userId).subscribe(handleSessionData)
            )
        }
        else {
            // schedule
            this.subscriptions.push(
                this.dbSchedule.getSchedule(this.eventId).subscribe(handleSessionData)
            )
        }
            // this.filterDates = (
            //     this.global.event != null && 
            //     this.global.event.startDate != undefined &&
            //     this.dates.includes(this.global.event.startDate)
            // ) ? this.global.event.startDate : this.dates[0];

			 
			// setTimeout(() => {
			// 	// slide to the event start date (if exist any session with that date) in the filter by dates bar
			// 	const slideChange = (index: number) => {
			// 		if (index > -1) {
			// 			document.getElementById(`segment-${index}`).scrollIntoView({
			// 				block: 'center',
			// 				inline: 'center'
			// 			});
			// 		}
			// 	}
			// 	slideChange(this.dates.indexOf(this.filterDates));
			// }, 100);
	}

    /**
     * @description make a request to the firestore db to retrive all locations related to this 
     * event, then allocate them in the array "locations".
     */
    getLocations(): void {
        this.dbSchedule.getLocations(this.eventId).then((locations) => {
            locations.forEach((location) => {
                this.locations.push(location.identifier);
            });
        }).catch((error) => {
            console.log(error);
        })
    }


	// activates and deactivates boolean variables that control the html tab, the part of filters by date and by tracks
	changeView($ev) {
		this.selectedDate = null;
		this.selectedTrackId = null;

		switch ($ev.detail.value) {
			case "byHour":
				this.viewTrack = false;
				this.viewHour = true;
				this.selectedDate = "all";
				this.sessions = this.sessionsAll;
				this.allSessionDates = Array.from(new Set([...this.sessions].map((item) => item.dateFormated)));

				break;
			case "byTracks":
				this.viewHour = false;
				this.viewTrack = true;
				break;
		}
	}

	openSession(sessionId) {
		this.sessionIndex = this.sessions.indexOf(this.sessions.find((session) => session.uid == sessionId));
		this.allowCloseReferences = false;
		let navigationExtras: NavigationExtras = {
			state: {
				sessionFeedbackModule: this.sessionFeedbackModule
			}
		};

		this.router.navigate(
			[`/event/${this.eventId}/schedule-detail/${this.moduleId}/${sessionId}`],
			navigationExtras
		);
	}

	// // loads more sessions when the infinite scroll is triggered
	// moreSessions(event) {
	// 	// verifies the type of vision of the module.
	// 	if (this.typeVisionModule === this.GLOBAL_VISION) {
	// 		this.moreSessionsGlobalVision(event);
	// 	} else if (this.typeVisionModule === this.GROUP_ACCESS_PERMISSION) {
	// 		this.moreSessionsLimitedAccessByGroups(event);
	// 	} else {
	// 		event.target.disabled = true;
	// 	}
	// }

	/******************************************************************************** methods of global vision *************************************************************************************** */

	allSessionsLength: number = 0; //get number of sessions you have in module
	allowFilterByDate: boolean = false; //controls the date filter

	getHumanDate(date: string) {
		return DateTime.fromISO(date).toFormat("HH:mm");
	}

	// load the global view
	// async getGlobalVision() {
	// 	this.loader = true;

	// 	this.viewHour = true;
	// 	this.viewTrack = false;
	// 	this.viewGroup = false;

	// 	this.searchBtn = true;

	// 	this.customs = await this.gettingAttendeeCustomFields().catch((e) => console.log(e));

	// 	// loads the first 100 sessions of the module.
 	// 	this.dbSchedule.getFirstPageSessionsGlobalVision(this.moduleId).subscribe((data) => {
	// 		let nextPage = null;
	// 		let list = [];

	// 		if (data) {
	// 			nextPage = data["nextPage"];
	// 			list = [...data["sessions"]];
	// 		}

	// 		// takes the start time of the last session loaded.
	// 		if (nextPage) {
	// 			this.lastSession = nextPage;
	// 		}

	// 		// treatment of sessions
	// 		for (let session of list) {
	// 			session = this.setTheSession(session);
	// 		}

	// 		this.sessions = list;
	// 		if (this.filterByTrackSpecial) {
	// 			this.sessionsByTracks = [];
	// 			list.forEach((session) => {
	// 				if (session.tracks.length > 0) {
	// 					session.tracks.forEach((track) => {
	// 						let groupTrack = this.sessionsByTracks.find(
	// 							(group) => group.track && group.track.uid === track.uid
	// 						);
	// 						if (!groupTrack) {
	// 							this.sessionsByTracks.push({
	// 								beginDate: "",
	// 								endDate: "",
	// 								mainLocation: null,
	// 								sessions: [session],
	// 								track: track
	// 							});
	// 						} else {
	// 							groupTrack.sessions.push(session);
	// 						}
	// 					});
	// 				} else {
	// 					this.sessionsByTracks.push({
	// 						beginDate: DateTime.fromObject(session.newStartTime).toISO(),
	// 						endDate: session.newEndTime ? DateTime.fromObject(session.newEndTime).toISO() : null,
	// 						mainLocation: null,
	// 						sessions: [session],
	// 						track: null
	// 					});
	// 				}
	// 			});
	// 			this.sessionsByTracks
	// 				.map((groupTrack) => {
	// 					groupTrack.sessions.sort((a, b) =>
	// 						DateTime.fromObject(a.newStartTime) > DateTime.fromObject(b.newStartTime)
	// 							? 1
	// 							: DateTime.fromObject(a.newStartTime) < DateTime.fromObject(b.newStartTime)
	// 							? -1
	// 							: 0
	// 					);
	// 					groupTrack.beginDate =
	// 						groupTrack.sessions.length > 0
	// 							? DateTime.fromObject(groupTrack.sessions[0].newStartTime).toISO()
	// 							: "";
	// 					groupTrack.endDate =
	// 						groupTrack.sessions.length > 0 &&
	// 						groupTrack.sessions[groupTrack.sessions.length - 1].newEndTime
	// 							? DateTime.fromObject(
	// 									groupTrack.sessions[groupTrack.sessions.length - 1].newEndTime
	// 							  ).toISO()
	// 							: "";
	// 					let locations = [];
	// 					groupTrack.sessions.forEach((session) => {
	// 						if (session.locations.length > 0) {
	// 							session.locations.forEach((location) => {
	// 								if (!locations.find((loc) => loc.uid === location.uid)) {
	// 									locations.push(location);
	// 								}
	// 							});
	// 						}
	// 					});

	// 					if (locations.length === 1) {
	// 						groupTrack.mainLocation = locations[0];
	// 					}

	// 					return groupTrack;
	// 				})
	// 				.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
	// 		}

	// 		this.allSessionDates = Array.from(new Set([...this.sessions].map((item) => item.dateFormated)));
	// 		// this.loadAllSessionsAndDates();
	// 		this.loader = false;
	// 	});

	// 	// counts the total sessions you have in the module. (global vision)
	// 	this.dbSchedule.countsSessionsInTheModule(this.moduleId, (total) => {
	// 		this.allSessionsLength = total;
	// 	});

	// 	// load all module sessions and all different dates. Using the api
	// 	this.dbSchedule.getAllSessionsVisionGlobal(this.moduleId).subscribe((sessions) => {
	// 		this.sessionsAll = [];
	// 		this.dates = [];

	// 		[...sessions].forEach((session) => {
	// 			this.sessionsAll.push(this.setTheSession(session));
	// 			// pick up all different dates of sessions
	// 			const index = this.dates.indexOf(session.date.toString());

	// 			if (index === -1) {
	// 				this.dates.push(session.newDate);
	// 			}
	// 		});
	// 		this.dates = [...this.dates].filter(
	// 			(date, index, self) =>
	// 				index ===
	// 				self.findIndex(
	// 					(x) =>
	// 						x.day === date.day &&
	// 						x.hour === date.hour &&
	// 						x.millisecond === date.millisecond &&
	// 						x.minute === date.minute &&
	// 						x.month === date.month &&
	// 						x.second === date.second &&
	// 						x.year === date.year &&
	// 						x.zone === date.zone
	// 				)
	// 		);
	// 		// Push sessions on store
	// 		this.pushScheduleSessionsOnStore(this.sessionsAll);
	// 		if (this.dates) this.allowFilterByDate = true;
	// 	});
	// }

	//this.http.post(PathApi.authChangeUserType, email, this.requestOptions)
	//             .subscribe(_ => {
	//                 // case exists, return 'exists' to front manipulation
	//                 resolve('exists');
	//             }, e => {
	//                 // case not exists, return 'available' to front manipulation
	//                 reject('available');
	//             });
	generateIcsFile(session) {
		this.loader = true;
		if (session) {
			return new Promise((resolve, reject) => {
				//decalage en heures
				const testDate = new Date();
				let offsetHours = testDate.getTimezoneOffset() / 60;
				this.http
					.post(
						PathApi.dbScheduleGenerateIcsFile,
						{
							moduleId: this.moduleId,
							eventId: this.eventId,
							sessionId: session.uid,
							eventLanguage: this.SEventData.getLanguage(),
							offsetHours: offsetHours
						},
						this.requestOptions
					)
					.subscribe(
						(res) => {
							if (res && res["message"] === "success") {
								const sessionNameWithExtension = session.name[this.eventFormatedLang] + ".ics";

								let sessionName = sessionNameWithExtension.toLowerCase();
								sessionName = sessionName.split(/[ ,]+/).join("_");
								sessionName = sessionName.split(/[ ']+/).join("");
								this.downloadFile(sessionName, res["result"]);
								resolve(res);
								this.loader = false;
							}
						},
						(err) => {
							reject(err);
						}
					);
			});
		}
	}

	linkEventToGoogleCalendar(session) {
		this.loader = true;
		if (session) {
			const googleLinkPrefix = "https://calendar.google.com/calendar/render?action=TEMPLATE&";
			return new Promise((resolve, reject) => {
				//decalage en heures
				const testDate = new Date();
				let offsetHours = testDate.getTimezoneOffset() / 60;
				this.http
					.post(
						PathApi.dbScheduleGenerateIcsFile,
						{
							moduleId: this.moduleId,
							eventId: this.eventId,
							sessionId: session.uid,
							eventLanguage: this.SEventData.getLanguage(),
							offsetHours: offsetHours
						},
						this.requestOptions
					)
					.subscribe(
						(res) => {
							if (res && res["message"] === "success") {
								const icsData: string = res["result"];
								// formating data for the link
								let splitedData = icsData.split("\n");
								splitedData = splitedData.map((s) => s.replace("\r", ""));
								const arrayTimestamp = splitedData.filter((s) => s.startsWith("DTSTAMP"));
								const timestamp = arrayTimestamp[0].split(":")[1];
								const arrayTimestampStart = splitedData.filter((s) => s.startsWith("DTSTART"));
								const timestampStart = arrayTimestampStart[0].split(":")[1];
								const arrayTimestampEnd = splitedData.filter((s) => s.startsWith("DTEND"));
								const timestampEnd = arrayTimestampEnd[0].split(":")[1];

								const sessionNameEncoded =
									session.name[this.eventFormatedLang] !== undefined
										? encodeURI(session.name[this.eventFormatedLang])
										: "";

								const sessionDescriptionEncoded =
									session.descriptions && session.descriptions[this.eventFormatedLang] != undefined
										? encodeURI(session.descriptions[this.eventFormatedLang])
										: "";

								const datesEncoded = encodeURI(timestampStart + "/" + timestampEnd);

								const link =
									googleLinkPrefix +
									"dates=" +
									datesEncoded +
									"&text=" +
									sessionNameEncoded +
									"&details=" +
									sessionDescriptionEncoded;

								let a = document.createElement("a");
								a.setAttribute("href", link);
								a.setAttribute("target", "blank");
								a.click();
								resolve(res);
								this.loader = false;
							}
						},
						(err) => {
							reject(err);
						}
					);
			});
		}
	}

	downloadAgenda() {
		this.loader = true;
		return new Promise((resolve, reject) => {
			// Decalage en heures
			const testDate = new Date();
			let offsetHours = testDate.getTimezoneOffset() / 60;
			this.http
				.post(
					PathApi.dbScheduleGenerateAllIcs,
					{
						moduleId: this.moduleId,
						eventId: this.eventId,
						eventLanguage: this.SEventData.getLanguage(),
						offsetHours: offsetHours
					},
					this.requestOptions
				)
				.subscribe(
					(res) => {
						if (res && res["message"] === "success") {
							this.downloadFile("Agenda.ics", res["result"]);
							resolve(res);
							this.loader = false;
						}
					},
					(err) => {
						reject(err);
					}
				);
		});
	}

	downloadFile = (filename: string, text) => {
		const element = document.createElement("a");
		element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
		element.setAttribute("download", filename);
		element.setAttribute("target", "_blank");
		element.style.display = "none";
		element.click();
	};

	// loads more sessions when the infinite scroll is triggered (global vision)
	moreSessionsGlobalVision(event) {
		if (this.sessions.length < this.sessionsAll.length) {
			if (this.lastSession) {
				// convert a date plus the start time of the last session to timastamp for the search of the bank.
				let year = "2019";
				let month = "12";
				let day = "01";
				let hour = "00";
				let minute = "00";
				let seconds = "00";

				// const array1 = this.lastSession.date.split("/");
				// day = array1[0];
				// month = array1[1];
				// year = array1[2];

				day =
					this.lastSession.newDate.day < 10
						? `0${this.lastSession.newDate.day}`
						: this.lastSession.newDate.day;
				month =
					this.lastSession.newDate.month < 10
						? `0${this.lastSession.newDate.month}`
						: this.lastSession.newDate.month;
				year = this.lastSession.newDate.year;

				// const array2 = this.lastSession.startTime.split(":");
				// hour = array2[0];
				// minute = array2[1];
				hour =
					this.lastSession.newStartTime.hour < 10
						? `0${this.lastSession.newStartTime.hour}`
						: this.lastSession.newStartTime.hour;
				minute =
					this.lastSession.newStartTime.minute < 10
						? `0${this.lastSession.newStartTime.minute}`
						: this.lastSession.newStartTime.minute;

				const lastTime = this.luxon.createTimeStamp(
					this.luxon.createDate(`${year}`, `${month}`, `${day}`, `${hour}`, `${minute}`, seconds)
				);

				// carries 100 more sessions
				this.dbSchedule.getNextPageSessionsGlobalVision(this.moduleId, this.lastSession, (data) => {
					this.lastSession = null;
					let list = [];

					if (data["sessions"].length >= 1) {
						this.lastSession = data["nextPage"];
						list = [...data["sessions"]];
					}

					const sessionIds = [...this.sessions].map((s) => s.uid);
					// treatment of sessions
					for (let session of list) {
						if (!sessionIds.includes(session.uid)) {
							session = this.setTheSession(session);
							this.sessions.push(session);
							if (this.sessions.length == this.allSessionsLength) {
								event.target.disabled = true;
							}
						}
					}

					this.allSessionDates = Array.from(new Set([...this.sessionsAll].map((item) => item.dateFormated)));
					this.filterByTrackSpecialOperation();
					// check if you still have sessions to search
					if (this.lastSession) {
						// this.virtualScroll.checkEnd();
						event.target.complete();
					} else {
						event.target.disabled = true;
					}
				});
			} else {
				event.target.disabled = true;
			}
		}
		// If the array that stores all module sessions has not been loaded.
		else if (this.allSessionsLength == 0) {
			// this.virtualScroll.checkEnd();
			event.target.complete();
		} else {
			event.target.disabled = true;
		}
	}

	/******************************************************************************** methods of divided by groups *************************************************************************************** */

	// load the divided by groups view
	getDividedByGroupsVision() {
		this.loader = true;

		this.viewHour = false;
		this.viewTrack = false;
		this.viewGroup = true;

		this.searchBtn = false;

		// load groups
		this.getGroupsEvent();
	}

	// loads the sessions of the group.
	// getSessionsGroupDividByGroup(group) {
	// 	const groupId = group.uid;

	// 	this.loader = true; //provisório

	// 	// displays time, track, and html name filters.
	// 	this.viewHour = true;
	// 	this.viewTrack = false;
	// 	this.searchBtn = true;

	// 	// hides part of html groups.
	// 	this.viewGroup = false;

	// 	// loads the group sessions.
	// 	this.subscriptions.push(
	// 		this.dbSchedule.getSessionsGroupGlobalVision(groupId, this.moduleId).subscribe((data) => {
	// 			this.loader = false;

	// 			let nextPage = null;
	// 			let list = [];

	// 			if (data) {
	// 				nextPage = data["nextPage"];
	// 				list = [...data["sessions"]];
	// 			}

	// 			// takes the start time of the last session loaded.
	// 			if (nextPage) {
	// 				this.lastSession = nextPage;
	// 			}

	// 			// treatment of sessions
	// 			for (let session of list) {
	// 				session = this.setTheSession(session);
	// 			}

	// 			this.sessions = list;
	// 			this.sessionsAll = list;

	// 			// Push sessions on store
	// 			this.pushScheduleSessionsOnStore(this.sessionsAll);

	// 			// pick up all different dates of sessions
	// 			this.dates = [];

	// 			for (let session of this.sessionsAll) {
	// 				const index = this.dates.indexOf(session.date.toString());

	// 				if (index === -1) {
	// 					this.dates.push(session.newDate);
	// 				}
	// 			}

	// 			this.dates = [...this.dates].filter(
	// 				(date, index, self) =>
	// 					index ===
	// 					self.findIndex(
	// 						(x) =>
	// 							x.day === date.day &&
	// 							x.hour === date.hour &&
	// 							x.millisecond === date.millisecond &&
	// 							x.minute === date.minute &&
	// 							x.month === date.month &&
	// 							x.second === date.second &&
	// 							x.year === date.year &&
	// 							x.zone === date.zone
	// 					)
	// 			);

	// 			// enable filter by date
	// 			if (this.dates) this.allowFilterByDate = true;
	// 		})
	// 	);
	// }

	/******************************************************************************** methods of group vision *************************************************************************************** */
	// group vision
	// async getGroupVision() {
	// 	this.loader = true;

	// 	this.viewHour = true;
	// 	this.viewTrack = false;
	// 	this.viewGroup = false;

	// 	this.searchBtn = true;

	// 	// get user groups
	// 	this.userGroups = [];
	// 	if (typeof this.global.groupsAttendees !== "undefined") {
	// 		this.userGroups = this.global.groupsAttendees;
	// 		// load sessions
	// 		this.getSessionsGroupVision();
	// 	}
	// 	// if the groups have not been loaded, call the loadGroups function of the global service.
	// 	else {
	// 		// get the id of the logged in user.
	// 		let userId = null;

	// 		// receives user uid
	// 		if (typeof this.SUserData.userId !== "undefined" && this.SUserData.userId !== null) {
	// 			userId = this.SUserData.userId;
	// 		} else {
	// 			userId = await this.global.loadUserId();
	// 		}

	// 		// get the type user of the logged in user.
	// 		let userType = null;

	// 		if (typeof this.global.userType !== "undefined" && this.global.userType !== null) {
	// 			userType = this.global.userType;
	// 		} else {
	// 			userType = await this.global.loadUserType();
	// 		}

	// 		// get the type groupsAttendee of the logged in user.
	// 		this.userGroups = [];
	// 		if (typeof this.global.groupsAttendees !== "undefined") {
	// 			this.userGroups = this.global.groupsAttendees;
	// 		} else {
	// 			this.userGroups = await this.global.loadGroups(userId, userType, this.eventId);
	// 		}

	// 		// load sessions
	// 		this.getSessionsGroupVision();
	// 	}
	// }

	// loads the participant group sessions.
	// getSessionsGroupVision() {
	// 	this.subscriptions.push(
	// 		this.dbSchedule.getSessionsGroupsVision(this.userGroups, this.moduleId).subscribe(
	// 			(data) => {
	// 				this.loader = false;
	// 				let nextPage = null;
	// 				let list = [];

	// 				if (data) {
	// 					nextPage = data["nextPage"];
	// 					list = [...data["sessions"]];
	// 				}

	// 				// takes the start time of the last session loaded.
	// 				if (nextPage) {
	// 					this.lastSession = nextPage;
	// 				}

	// 				// treatment of sessions
	// 				for (let session of list) {
	// 					session = this.setTheSession(session);
	// 				}

	// 				this.sessions = list;
	// 				this.sessionsAll = list;

	// 				// Push sessions on store
	// 				this.pushScheduleSessionsOnStore(this.sessionsAll);

	// 				// pick up all different dates of sessions
	// 				this.dates = [];

	// 				for (let session of this.sessionsAll) {
	// 					const index = this.dates.indexOf(session.date.toString());

	// 					if (index === -1) {
	// 						this.dates.push(session.newDate);
	// 					}
	// 				}

	// 				this.dates = [...this.dates].filter(
	// 					(date, index, self) =>
	// 						index ===
	// 						self.findIndex(
	// 							(x) =>
	// 								x.day === date.day &&
	// 								x.hour === date.hour &&
	// 								x.millisecond === date.millisecond &&
	// 								x.minute === date.minute &&
	// 								x.month === date.month &&
	// 								x.second === date.second &&
	// 								x.year === date.year &&
	// 								x.zone === date.zone
	// 						)
	// 				);
	// 				// enable filter by date
	// 				this.allSessionDates = Array.from(new Set([...this.sessions].map((item) => item.dateFormated)));
	// 				if (this.dates) this.allowFilterByDate = true;
	// 			},
	// 			(error) => {}
	// 		)
	// 	);
	// }

	/******************************************************************************** methods of limited access by groups *************************************************************************************** */
	//limited access by groups
	// getLimitedAccessByGroupsVision() {
	// 	this.loader = true;

	// 	this.viewHour = true;
	// 	this.viewTrack = false;
	// 	this.viewGroup = false;
	// 	this.searchBtn = true;

	// 	// get the first 100 sessions of the module.
	// 	this.dbSchedule.getFirstPageSessionsLimitedAccessByGroups(this.moduleId).subscribe((data) => {
	// 		let nextPage = null;
	// 		let list = [];
	// 		this.sessions = [];

	// 		if (data) {
	// 			nextPage = data["nextPage"];
	// 			list = [...data["sessions"]];
	// 		}

	// 		// takes the start time of the last session loaded.
	// 		if (nextPage) {
	// 			this.lastSession = nextPage;
	// 		}

	// 		// treatment of sessions
	// 		for (let session of list) {
	// 			session = this.setTheSession(session);
	// 		}

	// 		this.sessions = list;
	// 		this.allSessionDates = Array.from(new Set([...this.sessions].map((item) => item.dateFormated)));
	// 		this.loader = false;
	// 	});

	// 	// counts the total sessions you have in the module. (global vision)
	// 	this.dbSchedule.countsSessionsInTheModule(this.moduleId, (total) => {
	// 		this.allSessionsLength = total;
	// 	});

	// 	// load all module sessions and all different dates. Using the api
	// 	this.dbSchedule.getAllSessionsLimitedAccessByGroup(this.moduleId).subscribe((sessions) => {
	// 		this.sessionsAll = [];
	// 		this.dates = [];

	// 		[...sessions].forEach((session) => {
	// 			this.sessionsAll.push(this.setTheSession(session));
	// 			// pick up all different dates of sessions
	// 			const index = this.dates.indexOf(session.date.toString());

	// 			if (index === -1) {
	// 				this.dates.push(session.newDate);
	// 			}
	// 		});

	// 		this.dates = [...this.dates].filter(
	// 			(date, index, self) =>
	// 				index ===
	// 				self.findIndex(
	// 					(x) =>
	// 						x.day === date.day &&
	// 						x.hour === date.hour &&
	// 						x.millisecond === date.millisecond &&
	// 						x.minute === date.minute &&
	// 						x.month === date.month &&
	// 						x.second === date.second &&
	// 						x.year === date.year &&
	// 						x.zone === date.zone
	// 				)
	// 		);

	// 		// Push sessions on store
	// 		this.pushScheduleSessionsOnStore(this.sessionsAll);

	// 		if (this.dates) this.allowFilterByDate = true;
	// 	});
	// }

	// loads more sessions when the infinite scroll is triggered (limited access by groups )
	moreSessionsLimitedAccessByGroups(event) {
		if (this.sessions.length < this.allSessionsLength) {
			if (this.lastSession) {
				// convert a date plus the start time of the last session to timastamp for the search of the bank.
				let year = "2019";
				let month = "12";
				let day = "01";
				let hour = "00";
				let minute = "00";
				let seconds = "00";

				// const array1 = this.lastSession.date.split("/");
				// day = array1[0];
				// month = array1[1];
				// year = array1[2];
				day =
					this.lastSession.newDate.day < 10
						? `0${this.lastSession.newDate.day}`
						: this.lastSession.newDate.day;
				month =
					this.lastSession.newDate.month < 10
						? `0${this.lastSession.newDate.month}`
						: this.lastSession.newDate.month;
				year = this.lastSession.newDate.year;

				// const array2 = this.lastSession.startTime.split(":");
				// hour = array2[0];
				// minute = array2[1];
				hour =
					this.lastSession.newStartTime.hour < 10
						? `0${this.lastSession.newStartTime.hour}`
						: this.lastSession.newStartTime.hour;
				minute =
					this.lastSession.newStartTime.minute < 10
						? `0${this.lastSession.newStartTime.minute}`
						: this.lastSession.newStartTime.minute;

				const lastTime = this.luxon.createTimeStamp(
					this.luxon.createDate(year, month, day, hour, minute, seconds)
				);

				// carries 100 more sessions
				this.dbSchedule.getNextPageSessionsLimitedAccessByGroups(this.moduleId, lastTime, (data) => {
					this.lastSession = null;
					let list = [];

					if (data) {
						this.lastSession = data["nextPage"];
						list = [...data["sessions"]];
					}

					const sessionIds = [...this.sessions].map((s) => s.uid);
					// treatment of sessions
					for (let session of list) {
						if (!sessionIds.includes(session.uid)) {
							session = this.setTheSession(session);
							this.sessions.push(session);
							if (this.sessions.length == this.allSessionsLength) {
								event.target.disabled = true;
							}
						}
					}

					this.allSessionDates = Array.from(new Set([...this.sessionsAll].map((item) => item.dateFormated)));

					// check if you still have sessions to search
					if (this.lastSession) {
						// this.virtualScroll.checkEnd();
						event.target.complete();
					} else {
						event.target.disabled = true;
					}
				});
			} else {
				event.target.disabled = true;
			}
		}
		// If the array that stores all module sessions has not been loaded.
		else if (this.allSessionsLength == 0) {
			// this.virtualScroll.checkEnd();
			event.target.complete();
		} else {
			event.target.disabled = true;
		}
	}

	/**
	 * Push schedule sessions on store
	 * @param sessions
	 */
	pushScheduleSessionsOnStore(sessions: any[]) {
		this.store.dispatch(new GetScheduleSessions(sessions));
	}

	displayDate(date: string) {
		return new Date(date).toLocaleDateString(this.dt.deviceLocale, {
			month: "short",
			day: "2-digit",
			timeZone: this.dt.deviceTimeZone
		});
	}

	selectMonth(month: number) {
		let finalMonth;
		switch (month) {
			case 1:
				finalMonth = this.translateService.instant("global.texts.january");
				break;
			case 2:
				finalMonth = this.translateService.instant("global.texts.february");
				break;
			case 3:
				finalMonth = this.translateService.instant("global.texts.march");
				break;
			case 4:
				finalMonth = this.translateService.instant("global.texts.april");
				break;
			case 5:
				finalMonth = this.translateService.instant("global.texts.may");
				break;
			case 6:
				finalMonth = this.translateService.instant("global.texts.june");
				break;
			case 7:
				finalMonth = this.translateService.instant("global.texts.july");
				break;
			case 8:
				finalMonth = this.translateService.instant("global.texts.august");
				break;
			case 9:
				finalMonth = this.translateService.instant("global.texts.september");
				break;
			case 10:
				finalMonth = this.translateService.instant("global.texts.october");
				break;
			case 11:
				finalMonth = this.translateService.instant("global.texts.november");
				break;
			case 12:
				finalMonth = this.translateService.instant("global.texts.december");
				break;
		}
		return finalMonth;
	}

	scrollToTop() {
		this.content.scrollToTop(500);
	}

	ionViewDidEnter() {
		this.activeDate = "all";
	}

	getSpeakers(speakersObj) {
		const listKeys = Object.keys(speakersObj);

		// listKeys.forEach(item => {
		// 	speakersObj[item]
		// })
		return [...listKeys]
			.map((x) => speakersObj[x])
			.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
			);
	}

	/**
	 * open the <ion-select> element correspondent to the value passed as param ("filter")
	 * @param filter event triggered
	 */
	openFilter(filter: any): void {
		if (filter.target.value == "tracks") { this.filterTracksRef.open(); }
		else if (filter.target.value == "locations") { this.filterLocationsRef.open(); }

		this.filterRef.value = undefined;
	}
}
