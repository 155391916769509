import { Injectable } from '@angular/core';
import { NativeMarket } from '@capacitor-community/native-market';

@Injectable({
    providedIn: 'root'
})
export class MarketService {

    constructor() { }

    /**
     * This method will launch link in Play/App Store.
     * @param appId - ID of your application. Eg. com.example.app
     * @returns void
     */
    public open(appId: string) {
        NativeMarket.openStoreListing({appId: appId});
    }
}
