import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';
import { DocumentData } from '@angular/fire/firestore';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Injectable({
    providedIn: "root"
})
export class DateTimeService {

    public deviceLocale: string = environment.platform.oldDefaultLanguage.replace("_", "-");
    public deviceTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone; // tz identifier (like "America/Sao_Paulo")

    constructor(
        private eventData: EventDataService
    ) {
        // if this promise fail the property deviceLocale remain with its defauls value
        Device.getLanguageTag().then((languageTag) => {
            this.deviceLocale = languageTag.value;
        });
    }

    /**
     * @returns the event timezone indentifier (e.g. America/Sao_Paulo), to be used on time date convertions 
     */
    public eventTimeZone() {
        // return (this.eventData.eventData && this.eventData.eventData.timezone) ? 
        //     this.eventData.eventData.timezone :
        //     (await this.eventData.getEventDataSnapshot()).timezone;

        return this.eventData.eventData.timezone ;
    }

    /**
     * @description change the format of the date and time passed as milliseconds or as a instance of 'Date' in the dateTime param to
     * the locale format provided as param or the device format, then return it
     * @param dateTime in milliseconds
     * @param locale IETF BCP 47
     */
    public getFullDate(dateTime: number | Date, locale: string = this.deviceLocale): string {
        const date = new Intl.DateTimeFormat(locale, { dateStyle: "short", timeStyle: "short" }).format(dateTime);
        return date;
    }

    /**
     * @description Convert the "dateTime" value (timestamp) in the timezone (UTC+00:00) to the devices timezone and if the dateTime
     * compared to the current date is equal or less than a day (24h), return a string with the time elapsed (difference of time) in 
     * hours, minutes and seconds; else return a string with the full date-time in the locale format.
     * @param dateTime timestamp
     * @param locale if no argument is provided for "locale" param, the value used is the device locale
     * @returns 
     */
    public getTimeDate(dateTime: string, locale: string = this.deviceLocale) {
        const date = new Date(dateTime);
        const oneDay = 86400000; // 24 hours in milliseconds
        const today = new Date();
        const diffTime = today.getTime() - date.getTime();
        const diffDays = diffTime / oneDay;

        if (diffDays >= 1) {
            // one day or more
            return this.convertTimeZone(dateTime, this.deviceTimeZone);
        }
        else {
            // less than one day
            const rtf = new Intl.RelativeTimeFormat(locale);
            const diffHou = diffDays * 24;
            if (diffHou >= 1) {
                return rtf.format(Math.floor(diffHou * -1), "hour");
            }
            else {
                const diffMin = diffHou * 60;
                if (diffMin >= 1) {
                    return rtf.format(Math.floor(diffMin * -1), "minute");
                }
                else {
                    return rtf.format(Math.floor(diffMin * -60), "second");
                }
            }
        }
    }

    /**
     * Convert the provided date to the intended timezone
     * @param dateTime
     * @param intendedTZ timezone to convert to
     * @param locale if not provided uses the device locale
     */
    public convertTimeZone(dateTime: string | number | Date, intendedTZ: string, locale: string = this.deviceLocale): string {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            timeZone: this.evalTimezone(intendedTZ)
        }
        return new Date(dateTime).toLocaleString(locale, options);
    }

    /**
     * @description convert the given timestamp to the desired timezone
     * @param timestamp date in milliseconds
     * @param intendedTZ timezone to convert to
     */
    public getConvertedTp(timestamp: number, intendedTZ: string): number {
        // dateString is a string in the date time string format (i.e. a simplification of ISO 8601 calendar date extended format)
        const dateString: string = this.convertTimeZone(timestamp, intendedTZ, "en-US");
        return Date.parse(dateString);
    }

    /**
     * @returns the current "database time" i.e. the utc time of the present moment
     */
    public dbTime(): string {
        return new Date().toISOString();
    }

    /**
    * @param date accepts a date or timestamp of one
    * @param locale the default value is the deviceLocale
    * @returns a string with the time (e.g. 3:25 PM or 15:25) in the locale format
    */
    public getTime(date: number | Date | string, locale: string = this.deviceLocale): string {
        return new Date(date).toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit" });
    }

    /**
     * @description convert the timezone of the provided time value and return it in the locale format
     * @param time 
     * @param intendedTZ 
     * @param locale 
     * @returns 
     */
    public getConvertedTime(time: string | number | Date, intendedTZ: string, locale: string = this.deviceLocale) {
        return new Date(time).toLocaleTimeString(locale, { timeStyle: "short", timeZone: this.evalTimezone(intendedTZ) })
    }

    /**
     * @description convert the timezone of the provided date value and return it in the locale format
     * @param date 
     * @param intendedTZ 
     * @param locale 
     * @returns 
     */
    public getDate(date: string | number | Date, intendedTZ: string, locale: string = this.deviceLocale) {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric", 
            month: "2-digit", 
            day: "2-digit", 
            timeZone: this.evalTimezone(intendedTZ)
        }
        return new Date(date).toLocaleDateString(locale, options);
    }

    /**
     * @description evaluates if the value of "timezone" param correspons to "event" or "device", if so, 
     * return the correspondent values to those timezones, else return the receive value back
     * @param timezone 
     * @returns 
     */
    private evalTimezone(timezone: string) {
        switch (timezone) {
            case "event":
                return this.eventTimeZone();
            case "device":
                return this.deviceTimeZone;
            default:
                return timezone;
        }
    }

    /**
     * @description all the dates must be field of a firebase firestore document and they are expected to be in 
     * ISO string format, as this is the standard for all dates in the app
     * @param docs 
     * @returns an array of all the different dates (in ISO string format e.g. 2023-12-31T19:07:27.051Z)
     */
    getDiffDates(docs: Array<DocumentData>): Array<string> {
        let dates: Array<string> = [];
		docs.forEach((doc) => {
			doc.date = (typeof doc.date == "string") ? `${doc.date.substring(0, 11)}00:00:00.000` : doc.date;
			if (!dates.includes(doc.date)) {
				dates.push(doc.date);
			}
		});
        return dates;
    }

    /**
     * @description compare two dates in ISO string format
     * @param date1 
     * @param date2 
     */
    compareDates(date1: string, date2: string): boolean {
        return (date1.substring(0, 11) == date2.substring(0, 11));
    }

    /**
     * 
     * @param date a ISO string format of a date (e.g. "2024-03-17T03:40:52.523Z")
     * @returns 
     */
    public getTimestamp(date: string) {
        return new Date(date).valueOf();
    }
}