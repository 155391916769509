import { DocumentData } from "@angular/fire/firestore";
import * as luxon from "luxon";
import { CeuGroup } from "./ceu-group";

export class AppointmentRuleTimeSlots {
	startDateTime: luxon.DateTime;
	endDateTime: luxon.DateTime;
	minutesInterval: number;
	disabledTimeSlots: AppointmentTimeSlot[];
	sourceRuleId: string;

	constructor(
		startDate: luxon.DateTime,
		endDate: luxon.DateTime,
		minutesInterval: number,
		disabledTimeSlots: AppointmentTimeSlot[] = [],
		sourceRuleId: string
	) {
		this.startDateTime = startDate;
		this.endDateTime = endDate;
		this.minutesInterval = minutesInterval;
		this.disabledTimeSlots = disabledTimeSlots;
		this.sourceRuleId = sourceRuleId;
	}

	static fromDatabase(ruleTimeSlots: AppointmentRuleTimeSlotsDatabase, sourceRuleId: string) {
		return new AppointmentRuleTimeSlots(
			luxon.DateTime.fromISO(ruleTimeSlots.startDateTime, { setZone: true }),
			luxon.DateTime.fromISO(ruleTimeSlots.endDateTime, { setZone: true }),
			ruleTimeSlots.minutesInterval,
			(ruleTimeSlots.disabledTimeSlots || []).map((dts) => AppointmentTimeSlot.fromDatabaseFormat(dts)),
			sourceRuleId
		);
	}

	toDatabaseFormat(): AppointmentRuleTimeSlotsDatabase {
		const dbRuleTimeSlots = {
			startDateTime: this.startDateTime.toISO(),
			endDateTime: this.endDateTime.toISO(),
			minutesInterval: this.minutesInterval,
			disabledTimeSlots: (this.disabledTimeSlots || []).map((dts) => dts.toDatabaseFormat())
		};
		// Removes undefined fields and ensures Firebase compatibility
		Object.keys(dbRuleTimeSlots).forEach((key) =>
			dbRuleTimeSlots[key] === undefined ? delete dbRuleTimeSlots[key] : {}
		);
		return dbRuleTimeSlots;
	}

	public getAllIntervals(): AppointmentTimeSlot[] {
		const intervals: AppointmentTimeSlot[] = [];
		let i = 0;
		const getNewInterval = (counter) => {
			let newSlot = new AppointmentTimeSlot(
				this.startDateTime.plus({ minutes: this.minutesInterval * counter }),
				this.minutesInterval,
				this.sourceRuleId,
				undefined,
				AppointmentTimeSlotStatus.TIME_SLOT_ENABLED
			);
			newSlot = newSlot.getStatusWithOverridenStatusFromOtherSlots(this.disabledTimeSlots);
			return newSlot;
		};
		let newInterval = getNewInterval(i);
		while (newInterval.endDateTime <= this.endDateTime) {
			intervals.push(newInterval);
			i++;
			newInterval = getNewInterval(i);
		}
		return intervals;
	}
}

export class AppointmentRuleTimeSlotsDatabase {
	startDateTime: string;
	endDateTime: string;
	minutesInterval: number;
	disabledTimeSlots: AppointmentTimeSlotDatabase[];

	constructor(
		startDate: string,
		endDate: string,
		minutesInterval: number,
		disabledTimeSlots: AppointmentTimeSlotDatabase[]
	) {
		this.startDateTime = startDate;
		this.endDateTime = endDate;
		this.minutesInterval = minutesInterval;
		this.disabledTimeSlots = disabledTimeSlots;
	}
}

export class AppointmentRuleGroups {
	static readonly ALL_USERS_ID = "allUsers";
	applicantGroupId: string;
	invitedGroupId: string;

	constructor(applicantGroupId: string, invitedGroupId: string) {
		this.applicantGroupId = applicantGroupId;
		this.invitedGroupId = invitedGroupId;
	}

	static fromDatabase(ruleGroups: AppointmentRuleGroupsDatabase) {
		return new AppointmentRuleGroups(ruleGroups.applicantGroupId, ruleGroups.invitedGroupId);
	}

	toDatabaseFormat(): AppointmentRuleGroupsDatabase {
		const dbRuleGroups = {
			applicantGroupId: this.applicantGroupId,
			invitedGroupId: this.invitedGroupId
		};
		// Removes undefined fields and ensures Firebase compatibility
		Object.keys(dbRuleGroups).forEach((key) => (dbRuleGroups[key] === undefined ? delete dbRuleGroups[key] : {}));
		return dbRuleGroups;
	}
}

export class AppointmentRuleGroupsDatabase {
	applicantGroupId: string;
	invitedGroupId: string;

	constructor(applicantGroupId: string, invitedGroupId: string) {
		this.applicantGroupId = applicantGroupId;
		this.invitedGroupId = invitedGroupId;
	}
}

export class AppointmentRule {
	slots: AppointmentRuleTimeSlots;
	groups: AppointmentRuleGroups[];
	uid: string;
	name?: string;
	enableVisio: boolean;

	constructor(
		slots: AppointmentRuleTimeSlots,
		groups: AppointmentRuleGroups[],
		enableVisio: boolean,
		uid?: string,
		name?: string
	) {
		this.slots = slots;
		this.groups = groups;
		this.enableVisio = enableVisio;
		this.uid = uid;
		this.name = name;
	}

	static fromDatabase(rule: DocumentData) {
		return new AppointmentRule(
			AppointmentRuleTimeSlots.fromDatabase(rule.slots, rule.uid),
			rule.groups.map((group) => AppointmentRuleGroups.fromDatabase(group)),
			rule.enableVisio,
			rule.uid,
			rule.name
		);
	}

	toDatabaseFormat(): AppointmentRuleDatabase {
		const dbRule = {
			slots: this.slots.toDatabaseFormat(),
			groups: this.groups.map((g) => g.toDatabaseFormat()),
			enableVisio: this.enableVisio,
			uid: this.uid,
			name: this.name
		};
		// Removes undefined fields and ensures Firebase compatibility
		Object.keys(dbRule).forEach((key) => (dbRule[key] === undefined ? delete dbRule[key] : {}));
		return dbRule;
	}

	isComplyingToGroupsConstraints(
		applicantGroups: { [id: string]: CeuGroup }[],
		invitedGroups: { [id: string]: CeuGroup }[]
	) {
		return this.groups.some(
			(groupsRule) =>
				(Object.keys(applicantGroups).some((groupId) => groupId === groupsRule.applicantGroupId) ||
					groupsRule.applicantGroupId === AppointmentRuleGroups.ALL_USERS_ID) &&
				(Object.keys(invitedGroups).some((groupId) => groupId === groupsRule.invitedGroupId) ||
					groupsRule.invitedGroupId === AppointmentRuleGroups.ALL_USERS_ID)
		);
	}
}
export class AppointmentRuleDatabase {
	slots: AppointmentRuleTimeSlotsDatabase;
	groups: AppointmentRuleGroupsDatabase[];
	uid: string;
	name: string;
	enableVisio: boolean;

	constructor(
		slots: AppointmentRuleTimeSlotsDatabase,
		groups: AppointmentRuleGroupsDatabase[],
		enableVisio: boolean,
		uid?: string,
		name?: string
	) {
		this.slots = slots;
		this.groups = groups;
		this.enableVisio = enableVisio;
		this.uid = uid;
		this.name = name;
	}
}

export enum AppointmentTimeSlotStatus {
	APPOINTMENT_ACCEPTED = 1,
	APPOINTMENT_REJECTED = 2,
	APPOINTMENT_CANCELLED = 3,
	APPOINTMENT_PENDING = 4,
	TIME_SLOT_ENABLED = 5,
	TIME_SLOT_DISABLED = 6,
	APPOINTMENT_REJECTED_AUTOMATICALLY = 7,
	APPOINTMENT_CANCELLED_AUTOMATICALLY = 8
}

/**
 * Time slot of an appointment
 */
export class AppointmentTimeSlot {
	uid: string;
	/**
	 * Starting time of the appointment
	 *
	 * @type {luxon.DateTime}
	 * @memberof AppointmentTimeSlot
	 */
	startDateTime: luxon.DateTime;
	/**
	 * Duration of the appointment in minutes
	 *
	 * @type {number}
	 * @memberof AppointmentTimeSlot
	 */
	duration: number;
	subject?: string;
	status?: AppointmentTimeSlotStatus;
	applicant?: { uid: string; name: string; notation?: number; commentary?: string };
	invited?: { uid: string; name: string; notation?: number; commentary?: string };
	sourceRuleId: string;
	urlVisio: string;

	get endDateTime() {
		return this.startDateTime.plus({ minutes: this.duration });
	}

	get availableOrPending() {
		return (
			this.status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY
		);
	}

	get available() {
		return (
			this.status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY
		);
	}

	get isAppointment() {
		return (
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY ||
			this.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY
		);
	}

	constructor(
		startDateTime: luxon.DateTime,
		duration: number,
		sourceRuleId: string,
		subject?: string,
		status: AppointmentTimeSlotStatus = AppointmentTimeSlotStatus.TIME_SLOT_ENABLED,
		applicant?: { uid: string; name: string; notation?: number; commentary?: string },
		invited?: { uid: string; name: string; notation?: number; commentary?: string },
		urlVisio?: string,
		uid?: string
	) {
		this.startDateTime = startDateTime;
		this.duration = duration;
		this.sourceRuleId = sourceRuleId;
		this.subject = subject;
		this.status = status;
		this.applicant = applicant;
		this.invited = invited;
		this.urlVisio = urlVisio;
		this.uid = uid;
	}

	static fromDatabaseFormat(timeSlot: DocumentData) {
		return new AppointmentTimeSlot(
			luxon.DateTime.fromISO(timeSlot.startDateTime, { setZone: true }),
			timeSlot.duration,
			timeSlot.sourceRuleId,
			timeSlot.subject,
			timeSlot.status,
			timeSlot.applicant,
			timeSlot.invited,
			timeSlot.urlVisio,
			timeSlot.uid
		);
	}

	toDatabaseFormat() {
		const dbTimeSlot = {
			startDateTime: this.startDateTime.toISO(),
			duration: this.duration,
			status: this.status,
			subject: this.subject,
			applicant: this.applicant,
			invited: this.invited,
			uid: this.uid,
			sourceRuleId: this.sourceRuleId,
			urlVisio: this.urlVisio
		};
		// Removes removes undefined fields and ensures Firebase compatibility
		Object.keys(dbTimeSlot).forEach((key) => (dbTimeSlot[key] === undefined ? delete dbTimeSlot[key] : {}));
		return dbTimeSlot;
	}

	/**
	 * Returns the other user of an appointment
	 * @param userIdToExclude userId to excude
	 */
	getOtherUser(userIdToExclude: string) {
		return [this.applicant, this.invited].find((user) => user && user.uid !== userIdToExclude);
	}

	/**
	 *
	 *
	 * @param {AppointmentTimeSlot[]} slotsToCompareWith
	 * @param {AppointmentTimeSlotStatus[]} unavailableStatuses
	 * @returns
	 * @memberof AppointmentTimeSlot
	 */
	getStatusWithOverridenStatusFromOtherSlots(slotsToCompareWith: AppointmentTimeSlot[]) {
		const overrideSlot = slotsToCompareWith.find(
			(ste) =>
				this.endDateTime.equals(ste.endDateTime) &&
				this.startDateTime.equals(ste.startDateTime) &&
				this.sourceRuleId === ste.sourceRuleId
		);
		if (overrideSlot != null) {
			return new AppointmentTimeSlot(
				this.startDateTime,
				this.duration,
				this.sourceRuleId,
				this.subject,
				overrideSlot.status,
				this.applicant,
				this.invited,
				this.urlVisio,
				overrideSlot.uid
			);
		} else {
			return this;
		}
	}

	isSlotConflictingWithOthers(
		slotsToCompareWith: AppointmentTimeSlot[],
		unavailableStatuses: AppointmentTimeSlotStatus[]
	) {
		return !!slotsToCompareWith.find(
			(ste) =>
				unavailableStatuses.some((us) => ste.status === us) &&
				this.endDateTime.equals(ste.endDateTime) &&
				this.startDateTime.equals(ste.startDateTime) &&
				this.sourceRuleId === ste.sourceRuleId
		);
	}
}

export class AppointmentTimeSlotDatabase {
	uid: string;
	startDateTime: string;
	duration: number;
	subject: string;
	status: number;
	applicant?: { uid: string; name: string; notation?: number; commentary?: string };
	invited?: { uid: string; name: string; notation?: number; commentary?: string };
	sourceRuleId?: string;
	urlVisio: string;
}

export class AppointmentTimeSlotByDay {
	get date() {
		return this.slots ? this.slots[0].startDateTime : null;
	}

	get timezone() {
		return this.slots ? this.slots[0].startDateTime.toFormat("ZZZ") : null;
	}

	slots: AppointmentTimeSlot[];

	constructor(slots: AppointmentTimeSlot[]) {
		this.slots = slots;
	}

	static fromShuffledAppointmentTimeSlots(slots: AppointmentTimeSlot[]) {
		let appointmentsByDay: AppointmentTimeSlotByDay[] = [];
		slots.forEach((s) => {
			if (s.startDateTime) {
				const existingDay = appointmentsByDay.find((ats) =>
					ats.date.startOf("day").equals(s.startDateTime.startOf("day"))
				);
				if (existingDay) {
					existingDay.slots.push(s);
				} else {
					appointmentsByDay.push(new AppointmentTimeSlotByDay([s]));
				}
			}
		});
		appointmentsByDay = appointmentsByDay.sort(
			(day1, day2) => day1.date.diff(day2.date, "milliseconds").milliseconds
		);
		appointmentsByDay.forEach((day) => {
			day.slots = day.slots.sort(
				(slot1, slot2) => slot1.startDateTime.diff(slot2.startDateTime, "milliseconds").milliseconds
			);
		});
		return appointmentsByDay;
	}

	static getFromRules(rules: AppointmentRule[]) {
		return rules.map((rule) => new AppointmentTimeSlotByDay(rule.slots.getAllIntervals()));
	}
}
