import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MenuController, Platform } from "@ionic/angular";
import { TypeUser } from '../models/type-user';
import { UserDataService } from "../shared/services/user-data/user-data.service";
import { NotificationsService } from "../providers/notifications.service";
import { DocumentData } from "@angular/fire/firestore";
import { EventDataService } from "../shared/services/eventData/event-data.service";
import { ActiveMenuIconsService } from "../shared/services/active-menu-icons/active-menu-icons.service";
import { EventColors } from "../models/event-colors";
import { ModuleDataService } from "../shared/services/module-data/module-data.service";
import { AnalyticsService } from "../shared/services";
import { GroupDiscussionsService } from "../shared/services/group-discussions/group-discussions.service";
import { DaoCustomTranslationsService } from "../providers/db/dao-custom-translations.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
    eventId: string;
    userData: DocumentData;
    eventColors: EventColors = new EventColors();
    modules: any;
    isMobile: boolean = false;
    allowChat: boolean = false;
    notificationBadge: number = 0;

    eventTitle: string;

    constructor(
        private platform: Platform,
        private router: Router,
        private userDataService: UserDataService,
        private SNotif: NotificationsService,
        private SEventData: EventDataService,
        private SActiveMenuIcons: ActiveMenuIconsService,
        private SModuleData: ModuleDataService,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        public SGroupDiscussions: GroupDiscussionsService,
        private SCustomTranslations: DaoCustomTranslationsService,
    ) { }
    
    async ngOnInit() {
        this.isMobile = (
            this.platform.is("mobile") || this.platform.is('android') || this.platform.is('ios')
            // (
            //     !this.platform.is("desktop") &&
            //     this.platform.is("tablet")
            // ) 
            // window.innerWidth < 768
        );
        this.eventId = localStorage.getItem('eventId');

        this.SCustomTranslations.loadCustomTranslations(this.eventId);

        this.SEventData.getEventDataListener(this.eventId).subscribe((data) => {
            this.allowChat = data.allow_chat;
            this.eventTitle = data.title;
        });

        this.userDataService.getUserDataSnapshot(this.eventId).then((userData) => {
            this.userData = userData;

            if (this.isMobile) {
                this.SNotif.setupPushNotifications(
                    this.eventId, 
                    userData.uid, 
                    (userData.groups) ? Object.keys(userData.groups) : []
                );
            }

            // this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
            //     if (
            //         eventData.required_edit_profile == true &&
            //         (userData.edited_profile == undefined || userData.edited_profile == false)
            //     ) {
            //         this.router.navigate([
            //             `/event/${this.eventId}/edit-profile/${userData.moduleId}/${userData.type}/${userData.uid}`
            //         ]);
            //     }
            // }).catch((error) => { console.error(error); });
        }).catch((err) => {
            console.error(err)
        })

        this.SNotif.bgCount().subscribe((bgCount) => {
            this.notificationBadge = bgCount;
        })
        
        this.getModules(this.eventId, this.isMobile);
        this.loadColors(this.eventId);
        this.SAnalytics.updateUserAccessCount(this.eventId);
        this.SGroupDiscussions.getChatsListener(this.eventId).subscribe();
    }
    
    getModules(eventId: string, isMobile: boolean) {
        if (isMobile) {
            this.SModuleData.fetchData(this.eventId);
        } else {
            this.SModuleData.getListener(
                eventId, 
                (module) => { return module.habilitedApp == true; }
            ).subscribe((modules) => { this.modules = modules; });
        }
    }

    openModule(module) {
        this.menuCtrl.close();
        this.router.navigate([module.viewApp])
    }

    loadColors(eventId: string = this.eventId) {
        document.body.style.setProperty(`--main-color`, "#808080");
        this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            
            const i = this.eventColors.menu_color.indexOf('#');
            const mainColor = this.eventColors.menu_color.slice(i, i + 7)
            
            document.body.style.setProperty(`--main-color`, mainColor);
        })
    }

    // abre a homePage
    openHomePage() {
        this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
            this.router.navigate([eventData.homePage]);
        });
        this.SActiveMenuIcons.changeActiveIcon('home');
    }

    // abre o modulo "Principal" (redireciona)
    openPrincipal() {
        this.router.navigate([`/event/${this.eventId}/principal/:moduleId`]);
        this.SActiveMenuIcons.changeActiveIcon('modules');
    }

    // abre o modal para exibir notificações
    async openNotifications() {
        this.router.navigate([`event/${this.eventId}/notifications`]);
        this.SActiveMenuIcons.changeActiveIcon('notif');
        // this.notificationBadge = 0;
        // this.toastController.dismiss()
        // const modal = await this.modalCtrl.create({
        //     component: PathComponent.notifications,
        //     componentProps: {
        //         eventId: this.eventId
        //     }
        // });
        // this.SActiveMenuIcons.changeActiveIcon('notifications');
        // return await modal.present();
    }

    openChat() {
        // this.isMobile && this.menuCtrl.close();
        this.router.navigate([
            `/event/${this.eventId}/chat-history`
        ]);
        // routerLink="/event/{{eventId}}/chat-history"
        this.SActiveMenuIcons.changeActiveIcon('chat');
    }

    // ao clicar na foto de perfil no menu, vai para o perfil do usuário
    personalPage() {
        this.menuCtrl.close();
        this.userDataService.getUserDataSnapshot(this.eventId).then((userData) => {
            if (userData.type == TypeUser.ATTENDEE || userData.type == TypeUser.SPEAKER) {
                this.router.navigate([
                    `/event/${this.eventId}/personal-page/${userData.moduleId}/${userData.type}/${userData.uid}`
                ]);
            }
        }).catch((error) => {
            if (error.type == 1) {
                this.userDataService.needLoginToUse({
                    closeHandler: () => { this.SActiveMenuIcons.previousActiveIcon(); }
                });
            }
        })
        this.SActiveMenuIcons.changeActiveIcon('personalPage');
    }
}
