import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DaoGeralService } from 'src/app/providers/db/dao-geral.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  terms: string = null;
  constructor(private modalCtrl: ModalController, private daoGeral: DaoGeralService, private navParams: NavParams) { }

  ngOnInit() {
    if (this.navParams.get('terms') == undefined || this.navParams.get('terms') == null) {
      this.daoGeral.getTermsOfUse((terms) => {
        this.terms = terms;
      });
    } else {
      this.terms = this.navParams.get('terms');
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
