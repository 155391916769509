import { Injectable } from '@angular/core';
import { 
    Firestore, 
    DocumentData, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    updateDoc, 
    doc 
} from '@angular/fire/firestore';
import { share, startWith, takeUntil, tap } from 'rxjs/operators';
import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from "@capacitor-community/fcm";
import { LogoutService } from '../shared/services/logout/logout.service';
import { BehaviorSubject, firstValueFrom, Observable, Subscription } from 'rxjs';
import { PathApi } from '../paths/path-api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private notifications: DocumentData[] = null;
    private sharedObs: Observable<DocumentData[]> = null;
    private accessedNotifs = null;
    private badgeCount = 0;
    private bgNotifSubject = new BehaviorSubject<number>(this.badgeCount);
    private wakeUpSub: Subscription = null;
    private headers;
    private requestOptions;
    
    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private http: HttpClient,
    ) {
        const eventId = localStorage.getItem(`eventId`);
        this.wakeUpSub = this.getListener(eventId).subscribe();

        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }

    // async sendNotification(
    //     eventId: string,
    //     message: string,
    //     sender_name: string,
    //     userIds: string[]
    // ) {
    //     const promises = userIds.map((uid) => this.getPlayerId(eventId, uid));
    //     const include_player_ids = (await Promise.all(promises)).filter(
    //         (p) => !!p
    //     );

    //     if (!include_player_ids.length) return;

    //     return this.http
    //         .post(
    //             this.apiUrl,
    //             {
    //                 include_player_ids,
    //                 headings: {
    //                     en: sender_name
    //                 },
    //                 contents: {
    //                     en: message
    //                 },
    //                 priority: 10,
    //                 api_id: environment.onesignal.notification_api_id,
    //                 app_id: environment.onesignal.onesignal_appid
    //             },
    //             {
    //                 headers: this.headers,
    //                 observe: 'response'
    //             }
    //         )
    //         .toPromise();
    // }

    // getPlayerId(eventId: string, uid: string) {
    //     return this.afs
    //         .collection('events')
    //         .doc(eventId)
    //         .collection('attendees')
    //         .doc(uid)
    //         .valueChanges()
    //         .pipe(
    //             first(),
    //             map((a: any) => (a.notification ? a.notification.userId : null))
    //         )
    //         .toPromise();
    // }

    private getSharedObs(eventId: string) {
        const getObs = () => {
            const ref = collection(this.firestore, `events/${eventId}/notifications`);
            const refQ = query(ref, orderBy('delivery_date', 'desc'));

            return collectionData(refQ).pipe(
                takeUntil(this.Slogout.logoutSubject),
                share(),
                tap({ 
                    next: (data) => { 
                        this.notifications = data;
                        const tmp = this.getViewedNotif();
                        this.badgeCount = 0;
                        this.notifications.forEach(n => {
                            if (tmp[n.uid]) {
                                n.viewed = true;
                            } else {
                                this.badgeCount++;
                            }
                        });
                        this.bgNotifSubject.next(this.badgeCount);
                    },
                    complete: () => { this.notifications = null; }
                })
            )
        }

        if (this.sharedObs == null) {
            this.sharedObs = getObs();
        }
        return this.sharedObs;
    }

    public getListener(eventId: string) {
        if (this.wakeUpSub != null) this.wakeUpSub.unsubscribe();
        return (this.notifications == null) ?
            this.getSharedObs(eventId) :
            this.getSharedObs(eventId).pipe(startWith(this.notifications)); 
    }

    /**
     * 
     * @returns an object with the id of all viewed notifications as keys (all 
     * values have the boolean true value)
     */
    public getViewedNotif() {
        if (this.accessedNotifs == null) {
            const tmp = localStorage.getItem("notifications_viewed");
            this.accessedNotifs = (!tmp) ? {} : JSON.parse(tmp);
        }
        return this.accessedNotifs;
    }

    public addViewedNotif(notifId: string) {
        if (this.accessedNotifs == null) {
            this.getViewedNotif();
        } else if (this.accessedNotifs[notifId]) {
            return;
        }
        this.accessedNotifs[notifId] = true;
        localStorage.setItem("notifications_viewed", JSON.stringify(this.accessedNotifs));
        this.bgNotifSubject.next(--this.badgeCount);
    }

    /**
     * @returns the number of unread notifications, to be used in the badge 
     * counter
     */
    public bgCount() {
        return this.bgNotifSubject.pipe(takeUntil(this.Slogout.logoutSubject));
    }

    /**
     * @description register the app for push notifications and subscripe to the FCM (Firebase Cloud Messaging) 
     * topic equal to the eventId passed as param
     * @param eventId 
     */
    public async setupPushNotifications(
        eventId: string, 
        userId: string, 
        ugroups: string[] = []
    ) {
        const addListeners = async () => {
            await PushNotifications.addListener('registration', token => {
                console.log(`Registration token:  ${token.value}`);
                const ref = doc(this.firestore, `users/${userId}`)
                updateDoc(ref, {"fcmToken": token});
            });

            await PushNotifications.addListener('registrationError', err => {
                console.log(`Registration error: ${err.error}`);
            });

            await PushNotifications.addListener('pushNotificationReceived', notification => {
                console.log(`Push notification received: ${notification}`);
            });

            await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
                console.log(`Push notification action performed: ${notification.actionId}, ${notification.inputValue}`);
            });
        }

        const registerNotifications = async () => {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                console.log('(push notification) User denied permissions!');
            }

            await PushNotifications.register();
        }

        const getDeliveredNotifications = async () => {
            const notificationList = await PushNotifications.getDeliveredNotifications();
            console.log(`delivered notifications: ${notificationList}`);
        }

        await addListeners();
        await registerNotifications();
        await getDeliveredNotifications();

        return Promise.all([
            FCM.subscribeTo({ topic: eventId }),
            ...ugroups.map(gid => FCM.subscribeTo({ topic: gid }))
        ]).catch(err => console.error(err));
    }

    /**
     * Send a push notification by user ids that are used to retrieve the user 
     * fcm token
     */
    public sendPush(
        userIds: string[], 
        title: string, 
        msgBody: string, 
        imageUrl?: string
    ) {
        const body = { title: title, msgBody: msgBody, userIds: userIds };
        if (imageUrl) body["imageUrl"] = imageUrl;

        return firstValueFrom(this.http.post(
            PathApi.dbNotificationsSendPush,
            body,
            this.requestOptions
        ));
    }
}
