import { Injectable, HostListener } from '@angular/core';
import { ToastController, AlertController, LoadingController } from '@ionic/angular';
import { ConnectionService } from 'ng-connection-service';
import { IUtilityState } from '../../interfaces/utility.interfaces';
import { Store } from '@ngrx/store';
import { HaveNetworkConnection } from '../../actions/utility.actions';
import { GlobalService } from '../global/global.service';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(
        private toastCtrl: ToastController,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private connection: ConnectionService,
        private store: Store<IUtilityState>,
        private SGlobal: GlobalService
    ) {
    }

    /**
     * Present a toast
     * @param message 
     * @param duration 
     * @param position 
     * @param closeButton 
     * @param color 
     */
    async presentToast(message, duration, position,  color) {
        let toast = await this.toastCtrl.create({
            message: message,
            duration: duration,
            position: position,
            color: color
        });

        toast.present();
    }

    /**
     * Present an alert
     * @param title 
     * @param message 
     * @param buttons 
     * @param inputs 
     */
    async presentAlert(title, message, buttons = [], inputs = []) {
        let alert = await this.alertCtrl.create({
            header: title,
            message: message,
            buttons: buttons,
            inputs: inputs
        });

        return (alert);
    }

    /**
     * Present loading
     * @param message 
     * @param duration 
     * @param spinner 
     */
    async presentLoading(message, duration, spinner) {
        let loader = await this.loadingCtrl.create({
            message: message,
            duration: duration,
            spinner: spinner,
            cssClass: 'spinner-css-class'
        });

        return (loader);
    }

    /**
     * Check if index exist in array
     * @param array 
     * @param item 
     */
    checkIndexExists(array, item) {
        return array.map(function (e) { return e.uid; }).indexOf(item.uid);
    }

    /**
     * Check network connection
     */
    manageNetworkStatus() {
        let check = window.navigator.onLine;

        // Initial check
        if (check) {
            this.store.dispatch(new HaveNetworkConnection("ONLINE"));
        } else {
            this.store.dispatch(new HaveNetworkConnection("OFFLINE"));
        }

        this.connection.monitor().subscribe((isConnected) => {
            if (isConnected) {
                this.store.dispatch(new HaveNetworkConnection("ONLINE"));
            } else {
                this.store.dispatch(new HaveNetworkConnection("OFFLINE"));
            }
        });
    }
}
