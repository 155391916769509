import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { MenuController, AlertController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { 
    Firestore, 
    doc,
    docData,
} from '@angular/fire/firestore';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { DaoCheckinService } from 'src/app/providers/db/dao-checkin.service';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeScannerService } from 'src/app/providers/barcode-scanner/barcode-scanner.service';
import { EventColors } from 'src/app/models/event-colors';
import { Subscription } from 'rxjs';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-self-checkin',
    templateUrl: './self-checkin.page.html',
    styleUrls: ['./self-checkin.page.scss'],
    providers: [DaoCheckinService]
})

export class SelfCheckinPage implements OnInit, OnDestroy {
    public module = null

    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    eventId: string = null;
    moduleId: string = null;
    backBtn: boolean = true;
    attendee: CeuAttendee = null;
    lastCheckins: Array<any> = null;
    allowButtonsHeader: boolean = false;
    isDegrade: boolean = false;
    subscriptions: Subscription[] = [];
    language: string;
    isMobile: boolean = false;

    constructor(
        private route: ActivatedRoute,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private firestore: Firestore,
        private dbCheckin: DaoCheckinService,
        private router: Router,
        public alertController: AlertController,
        private translateService: TranslateService,
        private zone: NgZone,
        private scan: BarcodeScannerService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        this.loadColors();
        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.zone.run(() => {
                this.menuBadge = this.global.notificationBadge;
            })
        });

        const ref = doc(this.firestore, `modules/${this.moduleId}`);
        const subscription = docData(ref).subscribe((data: any) => {
            this.module = data;
        });

        this.subscriptions.push(subscription);
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    // separe gradient colors
    separeGradientColors(str) {
        let hex = [];
        let index = str.indexOf('#');

        while (index > -1) {
            hex.push(this.getHexColors(str))
            str = str.replace('#', '');
            index = str.indexOf('#');
        }

        return hex;
    }

    getHexColors(str) {
        let position = str.indexOf('#');
        let result = str.substring(position, position + 7);

        return result;
    }

    async ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.zone.run(() => {
        //         this.allowButtonsHeader = true;
        //     })
        // }

        this.getLastCheckins();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }

    existsInArray(array: Array<any>, obj) {
        return array.some(item => {
          return item['uid'] === obj['uid'];
        });
      }

    // get the last checkins of current user
    getLastCheckins() {
        const subs = this.SUserData.getUserDataListener(this.eventId).subscribe((userData) => {
            this.lastCheckins = [];
            for (let i in userData.checkins) {
                this.dbCheckin.getModuleCheckinAndCheckin(
                    userData.checkins[i], 
                    this.eventId, 
                    (checkin) => {
                        if (checkin && !this.existsInArray(this.lastCheckins, checkin)) {
                            this.lastCheckins.push(checkin);
                        }
                    }
                );
            }
        });
        this.subscriptions.push(subs);
    }

    // open qr (camera)
    openQr() {
        this.scan.scanSingleQRCode().then((result) => {
            let arrayAux = result.split('+');
            this.confirmCheckin(arrayAux[0], arrayAux[1]);
        });
    }

    // confirm checkin change status
    confirmCheckin(moduleId, checkinId) {
        this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
            let newCheckins = [];
            if (
                !userData.checkins ||
                (Array.isArray(userData.checkins) && userData.checkins.length == 0)
            ) {
                newCheckins.push(checkinId);
            } else if ((userData.checkins && userData.checkins.length > 0 && !userData.checkins.includes(checkinId))) {
                newCheckins = userData.checkins;
                newCheckins.push(checkinId);
            } else if ((userData.checkins && userData.checkins.length > 0 && userData.checkins.includes(checkinId))) {
                newCheckins = userData.checkins;
            }

            this.dbCheckin.validateCheckinForAttendee(
                true, 
                checkinId, 
                moduleId, 
                userData.uid, 
                userData.moduleId, 
                this.eventId, 
                newCheckins, 
                (status) => {
                    if (status == true) {
                        this.successAlert();
                        this.router.navigate([`/events/${this.eventId}/self-checkin/${this.moduleId}`]);
                    } else {
                        this.errorManualAlert();
                        this.router.navigate([`/events/${this.eventId}/self-checkin/${this.moduleId}`]);
                    }
                }
            );
        }).catch((error) => { console.error(error); });
    }

    async successAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.all_right'),
            message: this.translateService.instant('global.alerts.your_presence_confirmed'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (_) => {
                    },
                }
            ]
        });

        await alert.present();
    }

    async errorManualAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.error'),
            message: this.translateService.instant('global.alerts.not_confirm_your_presence'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.ok'),
                    handler: (_) => {
                        //
                    },
                }
            ]
        });

        await alert.present();
    }

    async invalidQrAlert() {
        const alert = await this.alertController.create({
            header: this.translateService.instant('global.alerts.error_scanning'),
            message: this.translateService.instant('global.alerts.invalid_qrcode'),
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.translateService.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                    }
                }
            ]
        });

        await alert.present();
    }
}
