import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/shared/services';
import { DaoQuizService } from 'src/app/providers/db/dao-quiz.service';
import { Subscription } from 'rxjs';
import { GetQuizs } from 'src/app/shared/actions/interactivity.actions';
import { AppState } from 'src/app/shared/reducers';
import { Store } from '@ngrx/store';
import { Quiz } from 'src/app/models/quiz';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-quiz-list-page',
    templateUrl: './quiz-list-page.page.html',
    styleUrls: ['./quiz-list-page.page.scss'],
})
export class QuizListPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    quizModule: any = null
    quizs: Quiz[] = [];
    isMobile: boolean = false

    eventId: string = null;
    moduleId: string = null;
    userId: string = null;

    backBtn: boolean = true;

    language: string;

    allowButtonsHeader: boolean = false;
    eventColors: EventColors = new EventColors();

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute,
        private router: Router,
        public SGlobal: GlobalService,
        private menuCtrl: MenuController,
        private daoQuiz: DaoQuizService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);
        this.userId = this.SUserData.userId;

        if (this.SGlobal.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }

        this.language = this.SEventData.getLanguage();
        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        this.loadColors();
    }

    ngOnInit() { 
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.store.dispatch(new GetQuizs({
            quizModule: null,
            quizs: []
        }))
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ionViewWillEnter() {
        this.getQuizs();
    }

    /**
     * Get quizs for modules
     */
    getQuizs() {
        this.subscriptions.push(this.daoQuiz.getQuizModule(this.eventId).subscribe((modules) => {
            if (modules.length > 0) {
                this.quizModule = modules[0];

                this.subscriptions.push(this.daoQuiz.getQuizsEvent(this.moduleId).subscribe((quizs) => {
                    this.quizs = [];
                    this.quizs = quizs;

                    this.store.dispatch(new GetQuizs({
                        quizModule: this.quizModule,
                        quizs: this.quizs
                    }))
                }));
            }
        }));
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    /**
     * On leaving component
     */
    ionViewWillLeave() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
