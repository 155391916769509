import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';

@Injectable()

export class PathApi {
    // authentication
    static authDeleteUser = `${environment.platform.apiBaseUrl}authDeleteUser`;
    static authResetPass = `${environment.platform.apiBaseUrl}authResetPass`;
    static authSupergodDefaultCreateUser = `${environment.platform.apiBaseUrl}authSupergodDefaultCreateUser`;
    static authChangeUserType = `${environment.platform.apiBaseUrl}authChangeUserType`;
    static authVerifyEmailExists = `${environment.platform.apiBaseUrl}authVerifyEmailExists`;
    static authClaimsUsers = `${environment.platform.apiBaseUrl}authClaimsUsers`;
    static authSetUserClaims = `${environment.platform.apiBaseUrl}authSetUserClaims`;
    static emailsSendCodeToUser = `${environment.platform.apiBaseUrl}emailsSendCodeToUser`; 

    // database
    static dbScheduleDatesOfTheSessions = `${environment.platform.apiBaseUrl}dbScheduleDatesOfTheSessions`
    static dbScheduleFilterSessionsByName = `${environment.platform.apiBaseUrl}dbScheduleFilterSessionsByName`
    static dbPersonalScheduleDatesOfTheSessions = `${environment.platform.apiBaseUrl}dbPersonalScheduleDatesOfTheSessions`
    static dbPersonalScheduleFilterSessionsByName = `${environment.platform.apiBaseUrl}dbPersonalScheduleFilterSessionsByName`
    static dbGetUserByEmail = `${environment.platform.apiBaseUrl}dbUserGetByEmail`;
    static dbCreateUser = `${environment.platform.apiBaseUrl}dbUserCreateUser`;
    static dbCreateUserSpeaker = `${environment.platform.apiBaseUrl}dbUserCreateUserSpeaker`;
    static dbUserSendCodeNumberEmail = `${environment.platform.apiBaseUrl}dbUserSendCodeNumber`;
    static dbUserVerifyCodeNumber = `${environment.platform.apiBaseUrl}dbUserVerifyCodeNumber`;
    static dbEventGetAllEvents = `${environment.platform.apiBaseUrl}dbEventGetAllEvents`;
    static dbEventGetClientEvents = `${environment.platform.apiBaseUrl}dbEventGetClientEvents?clientId=`;
    static dbEventGetEvent = `${environment.platform.apiBaseUrl}dbEventGetEvent?eventId=`;
    // static dbAttendeegetAttendeeCheckinStatus = `${environment.platform.apiBaseUrl}dbAttendeesCheckinStatus`;
    static dbScheduleGetAllSessions = `${environment.platform.apiBaseUrl}dbScheduleGetAllSessions`;
    static dbScheduleGenerateIcsFile = `${environment.platform.apiBaseUrl}dbScheduleGenerateIcsFile`
    static dbScheduleGenerateAllIcs = `${environment.platform.apiBaseUrl}dbScheduleGenerateAllIcs`

    static dbUserCreateUserFirstAccess = `${environment.platform.apiBaseUrl}dbUserCreateUserFirstAccess`

    // CHECK-IN
    static dbCheckinGetAttendees = `${environment.platform.apiBaseUrl}dbCheckinGetAttendees`;
    static dbCheckinSymplaCheckin = `${environment.platform.apiBaseUrl}dbCheckinSymplaCheckin`;

    // NOTIFICATION
    static dbNotificationsSendPush = `${environment.platform.apiBaseUrl}dbNotificationsSendPush`;
}
