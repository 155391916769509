import { Action } from '@ngrx/store';
import { IInteractivityQuestions, IInteractivitySurveys, IInteractivityQuizs, IInteractivitySpeakers, IInteractivityDocuments, IInteractivityFeedbacks, IInteractivityDiscussionsGroups } from '../interfaces/interactivity.interfaces';

export enum InteractivityActionsTypes {
    GetQuestions = "[Interactivity Service] Get questions",
    GetSurveys = "[Interactivity Service] Get surveys",
    GetQuizs = "[Interactivity Service] Get quiz",
    GetSpeakers = "[Interactivity Service] Get speakers",
    GetDocuments = "[Interactivity Service] Get documents",
    GetFeedbacks = "[Interactivity Service] Get feedbacks",
    GetDiscussionsGroups = "[Interactivity Service] Get discussions groups"
}

export class GetQuestions implements Action {
    readonly type = InteractivityActionsTypes.GetQuestions;

    constructor(public payload: IInteractivityQuestions) { }
}

export class GetSurveys implements Action {
    readonly type = InteractivityActionsTypes.GetSurveys;

    constructor(public payload: IInteractivitySurveys) { }
}

export class GetQuizs implements Action {
    readonly type = InteractivityActionsTypes.GetQuizs;

    constructor(public payload: IInteractivityQuizs) { }
}

export class GetSpeakers implements Action {
    readonly type = InteractivityActionsTypes.GetSpeakers;

    constructor(public payload: IInteractivitySpeakers) { }
}

export class GetDocuments implements Action {
    readonly type = InteractivityActionsTypes.GetDocuments;

    constructor(public payload: IInteractivityDocuments) { }
}

export class GetFeedbacks implements Action {
    readonly type = InteractivityActionsTypes.GetFeedbacks;

    constructor(public payload: IInteractivityFeedbacks) { }
}

export class GetDiscussionsGroups implements Action {
    readonly type = InteractivityActionsTypes.GetDiscussionsGroups;

    constructor(public payload: IInteractivityDiscussionsGroups) { }
}

export type InteractivityActionsUnion = GetQuestions | GetSurveys | GetQuizs | GetSpeakers | GetDocuments | GetFeedbacks | GetDiscussionsGroups;