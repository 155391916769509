import { Answer } from "./answer";
import { NameModule } from "./name-module";

export class Question {
    uid: string;
    mainTitle?: string;
    title: string;
    infobooth: string | NameModule;
    type: string;
    points?: number;
    graphic: string;
    maxResponses: number;
    answers: Array<Answer>;
    answer?: any;
    answered = false;
    visibility: boolean;
    graphicDifusion: boolean;
}