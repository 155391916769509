/**
 * By default it was set to use the "Europe / Paris" zone as the default for all dates. That goes for Api too.
 */

import { Injectable } from "@angular/core";
import { DateTime, Settings } from "luxon";
import { IDate } from "src/app/shared/interfaces/date.interface";
import { environment } from "../../../environments/environment";

@Injectable()
export class LuxonService {
	defaultLanguage: string; //default app language

	constructor() {
		// Settings.defaultZoneName = "Europe/Paris"
		// Settings.defaultZoneName = "America/Sao_Paulo"
		Settings.defaultZoneName = environment.platform.defaultTimezone;

		// gets the default language of the application.
		switch (environment.platform.defaultLanguage) {
			case "pt_BR" || "pt-BR" || "PtBR":
				this.defaultLanguage = "pt-BR";
				break;
			case "en_US" || "en-US" || "EnUS":
				this.defaultLanguage = "en-US";
				break;
			case "es_ES" || "es-ES" || "EsES":
				this.defaultLanguage = "es-ES";
				break;
			case "fr_FR" || "fr-FR" || "FrFR":
				this.defaultLanguage = "fr-FR";
				break;
			case "de_DE" || "de-DE" || "DeDE":
				this.defaultLanguage = "de-DE";
				break;
		}
	}

	/* cria data com a fuso horário do timezane 
    
    return object DateTime
    */
	createDate(year: string, month: string, day: string, hour: string, minute: string, seconds: string) {
		const aux = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds;
		return DateTime.fromISO(aux);
	}

	// cria timestamp
	createTimeStamp(date: DateTime) {
		return date.valueOf() / 1000;
	}

	// converte timestamp para DateTime
	convertTimestampToDate(timestamp: number) {
		const aux = DateTime.fromMillis(timestamp * 1000);
		return aux;
	}

	// converte um  DateTime para uma string do formato dd/mm/aaaa
	convertDateToStringIsNotUSA(date: DateTime): any {
		return date.toLocaleString({ day: "numeric", month: "numeric", year: "numeric" });
	}

	// converte um DateTime para uuma string com em português do Brasil (DD de MÊS)
	convertDateToStringTitlePortBrazil(date: DateTime) {
		var f = { year: "numeric", month: "long", day: "numeric" };
		return date.setLocale("pt-BR").toLocaleString(f as any);
	}

	// converte um DateTime para uuma string com em português do Brasil (DD de MÊS de YYYY)
	convertDateToStringTitlePortBrazilDDMM(date: DateTime) {
		var f = { month: "long", day: "numeric" };
		return date.setLocale("pt-BR").toLocaleString(f as any);
	}

	// converte um DateTime para uuma string com em português do Brasil (HH:MM)
	convertDateToStringTitlePortBrazilHHMM(date: DateTime) {
		let f = { hour: "numeric", minute: "numeric" };
		return date.setLocale("pt-BR").toLocaleString(f as any);
	}

	// converte um  DateTime para uma string do formato mm/dd/aaaa
	convertDateToStringIsUSA(date: DateTime): any {
		const year = date.year;
		const month = date.month;
		const day = date.day;

		return month + "/" + day + "/" + year;
	}

	// converte um DateTime em uma string no formato yyyy-mm-aa
	convertDateToStringYearMonthDay(date: DateTime) {
		return date.toISODate();
	}

	// converte um DateTime em uma string no formato { day: 'numeric', month: 'long' }
	convertDateToFormatDDMM(date: DateTime) {
		return date.setLocale(this.defaultLanguage).toLocaleString({ month: "long", day: "numeric" });
	}

	// convert string format (dd/mm/yyyy) to timestamp
	convertStringToTimestamp(date: string) {
		const array = date.split("/");

		const day = array[0];
		const month = array[1];
		const year = array[2];

		const hour = "00";
		const minute = "00";
		const second = "00";

		// create DateTime and  convert  to timestamp
		return this.createTimeStamp(this.createDate(year, month, day, hour, minute, second));
	}

	//torna a hora e o minuto da data
	dateTime(date: DateTime) {
		return date.toLocaleString({ hour: "numeric", minute: "numeric" });
	}

	dateTimeUSA(date: DateTime): any {
		let aux = date.setLocale("en-US");
		return aux.toLocaleString(DateTime.TIME_SIMPLE);
	}

	getTimestampCurrentDate() {
		return Date.now();
	}

	// return current time
	currentTime(): DateTime {
		let now = DateTime.local();
		return now;
	}

	/**
	 * Get string date from IDate object
	 * @param date
	 */
	getStringDateFromDate(date: IDate, type: number) {
		if (type == 0) {
			if (date.zone == "local") {
				return DateTime.local(
					date.year,
					date.month,
					date.day,
					date.hour,
					date.minute,
					date.second,
					date.millisecond
				).toFormat("MM/dd/yyyy");
			} else {
				return DateTime.fromObject(date).toFormat("MM/DD/YYYY");
			}
		} else {
			if (date.zone == "local") {
				return DateTime.local(
					date.year,
					date.month,
					date.day,
					date.hour,
					date.minute,
					date.second,
					date.millisecond
				).toFormat("dd/MM/yyyy");
			} else {
				return DateTime.fromObject(date).toFormat("DD/MM/YYYY");
			}
		}
	}
}
