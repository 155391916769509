import { Answer } from './quiz-answer';
import { NameModule } from './path/name-module';

export class Question {
    uid: string;
    title: string;
    infobooth: NameModule;
    type: string;
    graphic: string;
    graphicDifusion: boolean;
    answers: Object = {};
    maxResponses: number;
    image: any;
    answered = false;
    showTrue = false;
    visibility: boolean;
}
