import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { DaoAttendeesService } from 'src/app/providers/db/dao-attendees.service';
import { IonInfiniteScroll, IonVirtualScroll, MenuController, ModalController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { TypeVisionModule } from '../../models/type-vision-module';
import { DaoGroupsService } from 'src/app/providers/db/dao-groups.service';
import { Subscription } from 'rxjs';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { PathComponent } from 'src/app/models/path/path-components';
import { TranslateService } from '@ngx-translate/core';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-attendees',
    templateUrl: './attendees.page.html',
    styleUrls: ['./attendees.page.scss']
})

export class AttendeesPage implements OnInit, OnDestroy {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonVirtualScroll) virtualScroll: IonVirtualScroll;
    subscriptions: Subscription[] = [];
    searchOpen: boolean = false;
    searchText: string = '';
    lastAttendees;
    attendees: Array<any> = [];
    searchedAttendees: Array<any> = [];
    searchedAttendeesFixedArray: Array<any> = [];
    eventId: string = null;
    moduleId: string = null;
    loader: boolean = true;
    typeOrder: string;
    backBtn: boolean = true;
    allAttendeesList: Array<any> = [];
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;

    // module views
    typeVisionModule = null//type of module views.

    groups = [] //groups of the event.
    userGroups: any = [] //user groups
    viewGroup: boolean = false; //html of the event groups
    showNoResultImage = false;

    listCompany = []; // all companies in attendees list 
    listPost = []; // all posts in attendees list
    filterBloc = true; // to show or hide filter bloc
    allowFilterCompany = null; // to show or hide filter companies
    allowFilterPost = null; // to show or hide filter post
    allowFilterCustomFields = null;
    showLetters: boolean = true; // to show or hide letters 
    checkedCompanies = [];
    checkedPosts = [];
    checkedCustomFields: any[][] = [];
    isMobile: boolean = false;
    customFieldsTitle: any[] = [];
    customsFieldsArray: any[][] = [];
    customFieldsFomatTitle: any = [];
    allowFilterCustomArray: any = [];
    showOnlyLoggedUsers: any;

    companyFilterText: string 
    postFilterText: string

    moduleName: string;

    constructor(
        private dbAttendees: DaoAttendeesService,
        private route: ActivatedRoute,
        private router: Router,
        public toastController: ToastController,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private dbGroups: DaoGroupsService,
        private translateService: TranslateService,
        private modalCtrl: ModalController,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        this.loadColors();

        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_ATTENDEES_MODULE);

        this.startAll();
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        // get the value of the backBtn button.
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        this.startAll();
    }

    ngOnDestroy() {
        this.viewGroup = false
        // close getModule
        this.dbAttendees.closeRefGetModule();
        this.dbGroups.closeRefContAttendeesOfGroup();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    headerHeightFn(item, index) {
        return (40);
    }

    itemHeightFn(item, index) {
        return (97);
    }

    getFirstAttendeeOfModule(moduleId: string): any {
        this.dbAttendees.getFirstAttendeeFromModule(moduleId, (data) => {
            return data[0];
        })
    }

    startAll() {
        // get the module information
        this.dbAttendees.getModule(this.moduleId, async (module) => {
            this.moduleName = module.name[this.SEventData.getLanguage()]
            this.allowFilterCompany = (module.allowFilterCompany == undefined) ? 
                false : 
                module.allowFilterCompany;

            this.allowFilterPost = (module.allowFilterPost == undefined) ? 
                false :
                module.allowFilterPost;

            this.allowFilterCustomFields = (module.allowFilterCustomFields == undefined) ? 
                false :
                module.allowFilterCustomFields;

            this.filterBloc = (
                module.allowFilterCompany === true || 
                module.allowFilterPost === true || 
                this.allowFilterCustomFields === true
            );

            this.showLetters = (module.showLetters === true);
            this.companyFilterText = (module.companyFilterText) ? 
                module.companyFilterText : 
                this.translateService.instant('pages.attendees.company');
            
            this.postFilterText = (module.postFilterText) ? 
                module.postFilterText : 
                this.translateService.instant('pages.attendees.post');
            
            this.showOnlyLoggedUsers = (module.showOnlyLoggedUsers == undefined) ? 
                false :
                module.showOnlyLoggedUsers;

            // getting custom fields
            this.dbAttendees.getFirstAttendeeFromModule(this.moduleId, (att) => {
                // this.dbAttendees.getCustomFields(this.eventId, att[0].uid, (data) => {
                //     this.customFieldsTitle = [];
                //     if (data && data.length > 0) {
                //         data.forEach(el => {
                //             if (el.exibicao === '1') {
                //                 this.customFieldsTitle.push(el.name[this.SEventData.getLanguage()]);
                //             }
                //         });
                //         // getting individual allowing for custom fields
                //         // prefix : 'allowFilterCustomField_'
                //         this.allowFilterCustomArray = [];
                //         this.customFieldsFomatTitle = [];
                //         for (let i = 0; i < this.customFieldsTitle.length; i++) {
                //             const typeArray = this.customFieldsTitle[i].split(' ');
                //             let formatType = '';
                //             let j = 0;
                //             for (const ch of typeArray) {
                //                 j++;
                //                 formatType = formatType.concat(ch);
                //                 if (j !== typeArray.length) {
                //                     formatType = formatType.concat('_');
                //                 }
                //             }
                //             const name = 'allowFilterCustomField_' + formatType;
                //             this.allowFilterCustomArray.push({name: this.module[name] !== undefined ? this.module[name] : false});
                //             this.customFieldsFomatTitle.push(formatType);
                //             // console.log(this.allowFilterCustomArray[i].name)
                //         }
                //     }
                // });
            });

            this.typeOrder = module.orderUsers;
            this.typeVisionModule = module.typeVision

            if (this.typeVisionModule === TypeVisionModule.GLOBAL_VISION) {
                // verifies the type of vision of the module. global vision
                this.viewGroup = false

                this.getFirstAttendeesGlobalVision()
                this.getAttendeesAllGlobalVision()
            } else if (this.typeVisionModule === TypeVisionModule.DIVIDED_BY_GROUPS) {
                //divided by groups
                this.loader = true
                this.viewGroup = true

                // load groups
                this.getGroupsEvent()
            } else if (this.typeVisionModule === TypeVisionModule.GROUP_VISION) {
                // group vision
                this.loader = true
                this.viewGroup = false
                
                const userGroups: Object = (await this.SUserData.getUserDataSnapshot()).groups
		        const userGroupsIds: Array<string> = (userGroups) ? Object.keys(userGroups) : [];
                this.getAttendeesGroupVision(userGroupsIds)  // load attendees
            } else if (this.typeVisionModule === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
                // group access permission
                this.viewGroup = false

                this.getFirstAttendeesLimitedAccessByGroup()
                this.getAttendeesAllLimitedAccessByGroup()
            }
        });
    }
    async presentToast(time) {
        const toast = await this.toastController.create({
            message: time,
            duration: 3000
        });
        toast.present();
    }

    attendeeHeader() {
        const thisReference = this;
        return function(attendee, i, attendees) {
            let showLetters = thisReference.showLetters;
            if(!showLetters){
                return null;
            }
            if (i == 0) {
                return attendee.letter;
            } else if (i != 0 && attendee.letter != attendees[i - 1].letter) {
                return attendee.letter;
            }
        }
        
    }


    // loads more attendees when the infinite scroll is triggered
    moreAttendees(event) {
        // verifies the type of vision of the module.
        if (this.typeVisionModule === TypeVisionModule.GLOBAL_VISION) {
            this.moreAttendeesGlobalVision(event)
        }
        else if (this.typeVisionModule === TypeVisionModule.GROUP_ACCESS_PERMISSION) {
            this.moreAttendeesAccessByGroup(event)
        }
        else {
            event.target.disabled = true;
        }
    }

    profileDetails(attendee) {
        let navigationExtras: NavigationExtras = {
            state: {
                user: attendee  
            }
        };
        this.router.navigate([`/event/${this.eventId}/profile/${this.moduleId}/${attendee.type}/${attendee.uid}`], navigationExtras);
        this.global.previousPage = null;
    }

    searchBar(ev) {
        if (ev.target.value.length >= 1) {
            let value = ev.target.value.toLowerCase();
            // this.searchedAttendees = [];

            let filterdArr
            if(this.searchedAttendeesFixedArray.length === 0){
                filterdArr= this.allAttendeesList
                console.log('allAttendeesList')
            } else {
                filterdArr= this.searchedAttendeesFixedArray
                console.log('searchedAttendeesFixedArray')
            }
            // const filterdArr = (this.searchedAttendeesFixedArray.length === 0) ? this.allAttendeesList : this.searchedAttendeesFixedArray
            this.searchedAttendees = [...filterdArr].filter(item => {
                item.principal_title = this.getAttendeePrincipalTitle(item.title);
                if (item.name.toLowerCase().includes(value) || (item.principal_title !== null && item.principal_title.toLowerCase().includes(value)) || (item.company !== null && item.company.toLowerCase().includes(value))) {
                    return item
                }
            })
            if(this.searchedAttendees.length === 0) this.showNoResultImage = true;
        } else {
            this.searchedAttendees = this.searchedAttendeesFixedArray.length === 0 ? [] : [...this.searchedAttendeesFixedArray];
            this.showNoResultImage = false;
        }
    }

    resitFilter(){
        this.searchedAttendees = this.searchedAttendeesFixedArray.length === 0 ? [] : [...this.searchedAttendeesFixedArray];
        this.showNoResultImage = false;
    }

    // load the event groups.
    getGroupsEvent() {
        this.dbGroups.getGroups(this.eventId, (groups) => {
            this.groups = groups

            // Sort the groups alphabetically.
            groups.sort(function (a, b) {
                return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
            });

            // checks the number of sessions in the module that belongs to each group.
            for (const group of groups) {
                const groupId = group.uid
                const moduleAttendeeId = this.moduleId

                group.qtdSessions = 0

                // count the number of participants the group has in the module.
                this.dbGroups.contAttendeesOfGroup(groupId, moduleAttendeeId, (qtd: number) => {
                    group.qtdSessions = qtd
                })
            }

            this.loader = false
        })
    }

    /******************************************************************************** methods of global vision *************************************************************************************** */

    // loads the first 100 participants of the module. global vision
    getFirstAttendeesGlobalVision() {
        this.dbAttendees.getFirstAttendeesGlobalVision(this.typeOrder, this.moduleId, (data) => {
            let list = data;
            // this.attendees, this.allAttendeesList = [];
            this.attendees = [];

            let companies = [], posts = [], customs: any[][] = [];
            //get the last participant
            if (list.length > 0) {
                this.lastAttendees = data[data.length - 1];
            }

            list.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                element.principal_title = this.getAttendeePrincipalTitle(element.title);
                console.log('Value of show logged variable : ', this.showOnlyLoggedUsers)
                if (this.showOnlyLoggedUsers) {
                    if (element.firstAccess !== undefined || element.firstAccess === true) {
                        this.attendees.push(element);
                    }
                } else {
                    this.attendees.push(element);
                }
                (element.company !== null && element.company !== '') && companies.push(element.company);
                (element.principal_title !== '') && posts.push(element.principal_title);

                /* ------ Custom fields ----- */
                let customTemp = [];

                if (element.customFields) {
                    for (const cus of element.customFields) {
                        // && (cus.textValue[this.convertLangFormat(this.global.event.language)] !== '' &&
                        // cus.textValue[this.convertLangFormat(this.global.event.language)] !== undefined))
                        if (cus.exibicao === '1') {
                            customTemp.push(cus.textValue[this.SEventData.getLanguage()]);
                        }
                    }
                    customs.push(customTemp);
                }
            });

            this.attendees = list;
            // this.allAttendeesList = list;

            // this.attendees = list;
            // this.listCompany = Array.from(new Set([...this.listCompany])) // remove duplicated items
            // this.listPost = Array.from(new Set([...this.listPost]))
            companies = [...companies].sort((a, b) =>
                a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0
            );
            posts = [...posts].sort((a, b) =>
                a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0
            );
            if (this.attendees.some(x => x.company === null || x.company === '')) {
                companies.push(this.translateService.instant('pages.attendees.not_specified'));
            }
            if (this.attendees.some(x => x.principal_title === '')) {
                posts.push(this.translateService.instant('pages.attendees.not_specified'));
            }
            this.listCompany = Array.from(new Set([...companies])); // remove duplicated items
            this.listPost = Array.from(new Set([...posts]));

            /* ------ Custom fields ----- */
            const temp = customs.reduce((acc, val) => acc.concat(val), []);
            // console.log(Array.from(new Set([...temp])));
            const size = customs.length;
            for (let i = 0; i < size; i++) {
                // remove duplicated items
                this.customsFieldsArray[i] = Array.from(new Set([...customs[i]]));
            }

            // Short code to get unique values in arrays
            const tempArray = [];
            for (let i = 0; i < customs.length; i++) {
                let tempp = customs[i];
                for (let j = (i + 1); j < customs.length; j++) {
                    tempp.map(val => {
                        for (let id = 0; id < customs[j].length; id++) {
                            if (val.trim().toLowerCase() === customs[j][id].trim().toLowerCase()) {
                                customs[j][id] = '';
                            }
                        }
                        temp.push(customs[j])
                    });
                }
                // console.log(tempp)
                tempArray.push(tempp)
            }
            for (let i = 1; i < tempArray.length; i++) {
                if (tempArray[i].length === 0) {
                    tempArray[i] = tempArray[tempArray.length - 1];
                }
            }

            // console.log(this.customsFieldsArray);
            // console.log(tempArray);
            this.customsFieldsArray = tempArray;
            // console.log(this.customsFieldsArray);

            this.loader = false;
        });
    }

    // get all attendees of the module
    getAttendeesAllGlobalVision() {
        this.subscriptions.push(this.dbAttendees.getAttendeesAllGlobalVision(this.moduleId).subscribe((data) => {
            this.allAttendeesList = data
        }))
    }


    // loads more attendees when the infinite scroll is triggered (global vision)
    moreAttendeesGlobalVision(event) {
        if (this.lastAttendees) {
            let start = Date.now();

            this.dbAttendees.getNextPageAttendeesGlobalVision(this.moduleId, this.lastAttendees, this.typeOrder, (data) => {

                this.lastAttendees = null

                if (data.length > 0) {
                    this.lastAttendees = data[data.length - 1];
                }

                data.forEach(element => {
                    var letters = element.name.split('');
                    var letter = letters[0].toUpperCase();
                    element.letter = letter;
                    element.principal_title = this.getAttendeePrincipalTitle(element.title);
                    if (this.showOnlyLoggedUsers) {
                        if (element.firstAccess !== undefined || element.firstAccess === true) {
                            this.attendees.push(element);
                        }
                    } else {
                        this.attendees.push(element);
                    }
                });

                if (this.lastAttendees) {
                    this.virtualScroll.checkEnd();
                    event.target.complete();
                } else {
                    event.target.disabled = true;
                }
            })
        } else {
            event.target.disabled = true;
        }
    }


    /******************************************************************************** methods of divided by groups *************************************************************************************** */
    // loads the attendees of the group.
    getAttendeesGroupDividByGroup(group) {
        const groupId = group.uid

        this.loader = true
        this.viewGroup = false

        // loads the group attendees.
        this.dbAttendees.getAttendeesGroupGlobalVision(groupId, this.typeOrder, this.moduleId, (data) => {
            this.loader = false
            this.lastAttendees = null
            this.attendees, this.allAttendeesList = [];

            if (data.length > 0) {
                this.lastAttendees = data[data.length - 1]
            }

            data.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                element.principal_title = this.getAttendeePrincipalTitle(element.title);
                if (this.showOnlyLoggedUsers) {
                    if (element.firstAccess !== undefined || element.firstAccess === true) {
                        this.attendees.push(element);
                    }
                } else {
                    this.attendees.push(element);
                }
            });

            this.attendees = data;
            this.allAttendeesList = data;
        })
    }

    /******************************************************************************** methods of group vision *************************************************************************************** */
    // loads the participant group attendees.
    getAttendeesGroupVision(userGroupsIds: string[]) {
        this.dbAttendees.getAttendeesGroupsVision(userGroupsIds, this.typeOrder, this.moduleId, (data) => {
            this.loader = false
            this.lastAttendees = null
            this.attendees, this.allAttendeesList = [];

            if (data.length > 0) {
                this.lastAttendees = data[data.length - 1]
            }

            data.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                element.principal_title = this.getAttendeePrincipalTitle(element.title);
            });

            this.attendees = data
            this.allAttendeesList = data
        })
    }

    /******************************************************************************** methods of limited access by groups *************************************************************************************** */
    // loads the first 100 participants of the module. 
    getFirstAttendeesLimitedAccessByGroup() {
        // let start = Date.now();
        this.dbAttendees.getFirstAttendeesGlobalVision(this.typeOrder, this.moduleId, (data) => {
            let list = data
            this.attendees, this.allAttendeesList = [];

            //get the last participant
            if (list.length > 0) {
                this.lastAttendees = data[data.length - 1];
            }

            list.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                element.principal_title = this.getAttendeePrincipalTitle(element.title);
                if (this.showOnlyLoggedUsers) {
                    if (element.firstAccess !== undefined || element.firstAccess === true) {
                        this.attendees.push(element);
                    }
                } else {
                    this.attendees.push(element);
                }
            });

            this.attendees = list
            this.loader = false

            // if (this.attendees.length == data.length) {
            //   this.loader = false;
            // }
        })
    }

    // get all attendees of the module
    getAttendeesAllLimitedAccessByGroup() {
        this.subscriptions.push(this.dbAttendees.getAttendeesAllLimitedAccessByGroup(this.moduleId).subscribe((data) => {
            this.allAttendeesList = data
        }))
    }


    // loads more attendees when the infinite scroll is triggered 
    moreAttendeesAccessByGroup(event) {
        if (this.lastAttendees) {
            let start = Date.now();

            this.dbAttendees.getNextPageAttendeesLimitedAccessByGroup(this.moduleId, this.lastAttendees, this.typeOrder, (data) => {
                this.lastAttendees = null

                if (data.length > 0) {
                    this.lastAttendees = data[data.length - 1];
                }

                data.forEach(element => {
                    var letters = element.name.split('');
                    var letter = letters[0].toUpperCase();
                    element.letter = letter;
                    element.principal_title = this.getAttendeePrincipalTitle(element.title);
                    if (this.showOnlyLoggedUsers) {
                        if (element.firstAccess !== undefined || element.firstAccess === true) {
                            this.attendees.push(element);
                        }
                    } else {
                        this.attendees.push(element);
                    }
                });

                if (this.lastAttendees) {
                    this.virtualScroll.checkEnd();
                    event.target.complete();
                } else {
                    event.target.disabled = true;
                }
            })
        } else {
            event.target.disabled = true;
        }
    }


    getAttendeePrincipalTitle(title) {
        return title[this.SEventData.getLanguage()];
    }

    async showFilterBloc(){
        if(this.isMobile) {
            const list = []
            if(this.allowFilterCompany){
                list.push({key:'company', value: this.listCompany})
            }
            if(this.allowFilterPost){
                list.push({key:'post', value: this.listPost})
            }
            const modal = await this.modalCtrl.create({
                component: PathComponent.filter_by,
                componentProps: {
                    mode: 'mobile' ,
                    comp: 'attendees',
                    choices: list,
                    selectedValues: [{key:'company', value: this.checkedCompanies},{key:'post', value: this.checkedPosts}],
                    colors: {
                        'link_color': this.eventColors.link_color,
                        'title_color': this.eventColors.title_color,
                        'btn_text_color':this.eventColors.btn_text_color
                    },
                    companyFilterText:  this.companyFilterText,
                    postFilterText:  this.postFilterText
                },
                cssClass: 'modal-order-by'
            });

            modal.onDidDismiss().then(ret => {
                // this.typeOrder = ret.data.selectedValues
                console.log('selectedValues',ret.data.selectedValues)
                this.checkedCompanies = ret.data.selectedValues.filter(x => x.key === 'company')[0].value
                this.checkedPosts = ret.data.selectedValues.filter(x => x.key === 'post')[0].value
                this.filterArray(this.checkedPosts,this.checkedCompanies)

                // this.changeOrder()
            })
            return await modal.present();
        } else {
            this.filterBloc = !this.filterBloc
        }
    }

    getCustomFilterList(): string[] {
        let list = [];
        if (this.customFieldsTitle.length > 0) {
            list = this.customsFieldsArray;
        }
        return list;
    }

    async showFilterBlocCustoms(){
        if(this.isMobile) {
            const list = []

            if(this.allowFilterCompany){
                list.push({key:'company', value: this.listCompany})
            }
            if(this.allowFilterPost){
                list.push({key:'post', value: this.listPost})
            }
            if(this.allowFilterCustomFields){
                list.push({key: 'customs', value: this.getCustomFilterList()});
            }

            const modal = await this.modalCtrl.create({
                component: PathComponent.filter_by,
                componentProps: {
                    mode: 'mobile' ,
                    comp: 'attendees',
                    choices: list,
                    selectedValues: [{key: 'customs', value: this.checkedCustomFields},
                                    {key: 'company', value: this.checkedCompanies},
                                    {key: 'post', value: this.checkedPosts}],
                    colors: {
                        'link_color': this.eventColors.link_color,
                        'title_color': this.eventColors.title_color,
                        'btn_text_color': this.eventColors.btn_text_color
                    },
                    companyFilterText:  this.companyFilterText,
                    postFilterText:  this.postFilterText
                },
                cssClass: 'modal-order-by'
            });

            modal.onDidDismiss().then(ret => {
                // this.typeOrder = ret.data.selectedValues
                console.log('selectedValues', ret.data.selectedValues)
                this.checkedCustomFields = ret.data.selectedValues.filter(x => x.key === 'company')[0].value;
                this.checkedPosts = ret.data.selectedValues.filter(x => x.key === 'post')[0].value;
                this.checkedCustomFields = ret.data.selectedValues.filter(x => x.key === 'customs')[0].value;

                /* Small operation to determine witch filter function
                        the code will use */
                let customFieldActive = false;
                for (const name of this.customFieldsTitle) {
                    if (this.checkedCustomFields[name] && this.checkedCustomFields[name].length > 0) {
                        customFieldActive = true;
                    }
                }
                if (customFieldActive) {
                    this.filterArrayWithCustomFields(this.checkedPosts, this.checkedCompanies, this.checkedCustomFields);
                } else {
                this.filterArray(this.checkedPosts, this.checkedCompanies);
                }
                // this.changeOrder()
            })
            return await modal.present();
        } else {
            this.filterBloc = !this.filterBloc;
        }
    }


    checkedChanged(ev, type: string){
        console.log(ev.target.value, ev.target.checked);
        const value = ev.target.value;
        const isChecked = ev.target.checked;
        if (type === 'company') {
            this.checkedCompanies = isChecked ? [...this.checkedCompanies, value] : [...this.checkedCompanies].filter(x => x !== value)
            this.checkedCompanies = Array.from(new Set(this.checkedCompanies))
        } else if ((type === 'post')){
            this.checkedPosts = isChecked ? [...this.checkedPosts, value] : [...this.checkedPosts].filter(x => x !== value)
            this.checkedPosts = Array.from(new Set(this.checkedPosts))
        } else {
            if (this.checkedCustomFields) {
                console.log('hey')
                const custom = this.checkedCustomFields[type];
                // tslint:disable-next-line: curly
                if (!this.checkedCustomFields[type]) this.checkedCustomFields[type] = [];
                this.checkedCustomFields[type] = isChecked ? [...this.checkedCustomFields[type], value] :
                                                this.checkedCustomFields[type].filter(x => x !== value);
            }
        }
        console.log('Custom checked : ' , this.checkedCustomFields);
        console.log('Checked post : ' , this.checkedPosts);
        console.log('Checked company : ' , this.checkedCompanies);

        /* Small operation to determine witch filter function
                        the code will use */
        let customFieldActive = false;
        for (const name of this.customFieldsTitle) {
            if (this.checkedCustomFields[name] && this.checkedCustomFields[name].length > 0) {
                customFieldActive = true;
            }
        }
        if (customFieldActive) {
            this.filterArrayWithCustomFields(this.checkedPosts, this.checkedCompanies, this.checkedCustomFields);
        } else {
        this.filterArray(this.checkedPosts, this.checkedCompanies);
        }

    }

    filterArray(posts: Array<any>, companies: Array<any>){
        console.log('filterArray function used ...')
        const not_specified = this.translateService.instant('pages.attendees.not_specified')
        if (posts.length === 0 && companies.length > 0){
            console.log('hye')
            this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                item.principal_title = this.getAttendeePrincipalTitle(item.title);

                // tslint:disable-next-line: max-line-length
                return ((item.company === null || item.company === '') && companies.includes(not_specified) || companies.includes(item.company)) && item
                // return companies.includes(item.company) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            this.searchedAttendeesFixedArray = [...this.searchedAttendees];
            return ;
        }
        if (posts.length > 0 && companies.length === 0) {
            this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                item.principal_title = this.getAttendeePrincipalTitle(item.title);
                // if(posts.includes(not_specified)){
                //     return (item.principal_title === null || item.principal_title === '') && item
                // }
                // tslint:disable-next-line: max-line-length
                return ((item.principal_title === null || item.principal_title === '') && posts.includes(not_specified) || posts.includes(item.principal_title)) && item

                // return posts.includes(item.principal_title) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            this.searchedAttendeesFixedArray = [...this.searchedAttendees]
            return ;
        }
        if (posts.length > 0 && companies.length > 0) {
            this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                item.principal_title = this.getAttendeePrincipalTitle(item.title);

                // tslint:disable-next-line: max-line-length
                return (companies.includes(not_specified) && posts.includes(not_specified) && (item.company === null || item.company === '') && (item.principal_title === null ||
                        item.principal_title === '') || posts.includes(item.principal_title) && companies.includes(item.company)) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            if(this.searchedAttendees.length === 0) this.showNoResultImage = true;
            this.searchedAttendeesFixedArray = [...this.searchedAttendees]
            return ;
        }
        if(posts.length === 0 && companies.length === 0){
            this.searchedAttendees = []
            this.searchedAttendeesFixedArray = []
            this.showNoResultImage = false;
        }
    }

    filterArrayWithCustomFields(posts: Array<any>, companies: Array<any>, customFields?: Array<any>) {
        const not_specified = this.translateService.instant('pages.attendees.not_specified')
        if (posts.length === 0 && companies.length > 0) {

            for (const title of this.customFieldsTitle) {
                if (customFields[title] && customFields[title].length > 0) {
                    this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                        item.principal_title = this.getAttendeePrincipalTitle(item.title);

                        // tslint:disable-next-line: max-line-length
                        for (const cusTitle of this.customFieldsTitle) {
                            if (customFields[cusTitle] && item.customFields) {
                                for (let i = 0; i < item.customFields.length; i++) {
                                    // tslint:disable-next-line: curly
                                    if (companies.includes(not_specified) &&
                                        (item.company === null || item.company === '') &&
                                        ((item.customFields[i].textValue[this.SEventData.getLanguage()] === '') ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === null) ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === '')) ||
                                        companies.includes(item.company) &&
                                        customFields[cusTitle].includes(item.customFields[i]
                                            .textValue[this.SEventData.getLanguage()]))
                                        return item;
                                }
                            }
                        }
                    }).sort((a,b)=> 
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
                    if (this.searchedAttendees.length === 0) {
                        this.showNoResultImage = true;
                    }
                    this.searchedAttendeesFixedArray = [...this.searchedAttendees];
                    return ;
                }
            }
        }

        if (posts.length > 0 && companies.length === 0) {
            for (const title of this.customFieldsTitle) {
                if (customFields[title] && customFields[title].length > 0) {
                    this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                        item.principal_title = this.getAttendeePrincipalTitle(item.title);

                        // tslint:disable-next-line: max-line-length
                        for (const cusTitle of this.customFieldsTitle) {
                            if (customFields[cusTitle] && item.customFields) {
                                for (let i = 0; i < item.customFields.length; i++) {
                                    // tslint:disable-next-line: curly
                                    if (posts.includes(not_specified) &&
                                        (item.principal_title === null || item.principal_title === '') &&
                                        ((item.customFields[i].textValue[this.SEventData.getLanguage()] === '') ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === null) ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === '')) ||
                                        posts.includes(item.principal_title) &&
                                        customFields[cusTitle].includes(item.customFields[i]
                                            .textValue[this.SEventData.getLanguage()]))
                                        return item;
                                }
                            }
                        }
                    }).sort((a,b)=> 
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
                    if (this.searchedAttendees.length === 0) {
                        this.showNoResultImage = true;
                    }
                    this.searchedAttendeesFixedArray = [...this.searchedAttendees];
                    return ;
                }
            }
        }

        if (posts.length > 0 && companies.length > 0) {
            for (const title of this.customFieldsTitle) {
                if (customFields[title] && customFields[title].length > 0) {
                    this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                        item.principal_title = this.getAttendeePrincipalTitle(item.title);

                        // tslint:disable-next-line: max-line-length
                        for (const cusTitle of this.customFieldsTitle) {
                            if (customFields[cusTitle] && item.customFields) {
                                for (let i = 0; i < item.customFields.length; i++) {
                                    // tslint:disable-next-line: curly
                                    if (companies.includes(not_specified) &&
                                        posts.includes(not_specified) &&
                                        (item.company === null || item.company === '') &&
                                        (item.principal_title === null || item.principal_title === '') &&
                                        ((item.customFields[i].textValue[this.SEventData.getLanguage()] === '') ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === null) ||
                                        (item.customFields[i].textValue[this.SEventData.getLanguage()] === '')) ||
                                        companies.includes(item.company) &&
                                        posts.includes(item.principal_title) &&
                                        customFields[cusTitle].includes(item.customFields[i]
                                            .textValue[this.SEventData.getLanguage()]))
                                        return item;
                                }
                            }
                        }
                    }).sort((a,b)=> 
                        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
                    if (this.searchedAttendees.length === 0) {
                        this.showNoResultImage = true;
                    }
                    this.searchedAttendeesFixedArray = [...this.searchedAttendees];
                    return ;
                }
            }
        }

        if (posts.length === 0 && companies.length === 0){
            // this.searchedAttendees = []
            // this.searchedAttendeesFixedArray = []
            // this.showNoResultImage = false;
            customFields.map(x => console.log(x))


        // -------- Customs fields -------
        for (const title of this.customFieldsTitle) {

            if (customFields[title] && customFields[title].length > 0) {
                this.searchedAttendees = [...this.allAttendeesList].filter(item => {
                    item.principal_title = this.getAttendeePrincipalTitle(item.title);
                    // tslint:disable-next-line: curly
                    for (const cusTitle of this.customFieldsTitle) {
                        if (customFields[cusTitle] && item.customFields) {
                            for (let i = 0; i < item.customFields.length; i++) {
                                // tslint:disable-next-line: curly
                                if (companies.includes(not_specified) &&
                                    posts.includes(not_specified) &&
                                    (item.company === null || item.company === '') &&
                                    (item.principal_title === null ||
                                    item.principal_title === '') ||
                                    customFields[cusTitle].includes(item.customFields[i]
                                        .textValue[this.SEventData.getLanguage()]))
                                    return item;
                            }
                        }
                    }
                }).sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0);
                if (this.searchedAttendees.length === 0) {
                    this.showNoResultImage = true;
                }
                this.searchedAttendeesFixedArray = [...this.searchedAttendees];
                return ;
            }
        }
        }
    }

    removeItem(arrType, item?: any){
        switch (arrType) {
            case 'all':
                this.checkedPosts = []
                this.checkedCompanies = []
                for (let i = 0; i < this.customFieldsTitle.length; i++) {
                    this.checkedCustomFields[this.customFieldsTitle[i]] = [];
                }
                console.log(this.checkedCustomFields)
                break;
            case 'post':
                if(item !== undefined && item !== null){
                    this.checkedPosts =  [...this.checkedPosts].filter(p => p !== item)
                } else {
                    this.checkedPosts =  []
                }
                break;
            case 'company':
                if(item !== undefined && item !== null){
                    this.checkedCompanies =  [...this.checkedCompanies].filter(p => p !== item)
                } else {
                    this.checkedCompanies = [];
                }
                // this.checkedCompanies = []
                break;
            default:
                if (this.customFieldsTitle.includes(arrType)) {
                    const index = this.customFieldsTitle.indexOf(arrType);
                    const elt = this.customFieldsTitle[index];
                    if (this.checkedCustomFields[elt]) {
                        if (item !== undefined && item !== null) {
                            this.checkedCustomFields[elt] = [...this.checkedCustomFields[elt]].filter(p => p !== item);
                        } else {
                            this.checkedCustomFields[elt] =  [];
                        }
                    }
                }

        }
        /* Small operation to determine witch filter function
                        the code will use */
        let customFieldActive = false;
        for (const name of this.customFieldsTitle) {
            if (this.checkedCustomFields[name] && this.checkedCustomFields[name].length > 0) {
                customFieldActive = true;
            }
        }
        if (customFieldActive) {
            this.filterArrayWithCustomFields(this.checkedPosts, this.checkedCompanies, this.checkedCustomFields);
        } else {
        this.filterArray(this.checkedPosts, this.checkedCompanies);
        }
        if(this.searchedAttendees.length === 0) this.showNoResultImage = false;
    }

    getDoubleArraySize(array: [][]): number {
        let i = 0;
        // tslint:disable-next-line: curly
        for (i = 0; i < array.length[0]; i++);
        console.log(i);

        return i;
    }

    verifEmptyArrays(ch: any) {
        return this.checkedCustomFields.includes(ch);
    }
}
