import { Action } from '@ngrx/store';

export enum UserActionsTypes {
    GetUser = "[User Service] Get User"
}

export class GetUser implements Action {
    readonly type = UserActionsTypes.GetUser;

    constructor(public payload: any) { }
}

export type UserActionsUnion = GetUser;