import { Location } from "@angular/common";
import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
	AlertController,
	LoadingController,
	MenuController,
	ModalController,
	NavController,
    NavParams,
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { LightboxImgComponent } from "src/app/components/lightbox-img/lightbox-img.component";
import { TypeTracking } from "src/app/enums/type-tracking";
import { TypeUser } from "src/app/models/type-user";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { DaoEventsService } from "src/app/providers/db/dao-events.service";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import { RegexProvider } from "src/app/providers/regex/regex.service";
import { AnalyticsService, GlobalService } from "src/app/shared/services";
import { StorageService } from "../../providers/storage/storage.service";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";
import { ActiveMenuIconsService } from "src/app/shared/services/active-menu-icons/active-menu-icons.service";

@Component({
	selector: "app-profile-edit",
	templateUrl: "./profile-edit.page.html",
	styleUrls: ["./profile-edit.page.scss"],
    providers: [NavParams]
})
export class ProfileEditPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	public eventId: string = null;
	public userId: string = null;
	public moduleId: string = null;
	public typeUser: number = null;

	public attendee: any;

	public loader: boolean = true;
	public company: string = "";
	public title: string = "";
	public name: string = "";
	public site: string = "";
	public facebook: string = "";
	public instagram: string = "";
	public linkedin: string = "";
	public twitter: string = "";
	// public phone: string = '';
	public description: string = "";
	public photoUrl: string = null;
	public file: string = null;

	public listCustomFields: Array<any> = [];
    uneditedCfAnswers = [];
    cfAnswers = {};
    
	public listCustomFieldsProfil: Array<any> = [];
	public listCustomFieldOptions: Array<any> = [];
	formValidation: UntypedFormGroup;
	fieldOptions;
	// fieldsCustomOptions;
	platformIs: boolean;
	container: any;
	backBtn: boolean = true;

	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;
	pictureLoader: boolean = false;

	// langs enable
	enableLangPt: boolean = false;
	enableLangDe: boolean = false;
	enableLangEs: boolean = false;
	enableLangEn: boolean = false;
	enableLangFr: boolean = false;
	language: string;
	hideMenu: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private dbAttendees: DaoAttendeesService,
		private dbSpeakers: DaoSpeakersService,
		private dbEvent: DaoEventsService,
		private navCtrl: NavController,
		private storage: StorageService,
		public alertController: AlertController,
		public global: GlobalService,
		public loadingCtrl: LoadingController,
        private fb: UntypedFormBuilder,
		private events: Events,
		private menuCtrl: MenuController,
		private modalCtrl: ModalController,
		private translateService: TranslateService,
		private zone: NgZone,
		private regex: RegexProvider,
		private location: Location,
		private SAnalytics: AnalyticsService,
        public SUserData: UserDataService,
        private SEventData: EventDataService,
        private SActiveMenuIcons: ActiveMenuIconsService
	) {
		this.menuCtrl.enable(true);
        const params = this.route.snapshot.params;
		this.eventId = params.eventId;
		this.userId = params.userId;
		this.typeUser = params.type;
		this.moduleId = params.moduleId;

		this.loadColors();
        this.language = this.SEventData.getLanguage();
		
		this.menuBadge = this.global.notificationBadge;
		this.events.subscribe("menuBadge", () => {
			this.menuBadge = this.global.notificationBadge;
		});

		this.formValidation = fb.group({
			name: [null],
			company: [null],
			title: [null],
			email: [null],
			// 'phone': [null],
			site: [null],
			linkedin: [null],
			facebook: [null],
			instagram: [null],
			twitter: [null],
			description: [null]
			// 'language': [null]
		});

		this.platformIs = this.global.isBrowser;
	}

	/**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

	ngOnInit() {
		if (this.global.previousPage == "container") {
			this.backBtn = false;
		} else {
			this.backBtn = true;
		}

        if (this.SUserData.mustEditProfile) {
            this.menuCtrl.enable(false);
        }

		this.startEvent();
		if (this.typeUser == 4) {
			this.getFieldOptionsSpeaker();
			this.loadSpeaker();
		} else if (this.typeUser == 5) {
			this.getFieldOptionsAttendee();
			this.loadAttendee();
            this.getAttendeeCustomFields();
		}

		// Track access to edit profile
		this.SAnalytics.accessToProfile(this.eventId, this.userId, this.typeUser, TypeTracking.EDIT_PROFILE);
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	// load attendee
	allowLoaderFirstAccess: boolean = true;
	loadAttendee() {
		this.subscriptions.push(
			this.dbAttendees.getAttendeeByEvent(this.eventId, this.userId).subscribe((attendee) => {
				let aux = this.getSpeakerPrincipalTitle(attendee.title, attendee.description);
				this.attendee = attendee;
				this.photoUrl = attendee["photoUrl"];
				if (attendee) {
					this.formValidation.patchValue({
						name:
							this.formValidation.controls["name"].value !== null &&
							this.formValidation.controls["name"].value !== attendee["name"]
								? this.formValidation.controls["name"].value
								: attendee["name"],
						company:
							this.formValidation.controls["company"].value !== null &&
							this.formValidation.controls["company"].value !== attendee["company"]
								? this.formValidation.controls["company"].value
								: attendee["company"],
						title:
							this.formValidation.controls["title"].value !== null &&
							this.formValidation.controls["title"].value !== aux.title
								? this.formValidation.controls["title"].value
								: aux.title,

						email:
							this.formValidation.controls["email"].value !== null &&
							this.formValidation.controls["email"].value !== attendee["email"]
								? this.formValidation.controls["email"].value
								: attendee["email"],
						description:
							this.formValidation.controls["description"].value !== null &&
							this.formValidation.controls["description"].value !== aux.description
								? this.formValidation.controls["description"].value
								: aux.description,

						photoUrl: attendee["photoUrl"],
						site:
							this.formValidation.controls["site"].value !== null &&
							this.formValidation.controls["site"].value !== attendee["site"]
								? this.formValidation.controls["site"].value
								: attendee["site"],
						facebook:
							this.formValidation.controls["facebook"].value !== null &&
							this.formValidation.controls["facebook"].value !== attendee["facebook"]
								? this.formValidation.controls["facebook"].value
								: attendee["facebook"],
						instagram:
							this.formValidation.controls["instagram"].value !== null &&
							this.formValidation.controls["instagram"].value !== attendee["instagram"]
								? this.formValidation.controls["instagram"].value
								: attendee["instagram"],
						linkedin:
							this.formValidation.controls["linkedin"].value !== null &&
							this.formValidation.controls["linkedin"].value !== attendee["linkedin"]
								? this.formValidation.controls["linkedin"].value
								: attendee["linkedin"],
						twitter:
							this.formValidation.controls["twitter"].value !== null &&
							this.formValidation.controls["twitter"].value !== attendee["twitter"]
								? this.formValidation.controls["twitter"].value
								: attendee["twitter"]
						// 'phone': attendee['phone'],
						// 'language': attendee['language']
					});
				}

				if (this.allowLoaderFirstAccess) {
					this.allowLoaderFirstAccess = false;
					this.loader = false;
				}
			})
		);
	}

	// getFieldsCustomAttendee() {
	// 	this.dbAttendees.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.fieldsCustomOptions = fields;
	// 		this.getAttendeeCustomFields();
	// 	});
	// }

	getFieldOptionsAttendee() {
		this.dbAttendees.getFieldOptions(this.moduleId, (fields) => {
			this.fieldOptions = fields;
			this.setControlFields(fields);
			this.setUniqueEditFields();
		});
	}

	getFieldOptionsSpeaker() {
		this.dbSpeakers.getFieldOptions(this.moduleId, (fields) => {
			this.fieldOptions = fields;
			this.setControlFields(fields);
		});
	}

	setControlFields(fields) {
		if (fields.name.required == true && this.attendee) {
			this.formValidation.setControl("name", new UntypedFormControl(this.attendee.name, Validators.required));
		}
		if (fields.company.required == true) {
			this.formValidation.setControl("company", new UntypedFormControl(null, Validators.required));
		}
		if (fields.title.required == true) {
			this.formValidation.setControl("title", new UntypedFormControl(null, Validators.required));
		}
		if (fields.email.required == true) {
			this.formValidation.setControl("email", new UntypedFormControl(null, Validators.required));
		}
		// if (fields.phone.required == true) {
		//   this.formValidation.setControl('phone', new FormControl(null, Validators.required));
		// }
		if (fields.site.required == true) {
			this.formValidation.setControl("site", new UntypedFormControl(null, Validators.required));
		}
		if (fields.linkedin.required == true) {
			this.formValidation.setControl("linkedin", new UntypedFormControl(null, Validators.required));
		}
		if (fields.facebook.required == true) {
			this.formValidation.setControl("facebook", new UntypedFormControl(null, Validators.required));
		}
		if (fields.instagram.required == true) {
			this.formValidation.setControl("instagram", new UntypedFormControl(null, Validators.required));
		}
		if (fields.twitter.required == true) {
			this.formValidation.setControl("twitter", new UntypedFormControl(null, Validators.required));
		}
		if (fields.description.required == true) {
			this.formValidation.setControl("description", new UntypedFormControl(null, Validators.required));
		}
	}

	setUniqueEditFields() {
		if (this.fieldOptions) {
			let value = this.formValidation.value;
			if (this.fieldOptions.name.unique_edit == true && value.name !== null) {
				this.formValidation.get("name").disable();
			}
			if (this.fieldOptions.company.unique_edit == true && value.company !== null) {
				this.formValidation.get("company").disable();
			}
			if (this.fieldOptions.title.unique_edit == true && value.title !== null) {
				this.formValidation.get("title").disable();
			}
			if (this.fieldOptions.email.unique_edit == true && value.email !== null) {
				this.formValidation.get("email").disable();
			}
			// if (this.fieldOptions.phone.unique_edit == true && value.phone !== null) {
			//   this.formValidation.get('phone').disable();
			// }
			if (this.fieldOptions.site.unique_edit == true && value.site !== null) {
				this.formValidation.get("site").disable();
			}
			if (this.fieldOptions.linkedin.unique_edit == true && value.linkedin !== null) {
				this.formValidation.get("linkedin").disable();
			}
			if (this.fieldOptions.facebook.unique_edit == true && value.facebook !== null) {
				this.formValidation.get("facebook").disable();
			}
			if (this.fieldOptions.instagram.unique_edit == true && value.instagram !== null) {
				this.formValidation.get("instagram").disable();
			}
			if (this.fieldOptions.twitter.unique_edit == true && value.twitter !== null) {
				this.formValidation.get("twitter").disable();
			}
			if (this.fieldOptions.description.unique_edit == true && value.description !== null) {
				this.formValidation.get("description").disable();
			}
		}
	}
    
	getAttendeeCustomFields() {
        Promise.all([
            this.dbAttendees.getCustomFields(this.eventId, this.moduleId),
            this.dbAttendees.getCFAnswers(this.eventId, this.userId)    
        ]).then((result) => {
            this.listCustomFields = result[0].filter(cf => cf.edicao == 2); // user can edit
            result[1].forEach((e) => { this.cfAnswers[e.id] = e.answer; });
            this.uneditedCfAnswers = result[1];
        }).catch(error => console.error(error));
	}

	// load speaker
	loadSpeaker() {
		this.subscriptions.push(
			this.dbSpeakers.getSpeakerByEvent(this.eventId, this.userId).subscribe((attendee) => {
				this.attendee = attendee;
				this.photoUrl = attendee["photoUrl"];
				let auxSpeaker = this.getSpeakerPrincipalTitle(attendee["title"], attendee["description"]);

				this.formValidation.patchValue({
					name: attendee["name"],
					company: attendee["company"],
					title: auxSpeaker.title,
					email: attendee["email"],
					description: this.regex.removeHtml(auxSpeaker.description),
					photoUrl: attendee["photoUrl"],
					site: attendee["website"],
					facebook: attendee["facebook"],
					instagram: attendee["instagram"],
					linkedin: attendee["linkedin"],
					twitter: attendee["twitter"]
					// 'phone': attendee['phone'],
					// 'language': attendee['language']
				});
				this.loader = false;

				// this.getFieldsCustomSpeaker();
			})
		);
	}

	// getFieldsCustomSpeaker() {
	// 	this.dbSpeakers.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.fieldsCustomOptions = fields;
	// 		this.getCustomFieldsSpeaker();
	// 	});
	// }

	// getCustomFieldsSpeaker() {
	// 	this.dbSpeakers.getCustomFields(this.eventId, this.userId, (customFields) => {
	// 		this.zone.run(() => {
	// 			for (let aux of customFields) {
	// 				let position = this.fieldsCustomOptions[aux.uid].order;
	// 				this.listCustomFields[position] = aux;
	// 			}

	// 			for (let i = 0; i < this.listCustomFields.length; i++) {
	// 				let custom = this.listCustomFields[i];
	// 				if (custom.type == "select") {
	// 					if (custom.value == "" || custom.value == null || custom.value == undefined)
	// 						custom.value = "-1";

	// 					this.dbSpeakers.getCustomFieldOptions(this.moduleId, custom.uid, (listOptions) => {
	// 						this.listCustomFieldOptions[i] = listOptions;

	// 						if (i == this.listCustomFieldOptions.length - 1) {
	// 							this.setFieldOptionsInCustomSpeaker();
	// 						}
	// 					});
	// 				} else {
	// 					this.listCustomFieldOptions[i] = null;

	// 					if (i == this.listCustomFieldOptions.length - 1) {
	// 						this.setFieldOptionsInCustomSpeaker();
	// 					}
	// 				}
	// 			}
	// 		});
	// 	});
	// }

	// setFieldOptionsInCustomAttendee() {
	// 	this.dbAttendees.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		// this.fieldsCustomOptions = fields;
	// 		if (fields) {
	// 			for (let custom of this.listCustomFields) {
	// 				custom.initial_value = custom.value;
	// 				custom.hide_field = this.fieldsCustomOptions[custom.uid].hide_field;
	// 				custom.required = this.fieldsCustomOptions[custom.uid].required;
	// 				custom.unique_edit = this.fieldsCustomOptions[custom.uid].unique_edit;
	// 			}
	// 		}
	// 	});
	// }

	// setFieldOptionsInCustomSpeaker() {
	// 	this.dbSpeakers.getFieldOptionsCustom(this.moduleId, (fields) => {
	// 		this.fieldsCustomOptions = fields;

	// 		for (let custom of this.listCustomFields) {
	// 			custom.required = this.fieldsCustomOptions[custom.uid].required;
	// 			custom.unique_edit = this.fieldsCustomOptions[custom.uid].unique_edit;
	// 			custom.initial_value = custom.value;
	// 		}
	// 	});
	// }

	async selectedImage(type) {
		if (type === "file") {
			document.getElementById("picFromWeb").click();
		} else {
			// const iosConfig = [
			// 	{
			// 		text: this.translateService.instant("pages.editprofile.gallery"),
			// 		handler: () => {
			// 			this.pictureLoader = true;
			// 			this.storage.openGallery((data) => {
			// 				this.file = data;
			// 				this.pictureLoader = false;
			// 			});
			// 		}
			// 	},
			// 	{
			// 		text: this.translateService.instant("pages.editprofile.camera"),
			// 		handler: () => {
			// 			this.pictureLoader = true;
			// 			this.storage.takePicture((data) => {
			// 				this.file = data;
			// 				this.pictureLoader = false;
			// 			});
			// 		}
			// 	}
			// ];

			// const androidConfig = [
			// 	{
			// 		text: this.translateService.instant("pages.editprofile.gallery"),
			// 		handler: () => {
			// 			this.pictureLoader = true;
			// 			this.storage.openGallery((data) => {
			// 				this.file = data;
			// 				this.pictureLoader = false;
			// 			});
			// 		}
			// 	}
			// ];

			// const alert = await this.alertController.create({
			// 	header: this.translateService.instant("pages.editprofile.profile_pic_select"),
			// 	message: this.translateService.instant("pages.editprofile.gallery_or_camera"),
			// 	buttons: this.platform.is("ios") ? iosConfig : androidConfig
			// });

			//await alert.present();
		
            this.pictureLoader = true;
				this.storage.takePicture((data) => {
					this.file = data;
					this.pictureLoader = false;
			});
        }
	}

	selectPictureFromWeb(ev) {
		this.pictureLoader = true;
		const fileWeb: File = ev.target.files[0];

		const reader = new FileReader();
		reader.readAsDataURL(fileWeb);
		reader.onload = () => (this.file = reader.result as string);
		reader.onerror = (error) => console.log(error);
		this.pictureLoader = false;
	}

    /**
     * @description what should be done after the user data is updated (obs.: 
     * in case of error or success)
     * @param data 
     */
    updateUserCallback = async (data) => {
        if (data.code == 200) {
            await this.presentAlertSuccess();
            this.events.publish("languageUpdate", this.SEventData.getLanguage());
            this.menuCtrl.enable(true);
            this.hideMenu = false;
            this.SUserData.mustEditProfile = false;
			if (this.attendee.photoUrl)
				this.SUserData.updateUserPointsEngagement('add_profile_photo');
            this.loader = false;
        } else {
            this.presentAlertError();
            this.loader = false;
        }
    }

    /**
     * check all custom fields answers; returns a boolean value that 
     * corresponds with the occurence of an unanswered required custom field, 
     * i.e., if it is found that a required cf wasnt answered, the method stops
     * and return true, else, iterate through the whole array of custom fields
     * an proced to mark the answered cfs as such, then return false.
     */
    checkCFAnswers(): boolean {
        return !!this.listCustomFields.find((cf) => {
            return (cf.required && !this.cfAnswers[cf.uid]);
        });
    }

	updateUser() {
		this.loader = true;
		const value = this.formValidation.getRawValue();

		if (
			(this.fieldOptions["name"].required == true && !value.name) ||
            (this.fieldOptions["company"].required == true && !value.company) ||
            (this.fieldOptions["title"].required == true && !value.title) ||
            (this.fieldOptions["site"].required == true && !value.site) ||
            (this.fieldOptions["linkedin"].required == true && !value.linkedin) ||
            (this.fieldOptions["facebook"].required == true && !value.facebook) ||
            (this.fieldOptions["instagram"].required == true && !value.instagram) ||
            (this.fieldOptions["twitter"].required == true && !value.twitter) ||
            (this.fieldOptions["description"].required == true && !value.description) ||
            this.checkCFAnswers()
            /** ||
            (this.fieldOptions['phone'].required == true && value.phone)*/
		) {
			this.presentAlertErrorRequiredField();
			this.loader = false;
            return;
		}

        this.attendee.name = value.name;
        this.attendee.company = value.company;
        this.attendee.title[this.language] = value.title ? value.title : "";
        this.attendee.description[this.language] = value.description ? value.description : "";
        this.attendee.website = value.site ? value.site : "";
        this.attendee.linkedin = value.linkedin;
        this.attendee.facebook = value.facebook;
        this.attendee.instagram = value.instagram;
        this.attendee.twitter = value.twitter;
        this.attendee.edited_profile = true;

        if (this.typeUser == TypeUser.ATTENDEE) {
            Promise.all([
                this.dbAttendees.updateAttendee(
                    this.eventId,
                    this.moduleId,
                    this.attendee,
                    this.file
                ),
                this.dbAttendees.updateCustomFields(
                    this.eventId, 
                    this.SUserData.userId,
                    this.uneditedCfAnswers.filter((e) => {
                        if (this.cfAnswers[e.id] != e.answer) {
                            e.answer = this.cfAnswers[e.id];
                            return true;
                        }
                        return false;
                    })
                )
            ]).then(async () => {
                await this.presentAlertSuccess();
                this.events.publish("languageUpdate", this.SEventData.getLanguage());
                this.menuCtrl.enable(true);
                this.hideMenu = false;
                this.SUserData.mustEditProfile = false;
            }).catch(error => console.error(error))
            .finally(() => { this.loader = false; });

        } else if (this.typeUser == 4) {
            this.dbSpeakers.updateSpeaker(
                this.eventId,
                this.moduleId,
                this.attendee,
                this.listCustomFields,
                this.file,
                this.updateUserCallback
            );
        }
	}

	async presentAlertErrorRequiredField() {
		const alert = await this.alertController.create({
			header: this.translateService.instant("pages.editprofile.required_field_title"),
			message: this.translateService.instant("pages.editprofile.required_fields_text"),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.ok")
				}
			]
		});

		await alert.present();
	}

    goToPersonalPage(): void {
        this.SActiveMenuIcons.changeActiveIcon("personalPage");
		this.navCtrl.navigateRoot(`/event/${this.eventId}/personal-page/${this.moduleId}/${this.typeUser}/${this.userId}`);
	}

	async presentAlertSuccess() {
		const alert = await this.alertController.create({
			header: this.translateService.instant("pages.editprofile.all_ok"),
			message: this.translateService.instant("pages.editprofile.all_ok_subtitle"),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.ok"),
					handler: () => {
                        if (this.SUserData.mustEditProfile) {
                            this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
                                this.navCtrl.navigateRoot(eventData.homePage);    
                            }).catch((error) => {
                                console.error(error);

                                const homePage = localStorage.getItem('homePage');
                                if (homePage) {
                                    this.navCtrl.navigateRoot(homePage);
                                } else {
                                    this.goToPersonalPage();
                                }
                            });
                        } else {
                            this.goToPersonalPage();
                        }
					}
				}
			]
		});

		await alert.present();
	}

	async presentAlertError() {
		const alert = await this.alertController.create({
			header: this.translateService.instant("global.alerts.error"),
			message: this.translateService.instant("global.alerts.not_success"),
			buttons: [
				{
					text: this.translateService.instant("global.buttons.ok")
				}
			]
		});

		await alert.present();
	}

	async openLoadingContainer() {
		if (!this.container) {
			this.container = await this.loadingCtrl.create({
				spinner: "crescent"
			});
			this.container.present();
		}
	}

	async closeLoadingContainer() {
		if (this.container) {
			await this.container.dismiss();
			this.container = null;
		}
	}

	// zoom profile picture image
	openImage(url: string) {
		this.modalCtrl
			.create({
				component: LightboxImgComponent,
				componentProps: {
					img: url
				}
			})
			.then((modal) => {
				modal.present();
			});
	}

	startEvent() {
		this.subscriptions.push(
			this.dbEvent.getEvent(this.eventId).subscribe((event) => {
				if (event.description.de_DE !== undefined && event.description.de_DE !== null) {
					this.enableLangDe = true;
				}

				if (event.description.es_ES !== undefined && event.description.es_ES !== null) {
					this.enableLangEs = true;
				}

				if (event.description.en_US !== undefined && event.description.en_US !== null) {
					this.enableLangEn = true;
				}

				if (event.description.fr_FR !== undefined && event.description.fr_FR !== null) {
					this.enableLangFr = true;
				}

				if (event.description.pt_BR !== undefined && event.description.pt_BR !== null) {
					this.enableLangPt = true;
				}
			})
		);
	}

	getSpeakerPrincipalTitle(title, description) {
		let principalTitle = "";
		let principalDescription = "";
		switch (this.SEventData.getLanguage()) {
			case "pt_BR": {
				principalTitle = title.PtBR ? title.PtBR : "";
				principalDescription = description.PtBR ? description.PtBR : "";
				break;
			}
			case "en_US": {
				principalTitle = title.EnUS ? title.EnUS : "";
				principalDescription = description.EnUS ? description.EnUS : "";
				break;
			}
			case "es_ES": {
				principalTitle = title.EsES ? title.EsES : "";
				principalDescription = description.EsES ? description.EsES : "";
				break;
			}
			case "fr_FR": {
				principalTitle = title.FrFR ? title.FrFR : "";
				principalDescription = description.FrFR ? description.FrFR : "";
				break;
			}
			case "de_DE": {
				principalTitle = title.DeDE ? title.DeDE : "";
				principalDescription = description.DeDE ? description.DeDE : "";
				break;
			}
		}

		// case blank, get principal event language title or first language !== blank
		if (principalTitle == "" || principalTitle == null) {
			if (title[this.SEventData.getLanguage()] !== "") {
				principalTitle = title[this.SEventData.getLanguage()];
			} else {
				for (let aux in title) {
					if (title[aux] !== "") {
						principalTitle = title[aux];
					}
				}
			}
		}
		if (principalDescription == "" || principalDescription == null) {
			if (description[this.SEventData.getLanguage()] !== "") {
				principalDescription = description[this.SEventData.getLanguage()];
			} else {
				for (let aux in description) {
					if (description[aux] !== "") {
						principalDescription = description[aux];
					}
				}
			}
		}
		return {
			title: principalTitle,
			description: principalDescription
		};
	}

	ionViewWillLeave() {
		this.menuCtrl.enable(true);
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]');
		if (toasts) {
			for (let i = 0; i < toasts.length; i++) {
				(toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes("_globalMsg") ? "block" : "";
			}
		}
	}

	ionViewWillEnter() {
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]');
		if (toasts) {
			for (let i = 0; i < toasts.length; i++) {
				(toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes("_globalMsg")
					? "none"
					: "auto";
			}
		}
	}

	popPage() {
		this.navCtrl.back();
	}
}
