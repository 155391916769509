import { Injectable } from '@angular/core';
import { 
    Firestore,
    collection, 
    collectionData, 
    doc, 
    docData,
    getDoc
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoGamificationService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    /**
     * @description retrieve all QR Codes present in the Gamification module
     * @param moduleId 
     * @param onResolve 
     */
    getCodes(moduleId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/gamification-qrcodes`);
        return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    checkQr(uid: string, moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}/gamification-qrcodes/${uid}`);
        getDoc(ref)
            .then((snapshot) => {
                if (snapshot.exists()) {
                    onResolve({
                        status: true,
                        qrcode: snapshot.data()
                    });
                } else {
                    onResolve({
                        status: false,
                        qrcode: null
                    });
                }
            })
    }
}
