import { Action } from "@ngrx/store";
import { IVideoPlayer } from "../interfaces/video-player.interfaces";

export enum VideoPlayerActionsTypes {
	GetVideoPlayer = "[VideoPlayer Service] Update video player",
	UpdateSrc = "[VideoPlayer Service] Update src",
	UpdateType = "[VideoPlayer Service] Update type",
	UpdateStart = "[VideoPlayer Service] Update start time",
	UpdateSessionId = "[VideoPlayer Service] Update session id",
	UpdateInSession = "[VideoPlayer Service] Update in session",
	UpdateState = "[VideoPlayer Service] Update state"
}

export class GetVideoPlayer implements Action {
	readonly type = VideoPlayerActionsTypes.GetVideoPlayer;

	constructor(public payload: IVideoPlayer) {}
}
export class UpdateSrc implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateSrc;

	constructor(public payload: string) {}
}
export class UpdateType implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateType;

	constructor(public payload: string) {}
}
export class UpdateStart implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateStart;

	constructor(public payload: number) {}
}
export class UpdateSessionId implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateSessionId;

	constructor(public payload: string) {}
}
export class UpdateInSession implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateInSession;

	constructor(public payload: boolean) {}
}
export class UpdateState implements Action {
	readonly type = VideoPlayerActionsTypes.UpdateState;

	constructor(public payload: number) {}
}

export type VideoPlayerActionsUnion =
	| GetVideoPlayer
	| UpdateSrc
	| UpdateType
	| UpdateStart
	| UpdateSessionId
	| UpdateInSession
	| UpdateState;
