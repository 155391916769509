import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	Input,
	EventEmitter,
	Output,
	ElementRef
} from "@angular/core";
import {
	ActionSheetController,
	PopoverController,
	IonContent,
	Platform,
	LoadingController
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService, WherebyService, UtilityService, AnalyticsService } from "src/app/shared/services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ChatMessage } from "../../models/chat-message";
import { NotificationDateService } from "src/app/providers/date/notification-date.service";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";
import { DaoChatService } from "src/app/providers/db/dao-chat.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "src/app/providers/storage/storage.service";
import { first, tap } from "rxjs/operators";
import { Subscription, Observable } from "rxjs";
import { ChatSettingsPopupComponent } from "src/app/components/chat-settings-popup/chat-settings-popup.component";
import { GroupDiscussionsService } from "src/app/shared/services/group-discussions/group-discussions.service";
import { DateTime } from "luxon";
import { NotificationsService } from "src/app/providers/notifications.service";
import { TypeTracking } from "src/app/enums/type-tracking";
import { DaoModulesService } from "src/app/providers/db/dao-modules.service";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-chat",
	templateUrl: "./chat.page.html",
	styleUrls: ["./chat.page.scss"]
})
export class ChatPage implements OnInit, OnDestroy {
	@ViewChild("msgs") content: IonContent;
	@ViewChild("msgsComponentMode") componentModeContent: ElementRef;

	@Input() componentMode: boolean = false;
	@Output() goBackEvent: EventEmitter<any> = new EventEmitter();
	@Input() backToList: boolean = false;

	subscriptions: Subscription[] = [];

	isMobile: boolean = false;

	@Input() eventId: string = null;
	@Input() chatId: string = null;
	@Input() groupId: string = null;
	eventColors: EventColors = new EventColors();

	groupDiscussion;
	chat;

	userNameTitle: string = null;

	isVisioEnabled: boolean = false;
	btnVideoCallVisibilty: boolean = false;

	messages: Array<ChatMessage> = [];
	messagesLength: number = 0;
	message: any = "";
	loader: boolean = true;
	fileUrl: string = "";

	loaderUploadPic: boolean = false;

	messages$: Observable<any[]>;

	showVisioWebApp: boolean = false;
	urlVisio: string;
	msgLinkedToVisioOpened: ChatMessage;

	constructor(
		public global: GlobalService,
		private route: ActivatedRoute,
		private events: Events,
		private location: Location,
		private luxon: NotificationDateService,
		private dbAttendees: DaoAttendeesService,
		private popoverController: PopoverController,
		private gdService: GroupDiscussionsService,
		private dbChat: DaoChatService,
		private actionSheetController: ActionSheetController,
		private translateService: TranslateService,
		private storage: StorageService,
		private SWhereby: WherebyService,
		private translate: TranslateService,
		private SUtility: UtilityService,
		private platform: Platform,
		private SAnalytics: AnalyticsService,
		public loadingController: LoadingController,
        private dbModules: DaoModulesService,
        private dt: DateTimeService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
		private SNotif: NotificationsService
	) {
		if (!this.componentMode) {
			this.eventId = this.route.snapshot.paramMap.get("eventId");
			this.chatId = this.route.snapshot.paramMap.get("chatId");
			this.groupId = this.route.snapshot.paramMap.get("groupId");
		}
		// localStorage.setItem("eventId", this.eventId);

		this.loadColors();
		// this.events.subscribe("loadColors", () => {
		// 	this.loadColors();
		// });
		this.platform.is("ios") || this.platform.is("android") ? (this.isMobile = true) : (this.isMobile = false);
	}

	/**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

	ngOnInit() {
		this.initChat();
	}

	ngOnChanges(changes) {
		if (changes.groupId) {
			this.initChat();
		}
	}

	initChat() {
		this.messages$ = this.gdService.messages(this.eventId, this.groupId || this.chatId).pipe(
            tap((m) => {
				this.messagesLength = m.length;
				this.scrollToBottom(m.length);
			})
		);
		this.messages$.subscribe((list: ChatMessage[]) => {
			this.messages = list.sort((a,b) => {
				return (a.send_at < b.send_at) ? -1 : (a.send_at > b.send_at) ? 1 : 0
			})
		})

		this.subscriptions.push(
			this.SWhereby.isVisioEnabled(this.eventId, this.groupId).subscribe((isEnabled) => {
				this.isVisioEnabled = isEnabled;
			})
		);
		this.subscriptions.push(
			this.gdService.chat(this.eventId, this.groupId || this.chatId).subscribe((c) => {
				this.chat = c;
			})
		);
		this.subscriptions.push(
			this.dbAttendees.getAttendeeByEvent(this.eventId, this.SUserData.userId).subscribe(async (att) => {
				const { moduleId } = att
				this.dbModules.getModule(moduleId).subscribe((module) => {
					this.btnVideoCallVisibilty = module.showBtnVisio ? module.showBtnVisio : false;
				})
			})
        );
		if (this.groupId) {
			this.subscriptions.push(
				this.gdService.groupDiscussion(this.groupId).subscribe((g) => (this.groupDiscussion = g))
			);
		}

		this.getChatTitle();

		// Analytics track chat or group access
		if (this.chatId) {
			this.SAnalytics.chatAccess(this.eventId, this.chatId);
		} else if (this.groupId) {
			this.SAnalytics.groupAccess(this.eventId, this.groupId);
		}
	}

	ngOnDestroy() {
		this.subscriptions.map((s) => s.unsubscribe());
        // this.updateLastAccess();
	}

	ionViewWillEnter() {
		this.updateLastAccess();
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]')
        if(toasts){
            for(let i = 0; i < toasts.length; i++){
                (toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes('_globalMsg') ? 'none' : ''
            }
        }
		this.gdService.enterChat(this.chatId);
	}

	updateLastAccess() {
		this.gdService.updateLastAccess(this.eventId, this.groupId || this.chatId);
	}

	async presentPopover(ev: any) {
		const popover = await this.popoverController.create({
			component: ChatSettingsPopupComponent,
			componentProps: { params: this.route.snapshot.params },
			event: ev,
			animated: true,
			showBackdrop: true
		});

		return await popover.present();
	}

	// unique color for each user in the chat
	getColorFromUID(uid: string) {
		const r = uid.charCodeAt(0) + uid.charCodeAt(1);
		const g = uid.charCodeAt(2) + uid.charCodeAt(3);
		const b = 0;

		if (this.groupId) {
			return `rgba(${r}, ${g}, ${b}, 0.3)`;
		}

		return uid === this.SUserData.userId ? "rgba(224, 247, 245, 0.3)" : `rgba(234, 250, 224, 0.3)`;
	}

	// get chat title to display
	async getChatTitle() {
		if (this.chatId) {
			this.subscriptions.push(
				this.dbAttendees
					.getAttendeeByEvent(
						this.eventId,
						this.chatId.split("-").find((m) => m !== this.SUserData.userId)
					)
					.subscribe((user: any) => {
						this.userNameTitle = user.name;
					})
			);
		}

		if (this.groupId) {
			try {
				const gd = await this.gdService.groupDiscussion(this.groupId).pipe(first()).toPromise();

				this.userNameTitle = gd.title;
			} catch (e) {
				console.log(e);
			}
		}
	}

	/**
	 * Get an image to send from mobile
	 */
	async getImageToSend() {
		// const actionSheet = await this.actionSheetController.create({
		// 	buttons: [
		// 		{
		// 			text: this.translateService.instant("global.buttons.cancel"),
		// 			role: "destructive",
		// 			icon: "close",
		// 			handler: () => {}
		// 		},
		// 		{
		// 			text: this.translateService.instant("global.buttons.take_picture"),
		// 			icon: "camera",
		// 			handler: () => {
		// 				this.storage.takePicture((data) => {
		// 					this.uploadImage(data);
		// 				});
		// 			}
		// 		},
		// 		{
		// 			text: this.translateService.instant("global.buttons.open_gallery"),
		// 			icon: "photos",
		// 			handler: () => {
		// 				this.storage.openGallery((data) => {
		// 					this.uploadImage(data);
		// 				});
		// 			}
		// 		}
		// 	]
		// });

		// await actionSheet.present();

        this.storage.takePicture((data) => {
            this.uploadImage(data);
        });
	}

	/**
	 * Get an image to send from web
	 * @param ev
	 */
	getImageToSendFromWeb(ev) {
		const fileWeb: File = ev.target.files[0];

		const reader = new FileReader();
		reader.readAsDataURL(fileWeb);
		reader.onload = () => {
			this.uploadImage(reader.result);
		};
		reader.onerror = (error) => console.log(error);
	}

	// upload the image to send in chat message
	uploadImage(file) {
		this.loaderUploadPic = true;
		this.storage.uploadChatImage(this.eventId, file, (url) => {
			this.fileUrl = url;
			this.loaderUploadPic = false;
		});
	}

	scrollToBottom(len: number) {
		if (len > 5) {
			setTimeout(() => {
				// (!this.componentMode) ? this.content.scrollToBottom(500) : this.componentModeContent.nativeElement.scrollTop = this.componentModeContent.nativeElement.scrollHeight;
				!this.componentMode
					? this.content.scrollToBottom(500)
					: document.getElementById("msgsComMode") &&
					  document.getElementById("msgsComMode").scrollIntoView({ behavior: "auto", block: "end" });
			}, 500);
		}
	}

	msg(senderName, senderPhotoUrl) {
		const msg = new ChatMessage();
        
		msg.from_user = this.SUserData.userId;
		msg.message = this.message;
        msg.send_at = this.dt.getTimestamp(this.dt.dbTime());
		msg.message_picture = this.fileUrl;
		msg.send_from_user_name = senderName;
		msg.send_to_user_name = this.userNameTitle;
		msg.send_from_user_photo = senderPhotoUrl;
		msg.eventId = this.eventId;
		msg.message_picture = this.fileUrl;

		return msg;
	}

	// send message in chat to user
	async sendMsg() {
		const copyMessage = this.message;
		const copyFileUrl = this.fileUrl;

		if (!this.message && this.message.trim() === "" && (!this.fileUrl || this.fileUrl === undefined)) return;
		try {
            const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
			const msg = this.msg(userData.name, userData.photoUrl);
			const userIds = this.groupId
				? this.groupDiscussion.activeParticipants.filter(
						(p) => p !== this.SUserData.userId && !(this.groupDiscussion.mutedParticipants || []).includes(p)
				  )
				: this.gdService.getOtherUsersIds(this.chat.members);

			msg.to_user = !this.groupId && userIds[0]
			this.gdService.addMessage(
				this.eventId, 
				this.chatId || this.groupId, 
				msg, 
				!!this.groupId
			).then(() => {
				if (this.componentMode) {
					this.scrollToBottom(this.messagesLength);
				}

				this.SNotif.sendPush(
					[msg.to_user], 
					msg.send_from_user_name, 
					msg.message
				).catch(err => console.error(err));
				
				// Tracking send message on chat or group
				this.SAnalytics.sendMessageOnChatOrDiscussionGroup(
					this.eventId,
					this.chatId ? this.chatId : this.groupId,
					msg,
					this.chatId ? TypeTracking.SEND_MESSAGE_ON_CHAT : TypeTracking.SEND_MESSAGE_ON_GROUP
				);

				// await this.notificationsService.sendNotification(
				// 	this.eventId,
				// 	msg.message,
				// 	msg.send_from_user_name,
				// 	userIds
				// );

				this.events.publish("updateChatBadge");
				this.events.publish("chatHistoryBadge");
			}).catch(err => 
				console.error(err)
			).finally(() => {
				this.message = "";
				this.fileUrl = "";
			});
		} catch (e) {
			this.fileUrl = copyFileUrl;
			this.message = copyMessage;
		}
	}

	ionViewWillLeave() {
		this.updateLastAccess();

		this.events.publish("updateChatBadge");
		this.events.publish("chatHistoryBadge");
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]')
        if(toasts){
            for(let i = 0; i < toasts.length; i++){
                (toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes('_globalMsg') ? 'block' : ''
            }
        }
		this.gdService.leaveChat();
	}

	back() {
		this.location.back();
	}

	async sendMessageVisio(url: string): Promise<void> {
        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        const timezone = (eventData) ? eventData.timezone : localStorage.getItem('timezone');
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);

		const message = {
			message: this.translate.instant("global.texts.visio-launched"),
			url,
			from_user: this.SUserData.userId,
			send_at: this.luxon.getTimeStampFromDateNow(new Date(), timezone),
			send_from_user_name: (userData.name) ? userData.name : "",
			send_from_user_photo: (userData.photoUrl) ? userData.photoUrl : undefined,
			eventId: this.eventId
		} as ChatMessage;

		return this.dbChat.sendMessage(this.eventId, this.chatId || this.groupId, message);
	}
	async presentLoading() {
		const loading = await this.loadingController.create({
			cssClass: "my-custom-class",
			message: "Please wait...",
			duration: 15000
		});
		await loading.present();
	}

	async createVisio() {
		this.presentLoading();
		const userIds = this.groupId
			? this.groupDiscussion.activeParticipants.filter(
					(p) => p !== this.SUserData.userId && !(this.groupDiscussion.mutedParticipants || []).includes(p)
			  )
			: this.gdService.getOtherUsersIds(this.chat.members);
		const startDate = DateTime.local().toISO();
		const endDate = DateTime.local().plus({ days: 1 }).toISO();
		const meetingData = {
			roomNamePrefix: `/${this.groupId || this.eventId}`,
			roomMode: this.groupId ? "group" : "normal",
			startDate,
			endDate
		};
		const response = await this.SWhereby.createMeetingOnWhereby(meetingData);
		const membersList = await this.gdService.getChatMembers(this.eventId, this.chatId || this.groupId);
		const url = response.body.roomUrl;
		const members = membersList.map((id) => {
			return {
				[id]: true
			};
		});
		const visio = {
			uid: this.chatId || this.groupId,
			startDate,
			endDate,
			url,
			members
		};

		await this.SWhereby.setVisio(this.eventId, visio);

		await this.sendMessageVisio(url);

		// Send push notif for visio message
		// await this.notificationsService.sendNotification(
		// 	this.eventId,
		// 	this.translate.instant("global.texts.visio-launched"),
		// 	this.global.displayName,
		// 	userIds
		// );

		// Tracking send visio message on chat or group
		this.SAnalytics.sendVisioMessageOnChatOrDiscussionGroup(
			this.eventId,
			this.chatId ? this.chatId : this.groupId,
			this.chatId ? TypeTracking.SEND_MESSAGE_ON_CHAT : TypeTracking.SEND_MESSAGE_ON_GROUP
		);
		this.SWhereby.analyticsNewRoom(this.eventId, Object.keys(members));

		this.SUtility.presentToast(
			this.translateService.instant("global.toasts.visios.created"),
			2500,
			"bottom",
			"primary"
		);
		this.loadingController.dismiss();
	}

	async onOpenVisio() {
        const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
		if (!this.chat || userData) {
			return;
		}
		try {
			if (!this.isVisioEnabled) {
				this.SUtility.presentToast(
					this.translateService.instant("global.toasts.visios.not-available"),
					2500,
					"bottom",
					"danger"
				);

				return;
			}

			const url = await this.SWhereby.getVisio(this.eventId, this.chatId || this.groupId);
			if (!url) {
				await this.createVisio();
			}
            
			this.SWhereby.analyticsNewAccessToRoom(this.eventId, userData, null);

			const message = await this.msg(userData.name, userData.photoUrl);

			message.url = url;

			this.msgLinkedToVisioOpened = message;

			if (this.SWhereby.visioAvailableOnMobile()) {
				const fullUrl =
					url +
					"?embed&iframeSource=b3app&chat=on&screenshare=on&skipMediaPermissionPrompt&leaveButton=" +
					(this.SWhereby.isIOS() ? "on" : "off");

				// if (this.SWhereby.visioAvailableOnSF() && this.SWhereby.isAndroid()) {
				// 	this.subscriptions.push(this.SWhereby.openSFVisio(fullUrl).subscribe());
				// } else {
				// 	this.subscriptions.push(this.SWhereby.openInAppBrowserVisio(fullUrl).subscribe());
				// } todo: whereby
			} else {
				const fullUrl = url + "?embed&iframeSource=b3app";

				this.urlVisio = fullUrl;
				this.showVisioWebApp = true;
			}
		} catch (e) {
			this.SUtility.presentToast(
				this.translateService.instant("global.toasts.visios.error"),
				2500,
				"bottom",
				"danger"
			);
		}
	}

	async onCloseVisio() {
		this.showVisioWebApp = false;

		try {
			if (this.msgLinkedToVisioOpened) {
				this.msgLinkedToVisioOpened.url = "leaved";
				this.msgLinkedToVisioOpened.message = this.translateService.instant("global.texts.visio-leaved");
				await this.gdService.addMessage(
					this.eventId,
					this.chatId || this.groupId,
					this.msgLinkedToVisioOpened,
					!!this.groupId
				);
				this.msgLinkedToVisioOpened = null;
			}
		} catch (error) {
			this.msgLinkedToVisioOpened = null;
		}
	}

	/**
	 * Go back to list surveys on component mode
	 */
	goBackToList() {
		this.goBackEvent.emit(true);
	}

	ionViewDidEnter() {
		// if(this.componentMode){
		//     const element = document.getElementsByClassName('msg-wrap')[0];
		//     element.scrollTop = element.scrollHeight;
		// }
		document.querySelectorAll('ion-toast').forEach(item => {
			(item.id.split('_')[1] == this.chatId) && item.remove()
		})
	}

	unselectImage() {
		this.fileUrl = null;
	}
}
