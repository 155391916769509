import { Question } from './quiz-question';

export class Quiz {
    title: string;
    imgCapa: string;
    type: string;
    active_timer: boolean;
    timer_questions: number;
    change_answer: boolean;
    visibility: boolean = true;
    uid: string;
    questions: Array<Question> = [];
    max_responses: number;
    module_id: string;
    references: string;
    view_previous_answers: boolean;
    iconFamily: string;
    icon: string;
    view_answered: boolean;
    totalQuestions: number;
    order: number;
    questionsNumberVisibility: boolean;
    includeImgToAnswers: boolean;
}
