import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { DaoSpeakersService } from "src/app/providers/db/dao-speakers.service";
import { IonInfiniteScroll, IonVirtualScroll, MenuController, ModalController, Platform } from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { GlobalService, UtilityService, AnalyticsService } from "src/app/shared/services";
import { TypeVisionModule } from "../../models/type-vision-module";
import { DaoGroupsService } from "src/app/providers/db/dao-groups.service";
import { Subscription } from "rxjs";
import { TypeTracking } from "src/app/enums/type-tracking";
import { TranslateService } from "@ngx-translate/core";
import { PathComponent } from "src/app/models/path/path-components";
import { EventColors } from "src/app/models/event-colors";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
	selector: "app-speakers",
	templateUrl: "./speakers.page.html",
	styleUrls: ["./speakers.page.scss"]
})
export class SpeakersPage implements OnInit, OnDestroy {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild(IonVirtualScroll) virtualScroll: IonVirtualScroll;
    subscriptions: Subscription[] = [];

    public module = null

    searchOpen: boolean = false;
    lastSpeakers;
    speakers: Array<any> = [];
    searchedSpeakers: Array<any> = [];
    searchedSpeakersFixedArray: Array<any> = [];
    eventId: string = null;
    moduleId: string = null;
    loader: boolean = true;
    typeOrder: string;
    typeUser: string;
    backBtn: boolean = true;
    allowButtonsHeader: boolean = false;
    eventColors: EventColors = new EventColors();
    allSpeakersList: Array<any> = [];
    menuBadge: number = 0;

    // module views
    typeVisionModule = null//type of module views.
    GLOBAL_VISION = TypeVisionModule.GLOBAL_VISION // visão global 
    DIVIDED_BY_GROUPS = TypeVisionModule.DIVIDED_BY_GROUPS // visão dividida por grupo
    GROUP_VISION = TypeVisionModule.GROUP_VISION  // visão por grupos 
    GROUP_ACCESS_PERMISSION = TypeVisionModule.GROUP_ACCESS_PERMISSION //acessp limitado por grupo 

    viewGroup: boolean = false; //html of the event groups
    groups = [] //groups of the event.
    userGroups: any = [] //user groups

    language: string;
    showNoResultImage = false; // show image when seaching speakers

    listCompany = []; // all companies in attendees list 
    listPost = []; // all posts in attendees list
    filterBloc = true; // to show or hide filter bloc
    allowFilterCompany = null; // to show or hide filter bloc
    allowFilterPost = null; // to show or hide filter bloc
    showLetters: boolean = true; // to show or hide letters
    emailVisibilty: boolean = false;
    checkedCompanies = []
    checkedPosts = [] 
    isMobile: boolean = false;

    companyFilterText: string
    postFilterText: string
    constructor(
        private dbSpeakers: DaoSpeakersService,
        private route: ActivatedRoute,
        private router: Router,
        public toastController: ToastController,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private dbGroups: DaoGroupsService,
        private translateService: TranslateService,
        private modalCtrl: ModalController,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        this.language = this.SEventData.getLanguage();

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        // Analytics tracking
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_SPEAKERS_MODULE);

        this.loadColors();

        this.menuBadge = this.global.notificationBadge;

        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);;
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        // get the value of the backBtn button.
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // get the value the allowButtonsHeader
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }

        this.startAll();
    }

    ngOnDestroy() {
        this.viewGroup = false;
        // close getModule
        this.dbSpeakers.closeRefGetModule();
        this.dbGroups.closeRefContSpeakersOfGroup();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    headerHeightFn(item, index) {
        return (35);
    }

    itemHeightFn(item, index) {
        return (100);
    }

    startAll() {

        // get the module information
        this.dbSpeakers.getModule(this.moduleId, async (module) => {
            this.module = module;
            this.allowFilterCompany = this.module.allowFilterCompany !== undefined ? this.module.allowFilterCompany : false
            this.allowFilterPost = this.module.allowFilterPost!== undefined ? this.module.allowFilterPost : false
            this.filterBloc = (this.module.allowFilterCompany === true || this.module.allowFilterPost === true) ? true : false
            this.showLetters = this.module.showLetters === true ? this.module.showLetters : false
            this.emailVisibilty = module.emailVisibilty !== undefined ? module.emailVisibilty : false;
            this.companyFilterText = module.companyFilterText ? module.companyFilterText : this.translateService.instant('pages.attendees.company');
            this.postFilterText = module.postFilterText ? module.postFilterText : this.translateService.instant('pages.attendees.post');

            this.typeOrder = module.orderUsers;
            this.typeVisionModule = module.typeVision;

            // verifies the type of vision of the module. global vision
            if (this.typeVisionModule === this.GLOBAL_VISION) {
                this.loader = true;
                this.viewGroup = false;

                // load speakers
                this.getFirstSpeakersGlobalVision();
                this.getSpeakersAllGlobalVision();
            }

            //divided by groups
            if (this.typeVisionModule === this.DIVIDED_BY_GROUPS) {
                this.loader = true
                this.viewGroup = true

                // load groups
                this.getGroupsEvent()
            }

            // group vision
            if (this.typeVisionModule === this.GROUP_VISION) {
                this.loader = true;
                this.viewGroup = false;

                // get user groups
                // this.userGroups = [];
                const userData = await this.SUserData.getUserDataSnapshot(this.eventId);
                if (userData) { this.userGroups = userData.groups; }
                this.getSpeakersGroupVision();
                // if (typeof this.global.groupsAttendees !== 'undefined') {
                //     this.userGroups = this.global.groupsAttendees
                //     // load speakers
                //     this.getSpeakersGroupVision()
                // }
                // // if the groups have not been loaded, call the loadGroups function of the global service.
                // else {
                //     const userId = this.SUserData.userId;
                //     const userType = userData.type;
                //     // get the type groupsAttendee of the logged in user.
                //     this.userGroups = []
                //     if (typeof this.global.groupsAttendees !== 'undefined') {
                //         this.userGroups = this.global.groupsAttendees
                //     } else {
                //         this.userGroups = await this.global.loadGroups(userId, userType, this.eventId)
                //     }

                //     // load speakers
                //     this.getSpeakersGroupVision()
                // }
            }

            if (this.typeVisionModule === this.GROUP_ACCESS_PERMISSION) {
                this.loader = true
                this.viewGroup = false

                // load speakers
                this.getFirstSpeakersLimitedAccessByGroups()
                this.getSpeakersAllLimitedAccessByGroups()
            }

        })
    }

    speakerHeader() {
        const thisReference = this;
        return function(speaker, i, speakers) {
            let showLetters = thisReference.showLetters;
            if(!showLetters){
                return null;
            }
            if (i == 0) {
                return speaker.letter;
            } else if (i != 0 && speaker.letter != speakers[i - 1].letter) {
                return speaker.letter;
            }
        }
        
    }



    async presentToast(time) {
        const toast = await this.toastController.create({
            message: time,
            duration: 3000
        });
        toast.present();
    }

    // loads more speakers when the infinite scroll is triggered
    moreSpeakers(event) {
        // verifies the type of vision of the module.
        if (this.typeVisionModule === this.GLOBAL_VISION) {
            this.moreSpeakersGlobalVision(event)
        }
        else if (this.typeVisionModule === this.GROUP_ACCESS_PERMISSION) {
            this.moreSpeakersLimitedAccessByGroups(event)
        }
        else {
            event.target.disabled = true;
        }
    }

    profileDetails(speaker) {
        let navigationExtras: NavigationExtras = {
            state: {
                user: speaker,
                emailVisibilty: this.emailVisibilty
            }
        };
        this.router.navigate([`/event/${this.eventId}/profile/${this.moduleId}/4/${speaker.uid}`], navigationExtras);
        this.global.previousPage = null;
    }

    searchBar(ev) {
        if (ev.target.value.length >= 1) {
            let value = ev.target.value.toLowerCase();
            // this.searchedAttendees = [];

            const filterdArr = (this.searchedSpeakersFixedArray.length === 0) ? this.allSpeakersList : this.searchedSpeakersFixedArray
            this.searchedSpeakers = [...filterdArr].filter(item => {
                item.principal_title = this.getSpeakerPost(item.title);
                if (item.name.toLowerCase().includes(value) || (item.principal_title !== null && item.principal_title.toLowerCase().includes(value)) || (item.company !== null && item.company.toLowerCase().includes(value))) {
                    return item
                }
            })
            if(this.searchedSpeakers.length === 0) this.showNoResultImage = true;
        } else {
            this.searchedSpeakers = this.searchedSpeakersFixedArray.length === 0 ? [] : [...this.searchedSpeakersFixedArray];
            this.showNoResultImage = false;
        }
    }

    resitFilter(){
        this.searchedSpeakers = this.searchedSpeakersFixedArray.length === 0 ? [] : [...this.searchedSpeakersFixedArray];
        this.showNoResultImage = false;
    }

    // load the event groups.
    getGroupsEvent() {
        this.dbGroups.getGroups(this.eventId, (groups) => {
            this.groups = groups

            // Sort the groups alphabetically.
            groups.sort(function (a, b) {
                return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
            });

            // checks the number of speakers in the module that belongs to each group.
            for (const group of groups) {
                const groupId = group.uid
                const moduleSpeakerId = this.moduleId

                group.qtdSessions = 0

                // count the number of speakers the group has in the module.
                this.dbGroups.contSpeakersOfGroup(groupId, moduleSpeakerId, (qtd: number) => {
                    group.qtdSessions = qtd
                })
            }

            this.loader = false
        })
    }

    /******************************************************************************** methods of global vision *************************************************************************************** */
    // loads the first 100 speakers of the module. global vision
    getFirstSpeakersGlobalVision() {
        this.subscriptions.push(this.dbSpeakers.getFirstASpeakersGlobalVision(this.typeOrder, this.moduleId).subscribe((data) => {
            let list = data;
            this.speakers = [];
            // this.speakers, this.allSpeakersList = [];

            let companies = [], posts = [];

            //get the last participant
            if (list.length > 0) {
                this.lastSpeakers = data[data.length - 1];
            }

            list.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                element.principal_title = this.getSpeakerPost(element.title);
                this.speakers.push(element);
                (element.company !== null && element.company !== '') && companies.push(element.company);
                (element.principal_title !== '') && posts.push(element.principal_title);
            });

            this.speakers = list;
            // this.listCompany = Array.from(new Set([...this.listCompany])) // remove duplicated items
            // this.listPost = Array.from(new Set([...this.listPost]))
            this.speakers.some(x => x.company === null || x.company === '') && this.listCompany.push(this.translateService.instant('pages.attendees.not_specified'))
            this.speakers.some(x => x.principal_title === '') && this.listPost.push(this.translateService.instant('pages.attendees.not_specified'))
            companies = [...companies].sort((a,b) => 
                a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0
            );
            posts = [...posts].sort((a,b) => 
                a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0
            );
            this.speakers.some(x => x.company === null || x.company === '') && this.listCompany.push(this.translateService.instant('pages.attendees.not_specified'))
            this.speakers.some(x => x.principal_title === '') && this.listPost.push(this.translateService.instant('pages.attendees.not_specified'))
            this.listCompany = Array.from(new Set([...companies])); // remove duplicated items
            this.listPost = Array.from(new Set([...posts]));
            this.loader = false;
        }))
    }

    // get all speakers of the module
    getSpeakersAllGlobalVision() {
        this.subscriptions.push(this.dbSpeakers.getSpeakersAllGlobalVision(this.moduleId).subscribe((speakers) => {
            this.allSpeakersList = speakers;
        }))
    }

    // loads more speakers when the infinite scroll is triggered (global vision)
    moreSpeakersGlobalVision(event) {
        if (this.lastSpeakers) {
            this.dbSpeakers.getNextPageSpeakersGlobalVision(this.moduleId, this.lastSpeakers, this.typeOrder, (data) => {
                this.lastSpeakers = null

                if (data.length > 0) {
                    this.lastSpeakers = data[data.length - 1];
                }

                data.forEach(element => {
                    var letters = element.name.split('');
                    var letter = letters[0].toUpperCase();
                    element.letter = letter;
                    this.speakers.push(element);
                });

                if (this.lastSpeakers) {
                    this.virtualScroll.checkEnd();
                    event.target.complete();
                } else {
                    event.target.disabled = true;
                }
            })

        } else {
            event.target.disabled = true;
        }
    }

    /******************************************************************************** methods of divided by groups *************************************************************************************** */

    // loads the speakers of the group.
    getSpeakersGroupDividByGroup(group) {
        const groupId = group.uid

        this.loader = true
        this.viewGroup = false

        // loads the group speakers.
        this.dbSpeakers.getSpeakersGroupGlobalVision(groupId, this.typeOrder, this.moduleId, (data) => {
            this.loader = false
            this.lastSpeakers = null

            if (data.length > 0) {
                this.lastSpeakers = data[data.length - 1]
            }

            data.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                this.speakers.push(element);
            });

            this.speakers = data
            this.allSpeakersList = data
        })
    }

    /******************************************************************************** methods of group vision *************************************************************************************** */
    // loads the speakers group speakers.
    getSpeakersGroupVision() {
        this.dbSpeakers.getSpeakersGroupsVision(this.userGroups, this.typeOrder, this.moduleId, (data) => {
            this.loader = false;
            this.lastSpeakers = null;

            if (data.length > 0) {
                this.lastSpeakers = data[data.length - 1];
            }

            data.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                this.speakers.push(element);
            });

            this.speakers = data;
            this.allSpeakersList = data;
        })
    }


    /******************************************************************************** methods of limited access by groups *************************************************************************************** */
    // loads the first 100 speakers of the module.
    getFirstSpeakersLimitedAccessByGroups() {
        this.dbSpeakers.getFirstASpeakersLimitedAccessByGroups(this.typeOrder, this.moduleId, (data) => {
            let list = data

            // get the last participant
            if (list.length > 0) {
                this.lastSpeakers = data[data.length - 1];
            }

            list.forEach(element => {
                var letters = element.name.split('');
                var letter = letters[0].toUpperCase();
                element.letter = letter;
                this.speakers.push(element);
            });

            this.speakers = list;
            this.loader = false;
        })
    }

    // get all speakers of the module
    getSpeakersAllLimitedAccessByGroups() {
        this.subscriptions.push(this.dbSpeakers.getSpeakersAllLimitedAccessByGroup(this.moduleId).subscribe((data) => {
            this.allSpeakersList = data;
        }))
    }

    // loads more speakers when the infinite scroll is triggered 
    moreSpeakersLimitedAccessByGroups(event) {
        if (this.lastSpeakers) {
            this.dbSpeakers.getNextPageSpeakersLimitedAccessByGroup(this.moduleId, this.lastSpeakers, this.typeOrder, (data) => {
                this.lastSpeakers = null;

                if (data.length > 0) {
                    this.lastSpeakers = data[data.length - 1];
                }

                data.forEach(element => {
                    var letters = element.name.split('');
                    var letter = letters[0].toUpperCase();
                    element.letter = letter;
                    this.speakers.push(element);
                });

                if (this.lastSpeakers) {
                    this.virtualScroll.checkEnd();
                    event.target.complete();
                } else {
                    event.target.disabled = true;
                }
            })
        } else {
            event.target.disabled = true;
        }
    }

    getSpeakerPost(title) {
        return title[this.language];
    }

    async showFilterBloc(){
        if(this.isMobile) {
            const list = []
            if(this.allowFilterCompany){
                list.push({key:'company', value: this.listCompany})
            }
            if(this.allowFilterPost){
                list.push({key:'post', value: this.listPost})
            }
            const modal = await this.modalCtrl.create({
                component: PathComponent.filter_by,
                componentProps: {
                    mode: 'mobile' ,
                    comp: 'attendees',
                    choices: list,
                    selectedValues: [{key:'company', value: this.checkedCompanies},{key:'post', value: this.checkedPosts}],
                    colors: {
                        'link_color': this.eventColors.link_color,
                        'title_color': this.eventColors.title_color,
                        'btn_text_color':this.eventColors.btn_text_color
                    },
                    companyFilterText:  this.companyFilterText,
                    postFilterText:  this.postFilterText
                },
                cssClass: 'modal-order-by'
            });

            modal.onDidDismiss().then(ret => {
                this.checkedCompanies = ret.data.selectedValues.filter(x => x.key === 'company')[0].value
                this.checkedPosts = ret.data.selectedValues.filter(x => x.key === 'post')[0].value
                this.filterArray(this.checkedPosts,this.checkedCompanies)
                
                // this.changeOrder()
            })
            return await modal.present();
        } else {
            this.filterBloc = !this.filterBloc
        }
    }


    checkedChanged(ev, type: string){
        console.log(ev.target.value, ev.target.checked)
        const value = ev.target.value
        const isChecked = ev.target.checked
        if(type === 'company'){
            this.checkedCompanies = isChecked ? [...this.checkedCompanies, value] : [...this.checkedCompanies].filter(x => x !== value)
            this.checkedCompanies = Array.from(new Set(this.checkedCompanies))
        }
        else {
            this.checkedPosts = isChecked ? [...this.checkedPosts, value] : [...this.checkedPosts].filter(x => x !== value)
            this.checkedPosts = Array.from(new Set(this.checkedPosts))
        }
        this.filterArray(this.checkedPosts,this.checkedCompanies)
    }

    filterArray(posts: Array<any>, companies: Array<any>){
        const not_specified  = this.translateService.instant('pages.attendees.not_specified')
        if(posts.length === 0 && companies.length > 0){
            this.searchedSpeakers = [...this.allSpeakersList].filter(item => {
                item.principal_title = this.getSpeakerPost(item.title);
                return ((item.company === null || item.company === '') && companies.includes(not_specified) || companies.includes(item.company)) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            this.searchedSpeakersFixedArray = [...this.searchedSpeakers]
            return ;
        } 
        if(posts.length > 0 && companies.length === 0){
            this.searchedSpeakers = [...this.allSpeakersList].filter(item => {
                item.principal_title = this.getSpeakerPost(item.title);
                return ((item.principal_title === null || item.principal_title === '') && posts.includes(not_specified) || posts.includes(item.principal_title)) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            this.searchedSpeakersFixedArray = [...this.searchedSpeakers]
            return ;
        } 
        if(posts.length > 0 && companies.length > 0){
            this.searchedSpeakers = [...this.allSpeakersList].filter(item => {
                item.principal_title = this.getSpeakerPost(item.title);
                return (companies.includes(not_specified) && posts.includes(not_specified) && (item.company === null || item.company === '') && (item.principal_title === null || item.principal_title === '') || posts.includes(item.principal_title) && companies.includes(item.company)) && item
            }).sort((a,b)=> 
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
            )
            if(this.searchedSpeakers.length === 0) this.showNoResultImage = true;
            this.searchedSpeakersFixedArray = [...this.searchedSpeakers]
            return ;
        }
        if(posts.length === 0 && companies.length === 0){
            this.searchedSpeakers = []
            this.searchedSpeakersFixedArray = []
            this.showNoResultImage = false;
        }
    }
    removeItem(arrType, item?: any){
        switch (arrType) {
            case 'all':
                this.checkedPosts = []
                this.checkedCompanies = []
                break;
            case 'post':
                if(item !== undefined && item !== null){
                    this.checkedPosts =  [...this.checkedPosts].filter(p => p !== item)
                } else {
                    this.checkedPosts =  []
                }
                break;
            case 'company':
                if(item !== undefined && item !== null){
                    this.checkedCompanies =  [...this.checkedCompanies].filter(p => p !== item)
                } else {
                    this.checkedCompanies =  []
                }
                // this.checkedCompanies = []
                break;
        }
        this.filterArray(this.checkedPosts,this.checkedCompanies)
        if(this.searchedSpeakers.length === 0) this.showNoResultImage = false;
    }
}
