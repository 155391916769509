import { Injectable } from '@angular/core';
import { Firestore, doc, docData } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { Container } from 'src/app/models/container';

@Injectable({
    providedIn: 'root'
})
export class AppContainerService {
    
    constructor(
        private firestore: Firestore
    ) {}
    
    /**
     * 
     * @param containerId 
     * @returns a promise with container data
     */
    public getContainerData(containerId?: string) {
        containerId ??= environment.platform.containerId;
        const ref = doc(this.firestore, `containers/${containerId}`);
        return firstValueFrom(docData(ref));
    }
}