import { Component, OnInit } from '@angular/core';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoInfoboothService } from 'src/app/providers/db/dao-infobooth.service';
import { LightboxImgComponent } from 'src/app/components/lightbox-img/lightbox-img.component';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-page-booth',
    templateUrl: './page-booth.page.html',
    styleUrls: ['./page-booth.page.scss'],
})

export class PageBoothPage implements OnInit {
    public module = null

    backBtn: boolean = true;
    isMobile: boolean = false;
    eventId: string = null;
    moduleId: string = null;
    pageId: string = null

    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    allowButtonsHeader: boolean = false;
    title: any
    htmlContent: any
    loader: boolean = true;
    user: any;
    eventLanguage: string;
    htmlBlocContent: any;
    cssContent: any;
    page: any;
    subscriptions: Subscription[] = [];
    language: string;

    constructor(
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private route: ActivatedRoute,
        private dbInfobooth: DaoInfoboothService,
        private router: Router,
        private modalCtrl: ModalController,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.route.params.subscribe((params) => {
            this.eventId = params.eventId;
            this.moduleId = params.moduleId;
            this.pageId = params.pageId;
            // localStorage.setItem('eventId', this.eventId);

            // Analytics tracking
            this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
            this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_INFOBOOTH_MODULE);

            // enable menu
            this.menuCtrl.enable(true);

            // loads the colors of the event
            this.loadColors();
            // this.events.subscribe('loadColors', () => {
            //     this.loadColors();
            // });

            // pick up notification module information
            this.menuBadge = this.global.notificationBadge;
            this.events.subscribe('menuBadge', () => {
                this.menuBadge = this.global.notificationBadge;
            });
        })
        this.language = SEventData.getLanguage();
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        this.eventLanguage = this.SEventData.getLanguage();
        
        this.title = {
            PtBR: '',
            EnUS: '',
            EsES: '',
            FrFR: '',
            DeDE: ''
        }

        this.htmlContent = {
            PtBR: '',
            EnUS: '',
            EsES: '',
            FrFR: '',
            DeDE: ''
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }

        this.loadModule();
        // loads the data from the infobooth page
        this.loadPage();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    loadModule() {
        this.dbInfobooth.getModule(this.moduleId, (module) => {
            this.module = module
        });
    }

    loadPage() {
        this.dbInfobooth.getPage(this.moduleId, this.pageId, (page) => {
            if (page !== undefined) {
                this.page = page;
                console.log(this.page)
                this.title = page.title;
                this.htmlContent = page.htmlContent;
                this.htmlBlocContent = page.pageHTMLContent;

                // const width = JSON.parse(page.pageContent).body.values.contentWidth
                // const re = new RegExp(width, 'g')
                // this.htmlBlocContent = this.htmlBlocContent.replace(re, '100%')
            }
            this.loader = false;
        })
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    // zoom profile picture image
    openImage(url: string) {
        // this.photoViewer.show(url, '', { share: false });
        this.modalCtrl.create({
            component: LightboxImgComponent,
            componentProps: {
                img: url
            }
        }).then(modal => {
            modal.present();
        });

    }

}
