import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { DaoEventsService } from 'src/app/providers/db/dao-events.service';
import { GlobalService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-terms-n-privacy',
    templateUrl: './terms-n-privacy.component.html',
    styleUrls: ['./terms-n-privacy.component.scss']
})
export class TermsNPrivacyComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    languages;
    term;
    privacy;
    cookiePolicy ;
    termsOfUseLabel ;
    privacyLabel ;
    cookiePolicyLabel ;
    termsOfUseVisibility ;
    privacyVisibility ;
    cookiePolicyVisibility ;

    eventId: string;
    showAll: boolean = true;
    showTerms: boolean = false;
    showPrivacy: boolean = false;
    showCookie: boolean = false;

    eventColors: EventColors = new EventColors();
    type: string = null;
    
    constructor(
        private navParams: NavParams,
        private modalCtrl: ModalController,
        private dbEvents: DaoEventsService,
        public global: GlobalService,
        private translateService: TranslateService,
        private SEventData: EventDataService
    ) {
        this.eventId = this.navParams.get('eventId');
        if (this.navParams.get('type')) {
            this.type = this.navParams.get('type');
            if (this.type == 'termsOfUse') {
                this.showAll = false;
                this.showPrivacy = false;
                this.showCookie = false;
                this.showTerms = true;
                
            } else if(this.type == 'privacy') {
                this.showAll = false;
                this.showTerms = false;
                this.showCookie = false;
                this.showPrivacy = true;
            } else {
                this.showAll = false;
                this.showTerms = false;
                this.showPrivacy = false;
                this.showCookie = true;
            }
        }
        this.loadColors();
    }


    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        this.subscriptions.push(this.dbEvents.getEvent(this.eventId).subscribe((event) => {
            this.term = event['terms_of_use'];
            this.privacy = event['privacy'];
            this.languages = event['languages'];
            this.cookiePolicy = event['cookiePolicy'];

            this.termsOfUseLabel = (event['termsOfUseLabel'] && event['termsOfUseLabel'][this.SEventData.getLanguage(true)]) ? 
                event['termsOfUseLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.terms_of_use');
            
            this.privacyLabel = (event['privacyLabel'] && event['privacyLabel'][this.SEventData.getLanguage(true)]) ? 
                event['privacyLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.privacy');

            this.cookiePolicyLabel = (event['cookiePolicyLabel'] && event['cookiePolicyLabel'][this.SEventData.getLanguage(true)]) ? 
                event['cookiePolicyLabel'][this.SEventData.getLanguage(true)] : 
                this.translateService.instant('global.texts.cookie');
            
            this.termsOfUseVisibility = (event['termsOfUseVisibility'] !== undefined) ? event['termsOfUseVisibility'] : true;
            this.privacyVisibility = (event['privacyVisibility'] !== undefined) ? event['privacyVisibility'] : true;
            this.cookiePolicyVisibility = (event['cookiePolicyVisibility'] !== undefined) ? event['cookiePolicyVisibility'] : true;
        }));
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    close() {
        this.modalCtrl.dismiss();
        this.type = null;
        this.eventId = null;
    }

    getLabel(type: string) {
        let selectedLabel ;
        switch (type) {
            case 'terms-of-use':
                selectedLabel = this.termsOfUseLabel
                break;
            case 'privacy':
                selectedLabel = this.privacyLabel
                break;
            case 'cookie':
                selectedLabel = this.cookiePolicyLabel
                break;
        }
        return selectedLabel ? selectedLabel[this.SEventData.getLanguage(true)] : ''
    }

    showRightBlock(type: string){
        this.showAll = false;
        this.showTerms = false;
        this.showPrivacy = false;
        this.showCookie = false;
        switch (type) {
            case 'terms-of-use':
                this.showTerms = true;
                break;
            case 'privacy':
                this.showPrivacy = true;
                break;
            case 'cookie':
                this.showCookie = true;
                break;
        }
    }

    goBack(){
        this.showAll = true;
        this.showTerms = false;
        this.showPrivacy = false;
        this.showCookie = false;
    }
}
