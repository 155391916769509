import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { PathComponent } from './models/path/path-components';
import { HomeComponent } from './home/home.component';
import { EventDataService } from './shared/services/eventData/event-data.service';
import { UserDataService } from './shared/services/user-data/user-data.service';
import { getAuth, onAuthStateChanged } from '@angular/fire/auth';

/**
 * @returns a boolean value that corresponds to the condition of which a user
 * is logged in the application
 */
const authenticated = () => {
    const router = inject(Router);
    return new Promise<boolean>((resolve) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log(`auth: ${!!user}`);
            if (user) {
                resolve(true);
            } else {
                router && router.navigate(["login"]);
                resolve(false);
            }

            unsubscribe();
        });
    });
}

/**
 * @description if user is logged, made a initial request for event and user 
 * data to be stored an used across the application. If both requests are 
 * successiful, redirect the view to the event homePage
 */
const redirectToHomePage = () => {
    const SEventData = inject(EventDataService);
    const SUserData = inject(UserDataService);
    const router = inject(Router);

    if (!SEventData || !SUserData || !router) {
        return true;
    }
    if (authenticated()) {
        Promise.all([
            SEventData.getEventDataSnapshot(),
            SUserData.getUserDataSnapshot()
        ]).then(([eventData, userData]) => {
            router.navigateByUrl(eventData.homePage);
        }).catch(() => { router.navigate(["login"]); });

        return false;
    } else {
        return true;
    }    
}

const routes: Routes = [
    // { path: '', component: PathComponent.select_login },
    { path: '', component: PathComponent.login, canActivate: [redirectToHomePage] },
    { path: 'login', component: PathComponent.login },
    { path: 'login/:eventId', component: PathComponent.login },
    { path: 'public_register/:eventId', component: PathComponent.public_register },
    { path: 'public_login/:eventId', component: PathComponent.public_login },
    { 
        path: 'edit_profile/:eventId/:moduleId/:type/:userId',
        loadChildren: () => import("./modules/profile-edit/profile-edit.module").then(m => m.ProfileEditModule),
        canActivate: [authenticated],
        
    },
    { 
        path: 'admin-events', 
        component: PathComponent.admin_events,
        canActivate: [authenticated]
    },    // SUPERGOD/GOD Events
    { 
        path: 'c-events/:clientId', 
        component: PathComponent.client_events,
        canActivate: [authenticated]
    }, // CLIENT/EMPLOYEE Events
    { 
        path: 'user-events/:userId', 
        component: PathComponent.user_events,
        canActivate: [authenticated]
    },
    // { path: 'public', component: PathComponent.public_events },
    // {
    //     path: 'event/:eventId',
    //     loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
    // },
    {
        path: 'event/:eventId',
        component: HomeComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/modules.module').then(m => m.ModulesModule)
            }
        ],
        canActivate: [authenticated]
    },
    { path: 'plogin', component: PathComponent.loginp },
    {
        path: 'documents-module',
        loadChildren: () => import('./content/pages/documents-module/documents-module.module').then(m => m.DocumentsModulePageModule)
    },
    { path: ':shortcode', component: PathComponent.start }, // this, must be the last route 
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(
        // private navCtrl: NavController,
        // private SEventData: EventDataService,
        // private SUserData: UserDataService
    ) {
        // /**
        //  * @description made a initial request for event and user data to be stored an used across the 
        //  * application. If both requests are successiful, redirect the view to the event homePage
        //  */
        // const redirectToHomePage = () => {
        //     Promise.all([
        //         this.SEventData.getEventDataSnapshot(),
        //         this.SUserData.getUserDataSnapshot()
        //     ]).then(() => {
        //         this.navCtrl.navigateRoot([localStorage.getItem('homePage')]);
        //     });
        // }

        // if (
        //     localStorage.getItem('homePage') &&
        //     localStorage.getItem('userToken') &&
        //     localStorage.getItem('userIdentifier')
        // ) {
        //     redirectToHomePage();
        // } 
        // else if (
        //     localStorage.getItem('homePage') &&
        //     localStorage.getItem('eventId') &&
        //     localStorage.getItem('eventVisible') !== null
        // ) {
        //     const visible = (localStorage.getItem('eventVisible')) ? true : false;
        //     if (visible) {
        //         redirectToHomePage();
        //     }
        // }
    }
}
