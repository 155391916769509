import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], terms: string, language?: string): any[] {
    if (!items) return [];
    if (!terms) return items;
    terms = terms.toLowerCase();
    return items.filter(it => {
      // if (it.title !== undefined) {
      //   return it.title.toLowerCase().includes(terms);
      // } else if (it.name !== undefined) {
      //   return it.name.toLowerCase().includes(terms);
      // }
      if (language) { return it.name[language].toLowerCase().includes(terms.toLowerCase()); }
      if (it.name !== undefined) return it.name.toLowerCase().includes(terms.toLowerCase());
      if (it.title !== undefined) return it.title.toLowerCase().includes(terms.toLowerCase());
      if (it.appName !== undefined) return it.appName.toLowerCase().includes(terms.toLowerCase());
    });
  }
}
