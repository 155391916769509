import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { GlobalService } from 'src/app/shared/services';

import { TypeModule } from 'src/app/enums/type-module';
import { switchAll, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, firstValueFrom, of } from 'rxjs';
import { Firestore, collection, collectionData, doc, docData, query, where } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoEventsService {
    public headers;
    public requestOptions;

    constructor(
        public global: GlobalService,
        private firestore: Firestore,
        private Slogout: LogoutService
    ) {
        this.headers = new HttpHeaders();
        this.headers.append("Accept", 'application/json');
        this.headers.append("Content-Type", 'application/json');
        this.requestOptions = { headers: this.headers };
    }


    /**
     * Getting event
     * @param eventId 
     */
    getEvent(eventId: string): Observable<any> {
        const ref = doc(this.firestore, `events/${eventId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    getEventByShortcode(shortcode: string, onResolve) {
        const ref = collection(this.firestore, `events`);
        const refQ = query(ref, where('shortcode', '==', shortcode));

        firstValueFrom(collectionData(refQ)).then((docs) => {
                if (docs.length > 0) {
                    docs.forEach((doc) => {
                        onResolve(doc);
                    });
                } else {
                    onResolve(null);
                }
            })
            .catch((err) => {
                onResolve(err);
            });
    }

    getAllEvents(onResolve) {
        const ref = collection(this.firestore, `events`);
        collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((events) => {
            events.sort(function (a, b) {
                return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
            });

            onResolve({
                code: 200,
                message: 'success',
                result: events
            });

        });
    }

    getClientEvents(clientId: string, onResolve) {
        const ref = collection(this.firestore, `events`);
        const refQ = query(ref, where('client.uid', '==', clientId));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((events) => {
            events.sort(function (a, b) {
                return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
            });

            onResolve({
                code: 200,
                message: 'success',
                result: events
            });
        });
    }

    getUserEvents(userId: string, onResolve) {
        const ref = doc(this.firestore, `users/${userId}`);
        let events = [];

        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            let auxEvents = [];
            auxEvents = doc['events'];

            if (auxEvents.length <= 0) { onResolve(events) }
            let cont = 0
            for (let event of auxEvents) {
                this.getEvent(event).subscribe((eventData) => {
                    cont++

                    for (let event of events) {
                        let indexUid = this.checkUidExistsInArray(auxEvents, event.uid);
                        if (indexUid == -1) {
                            events.splice(indexUid, 1);
                        }
                    }

                    if (eventData !== null && eventData !== undefined) {
                        let index = this.checkIndexExists(events, eventData);
                        if (index == -1) {
                            events.push(eventData);
                        } else {
                            events[index] = eventData;
                        }
                    }

                    if (cont >= auxEvents.length) {
                        events.sort(function (a, b) {
                            return a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
                        });

                        onResolve(events);
                    }
                });
            }
        });
    }

    checkIndexExists(array, item) {
        return array.map(function (e) { return e.uid; }).indexOf(item.uid);
    }

    checkUidExistsInArray(array, item) {
        return array.map(function (e) { return e; }).indexOf(item);
    }

    getPublicEvents(onResolve) {
        const ref = collection(this.firestore, `events`);
        const refQ = query(ref, where('visibility', '==', true));
        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            onResolve(docs);
        });
    }

    getModuleEvent(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.EVENT));
        return firstValueFrom(collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                return (docs && docs.length > 0) ? of(docs[0]) : null;
            })
        ));
    }
}
