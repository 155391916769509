import { Injectable } from "@angular/core";
import { Storage, getDownloadURL, ref, uploadString, deleteObject, uploadBytes } from "@angular/fire/storage";
import { Camera, CameraResultType } from '@capacitor/camera';
import { Platform } from "@ionic/angular";
import { UUID } from "angular2-uuid";

@Injectable({
	providedIn: "root"
})
export class StorageService {
	constructor(
        private storage: Storage,
		private platform: Platform
	) { }
    
    // Take a picture with the cellphone camera or get one from the gallery
    async takePicture(callback) {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.DataUrl
        });
        callback(image.dataUrl);
    }

	uploadAttendeeProfile(eventId, userId, file, onResolve) {
		let metadata = {
			contentType: "image/jpeg"
		};
        const storageRef = ref(this.storage, `/profile-pictures-attendees/${eventId}/${userId}`)
        uploadString(storageRef, file, "data_url", metadata).then(() => {
            getDownloadURL(storageRef).then((url) => {
                onResolve(url)
            }).catch((error) => { 
                console.error(error); 
                onResolve(null);
            });
        }).catch((error) => { 
            console.error(error);
            onResolve(null);
        })
	}

	uploadSpeakerProfile(eventId, userId, file, onResolve) {
		let metadata = {
			contentType: "image/jpeg"
		};

        const storageRef = ref(this.storage, `/profile-pictures-speakers/${eventId}/${userId}`);
        uploadString(storageRef, file, "data_url", metadata).then(() => {
            getDownloadURL(storageRef).then((url) => {
                onResolve(url)
            }).catch((error) => { console.error(error); onResolve(null); });
        }).catch((error) => { console.error(error) });

		// const ref = this.storage.child('/profile-pictures-speakers').child(eventId).child(userId);
		// ref.putString(file, 'data_url', metadata).then((snapshot) => {
		//     ref.getDownloadURL().then((url: string) => {
		//         onResolve(url);
		//     }).catch((error) => {
		//         onResolve(null);
		//     })
		// }).catch((error) => {
		//     onResolve(null);
		// });
	}

	uploadChatImage(eventId: string, file: any, onResolve) {
		let metadata = {
			contentType: "image/jpeg"
		};
		const uid = UUID.UUID();

        const storageRef = ref(this.storage, `/chat/${eventId}/${uid}`);
        uploadString(storageRef, file, "data_url", metadata).then(() => {
            getDownloadURL(storageRef).then((url) => {
                onResolve(url)
            }).catch((error) => { console.error(error); onResolve(null); });
        }).catch((error) => { console.error(error) });
	}

	uploadImgNewsFeed(file: any, postId, eventId, moduleId) {
		return new Promise((resolve, reject) => {
			let metadata = {
				contentType: "image/jpeg"
			};

            const storageRef = ref(this.storage, `/news_feed/${eventId}/${moduleId}/${postId}.jpeg`);
            uploadString(storageRef, file, "data_url", metadata).then(() => {
                getDownloadURL(storageRef).then((url) => {
                    resolve(url)
                }).catch((error) => { console.error(error); reject(); });
            }).catch((error) => { console.error(error) });
		});
	}

	deletePost(postId: string, eventId: string, moduleId: string) {
        const storageRef = ref(this.storage, `/news_feed/${eventId}/${moduleId}/${postId}`);
        deleteObject(storageRef);
	}

	/**
	 * Upload doc for survey type document
	 * @param file
	 * @param eventId
	 * @param moduleId
	 * @param surveyId
	 * @param questionId
	 */
	uploadSurveyDocImg(
		file: any,
		eventId: string,
		moduleId: string,
		surveyId: string,
		questionId: string,
		name: string,
		mobile: boolean
	) {
		return new Promise((resolve, reject) => {
			if (mobile) {
				let metadata = {
					contentType: "image/jpeg"
				};

                const storageRef = ref(this.storage, `/documents-surveys/${eventId}/${moduleId}/${surveyId}/${questionId}/${name}`);
                uploadString(storageRef, file, "data_url", metadata).then(() => {
                    getDownloadURL(storageRef).then((url) => {
                        resolve(url)
                    }).catch((error) => { console.error(error); reject(); });
                }).catch((error) => { console.error(error) });
			} 
            else {
                const storageRef = ref(this.storage, `/documents-surveys/${eventId}/${moduleId}/${surveyId}/${questionId}/${name}`);
                uploadBytes(storageRef, file).then(() => {
                    getDownloadURL(storageRef).then((url) => {
                        resolve(url)
                    }).catch((error) => { console.error(error); reject(); });
                }).catch((error) => { console.error(error) });
			}
		});
	}

	userDocumentFile(file: any, eventId: string, userId: string, onResolve) {
        const storageRef = ref(this.storage, `/users-documents/${eventId}/${userId}/${file.name}`);
		uploadBytes(storageRef, file).then(() => {
            getDownloadURL(storageRef).then((url) => {
                onResolve(url)
            }).catch((error) => { console.error(error); onResolve(null); });
        }).catch((error) => { console.error(error) });
	}

	deleteUserDocumentFile(eventId: string, userId: string, filename: string) {
        const storageRef = ref(this.storage, `/users-documents/${eventId}/${userId}/${filename}`);
		deleteObject(storageRef);
	}
}
