import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  constructor(
  ) {
  }

  /**
  * monitors the network connection.
  * @returns boolean
  */
  status() {
    return navigator.onLine;
    // // this.events.publish('network:status', status)

    // window.addEventListener('offline', () => {
    //   // Executar tarefas quando não houver conexão com a Internet 
    //   // this.events.publish('network:status', false)
    //   this.status = false
    // });

    // window.addEventListener('online', () => {
    //   // Executar tarefas quando não houver conexão com a Internet 
    //   // this.events.publish('network:status', true)
    //   this.status = true
    // });

  }

}

    // let onlineOffline: boolean = navigator.onLine;
    // window.addEventListener ('offline', () => { 
    //   // Executar tarefas quando não houver conexão com a Internet 
    // });

    // window.addEventListener ('online', () => { 
    //   // Executar tarefas quando não houver conexão com a Internet 
    // });

    // Isso faz uso do objeto global do navegador da Angular para verificar a conectividade da Internet. O navigator.onLine retorna um valor booleano, true se houver conexão com a internet e false quando não houver conexão. Um exemplo disso está sendo mostrado abaixo.
