export class Participant {
    uid: string;
    name: string;
    email?: string;
    emailRecovery?: string;
    creator: boolean;
    avatar: string;

    constructor(
        uid: string,
        name: string,
        email?: string,
        emailRecovery?: string,
        creator?: boolean,
        avatar?: string
    ) {
        this.uid = uid;
        this.name = name;
        this.email = email;
        this.emailRecovery = emailRecovery;
        this.creator = creator;
        this.avatar = avatar;
    }
}
