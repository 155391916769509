import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService, UtilityService } from '../shared/services';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { DaoScheduleService } from '../providers/db/dao-schedule.service';
import { DaoSpeakersService } from '../providers/db/dao-speakers.service';
import { Hotsite } from './@types/hotsite';
import { TypeModule } from '../enums/type-module';
import { EventDataService } from '../shared/services/eventData/event-data.service';

@Component({
  selector: 'app-hotsite',
  templateUrl: './hotsite.component.html',
  styleUrls: ['./hotsite.component.scss'],
})
export class HotsiteComponent implements OnInit {
  isLoading: boolean = true;
  eventId: string;
  event: DocumentData;
  eventLanguage: string = "EnUS";
  schedule: DocumentData[] = [];
  scheduleDates: string[] = [];
  selectedDate: string = '';
  paginatedSchedule: DocumentData[] = [];
  selectedSession
  speakersSelectedSession: DocumentData[] = [];
  speakers: DocumentData[] = [];
  hotsite: Hotsite;
  infoboothModule = null;
  infoboothPage = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private SEventData: EventDataService,
    private dbSchedule: DaoScheduleService,
    private dbSpeakers: DaoSpeakersService,
    private aFirestore: AngularFirestore,
  ) {}

  ngOnInit() {
    this.eventId = this.route.snapshot.params['eventId'];
    this.getEvent();
    this.getSchedule();
    this.getSpeakers();
    this.getInfoboothModule();

    setTimeout(() => {
      if(!this.event) {
        window.location.reload();
      }
    }, 3000)

    localStorage.removeItem('userToken')
    localStorage.removeItem('userIdentifier')
    localStorage.removeItem('eventId')
  }

  getEvent() {
    this.SEventData.getEventDataListener(this.eventId).subscribe((event: DocumentData) => {
      if(!event || !event['hotsite'] || !event['hotsite']['displayHotsite']) {
        return this.goToLogin();
      }
      this.event = event;
      this.hotsite = event['hotsite'];
      this.eventLanguage = this.SEventData.getLanguage();
      this.isLoading = false;
    })
  }

  getSchedule() {
    this.dbSchedule.getSchedule(this.eventId).subscribe((schedule) => {
      this.scheduleDates = [];
      
      this.schedule = schedule.map((session) => {
        // formatting the event date, start time and end time
        session.date = String(new Date(session.date).getDate()).padStart(2, '0') + '/' + String(new Date(session.date).getMonth()+1).padStart(2, '0');
        session.startTime = String(new Date(session.startTime).getHours()).padStart(2, '0') + ':' + String(new Date(session.startTime).getMinutes()).padStart(2, '0');
        session.endTime = String(new Date(session.endTime).getHours()).padStart(2, '0') + ":" + String(new Date(session.endTime).getMinutes()).padStart(2, '0');
        return session;
      });

      this.scheduleDates = <string[]>schedule.reduce((acc, session) => {
        if(!acc.includes(session.date)) {
          acc.push(session.date);
        }
        return acc;
      }, [])
      this.selectDate(this.scheduleDates[0]);
      this.selectedSession = this.paginatedSchedule[0];
    });
  }

  selectDate(date: string) {
    this.selectedDate = date ?? '';
    this.paginatedSchedule = this.schedule.filter((session) => session.date === this.selectedDate);
  }

  selectSession(session) {
    this.selectedSession = session;
    const speakersUid = Object.keys(session.speakers);
    this.speakersSelectedSession = this.speakers.filter((speaker) => speakersUid.includes(speaker.uid));
  }

  getSpeakers() {
    this.dbSpeakers.getSpeakersByEvent(this.eventId).then(data => {
        this.speakers = data;
    })
  }

  getInfoboothModule() {
    const ref = this.aFirestore.firestore;

    ref
      .collection(`events/${this.eventId}/modules`)
      .where('type', '==', TypeModule.INFOBOOTH)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          this.infoboothModule = snapshot.docs[0].data();
          this.getInfoboothPage();          
        }
      })
      .catch((err) => console.error(err))
  }

  getInfoboothPage() {
    if (this.infoboothModule) {
      const ref = this.aFirestore.firestore;

      ref
        .collection(`events/${this.eventId}/modules/${this.infoboothModule.uid}/pages`)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            this.infoboothPage = snapshot.docs[0].data();
          }
        })
        .catch((err) => console.error(err))
    }
  }

  goToLogin() {
    this.router.navigate(['']);
  }
}
