import { Injectable } from "@angular/core";
import { 
    Firestore, 
    DocumentData, 
    collection, 
    query, 
    orderBy, 
    collectionData, 
    updateDoc, 
    doc, 
    docData,
    where,
    writeBatch,
    docSnapshots,
    getDocFromServer
} from '@angular/fire/firestore';
import { TypeModule } from "src/app/enums/type-module";
import { AskQuestion } from "src/app/models/ask-question";
import { filter, map, switchMap, take, takeUntil } from "rxjs/operators";
import { of, combineLatest } from "rxjs";
import * as _ from "lodash";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { LogoutService } from "src/app/shared/services/logout/logout.service";

@Injectable({
	providedIn: "root"
})
export class DaoAskQuestionService {
	constructor(
        private firestore: Firestore,
        private platform: Platform, 
        private storage: Storage,
        private Slogout: LogoutService
    ) {}

	/**
	 * Get question module
	 * @param moduleId
	 */
	getQuestionsModule(moduleId: string) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        return docData(ref).pipe(take(1));
	}

	/**
	 * Get questions for modules
	 * @param moduleId
	 */
	getAskQuestions(moduleId) {
        const ref = collection(this.firestore, `modules/${moduleId}/items`);
        const refQ = query(ref, orderBy("order", "asc"));

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject), 
            map((documents) => {
                return documents.filter((d) => { return (d && d.visibility); })
            }), 
            filter((documents) => { 
                return (documents && documents.length > 0);
            })
        )
	}

	/**
	 * Get questions for session
	 * @param eventId
	 * @param moduleId
	 * @param sessionId
	 * @param userId
	 */
	getQuestions(eventId: string, moduleId: string, sessionId: string, userId: string) {
        const ref = collection(this.firestore, `modules/${moduleId}/sessions/${sessionId}/questions`);
        const refQ = query(ref, ...[where("visibility", "==", true), orderBy("createdAt")]);
        
        let questions = [];

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                if (docs && docs.length > 0) {
                    questions = [];
                    let obs = [];
                    let reference;
                    for (let i = 0; i < docs.length; i++) {
                        let question = docs[i];
                        questions.push(question);
                        
                        reference = doc(this.firestore, `events/${eventId}/attendees/${question.userId}`);
                        obs.push(
                            docData(reference).pipe(
                                take(1),
                                switchMap((user) => {
                                    if (user) {
                                        question.userData = user;
                                        return of(true);
                                    } else {
                                        return of(false);
                                    }
                                })
                            )
                        );
                        
                        reference = collection(this.firestore, `modules/${moduleId}/sessions/${sessionId}/questions/${question.uid}/votes`);
                        obs.push(
                            collectionData(reference).pipe(
                                takeUntil(this.Slogout.logoutSubject),
                                switchMap((votesArray: any[]) => {
                                    question.totalVotes =
                                        votesArray && votesArray.length > 0 ? votesArray.length : 0;
                                    question.userLiked = false;
                                    votesArray.forEach((vote) => {
                                        if (vote && question && vote.uid == userId) {
                                            question.userLiked = true;
                                        }
                                    });
                                    return of(true);
                                })
                            )
                        );
                    }
                    return combineLatest(obs).pipe(
                        switchMap(() => {
                            return of(_.cloneDeep(questions));
                        })
                    );
                } else {
                    return of([]);
                }
            })
        )
	}

	/**
	 * Get asked item for question
	 * @param moduleId
	 * @param itemId
	 */
	getAskItem(moduleId, itemId) {
        const ref = doc(this.firestore, `modules/${moduleId}/items/${itemId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	/**
	 * Get general questions
	 * @param eventId
	 * @param moduleId
	 * @param itemId
	 * @param userId
	 */
	getQuestionsGeneral(eventId: string, moduleId: string, itemId: string, userId: string) {
		let questions = [];

        const ref = collection(this.firestore, `modules/${moduleId}/items/${itemId}/questions`);
        const refQ = query(ref, ...[where("visibility", "==", true), orderBy("createdAt")]);

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((docs) => {
                if (docs && docs.length > 0) {
                    questions = [];
                    let obs = [];
                    let reference;

                    for (let i = 0; i < docs.length; i++) {
                        let question = docs[i];
                        questions.push(question);

                        reference = doc(this.firestore, `events/${eventId}/attendees/${question.userId}`);
                        obs.push(
                            docData(reference).pipe(
                                take(1),
                                switchMap((user) => {
                                    if (user) {
                                        question.userData = user;
                                        return of(true);
                                    } else {
                                        return of(false);
                                    }
                                })
                            ) 
                        );

                        reference = collection(this.firestore, `modules/${moduleId}/items/${itemId}/questions/${question.uid}/votes`);
                        obs.push(
                            collectionData(reference).pipe(
                                takeUntil(this.Slogout.logoutSubject),
                                switchMap((votesArray: any[]) => {
                                    question.totalVotes =
                                        votesArray && votesArray.length > 0 ? votesArray.length : 0;
                                    question.userLiked = false;
                                    votesArray.forEach((vote) => {
                                        if (vote && question && vote.uid == userId) {
                                            question.userLiked = true;
                                        }
                                    });
                                    return of(true);
                                })
                            )
                        );
                    }
                    return combineLatest(obs).pipe(
                        switchMap(() => {
                            return of(_.cloneDeep(questions));
                        })
                    );
                } else {
                    return of([]);
                }
            })
        )
	}

	/**
	 * Create a question for session
	 * @param eventId
	 * @param moduleId
	 * @param sessionOrItemId
	 * @param question
	 * @param type
	 */
	async createQuestion(
		eventId: string,
		moduleId: string,
		sessionOrItemId: string,
		question: AskQuestion,
		type: string
	) {
        const batch = writeBatch(this.firestore);

        const ref1 = doc(collection(this.firestore, `modules/${moduleId}/sessions/${sessionOrItemId}/questions`));
        const docId = ref1.id;

        const ref2 = doc(this.firestore, `events/${eventId}/sessions/${sessionOrItemId}/questions/${docId}`);
        const ref3 = doc(this.firestore, `modules/${moduleId}/items/${sessionOrItemId}/questions/${docId}`);

		question.uid = docId;
		question = Object.assign({}, question);


        switch (type) {
            case "session":
                batch.set(ref1, question);
                batch.set(ref2, question);
                break;
            case "item":
                batch.set(ref3, question);
                break;
            default:
                return docId;
        }

        const promise = new Promise<void>((resolve, reject) => {
            batch
                .commit()
                .then(() => { resolve(); })
                .catch((error) => {
                    console.log(error);
                    reject();
                })
        })

        await promise;
        return docId; 
	}

	/**
	 * Add or remove vote on question
	 * @param eventId
	 * @param moduleId
	 * @param sessionOrItemId
	 * @param userId
	 * @param questionId
	 * @param type
	 */
	async addOrRemoveVote(eventId, moduleId, sessionOrItemId, userId, questionId, type: string) {
		if (userId) {
            const batch = writeBatch(this.firestore);

            const ref1 = doc(this.firestore, `events/${eventId}/sessions/${sessionOrItemId}/questions/${questionId}/votes/${userId}`);
            const ref2 = doc(this.firestore, `modules/${moduleId}/sessions/${sessionOrItemId}/questions/${questionId}/votes/${userId}`);
            const ref3 = doc(this.firestore, `modules/${moduleId}/items/${sessionOrItemId}/questions/${questionId}/votes/${userId}`);

            let exists;
            let promise = new Promise<boolean>((resolve) => { resolve(false); });

            try {
                switch (type) {
                    case "session":
                        exists = (await getDocFromServer(ref2)).exists();
                        if (exists) {
                            batch.delete(ref1);
                            batch.delete(ref2);
                        }
                        else {
                            batch.set(ref1, { uid: userId });
                            batch.set(ref2, { uid: userId });
                        }
                        break;
                    case "item":
                        exists = (await getDocFromServer(ref3)).exists();
                        if (exists) {
                            batch.delete(ref3);
                        }
                        else {
                            batch.set(ref3, { uid: userId });
                        }
                        break;
                    default:
                        return promise;
                }
            }
            catch {
                return promise;
            }

            promise = new Promise<boolean>((resolve) => {
                batch
                    .commit()
                    .then(() => { resolve(true); })
                    .catch((error) => {
                        console.error(error); 
                        resolve(false);
                    })
            });
            
            return promise;     
		} 
        else {
			return this.saveOrRemoveLikeForQuestionForAnonymousUser(eventId, questionId);
		}
	}

	/**
	 * Save localy the id of questions liked
	 * @param eventId
	 * @param questionId
	 */
	async saveOrRemoveLikeForQuestionForAnonymousUser(eventId: string, questionId: string) {
		let questionsString: string = "";
		let questionsSaved: any = {};
		if (this.platform.is("ios") || this.platform.is("android")) {
			questionsString = await this.storage.get("questionsLiked");
		} else {
			questionsString = localStorage.getItem("questionsLiked");
		}

		if (JSON.parse(questionsString) && JSON.parse(questionsString) != null) {
			questionsSaved = JSON.parse(questionsString);
		}

		// If no questions object
		if (!questionsSaved || !questionsSaved[eventId]) {
			questionsSaved[eventId] = {
				questions: [questionId]
			};
		}
		// if don't already exist
		else if (
			(questionsSaved && questionsSaved[eventId] && questionsSaved[eventId].questions.length == 0) ||
			!questionsSaved[eventId].questions.includes(questionId)
		) {
			questionsSaved[eventId].questions.push(questionId);
		}
		// If already exist remove vote
		else if (questionsSaved && questionsSaved[eventId] && questionsSaved[eventId].questions.includes(questionId)) {
			questionsSaved[eventId].questions.splice(questionsSaved[eventId].questions.indexOf(questionId), 1);
		}

		if (this.platform.is("ios") || this.platform.is("android")) {
			this.storage.set("questionsLiked", JSON.stringify(questionsSaved));
		} else {
			localStorage.setItem("questionsLiked", JSON.stringify(questionsSaved));
		}

		return questionsSaved;
	}

	/**
	 * Get questions module for event
	 * @param eventId
	 */
	getAskQuestionModule(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where("type", "==", TypeModule.ASK_QUESTION));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
	}
}
