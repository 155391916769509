import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import {
    MenuController,
    ModalController
} from "@ionic/angular";
// import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

import { Subscription } from "rxjs";
import { EventColors } from "src/app/models/event-colors";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Component({
    selector: 'app-personal-qrcode',
    templateUrl: './personal-qrcode.page.html',
    styleUrls: ['./personal-qrcode.page.scss'],
})
export class PersonalQRcodePage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    public userId: string = null;
    @Input() eventId: string = null;
    @Input() moduleId: string = null;

    public documents = [];
    allowQR: boolean = false;
    backBtn: boolean = true;

    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    language: string;

    doc;
    loaderToast;
    isMobile = window.innerWidth < 768;
    constructor(
        public global: GlobalService,
        private modalCtrlWriteMessages: ModalController,
        // private events: Events,
        private menuCtrl: MenuController,
        private SEventData: EventDataService
    ) {
        this.menuCtrl.enable(true);

        this.loadColors();
        this.language = this.SEventData.getLanguage();
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    async ngOnInit() {
        if (this.global.previousPage == "container") {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        const eventData = await this.SEventData.getEventDataSnapshot(this.eventId);
        this.allowQR = (eventData) ? eventData.allowProfileQR : null;
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }


    getSpeakerAttendeePrincipalTitle(title, description) {
        return {
            title: title[this.language],
            description: description[this.language]
        };
    }

    closeModal() {
        this.modalCtrlWriteMessages.dismiss()
    }
}
