import { Injectable } from '@angular/core';
import { TypeModule } from '../../models/type-module'
import { ModuleDataService } from 'src/app/shared/services/module-data/module-data.service';
import { DocumentData, Firestore, collection, collectionData, doc, docData, limit, orderBy, query, where, writeBatch } from '@angular/fire/firestore';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { firstValueFrom, takeUntil } from 'rxjs';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';

@Injectable({
    providedIn: 'root'
})
export class DaoPersonalAgendaService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private md: ModuleDataService,
        private userData: UserDataService
    ) { }

    getModule(moduleId: string, onResolve) {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
            onResolve(doc);
        });
    }

    getId(eventId: string, onResolve){
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, ...[where('type', '==', 13), limit(1)]);

        firstValueFrom(collectionData(refQ)).then((docs) => {
            docs.forEach((doc) => { onResolve(doc); });
        }).catch((erro) => {
            console.log(`${erro}`);
        })
    }

    getSession(eventId: string, sessionId: string) {
        const ref = doc(this.firestore, `events/${eventId}/sessions/${sessionId}`);
        return firstValueFrom(docData(ref));
    }

    /**
     * @param eventId 
     * @param attendeeId 
     * @returns an observable of all firestore documents of sessions in the current attendee (user) personal 
     * schedule, i.e. documents present in the 'sessions' collection
     */
    public getSessionsData(eventId: string, attendeeId: string) {
        const ref = collection(this.firestore, `events/${eventId}/attendees/${attendeeId}/sessions`);
        const refQ = query(ref, orderBy('starttime'));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    // add session to calendar
    public async addSession(session: DocumentData) {
        const batch = writeBatch(this.firestore);

        const sessionId = session.uid
        const moduleScheduleId = session.moduleId
        const eventId = session.eventId

        // picks up the participant's uid and module information.
        const attendeeId = this.userData.userId
        const moduleAttendeeId = this.userData.userData.moduleId
        
        const ref1 = doc(this.firestore, `events/${eventId}/sessions/${sessionId}/attendees/${attendeeId}`);
        const ref2 = doc(this.firestore, `modules/${moduleScheduleId}/sessions/${sessionId}/attendees/${attendeeId}`);
        const ref3 = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}`);
        const ref4 = doc(this.firestore, `modules/${moduleAttendeeId}/attendees/${attendeeId}/sessions/${sessionId}`);

        const objSession = {
            uid: sessionId,
            moduleId: moduleScheduleId,
            eventId: eventId
        }

        const objAttendee = {
            uid: attendeeId,
            email: this.userData.userData.email,
            name: this.userData.userData.name,
            moduleId: moduleAttendeeId,
            eventId: eventId
        }


        // module personal agenda
        // const pos = this.global.modulesEvent.map(function (e) { return e.type; }).indexOf(TypeModule.PERSONALSCHEDULE);
        // const modulePersonalAgendaId = this.global.modulesEvent[pos].uid

        const modulePersonalAgendaId = (await this.md.getModuleId(eventId, TypeModule.PERSONALSCHEDULE))

        // add info user in path agenda personal
        const ref5 = doc(this.firestore, `modules/${modulePersonalAgendaId}/attendees/${attendeeId}`);
        const ref6 = doc(this.firestore, `modules/${modulePersonalAgendaId}/attendees/${attendeeId}/sessions/${sessionId}`);


        batch.set(ref1, objAttendee)
        batch.set(ref2, objAttendee)
        batch.set(ref3, session)
        batch.set(ref4, session)
        batch.set(ref5, objAttendee)
        batch.set(ref6, session)

        return batch.commit().then(function () {

        }).catch((err) => {
            console.error(err)
        })
    }


    // delete session to calendar
    public async deleteSession(eventId: string, moduleScheduleId: string, sessionId: string) {
        const batch = writeBatch(this.firestore);

        // picks up the participant's uid and module information.
        const attendeeId = this.userData.userId
        const moduleAttendeeId = this.userData.userData.moduleId

        // relates the session to the participant(bidirectional)
        const ref1 = doc(this.firestore, `events/${eventId}/sessions/${sessionId}/attendees/${attendeeId}`);
        const ref2 = doc(this.firestore, `modules/${moduleScheduleId}/sessions/${sessionId}/attendees/${attendeeId}`);
        const ref3 = doc(this.firestore, `events/${eventId}/attendees/${attendeeId}/sessions/${sessionId}`);
        const ref4 = doc(this.firestore, `modules/${moduleAttendeeId}/attendees/${attendeeId}/sessions/${sessionId}`);

        // module personal agenda
        // const pos = this.global.modulesEvent.map(function (e) { return e.type; }).indexOf(TypeModule.PERSONALSCHEDULE);
        // const modulePersonalAgendaId = this.global.modulesEvent[pos].uid

        const modulePersonalAgendaId = (await this.md.getModuleId(eventId, TypeModule.PERSONALSCHEDULE));

        const ref5 = doc(this.firestore, `modules/${modulePersonalAgendaId}/attendees/${attendeeId}/sessions/${sessionId}`);

        // remove docs
        batch.delete(ref1)
        batch.delete(ref2)
        batch.delete(ref3)
        batch.delete(ref4)
        batch.delete(ref5)

        return batch.commit().then(function () {

        }).catch((err) => {
            console.error(err)
        })
    }

    public getSessionAtts(eventId: string, sessionId: string) {
        const ref = collection(
            this.firestore, 
            `events/${eventId}/sessions/${sessionId}/attendees`
        );
        return collectionData(ref);
    }

}