import { Injectable } from '@angular/core';
import { TypeUser } from 'src/app/models/type-user';
import { 
    Firestore, 
    doc,
    docData,
    DocumentData,
    updateDoc, 
} from '@angular/fire/firestore';
import {
    catchError,
    firstValueFrom,
    Observable, 
    of, 
    share, 
    startWith, 
    switchMap, 
    takeUntil, 
    tap
} from 'rxjs';
import { LogoutService } from '../logout/logout.service';
import { Auth, authState } from '@angular/fire/auth';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActiveMenuIconsService } from '../active-menu-icons/active-menu-icons.service';
import { EventDataService } from '../eventData/event-data.service';


interface INeedLoginPopUpParams {
    closeHandler?: () => void,
    redirectHandler?: () => void
    eventId?: string
}

@Injectable({
    providedIn: 'root'
})
export class UserDataService {
    public userData: DocumentData = null;
    private userDataSharedObs: Observable<DocumentData> = null;
    private _userId: string = null;
    public get userId() {
        if (this._userId == null) { this._userId = localStorage.getItem("userIdentifier"); }
        return this._userId;
    }
    public set userId(value: string) { this._userId = value }
    public mustEditProfile: boolean = false;    // used by "ProfileEditPage" component

    constructor(
        private firestore: Firestore,
        private auth: Auth,
        private Slogout: LogoutService,
        private alertCtrl: AlertController,
        private translateService: TranslateService,
        private SEventData: EventDataService,
        private navCtrl: NavController
    ) { }

    /**
     * @return provides the firebase firestore document reference correspondent
     * to the user id nad type passed as params
     */
    public userRef(params?: { eventId: string, userId: string, userType: TypeUser}) {
        if (!params) {
            return doc(this.firestore, `users/${this.userId}`);
        }

        switch (params.userType) {
            case TypeUser.ATTENDEE:
                // ref = this.firestore.collection('events').doc(eventId).collection('attendees').doc(userId);
                return doc(this.firestore, `events/${params.eventId}/attendees/${params.userId}`);
            case TypeUser.SPEAKER:
                // ref = this.firestore.collection('events').doc(eventId).collection('speakers').doc(userId);
                return doc(this.firestore, `events/${params.eventId}/speakers/${params.userId}`);
            case (TypeUser.SUPERGOD || TypeUser.GOD || TypeUser.CLIENT || TypeUser.EMPLOYEE):
                // ref = this.firestore.collection('users').doc(userId);
                return doc(this.firestore, `users/${params.userId}`);
            default:
                return doc(this.firestore, `users/${this.userId}`);
        }
    }

    /**
     * @description returns as a callback an Observable for a firebase firestore DocumentData value, of the current user, 
     * or returns null if a problem happened i nthe user authentification  
     * @param eventId 
     * @param callback 
     */
    private getUserDataSharedObs(eventId: string) {
        const getObservable = (eventId: string, userId: string, userType: number): Observable<DocumentData> => {
            const ref = this.userRef({
                eventId: eventId,
                userId: userId,
                userType: userType
            });

            return docData(ref).pipe(
                share(), 
                takeUntil(this.Slogout.logoutSubject),
                tap({
                    next: (userData) => {
                        this.userData = userData;
                    },
                    complete: () => {
                        console.log(`finalize userData`)
                        this.userData = null;
                    }
                })
            );
        }

        if (this.userDataSharedObs == null) {
            this.userDataSharedObs = authState(this.auth).pipe(
                takeUntil(this.Slogout.logoutSubject),
                switchMap((userInfo) => {
                    if (!userInfo) {
                        return of(null);
                    }

                    const ref = doc(this.firestore, `users/${userInfo.uid}`);
                    return docData(ref);
                }),
                switchMap((userData) => {
                    if (!userData) {
                        if (localStorage.getItem('eventVisible')) {
                            return new Observable<DocumentData>((observer) => { 
                                observer.error({type: 1, msg: `event is public`});
                            });
                        } else {
                            return new Observable<DocumentData>((observer) => { 
                                observer.error({type: 2, msg: `error retrieving user data of auth state`});
                            });
                        }
                    }

                    return getObservable(eventId, userData.uid, userData.type);
                })
            );
        }
        return this.userDataSharedObs;
    }

    /**
     * @description sends up to date userData (firebase firestore DocumentData) and the corresponding subscription of 
     * the observable used, to the callback function provided as a parameter of this method. The callback function 
     * is called everytime the user data is updated in the database, unless the subscription is properly unsubscribed
     * inside the callback function
     * @errors type 1: event is public and the current user inst logged; 
     * type 2: user data couldnt be retrieved
     * @param eventId 
     * @param callback 
     */
    public getUserDataListener(eventId?: string): Observable<DocumentData> {
        eventId ??= localStorage.getItem("eventId");
        if (!eventId) {
            console.error(`eventId has invalid value: ${eventId}`);
            return new Observable((observer) => { 
                observer.error(`The value passed to the 'eventId' parameter of 'UserDataService.getUserDataListener' method is not valid - (eventId: ${eventId})`);
            });
        }
        const userData$ = (this.userData == null) ? 
            this.getUserDataSharedObs(eventId) : 
            this.getUserDataSharedObs(eventId).pipe(startWith(this.userData));
        
        // return userData$.pipe(map((userData) => {
        //     this.userData = userData;
        //     return userData;
        // }));
        return userData$;
    }
    
    /**
     * @errors type 1: event is public and the current user inst logged; 
     * type 2: user data couldnt be retrieved
     * @param eventId 
     * @returns a promise of the user data value, i.e. a snapshot of the firebase firestore document
     */
    public getUserDataSnapshot(eventId?: string): Promise<DocumentData> {
        eventId ??= localStorage.getItem("eventId");
        if (!eventId) {
            console.error(`eventId has invalid value: ${eventId}`);
            return new Promise((resolve, reject) => { reject(); }); 
        }

        return firstValueFrom(this.getUserDataListener(eventId));
    }

    public async needLoginToUse(params: INeedLoginPopUpParams = null) {
        params ??= {
            closeHandler: () => {},
            redirectHandler: () => {},
            eventId: null
        };
        params.closeHandler ??= () => {};
        params.redirectHandler ??= () => {};
        params.eventId ??= localStorage.getItem("eventId");

        const alert = await this.alertCtrl.create({
            message: this.translateService.instant('global.texts.need_login_to_use'),
            buttons: [
                {
                    // text: this.translateService.instant('global.buttons.ok'),
                    text: 'close',
                    handler: () => { params.closeHandler(); }
                },
                {
                    text: "login page",
                    handler: async () => {
                        params.redirectHandler();
                        this.navCtrl.navigateForward(`public_register/${params.eventId}`);
                    }
                }
            ]
        });

        await alert.present();
    }

    /**
     * 
     * @param enableNeedLoginPopUp default is 'false'
     * @param popUpParams 
     * @returns a boolean value referent to the login state of a user
     */
    public userLogged(
        enableNeedLoginPopUp = false, 
        popUpParams: INeedLoginPopUpParams = null
    ): boolean {
        if (enableNeedLoginPopUp && (this.userData == null)) {
            this.needLoginToUse(popUpParams);
        }

        return (this.userData != null);
    }

    updateUserPointsEngagement(action: string, removePoints: boolean = false) {
        let field = null;
        if (this.SEventData.eventData['engagementFields'])
            field = this.SEventData.eventData['engagementFields'].find(field => field.name.toLowerCase() === action);
        if (!field) return;
        if (!field.active) return;
        console.log(field);

        const docRef = doc(this.firestore, `events/${this.SEventData.eventId}/attendees/${this.userId}`);

        this.userDataSharedObs.subscribe(userData => {
            this.userData = userData;
        });

        let userPoints = 0;

        if (this.userData.engagementPoints && this.userData.engagementPoints[action]) 
            userPoints = this.userData.engagementPoints[action];

        if (field.name.toLowerCase() === 'add_profile_photo')
            userPoints = 0; // keep it at 0 to only update once for 'add_profile_photo'

        if (removePoints) {
            userPoints = userPoints - field.points > 0 ? userPoints - field.points : 0;
            updateDoc(docRef, {
                engagementPoints: {
                    ...this.userData.engagementPoints,
                    [`${action}`]: userPoints,
                }
            });
        } else {
            userPoints += field.points;
            updateDoc(docRef, {
                engagementPoints: {
                    ...this.userData.engagementPoints,
                    [`${action}`]: userPoints,
                }
            });
        }
    }
}
