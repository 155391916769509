import { Action } from '@ngrx/store';

export enum UtilityActionsTypes {
    HaveNetworkConnection = "[Utility Service] Have network connection"
}

export class HaveNetworkConnection implements Action {
    readonly type = UtilityActionsTypes.HaveNetworkConnection;

    constructor(public payload: string) { }
}

export type UtilityActionsUnion = HaveNetworkConnection;