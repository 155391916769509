import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISchedulesState } from '../interfaces/schedules.interfaces';

export const getSchedulesState = createFeatureSelector<ISchedulesState>('schedules');

export const getScheduleSessions = createSelector(getSchedulesState, (state: ISchedulesState) => state.scheduleSessions);

/**
 * Check for first and last
 */
export const sessionIsLastPrev = () => createSelector(getSchedulesState, (state: ISchedulesState, uid: string) => {
    if (!state.scheduleSessions || state.scheduleSessions.length <= 1) {
        return ({
            status: "error",
            last: false,
            first: false
        })
    }
    let currentIndex = state.scheduleSessions.indexOf(state.scheduleSessions.find((session) => session.uid == uid));
    let response = {
        status: "valid",
        last: false,
        first: false
    }

    if (currentIndex == -1) {
        return ({
            status: "error",
            last: false,
            first: false
        })
    }
    if (currentIndex == state.scheduleSessions.length - 1) {
        response.last = true;
    }
    if (currentIndex == 0) {
        response.first = true;
    }

    return (response);
})

/**
 * Return next session
 */
export const getNextSession = () => createSelector(getSchedulesState, (state: ISchedulesState, uid: string) => {
    let currentIndex = state.scheduleSessions.indexOf(state.scheduleSessions.find((session) => session.uid == uid));

    if (currentIndex == -1) {
        return ({
            status: "error",
            session: null
        })
    } else if (currentIndex == state.scheduleSessions.length - 1) {
        return ({
            status: "last",
            session: state.scheduleSessions[currentIndex]
        })
    } else {
        return ({
            status: "valid",
            session: state.scheduleSessions[currentIndex + 1]
        })
    }
})

/**
 * Return previous session
 */
export const getPrevSession = () => createSelector(getSchedulesState, (state: ISchedulesState, uid: string) => {
    let currentIndex = state.scheduleSessions.indexOf(state.scheduleSessions.find((session) => session.uid == uid));

    if (currentIndex == -1) {
        return ({
            status: "error",
            session: null
        })
    } else if (currentIndex == 0) {
        return ({
            status: "first",
            session: state.scheduleSessions[currentIndex]
        })
    } else {
        return ({
            status: "valid",
            session: state.scheduleSessions[currentIndex - 1]
        })
    }
})