import { Injectable } from '@angular/core';
import { collection, collectionData, doc, docData, DocumentData, Firestore, query, QueryConstraint, where } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, of, share, startWith, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { LogoutService } from '../logout/logout.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventDataService {
    private eventDataSharedObs: Observable<DocumentData> = null;
    public eventData: DocumentData = null;
    public eventId: string = null;
    private _language: string = null;

    public set language(value: string) {
        this._language = value;
    }

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService,
        private translateService: TranslateService
    ) { }

    /**
     * @description using the idea of singletons, this method returns an Observable that can be shared 
     * among subscribers, i.e. anywhere in the application the firestore DocumentData of the event can be 
     * retrived with a single observable
     */
    private getEventDataSharedObs(eventId: string = this.eventId): Observable<DocumentData> {
        if (!eventId) {
            console.error(`eventId has invalid value: ${eventId}`);
            return;
        }

        if (this.eventDataSharedObs == null) {
            const ref = doc(this.firestore, `events/${eventId}`);
            this.eventDataSharedObs = docData(ref).pipe(
                share(), 
                takeUntil(this.Slogout.logoutSubject),
                tap({
                    next: (eventData) => {
                        console.log(`tap event :${!!eventData}`)
                        this.eventDataHandler(eventData);
                    },
                    complete: () => {
                        console.log(`finalize event data`)
                        this.eventData = null
                    }
                })
            );
        }

        return this.eventDataSharedObs;
    }

    /**
     * 
     * @param eventId 
     * @returns 
     */
    public getEventDataListener(eventId?: string): Observable<DocumentData> {
        eventId ??= localStorage.getItem("eventId");
        if (!eventId) {
            console.error(`eventId has invalid value: ${eventId}`);
            return new Observable((observer) => { 
                observer.error(`The value passed to the 'eventId' parameter of 'EventDataService.getEventDataListener' method is not valid - (eventId: ${eventId})`);
            });
        }

        const eventData$ = (this.eventData == null) ?
            this.getEventDataSharedObs(eventId) :
            this.getEventDataSharedObs(eventId).pipe(startWith(this.eventData));

        // let eventData$: Observable<DocumentData>;

        // return eventData$.pipe(map((eventData) => {
        //     this.eventDataHandler(eventData);
        //     return eventData;
        // }));
        return eventData$;
    }

    /**
     * 
     * @param eventId 
     * @returns 
     */
    public getEventDataSnapshot(eventId?: string) {
        eventId ??= localStorage.getItem("eventId");
        if (!eventId) {
            console.error(`eventId has invalid value: ${eventId}`);
            return new Promise<DocumentData>((resolve, reject) => { reject(); });
        }

        return firstValueFrom(this.getEventDataListener(eventId));
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    private eventDataHandler(data: any) {
        this.eventData = data;
        this.eventId = data.uid;

        localStorage.setItem('eventId', data.uid);

        // this.eventHomePage = data.homePage;
        localStorage.setItem('homePage', data.homePage);

        // this.eventTimezone = data.timezone;
        localStorage.setItem('timezone', data.timezone);

        // this.allow_language = data.allow_language
        this.translateService.use(data.language);
        localStorage.setItem('usedLanguage', data.language);
        this._language = data.language;

        // this.eventColors = data.colors;

        if (this.eventData.allowProfileQR == undefined || this.eventData.allowProfileQR == null) {
            this.eventData.allowProfileQR = true;
        }

        if (this.eventData.publicOptions == undefined) {
            this.eventData.publicOptions = {
                google_btn: false,
                facebook_btn: false,
                twitter_btn: false,
                microsoft_btn: false,
                yahoo_btn: false
            }
        }
        else {
            this.eventData.publicOptions = {
                google_btn: this.eventData.publicOptions.google_btn ? this.eventData.publicOptions.google_btn : false,
                facebook_btn: this.eventData.publicOptions.facebook_btn ? this.eventData.publicOptions.facebook_btn : false,
                twitter_btn: this.eventData.publicOptions.twitter_btn ? this.eventData.publicOptions.twitter_btn : false,
                microsoft_btn: this.eventData.publicOptions.microsoft_btn ? this.eventData.publicOptions.microsoft_btn : false,
                yahoo_btn: this.eventData.publicOptions.yahoo_btn ? this.eventData.publicOptions.yahoo_btn : false
            }
        }

        // this.events.publish('allowLanguage')
        // this.events.publish('loadColors');
    }

    /**
     * @description evaluate if the language passed as param is supported, if it is return it formated, else returns 
     * the default language
     * @param language 
     * @returns 
     */
    private evalLanguage(language: string = this._language) {
        const languagesSuported = ['pt_BR', 'en_US', 'es_ES', 'fr_FR', 'de_DE'];
        if (languagesSuported.find((el) => { return language === el; })) {
            const string  = language.replace("_", "");
            return (string.charAt(0).toUpperCase() + string.slice(1));
        }
        return environment.platform.defaultLanguage;
    }

    public getLanguage(oldFormat: boolean = false) {
        if (oldFormat) { return (this._language) ? this._language : environment.platform.oldDefaultLanguage; }
        if (this._language == null) { return environment.platform.defaultLanguage; }
        return this.evalLanguage();
    }

    public getEventsByQuery(qc: QueryConstraint[], firstMatch: boolean): Promise<DocumentData[] | DocumentData> {
        const ref = collection(this.firestore, `events`);
        const refQ = query(ref, ...qc);

        let obs;
        if (firstMatch) {
            obs = collectionData(refQ).pipe(switchMap((events) => {
                if (events.length > 0) {
                    this.eventDataHandler(events[0]);
                    return of(events[0])
                } else {
                    return throwError(() => new Error(`No event match the query`));
                }
            }));
        } else {
            obs = collectionData(refQ);
        }
        
        return firstValueFrom(obs);
    }

    public getEventByShortcode(shortcode: string): Promise<DocumentData> {
        const qc: QueryConstraint[] = [where('shortcode', '==', shortcode)];
        return this.getEventsByQuery(qc, true);
    }
}
