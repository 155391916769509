import { Injectable } from '@angular/core';
import { Share, CanShareResult, ShareOptions } from '@capacitor/share';
import { FileService } from '../file/file.service';

@Injectable()
export class ShareService {

    constructor(private fileService: FileService ) { }

    /**
     * @description share content with apps (such as social media) [not working as intended yet]
     * @param title 
     * @param text 
     * @param url 
     * @param files 
     */
    public async share(title: string = null, text: string = null, url: string = null, files: string[] = null) {
        Share.canShare().then((result: CanShareResult) => {
            if (result.value) {
                this.downloadImg(files).then((pathToFiles) => {
                    const options: ShareOptions = {
                        title: title,
                        text: text,
                        url: url,
                        files: pathToFiles
                    };
                    Share.share(options);
                });
            }
            else {
                alert("Error: Share service not supported")
            }
        }).catch((error) => {
            alert(error);
        });
    }

    /**
     * 
     * @param files array of img URLs
     * @description download all of the images from the parameter files
     */
    private async downloadImg(files: string[]) {
        let pathToFiles: string[] = new Array<string>();
        if (files != null) {
            for (let i = 0; i < files.length; i++) {
                alert(`downloading : ${files[i]}`);
                let response = await this.fileService.downloadFile("", "jpg", files[i]);
                
                alert(response.path);
                pathToFiles.push(response.path);
            }
        }
        return pathToFiles;
    }
}
