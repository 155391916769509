import { Injectable } from '@angular/core';
import { 
    Firestore,
    collection, 
    query, 
    orderBy, 
    collectionData, 
    doc, 
    docData,
} from '@angular/fire/firestore';
import { takeUntil } from 'rxjs';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
  providedIn: 'root'
})
export class DaoWidgetsService {

  constructor(
    private firestore: Firestore,
    private Slogout: LogoutService
  ) { }

  getWidgetByModule(moduleId: string, onResolve) {
    const ref = collection(this.firestore, `modules/${moduleId}/widgets`);
    const refQ = query(ref, orderBy('order', 'asc'));
    
    collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
        onResolve(docs);
    });
  }

  getModule(moduleId: string, onResolve) {
    const ref = doc(this.firestore, `modules/${moduleId}`);
    
    docData(ref).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((doc) => {
        onResolve(doc);
    });
  }
}
