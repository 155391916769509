import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';

import { HttpClientModule, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';  // todo: update plugin splash-screen
//import { Globalization } from '@ionic-native/globalization/ngx';  // todo: update plugin globalization

import { AppComponent } from './app.component';
import { PathComponent } from './models/path/path-components';
import { SharedModule } from './shared/shared.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NotificationDateService } from './providers/date/notification-date.service';
import { DaoModulesService } from './providers/db/dao-modules.service';
import { DateService } from './providers/date/date.service';
import { DaoGeralService } from './providers/db/dao-geral.service';
import { LuxonService } from './providers/luxon/luxon.service';
import { ConnectionService } from 'ng-connection-service';

import { AppRoutingModule } from './app-routing.module';
import { AvatarModule } from 'ngx-avatar';
import { IonicStorageModule } from '@ionic/storage';
import { StarRatingModule } from 'angular-star-rating';

// Services imports
import {
    AnalyticsService,
    GlobalService,
    IntegrationsService,
    PublicService,
    UtilityService,
    VideoService,
    WherebyService
} from './shared/services';

// Ngrx part
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './shared/reducers';
import { GroupDiscussionsService } from './shared/services/group-discussions/group-discussions.service';

import { ChartsModule, ThemeService } from 'ng2-charts';
import { VideoPlayerModule } from './content/reusable_components/video-player/video-player.module';

import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { HomeComponent } from './home/home.component';
import { Capacitor } from '@capacitor/core';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        PathComponent.start,
        PathComponent.select_login,
        PathComponent.login,
        PathComponent.loginp,
        PathComponent.admin_events,
        PathComponent.client_events,
        PathComponent.user_events,
        PathComponent.public_events,
        PathComponent.terms_of_use,
        PathComponent.privacy_terms,
        PathComponent.feed_comments,
        PathComponent.chat_list,
        PathComponent.terms_n_privacy,
        PathComponent.public_login,
        PathComponent.public_register,
        PathComponent.lightbox_img,
        PathComponent.login_with_code_confirm,
        PathComponent.login_without_code_confirm,
        PathComponent.login_with_shortcode,
        HomeComponent
    ],
    // entryComponents: [
    //     PathComponent.terms_of_use,
    //     PathComponent.privacy_terms,
    //     PathComponent.notifications,
    //     PathComponent.feed_comments,
    //     PathComponent.chat_list,
    //     PathComponent.terms_n_privacy,
    //     PathComponent.public_login,
    //     PathComponent.public_register,
    //     PathComponent.feed_more_options,
    //     PathComponent.lightbox_img,
    //     PathComponent.login_with_code_confirm,
    //     PathComponent.login_without_code_confirm,
    //     PathComponent.login_with_shortcode
    // ],
    imports: [
        provideFirebaseApp(() => { 
            const app = initializeApp(environment.firebase);
            if (Capacitor.isNativePlatform()) {
                initializeAuth(app, { persistence: indexedDBLocalPersistence })
            }
            return app;
        }),
        provideFirestore(() => getFirestore()),
        provideFunctions(() => getFunctions()),
        provideStorage(() => getStorage()),
        provideAuth(() => getAuth()),
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),

        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AvatarModule,
        SharedModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        StoreDevtoolsModule.instrument(),
        ChartsModule,
        VideoPlayerModule,
        NgxQRCodeModule,
        StarRatingModule.forRoot()
    ],
    providers: [
        //SplashScreen,  // todo: update plugin splash-screen
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        GlobalService,
        // { provide: SETTINGS, useValue: {} },
        DaoModulesService,
        DateService,
        NotificationDateService,
        DaoGeralService,
        LuxonService,
        //Globalization,  // todo: update plugin globalization
        ConnectionService,
        ThemeService,

        AnalyticsService,
        GlobalService,
        IntegrationsService,
        PublicService,
        UtilityService,
        WherebyService,
        GroupDiscussionsService,
        VideoService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
