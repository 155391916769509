import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VideoPlayerComponent } from "./video-player.component";
import { SharedModule } from "src/app/shared/shared.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { YouTubePlayerModule } from "@angular/youtube-player";

@NgModule({
	imports: [IonicModule, CommonModule, SharedModule, DragDropModule, YouTubePlayerModule],
	declarations: [VideoPlayerComponent],
	exports: [VideoPlayerComponent]
})
export class VideoPlayerModule {}
