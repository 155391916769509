import { Injectable } from "@angular/core";
import { 
    Firestore, 
    DocumentData, 
    collection, 
    query, 
    collectionData, 
    updateDoc, 
    doc, 
    setDoc,
    docData,
    where,
    writeBatch,
    QueryConstraint,
    limit
} from '@angular/fire/firestore';
import { NotificationDateService } from "../date/notification-date.service";
import { TypeModule } from "src/app/enums/type-module";
import { DaoModulesService } from "./dao-modules.service";
import { CeuModule } from "src/app/models/ceu-module";
import { WherebyService } from "src/app/shared/services";
import localeFr from "@angular/common/locales/fr";
import localePt from "@angular/common/locales/pt";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import {
	AppointmentRule,
	AppointmentRuleDatabase,
	AppointmentRuleGroups,
	AppointmentTimeSlot,
	AppointmentTimeSlotByDay,
	AppointmentTimeSlotDatabase,
	AppointmentTimeSlotStatus
} from "src/app/models/appointments";
import { ChatMessage } from "src/app/models/chat-message";
import { GroupDiscussionsService } from "src/app/shared/services/group-discussions/group-discussions.service";
import { map, mergeMap, take, takeUntil } from "rxjs/operators";
import { combineLatest, firstValueFrom, forkJoin, of } from "rxjs";
import { DaoAttendeesService } from "./dao-attendees.service";
import { DateTime } from "luxon";
import { TranslateService } from "@ngx-translate/core";
import { LogoutService } from "src/app/shared/services/logout/logout.service";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";

@Injectable({
	providedIn: "root"
})
export class DaoAppointmentsService {
	constructor(
		private firestore: Firestore,
		private daoModules: DaoModulesService,
		private gdService: GroupDiscussionsService,
		private notificationDateService: NotificationDateService,
		private dbAttendees: DaoAttendeesService,
		private wherebyService: WherebyService,
		private translate: TranslateService,
        private Slogout: LogoutService,
        private userData: UserDataService,
        private SEventData: EventDataService
	) {}

	async createAppointmentRequest(appointment: AppointmentTimeSlot, appointmentMessage: string) {
		if (!this.SEventData.eventId) {
			throw new Error("No event id provided");
		}
		
        const colRef = collection(this.firestore, `events/${this.SEventData.eventId}/appointments`)
        const docRef = doc(colRef);

        appointment.uid = docRef.id;
        
        await setDoc(docRef, appointment.toDatabaseFormat());
		await this.sendAppointmentUpdateViaChat(appointment, appointmentMessage);
	}

	async sendAppointmentUpdateViaChat(appointment: AppointmentTimeSlot, customMessage?: string) {
		if (
			!appointment.isAppointment ||
			!appointment.applicant ||
			!appointment.applicant.uid ||
			!appointment.invited ||
			!appointment.invited.uid
		) {
			return;
		}

		let notificationMessage: string;

		if (!notificationMessage) {
			switch (appointment.status) {
				case AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED:
					notificationMessage = this.translate.instant("appointments.notification.accepted", {
						subject: appointment.subject
					});
					break;
				case AppointmentTimeSlotStatus.APPOINTMENT_PENDING:
					notificationMessage = this.translate.instant("appointments.notification.pending", {
						subject: appointment.subject,
						date: appointment.startDateTime.toLocaleString({
							locale: this.SEventData.getLanguage(true).replace("_", "-"),
							weekday: "short",
							month: "2-digit",
							day: "2-digit",
							hour: "2-digit",
							minute: "2-digit"
						})
					});
					break;
				case AppointmentTimeSlotStatus.APPOINTMENT_REJECTED:
					notificationMessage = this.translate.instant("appointments.notification.rejected", {
						subject: appointment.subject
					});
					break;
				case AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED:
					notificationMessage = this.translate.instant("appointments.notification.cancelled", {
						subject: appointment.subject
					});
					break;
				case AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY:
					notificationMessage = this.translate.instant("appointments.notification.rejected_automatically", {
						subject: appointment.subject
					});
					break;
				case AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY:
					notificationMessage = this.translate.instant("appointments.notification.cancelled_automatically", {
						subject: appointment.subject
					});
					break;
			}
		}

        const eventData = await this.SEventData.getEventDataSnapshot();
        const timezone = (eventData)? eventData.timezone : localStorage.getItem("timezone");
		const message = {
			message: customMessage || notificationMessage,
			from_user: this.userData.userId,
			send_at: this.notificationDateService.getTimeStampFromDateNow(new Date(), timezone),
			send_from_user_name: this.userData.userData.displayName,
			send_from_user_photo: this.userData.userData.photoUrl,
			eventId: this.SEventData.eventId,
			message_picture: "",
			appointment: appointment.toDatabaseFormat()
		} as ChatMessage;

		const chatId = await this.gdService.getOrCreateChat(
			this.SEventData.eventId,
			this.userData.userId,
			appointment.getOtherUser(this.userData.userId).uid
		);

		await this.gdService.addMessage(this.SEventData.eventId, chatId, message, false);

		// await this.notificationsService.sendNotification(
		// 	this.SEventData.eventId,
		// 	notificationMessage,
		// 	message.send_from_user_name,
		// 	[appointment.getOtherUser(this.global.user.uid).uid]
		// );
	}

	async createVisioForAppointment(appointment: AppointmentTimeSlot) {
		const startDate = DateTime.local().toISO();
		const endDate = DateTime.local().plus({ month: 1 }).toISO();
		const meetingData = {
			roomNamePrefix: `/${this.SEventData.eventId}`,
			roomMode: "normal",
			startDate,
			endDate
		};
		const response = await this.wherebyService.createMeetingOnWhereby(meetingData);
		let url: string = response.body.roomUrl;
		const members = [appointment.applicant.uid, appointment.invited.uid];
		const visio = {
			uid: `appointment-${appointment.uid}`,
			startDate,
			endDate,
			url,
			members
		};

        const ref = doc(this.firestore, `events/${this.SEventData.eventId}/appointments/${appointment.uid}`);

		docData(ref).pipe(take(1)).subscribe((doc) => {
            if (!doc.urlVisio) {
                updateDoc(ref, { urlVisio: url });

                this.wherebyService.setVisio(this.SEventData.eventId, visio);
			    this.wherebyService.analyticsNewRoom(this.SEventData.eventId, members);
            } 
            else {
                url = doc.urlVisio;
            }
        })
		

		return url;
	}

	getAppointmentById(appointmentId: string) {
        const ref = doc(this.firestore, `events/${this.SEventData.eventId}/appointments/${appointmentId}`)
        
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject), map((appointment) => {
			return AppointmentTimeSlot.fromDatabaseFormat(appointment);
		}));
	}

	getRuleById(sourceRuleId: string) {
        return this.getModule().pipe(
            mergeMap((module: DocumentData) => {
                const ref = doc(this.firestore, `events/${this.SEventData.eventId}/modules/${module.uid}/timeSlotsRules/${sourceRuleId}`)
                return docData(ref).pipe(map((rule) => {
                    if (!rule) {
                        return null;
                    } else {
                        return AppointmentRule.fromDatabase(rule);
                    }
                }))
            })
        );
	}

	getAppointmentsByUserId(userId: string) {
        const ref = collection(this.firestore, `events/${this.SEventData.eventId}/appointments`);
        const refApplicantQ = query(ref, where("applicant.uid", "==", userId));
        const refInvitedQ = query(ref, where("invited.uid", "==", userId));

        const appointmentsAsApplicantObservable = collectionData(refApplicantQ).pipe(map((aps) => aps.map((ap) => AppointmentTimeSlot.fromDatabaseFormat(ap))));
        const appointmentsAsInvitedObservable = collectionData(refInvitedQ).pipe(map((aps) => aps.map((ap) => AppointmentTimeSlot.fromDatabaseFormat(ap))));

		return combineLatest([appointmentsAsApplicantObservable, appointmentsAsInvitedObservable]).pipe(
			map(([appointmentsAsApplicant, appointmentsAsInvited]) => [
				...appointmentsAsApplicant,
				...appointmentsAsInvited
			])
		);
	}

	async changeTimeSlotStatus(timeSlots: AppointmentTimeSlot[], status: AppointmentTimeSlotStatus) {
		const batch = writeBatch(this.firestore);

		timeSlots.forEach((timeSlot) => {
			if (!timeSlot) {
				throw new Error("Empty appointment");
			}
			
			if (timeSlot.uid) {
                const ref = doc(this.firestore, `events/${this.SEventData.eventId}/appointments/${timeSlot.uid}`);

				if (status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED) {
					batch.delete(ref);
				} else {
					batch.update(ref, { status });
					timeSlot.status = status;
				}
			} else if (status !== AppointmentTimeSlotStatus.TIME_SLOT_ENABLED) {
                const refCol = collection(this.firestore, `events/${this.SEventData.eventId}/appointments`);
                const refDoc = doc(refCol);

				const newTimeSlot = timeSlot.toDatabaseFormat();
				newTimeSlot.status = status;
				newTimeSlot.uid = refDoc.id;
				newTimeSlot.applicant = { uid: this.userData.userId, name: this.userData.userData.name };
				batch.set(refDoc, newTimeSlot);
			}
		});

		const appointments = timeSlots.filter(
			(timeSlot) => timeSlot.isAppointment && timeSlot.applicant && timeSlot.invited
		);

		for (const appointment of appointments) {
			const chatId = await this.gdService.getOrCreateChat(
				this.SEventData.eventId,
				appointment.applicant.uid,
				appointment.invited.uid
			);

            const ref = collection(this.firestore, `events/${this.SEventData.eventId}/chats/${chatId}/messages`);
            const refQ = query(ref, where("appointment.uid", "==", appointment.uid));
            
            collectionData(refQ).pipe(take(1)).subscribe((docs) => {
                docs.forEach((document) => {
                    const refDoc = doc(ref, document.id);
                    batch.update(refDoc, { ["appointment.status"]: status });
                })
            });
		}

		await batch.commit();

		for (const appointment of appointments) {
			await this.sendAppointmentUpdateViaChat(appointment);
		}

		if (status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED) {
			// Reject appointments with same time slot
			for (const appointment of appointments) {
				const apDbFormat = appointment.toDatabaseFormat();
                const ref = collection(this.firestore, `events/${this.SEventData.eventId}/appointments`);
                let qc: QueryConstraint[] = [
                    where("status", "==", AppointmentTimeSlotStatus.APPOINTMENT_PENDING),
                    where("startDateTime", "==", apDbFormat.startDateTime),
                    where("duration", "==", apDbFormat.duration),
                    where("sourceRuleId", "==", appointment.sourceRuleId)
                ];

                let refQ;
                refQ = query(ref, ...[...qc, where("invited.uid", "==", appointment.invited.uid)]);
                const aps1 = (await firstValueFrom(collectionData(refQ))).map((doc) => {
                    return AppointmentTimeSlot.fromDatabaseFormat(doc as AppointmentTimeSlotDatabase);
                });

                refQ = query(ref, ...[...qc, where("invited.uid", "==", appointment.applicant.uid)]);
                const aps2 = (await firstValueFrom(collectionData(refQ))).map((doc) => {
                    return AppointmentTimeSlot.fromDatabaseFormat(doc as AppointmentTimeSlotDatabase);
                });

                refQ = query(ref, ...[...qc, where("applicant.uid", "==", appointment.invited.uid)]);
                const aps3 = (await firstValueFrom(collectionData(refQ))).map((doc) => {
                    return AppointmentTimeSlot.fromDatabaseFormat(doc as AppointmentTimeSlotDatabase);
                });

                refQ = query(ref, ...[...qc, where("applicant.uid", "==", appointment.applicant.uid)]);
                const aps4 = (await firstValueFrom(collectionData(refQ))).map((doc) => {
                    return AppointmentTimeSlot.fromDatabaseFormat(doc as AppointmentTimeSlotDatabase);
                });

				const myAppointments = [...aps1, ...aps2, ...aps3, ...aps4].filter(
					(ap) => ap.applicant.uid === this.userData.userId
				);
				const otherAppointments = [...aps1, ...aps2, ...aps3, ...aps4].filter(
					(ap) => ap.applicant.uid !== this.userData.userId
				);

				if (myAppointments.length > 0) {
					await this.changeTimeSlotStatus(
						myAppointments,
						AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED_AUTOMATICALLY
					);
				}
				if (otherAppointments.length > 0) {
					await this.changeTimeSlotStatus(
						otherAppointments,
						AppointmentTimeSlotStatus.APPOINTMENT_REJECTED_AUTOMATICALLY
					);
				}
			}
		}
	}

	canRequestAppointment(otherUser: DocumentData) {
		return combineLatest([this.getModule(), this.getAvailableTimeSlotsByDayForUser(otherUser)]).pipe(
			map(([appointmentModule, timeSlots]) => {
				if (appointmentModule == null || !appointmentModule.habilitedApp) {
					return false;
				} else {
					return timeSlots.length > 0;
				}
			})
		);
	}

	getModule() {
        const ref = collection(this.firestore, `events/${this.SEventData.eventId}/modules`);
        const refQ = query(ref, ...[where("type", "==", TypeModule.APPOINTMENTS), limit(1)]);

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject), map((m) => { return m[0]; }));
	}

	async getAttendeeModule() {
		return await new Promise<CeuModule>((resolve, reject) => {
			this.daoModules.getModulesEvent(this.SEventData.eventId, (modules: CeuModule[]) => {
				resolve(modules.find((m) => m.type === TypeModule.ATTENDEE));
			});
		});
	}

	private getTimeSlotsRules() {
		return this.getModule().pipe(
			mergeMap((module) => {
				if (!module) {
					return of(new Array<AppointmentRule>());
				}
                const ref = collection(this.firestore, `events/${this.SEventData.eventId}/modules/${module.uid}/timeSlotsRules`);
                return collectionData(ref).pipe(takeUntil(this.Slogout.logoutSubject), map((rulesData) => 
                    rulesData.map((rule) =>
                        AppointmentRule.fromDatabase(rule as AppointmentRuleDatabase)
					)
                ));
			})
		);
	}

	getAllTimeSlotsByDay() {
		const userObservable = this.dbAttendees.getAttendeeByEvent(this.SEventData.eventId, this.userData.userId);
		const rulesObservable = this.getTimeSlotsRules();
		const userTimeSlotsObservable = this.getAppointmentsByUserId(this.userData.userId);
		const appointmentsModuleObservable = this.getModule();
		return combineLatest([
			rulesObservable,
			userTimeSlotsObservable,
			userObservable,
			appointmentsModuleObservable
		]).pipe(
			map(([rules, userTimeSlots, userProfile, appointmentsModule]) => {
				rules = rules.filter((tsr) =>
					tsr.groups.some(
						(groupRule) =>
							Object.keys(userProfile.groups).some((g) => g === groupRule.invitedGroupId) ||
							groupRule.invitedGroupId === AppointmentRuleGroups.ALL_USERS_ID
					)
				);

				let defaultSlots: AppointmentTimeSlot[] = [];
				rules.forEach((tsr) =>
					defaultSlots.push(...new AppointmentRule(tsr.slots, [], tsr.enableVisio).slots.getAllIntervals())
				);
				defaultSlots = defaultSlots.filter((ds) => ds.status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED);

				userTimeSlots.forEach((uts) => {
					const overridingSlotIndex = defaultSlots.findIndex(
						(ds) =>
							(!uts.availableOrPending ||
								(uts.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING &&
									uts.applicant.uid === userProfile.uid)) &&
							uts.startDateTime.equals(ds.startDateTime) &&
							uts.endDateTime.equals(ds.endDateTime) &&
							uts.sourceRuleId === ds.sourceRuleId
					);
					if (overridingSlotIndex > -1) {
						defaultSlots[overridingSlotIndex] = uts;
					} else if (
						!appointmentsModule.hideAppointmentsOnTimeSlotDelete &&
						uts.isAppointment &&
						(uts.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
							(uts.applicant.uid === userProfile.uid &&
								uts.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING))
					) {
						defaultSlots.push(uts);
					}
				});

				const timeSlotsByDay = AppointmentTimeSlotByDay.fromShuffledAppointmentTimeSlots(defaultSlots);
				return timeSlotsByDay;
			})
		);
	}

	getAvailableTimeSlotsByDayForUser(userProfile: DocumentData) {
		const moduleObservable = this.getModule();
		const rulesObservable = this.getTimeSlotsRules();
		const invitedTimeSlotsObservable = this.getAppointmentsByUserId(userProfile.uid);
		const applicantTimeSlotsObservable = this.getAppointmentsByUserId(this.userData.userId);
		const applicantUserObservable = this.dbAttendees.getAttendeeByEvent(
			this.SEventData.eventId,
			this.userData.userId
		);
		const invitedUserObservable = this.dbAttendees.getAttendeeByEvent(this.SEventData.eventId, userProfile.uid);

		return combineLatest([
			moduleObservable,
			rulesObservable,
			invitedTimeSlotsObservable,
			applicantTimeSlotsObservable,
			applicantUserObservable,
			invitedUserObservable
		]).pipe(
			map(
				([
					appointmentModule,
					rules,
					invitedTimeSlots,
					applicantTimeSlots,
					applicantProfile,
					invitedProfile
				]) => {
					if (appointmentModule == null || !appointmentModule.habilitedApp) {
						return [];
					}

					let defaultSlots: AppointmentTimeSlot[] = [];

					const rulesComplyingGroupsContraints = rules.filter((rule) =>
						rule.isComplyingToGroupsConstraints(applicantProfile.groups, invitedProfile.groups)
					);
					rulesComplyingGroupsContraints.forEach((tsr) =>
						defaultSlots.push(
							...new AppointmentRule(tsr.slots, tsr.groups, tsr.enableVisio).slots.getAllIntervals()
						)
					);
					defaultSlots = defaultSlots.filter(
						(ds) => ds.status === AppointmentTimeSlotStatus.TIME_SLOT_ENABLED
					);

					// Removes unavailable timeslots for invited
					defaultSlots = defaultSlots.filter(
						(ds) =>
							!ds.isSlotConflictingWithOthers(invitedTimeSlots, [
								AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED,
								AppointmentTimeSlotStatus.TIME_SLOT_DISABLED
							])
					);

					// Removes timeslots with existing pending appointment requested by invited
					if (appointmentModule.autoHidePendingAppointmentsAvailability) {
						defaultSlots = defaultSlots.filter(
							(ds) =>
								!ds.isSlotConflictingWithOthers(
									invitedTimeSlots.filter((ats) => ats.applicant.uid === invitedProfile.uid),
									[AppointmentTimeSlotStatus.APPOINTMENT_PENDING]
								)
						);
					}

					// Removes unavailable timeslots for applicant
					defaultSlots = defaultSlots.filter(
						(ds) =>
							!ds.isSlotConflictingWithOthers(applicantTimeSlots, [
								AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED,
								AppointmentTimeSlotStatus.TIME_SLOT_DISABLED
							])
					);

					// Removes timeslots with existing pending appointment requested by applicant
					defaultSlots = defaultSlots.filter(
						(ds) =>
							!ds.isSlotConflictingWithOthers(
								applicantTimeSlots.filter((ats) => ats.applicant.uid === applicantProfile.uid),
								[AppointmentTimeSlotStatus.APPOINTMENT_PENDING]
							)
					);
					return AppointmentTimeSlotByDay.fromShuffledAppointmentTimeSlots(defaultSlots);
				}
			)
		);
	}

	initLocale() {
		let langtoUse;
		switch (this.SEventData.getLanguage()) {
			case "pt_BR": {
				langtoUse = localePt;
				break;
			}
			case "en_US": {
				langtoUse = localeEn;
				break;
			}
			case "es_ES": {
				langtoUse = localeEs;
				break;
			}
			case "fr_FR": {
				langtoUse = localeFr;
				break;
			}
			case "de_DE": {
				langtoUse = localeDe;
				break;
			}
		}
		registerLocaleData(langtoUse);
		return this.SEventData.getLanguage().replace("_", "-");
	}

	updateAppointmentFeedback(eventId: string, appointmentId: string, appointment: AppointmentTimeSlot) {
        const ref = doc(this.firestore, `events/${eventId}/appointments/${appointmentId}`);
        const obj = appointment.toDatabaseFormat();
        
        updateDoc(ref, obj);
	}
}
