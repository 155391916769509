import { Component, OnDestroy, OnInit } from '@angular/core';
import { DaoCheckinService } from 'src/app/providers/db/dao-checkin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { MenuController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { TypeVisionCheckin } from 'src/app/enums/type-vision-checkin';
import { TypeTracking } from 'src/app/enums/type-tracking';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-checkin',
    templateUrl: './checkin.page.html',
    styleUrls: ['./checkin.page.scss'],
    providers: [DaoCheckinService]
})

export class CheckinPage implements OnInit, OnDestroy {
    public module = null

    moduleId: string;
    eventId: string;
    checkins: Array<any> = [];
    backBtn: boolean = true;
    searchOpen: boolean = false;
    searchText: string = '';
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    loader: boolean = true;
    language: string;
    subscriptions: Subscription[] = [];
    isMobile: boolean = false;

    constructor(
        private daoCheckin: DaoCheckinService,
        private route: ActivatedRoute,
        private router: Router,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        // private auth: AuthService
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);
        this.SAnalytics.createTrackRoadToModule(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_CHECKIN_MODULE);

        this.loadColors();
        this.language = this.SEventData.getLanguage();
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        this.loadModule();
        // this.global.loadGroups(this.SUserData.userId, this.SUserData.userData.type, this.eventId);
        this.loadCheckins();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }


    loadModule() {
        this.daoCheckin.getModule(this.moduleId, (module) => {
            this.module = module
        });
    }

    loadCheckins() {
        this.daoCheckin.getCheckins(this.moduleId, (checkins) => {
            this.SUserData.getUserDataListener(this.eventId).subscribe((userData) => {
                this.checkins = [];
                for (const checkin of checkins) {
                    if (checkin.visibility) {
                        if (checkin.typeVisionViewer === TypeVisionCheckin.GLOBAL_VISION) {
                            this.checkins.push(checkin);
                        }
                        else {
                            for (const groupId in userData.groups) {
                                if (checkin.viewerGroups[groupId]) {
                                    this.checkins.push(checkin);
                                }
                            }
                        }
                    }
                }
            })
            this.loader = false;
        });
    }

    openDetail(uid) {
        this.router.navigate([`/event/${this.eventId}/checkin-detail/${this.moduleId}/${uid}`]);
    }
}
