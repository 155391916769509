import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoInteractivityService } from 'src/app/providers/db/dao-interactivity.service';
import { DaoQuizService } from 'src/app/providers/db/dao-quiz.service';
import { DaoAskQuestionService } from 'src/app/providers/db/dao-ask-queston.service';
import { Subscription } from 'rxjs';
import { EventColors } from 'src/app/models/event-colors';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';

@Component({
    selector: 'app-interactivity',
    templateUrl: './interactivity.page.html',
    styleUrls: ['./interactivity.page.scss'],
})
export class InteractivityPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    eventId: string = null;
    backBtn: boolean = true;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    isMobile: boolean = false;

    moduleInteractivity = null;
    moduleSurvey = null;
    moduleQuiz = null;
    moduleTraining = null;
    moduleAskQuestion = null;
    moduleWordCloud = null;
    allowButtonsHeader: boolean = false;

    language: string;

    constructor(
        private events: Events,
        private route: ActivatedRoute,
        private router: Router,
        public global: GlobalService,
        private menuCtrl: MenuController,
        private daoInteractivity: DaoInteractivityService,
        private daoQuizService: DaoQuizService,
        private daoAskQuestionService: DaoAskQuestionService,
        private SAnalytics: AnalyticsService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        
        this.loadColors();
        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.allowButtonsHeader = true;
        // }
        this.start();
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    start() {
        this.getQuiz();
        this.getAskQuestion();
        Promise.all([
            this.getInteractivity(),
            this.getSurvey(),
            this.getTraining(),
            this.getWordCloud()
        ])
            .then((response) => {
                this.moduleInteractivity = response[0];
                this.moduleSurvey = response[1][0];
                this.moduleTraining = response[2][0];
                this.moduleWordCloud = response[3][0];
            })
            .catch((e) => {
                console.log(e)
            });
    }

    getInteractivity() {
        return new Promise((resolve) => {
            this.daoInteractivity.getInteractivityModule(this.eventId, (moduleInteractivity) => {
                resolve(moduleInteractivity);
            })
        })
    }

    getSurvey() {
        return new Promise((resolve) => {
            this.daoInteractivity.getSurveyModule(this.eventId, (moduleSurvey) => {
                resolve(moduleSurvey);
            });
        })
    }

    /**
     * Get quiz module
     */
    getQuiz() {
        this.subscriptions.push(this.daoQuizService.getQuizModule(this.eventId).subscribe((modulesQuiz) => {
            if (modulesQuiz.length > 0) {
                this.moduleQuiz = modulesQuiz[0];
            }
        }));
    }

    getTraining() {
        return new Promise((resolve) => {
            this.daoInteractivity.getTrainingModule(this.eventId, (moduleTraining) => {
                resolve(moduleTraining)
            })
        })
    }

    getAskQuestion() {
        this.subscriptions.push(this.daoAskQuestionService.getAskQuestionModule(this.eventId).subscribe((modulesAskQuestion) => {
            if (modulesAskQuestion.length > 0) {
                this.moduleAskQuestion = modulesAskQuestion[0];
            }
        }))
    }

    getWordCloud() {
        return new Promise((resolve) => {
            this.daoInteractivity.getWordCloudModule(this.eventId, (moduleWordCloud) => {
                resolve(moduleWordCloud)
            })
        })
    }

    openPage(url) {
        if ((this.moduleInteractivity && this.moduleInteractivity.answerOffline) || this.SUserData.userLogged()) {
            this.router.navigate([url]);
        }
    }

    ionViewWillLeave() {
        // save another module view access
        this.SAnalytics.moduleAccess(this.eventId, this.moduleInteractivity['uid']);
    }
}
