import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NavParams, LoadingController, ModalController, AlertController, NavController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../providers/authentication/auth.service';
import { TypeUser } from '../models/type-user';
import { GlobalService } from 'src/app/shared/services';
import { CeuEvent } from '../models/ceu-event';
import { TermsOfUseComponent } from '../components/terms-of-use/terms-of-use.component';
import { PrivacyTermsComponent } from '../components/privacy-terms/privacy-terms.component';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';  // todo: update plugin splash-screen
import { environment } from 'src/environments/environment';
import { DaoGeralService } from '../providers/db/dao-geral.service';
import { DaoEventsService } from '../providers/db/dao-events.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserDataService } from '../shared/services/user-data/user-data.service';
import { EventDataService } from '../shared/services/eventData/event-data.service';

@Component({
    selector: 'app-loginp',
    templateUrl: './loginp.page.html',
    styleUrls: ['./loginp.page.scss'],
})
export class LoginpPage implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    displayPublicEventsBtn: boolean = environment.platform.isClientApp;
    loginLogo: string = environment.platform.loginLogo;
    loginLogoSize: string = environment.platform.loginLogoClass;
    redirectUrl: string = null;
    @ViewChild('passwordInput') passwordInput: ElementRef;
    insertPasswordView: boolean = false;
    normalView: boolean = true;
    firstAccessView: boolean = false;
    createPassView: boolean = false;

    msgEmail: string = null;
    msgPassword: string = null;
    msgCode: string = null;
    msgCreateAccount: string = null;
    msgEmailAlreadyUse: string = null;
    msgTermRequired: string = null;
    msgTimeRemaining: string = null;

    count: number;
    interval: any;
    seconds: number = 180;
    timeRemaining;

    email: string = null;
    password: string = null;
    userType: number;

    createPass: string = null;
    confirmPass: string = null;
    userUid: string = null;
    userTempId: string = null;
    codeNumber: string;
    codeNumberTyped: string = null;
    termCheck: boolean;

    container: any;
    clientId: string = null;
    user = null;
    eventId: string = null;
    event: CeuEvent = null;
    activeLoader: boolean = false;
    activeLoader2: boolean = false;
    eventLogo: string = null;
    allowCloseModal: boolean = true;
    emailDisabled: boolean = false;
    constructor(
        public global: GlobalService,
        private navParams: NavParams,
        private translateService: TranslateService,
        private auth: AuthService,
        public loadingCtrl: LoadingController,
        public modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private navCtrl: NavController,
        //private splashScreen: SplashScreen,  // todo: update plugin splash-screen
        private daoGeral: DaoGeralService,
        private daoEvent: DaoEventsService,
        private eventsA: Events,
        private SUserData: UserDataService,
        private SEventData: EventDataService
    ) {
        this.redirectUrl = this.navParams.get('redirectUrl');
        this.eventId = this.navParams.get('eventId');
        // this.global.getEvent(this.eventId, (status) => { });
    }

    ngOnInit() {
        console.log("First file login");
        if (this.eventId) {
            this.SEventData.getEventDataSnapshot(this.eventId).then((eventData) => {
                this.eventLogo = eventData.logo.url;
            });
        }
    }

    /**
     * Unsubscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    /* The first step of login is check e-mail in database to find 
    user (and verify if is first access) */
    verifyEmail() {
        if (this.email !== null) {
            this.activeLoader = true;
            this.email = this.email.toLowerCase();
            this.auth.verifyEmailDb(this.email, (user) => {
                if (user['code'] !== 404) {
                    this.user = user['result'];
                    this.userUid = user['result']['uid'];
                    this.userType = user['result']['type'];
                    this.emailDisabled = true;
                    // verify if type is client or employee to get client id and display correct events later
                    if (this.userType == TypeUser.CLIENT) {
                        this.clientId = user['result']['uid'];
                    } else if (this.userType == TypeUser.EMPLOYEE) {
                        this.clientId = user['result']['clientId'];
                    }
                    // case e-mail exists and is first access, display verify code view;
                    if (user['result']['firstAccess'] == true) {
                        this.normalView = false;
                        this.firstAccessView = true;
                        this.createCodeNumber();
                        this.activeLoader = false;
                    } else if (user['result'] == 'email-not-found') {
                        this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                        this.emailDisabled = false;
                        this.activeLoader = false;
                    } else {
                        this.firstAccessView = false;
                        this.insertPasswordView = true;
                        // this.passwordInput.nativeElement.focus();
                        this.activeLoader = false;
                    }
                } else {
                    this.msgEmail = this.translateService.instant('pages.login.email_not_found');
                    this.activeLoader = false;
                }
            });
        } else {
            this.msgEmail = this.translateService.instant('pages.login.blank_email');
        }

    }

    // case user exists and is not first access, user enter password and make login
    makeLogin() {
        this.msgPassword = null;
        if (this.password !== null && this.password !== undefined) {
            if (this.termCheck == true) {
                this.activeLoader = true;
                this.msgTermRequired = null;
                this.email = this.email.toLowerCase();
                this.auth.login(this.email, this.password, async (data) => {
                    // avancar
                    if (data.code == 'auth/wrong-password') {
                        this.activeLoader = false;
                        this.msgPassword = this.translateService.instant('pages.login.incorrect_pass');
                    } else if (data.user['uid'] !== null) {
                        localStorage.setItem('userIdentifier', data.user['uid']);
                        if (this.eventId !== null && this.eventId !== '' && this.eventId !== undefined) {
                            localStorage.setItem('eventId', this.eventId);
                            this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
                                let eventInterval = setInterval(() => {
                                    if (userData.events) {
                                        clearInterval(eventInterval);
                                        const haveEvent = userData.events.map(function (e) { return e; }).indexOf(this.eventId);
                                        if (haveEvent <= -1) {
                                            // alert, user not have access to event
                                            this.activeLoader = false;
                                            this.auth.removeStorageReferences();
                                            this.msgPassword = this.translateService.instant('pages.login.not_have_access_to_event');
                                        } else if (haveEvent >= 0) {
                                            if (this.allowCloseModal) {
                                                this.allowCloseModal = false;
                                                // this.splashScreen.show();  // todo: update plugin splash-screen
                                                window.location.reload();
                                                // if (!this.global.isBrowser) 
                                                // this.splashScreen.hide();  // todo: update plugin splash-screen
                                                this.close();
                                            }
                                        }
                                    }
                                }, 500);
                            });
                        } else {
                            // this.global.loadService((_) => { });
                            this.redirectUser(data);
                        }
                    } else if (data.code == 'auth/too-many-requests') {
                        this.activeLoader == false;
                        this.msgPassword = this.translateService.instant('pages.login.many_pass_errors');
                    } else if (data.code == 'auth/argument-error') {
                        this.activeLoader == false;
                        this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
                    }
                });
            } else {
                this.activeLoader = false;
                this.msgTermRequired = this.translateService.instant('pages.login.term_check_required');
            }
        } else {
            this.activeLoader == false;
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
        }
    }

    createCodeNumber() {
        // call function to create code, insert in user database and send e-mail to user;
        this.auth.sendCodeNumberToEmail(this.user, (data) => {

            if (data !== false) {
                this.codeNumber = data;
                this.userTempId = this.user['uid'];

                this.count = this.seconds;
                this.interval = setInterval(() => {
                    this.count--;

                    var date = new Date(null);
                    date.setSeconds(this.count); // specify value for SECONDS here
                    this.timeRemaining = date.toISOString().substr(11, 8);

                    if (this.count == 0) {
                        clearInterval(this.interval);
                        this.msgTimeRemaining = this.translateService.instant('pages.login.resend_code_number');
                    }
                }, 1000);
                this.activeLoader2 = false;
            } else {
                this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
            }
        })
    }

    /* in case of first access, the user needs to verify your identity 
    by code number sended to e-mail and create your account */
    verifyCodeNumber() {
        if (this.codeNumberTyped !== null) {
            this.activeLoader = true;
            this.auth.verifyCodeNumber(this.codeNumberTyped, this.userTempId, (data) => {
                if (data['result'] == true) {
                    clearInterval(this.interval);
                    this.firstAccessView = false;
                    this.createPassView = true;
                    this.activeLoader = false;
                } else if (data['result'] == false) {
                    this.msgCode = this.translateService.instant('pages.login.invalid_code');
                    this.activeLoader = false;
                } else {
                    // not ok
                    this.msgCode = this.translateService.instant('pages.login.error_sendemail_code');
                    this.activeLoader = false;
                }
            });
        } else {
            this.msgCode = 'O código não pode estar vazio'
        }
    }

    /* to create account, the user enter your password two times, check both 
    is equal and add to Firebase Authentication and update in Firestore */
    createAccount() {
        this.msgPassword = null;
        if (this.createPass.length >= 6) {
            if (this.createPass == this.confirmPass) {
                this.activeLoader = true;
                this.email = this.email.toLowerCase();
                this.auth.createAccount(this.email, this.createPass, this.userTempId, this.userType).then((success) => {
                    if (success['message'] == 'error') {
                        if (success['code'] == "auth/email-already-in-use") {
                            this.activeLoader = false;
                            this.msgEmailAlreadyUse = this.translateService.instant('pages.login.email_already_use');
                        } else {
                            this.activeLoader = false;
                            this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                        }
                    } else if (success['message'] == 'success') {
                        // call API sending old uid and new uid; 
                        this.password = this.createPass;
                        this.makeLogin();
                    }
                }, (error) => {
                    this.activeLoader = false;
                    this.msgCreateAccount = this.translateService.instant('pages.login.pass_error');
                })
            } else {
                this.activeLoader = false;
                this.msgPassword = this.translateService.instant('pages.login.password_not_match');
            }
        } else {
            this.activeLoader = false;
            this.msgPassword = this.translateService.instant('pages.login.invalid_pass');
        }
    }

    async openLoadingContainer() {
        if (!this.container) {
            this.container = await this.loadingCtrl.create({
                spinner: 'crescent'
            });
            this.container.present();
        }
    }

    async closeLoadingContainer() {
        if (this.container) {
            await this.container.dismiss();
            this.container = null;
        }
    }

    redirectUser(data) {
        if (this.userType == TypeUser.SUPERGOD || this.userType == TypeUser.GOD) {
            this.navCtrl.navigateRoot(['/admin-events']);
        } else if (this.userType == TypeUser.CLIENT || this.userType == TypeUser.EMPLOYEE) {
            this.navCtrl.navigateRoot(['/c-events', this.clientId]);
        } else if (this.userType == TypeUser.SPEAKER || this.userType == TypeUser.ATTENDEE) {
            if (this.user.events.length == 1) {
                this.daoGeral.loadUser(this.user['uid'], this.userType, this.user.events[0]).pipe(
                    take(1)
                ).subscribe((user) => {
                    this.redirectUserToEvent(this.user.events[0]);
                });
            } else {
                this.navCtrl.navigateRoot(['/user-events', data.user['uid']]);
            }
        }
    }

    firstAccess: boolean = true;
    redirectUserToEvent(eventId: string) {
        localStorage.setItem('eventId', eventId);
        this.global.previousPage = 'container';

        this.subscriptions.push(this.daoEvent.getEvent(eventId).subscribe((event) => {
            this.SUserData.getUserDataSnapshot(eventId).then((userData) => {
                if (!this.firstAccess) { return; }

                this.firstAccess = false;

                if (event.required_edit_profile == false) {
                    this.navCtrl.navigateRoot([event.homePage])
                        .then((_) => {
                            if (_ == true) {
                                this.eventsA.publish('updateScreen');
                            }
                        });
                } else if (event.required_edit_profile == true && userData.edited_profile == true) {
                    this.navCtrl.navigateRoot([event.homePage])
                        .then((_) => {
                            if (_ == true) {
                                this.eventsA.publish('updateScreen');
                            }
                        });
                } else if (event.required_edit_profile == true && (userData.edited_profile == false || userData.edited_profile == null)) {
                    this.global.cantGoBackFromProfile = true;
                    this.navCtrl.navigateRoot([`/event/${eventId}/edit-profile/${userData.moduleId}/${userData.type}/${userData.uid}`])
                        .then((_) => {
                            if (_ == true) {
                                this.eventsA.publish('updateScreen');
                            }
                        });
                }
            });
        }))
    }

    async openTermsOfUse() {
        const modal = await this.modalCtrl.create({
            component: TermsOfUseComponent
        });
        return await modal.present();
    }

    async openPrivacy() {
        const modal = await this.modalCtrl.create({
            component: PrivacyTermsComponent
        });
        return await modal.present();
    }

    async recoveryPassword() {
        const prompt = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: this.translateService.instant('pages.login.recovery_pass_txt'),
            inputs: [
                {
                    name: 'email',
                    placeholder: this.translateService.instant('pages.login.your_email_plc'),
                    value: this.email,
                    type: 'email'
                },
            ],
            buttons: [
                {
                    text: this.translateService.instant('global.buttons.cancel'),
                    handler: data => {
                        // cancel operation
                    }
                },
                {
                    text: this.translateService.instant('pages.login.recovery_btn'),
                    handler: data => {
                        // check e-mail and recovery password
                        if (data.email !== null && data.email !== undefined && data.email !== null) {
                            this.auth.checkAuthEmail(data.email, (status) => {
                                if (status) {
                                    this.auth.recoveryPassword(data.email, (status) => {
                                        if (status == true) {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_success'))
                                        } else {
                                            this.alertRecoveryPass(this.translateService.instant('pages.login.recovery_fail'))
                                        }
                                    });
                                } else {
                                    // e-mail não encontrado
                                    this.alertRecoveryPass(this.translateService.instant('pages.login.email_not_found'))
                                }
                            })
                        } else {
                            // blank email error
                            this.alertRecoveryPass(this.translateService.instant('pages.login.blank_email'));
                        }
                    }
                }
            ]
        });
        prompt.present();
    }

    async alertRecoveryPass(message) {
        const alert = await this.alertCtrl.create({
            header: this.translateService.instant('pages.login.recovery_pass_title'),
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    async close() {
        let modal = await this.modalCtrl.getTop();
        modal.dismiss();
    }

    ionViewWillLeave() {
        this.eventId = null;
        this.event = null;
    }

}
