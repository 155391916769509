import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, DocumentData, query, where, orderBy, collectionSnapshots, doc, docData } from '@angular/fire/firestore';
import { filter, firstValueFrom, map, Observable, of, startWith, switchMap, takeUntil, tap } from 'rxjs';
import { TypeModule } from 'src/app/enums/type-module';
import { LogoutService } from '../logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class ModuleDataService {
    private modules: DocumentData[] = null;
    private sharedObs: Observable<DocumentData[]> = null;

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    private getSharedObs(eventId: string) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, orderBy('order'));

        if (this.sharedObs != null) {
            return this.sharedObs;
        }

        return collectionSnapshots(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            filter((data) => {
                return !data[0].metadata.fromCache
            }),
            switchMap((docs) => {
                const modules = docs.map((m) => { return m.data(); });
                return of(modules);
            }),
            tap({
                next: (docs) => { this.modules = docs; },
                complete: () => { this.modules = null; }
            })
        );
    }

    public getListener(eventId: string, predicate?) {
        const obs = (this.modules == null) ?
            this.getSharedObs(eventId) :
            this.getSharedObs(eventId).pipe(startWith(this.modules));

        return (predicate == null) ?
            obs :
            obs.pipe(map(docs => docs.filter(predicate)));
    }

    public getSnapshot(eventId: string, predicate?) {
        return firstValueFrom(this.getListener(eventId, predicate));
    }

    public fetchData(eventId: string): void {
        this.getSnapshot(eventId).catch(error => console.error(error));
    }

    /**
     * @description fetch the first matched module id for the provided module type
     * @param eventId 
     * @param type 
     */
    public getModuleId(eventId: string, type: TypeModule): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.getSnapshot(eventId).then((modules) => {
                const module = modules.find((m) => { return m.type == type; });
                
                if (module) { resolve(module.uid); }
                else { reject("there isnt any module of this type"); }
            })
        });
    }

    public getSingleModuleListener(moduleId: string, eventId?: string) {
        const path = (eventId == null) ?
            `modules/${moduleId}`:
            `events/${eventId}/modules/${moduleId}`;
        
        const ref = doc(this.firestore, path);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
    }

    public getSingleModuleSnapshot(moduleId: string, eventId?: string) {
        return firstValueFrom(this.getSingleModuleListener(moduleId, eventId));
    }
}
