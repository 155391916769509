import { Injectable } from '@angular/core';
import { Browser, OpenOptions } from '@capacitor/browser';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class InAppBrowserService {

    constructor(private platform: Platform) { }

    /**
     *  @description
     */
    async openLink(url: string) {
        const pattern = new RegExp('^(https?|ftp)://');
        if (!pattern.test(url)) { url = 'https://' + url; }

        if (this.platform.is("mobile")) {
            let options: OpenOptions = {
                url: url,
                //windowName: "_system"
            }
            await Browser.open(options);
        } else {
            window.open(url, "_blank");
        }
    }
}
