import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { MenuController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute } from '@angular/router';
import { DaoCustomPageService } from 'src/app/providers/db/dao-custom-page.service';
import { Router } from '@angular/router';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-custom-pages',
    templateUrl: './custom-pages.page.html',
    styleUrls: ['./custom-pages.page.scss'],
})
export class CustomPagesPage implements OnInit, OnDestroy {
    public module = null
    backBtn: boolean = true;

    eventId: string = null;
    moduleId: string = null;
    isMobile: boolean = false;

    eventColors: EventColors = new EventColors();

    menuBadge: number = 0;
    pages = [];
    loader: boolean = true;
    subscriptions: Subscription[] = [];
    language: string;

    constructor(
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private SAnalytics: AnalyticsService,
        private route: ActivatedRoute,
        private router: Router,
        private zone: NgZone,
        private dbCustomPage: DaoCustomPageService,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        const params = this.route.snapshot.params;

        this.eventId = params.eventId;
        this.moduleId = params.moduleId;
        // this.menuCtrl.enable(true);

        this.loadColors();
        this.language = this.SEventData.getLanguage();

        // pick up notification module information
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.zone.run(() => {
                this.menuBadge = this.global.notificationBadge;
            })
        });
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
        
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }

        // computes one access in the analytics
        this.SAnalytics.moduleAccess(this.eventId, this.moduleId);

        this.loadModule();
        this.loadPages();
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    loadModule() {
        this.dbCustomPage.getModule(this.moduleId, (module) => {
            this.module = module;
        });
    }

    loadPages() {
        this.dbCustomPage.getPages(this.moduleId, (pages) => {
            this.zone.run(() => {
                this.pages = pages;
                this.loader = false;
                console.log(this.pages)
            });
            // for(let page of pages) {
            //   this.pages.push(page);
            // }

        })
    }

    openPage(page) {
        this.router.navigate([`/event/${this.eventId}/page-booth/${page.moduleId}/pageId/${page.uid}`])

    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }


    styleIndex: number = 0;    // determina qual  o background color das default imgs
    bgColors: Array<string> = ['#FA0101', '#FAC401', '#0010AD', '#00AD31', '#FA5D00', '#6C00AD', '#0085AD'];

    // determina qual é a background color das default imgs 
    // recebe um array com codigos em hexadecimal de cores (bgColors), um index para determinar a cor no array (styleIndex) e o index das pastas 
    // retorna uma string do codigo da cor bgColors[styleIndex]
    defaultImgBgStyle(index: number) {
        if (index == 0 && this.styleIndex != 0) {
            // Essa condição permite que no caso da ultima pasta da galeria ser customImg, 
            // quando o componente recarrega/recebe novamente o valor do array pages
            // o elemento do index == 0 deve sempre corresponder a primeira cor do array bgColors
            this.styleIndex = 0;
        }

        let color = this.bgColors[this.styleIndex];

        if ((index == this.pages.length - 1) || (this.styleIndex == (this.bgColors.length - 1))) {
            // quando o valor de index corresponde ao ultimo elemento do array pages ou
            // o valor de styleIndex é igual ao ultimo index do array bgColors
            // o valor de styleIndex é resetado
            this.styleIndex = 0;
        }
        else {
            this.styleIndex++;
        }

        return color;
    }
}
