import { SelectLoginPage } from "src/app/select-login/select-login.page";
import { LoginPage } from "src/app/login/login.page";
import { AdminEventsPage } from "src/app/admin-events/admin-events.page";
import { ClientEventsPage } from "src/app/client-events/client-events.page";
import { EventPage } from "src/app/modules/event/event.page";
import { WidgetsPage } from "src/app/modules/widgets/widgets.page";
import { AttendeesPage } from "src/app/modules/attendees/attendees.page";
import { SpeakersPage } from "src/app/modules/speakers/speakers.page";
import { SchedulePage } from "src/app/modules/schedule/schedule.page";
import { ScheduleDetailPage } from "src/app/modules/schedule-detail/schedule-detail.page";
import { LocationPage } from "src/app/modules/location/location.page";
import { ProfilePage } from "src/app/modules/profile/profile.page";
import { UserEventsPage } from "src/app/user-events/user-events.page";
import { PersonalPagePage } from "src/app/modules/personal-page/personal-page.page";
import { ProfileEditPage } from 'src/app/modules/profile-edit/profile-edit.page';
import { SplashPage } from "src/app/splash/splash.page";
import { PublicEventsPage } from "src/app/public-events/public-events.page";
import { GalleryPage } from "src/app/modules/gallery/gallery.page";
import { CheckinPage } from "src/app/modules/checkin/checkin.page";
import { CheckinDetailPage } from "src/app/modules/checkin-detail/checkin-detail.page";
import { GalleryFolderPage } from "src/app/modules/gallery-folder/gallery-folder.page";
import { DocumentsFolderPage } from "src/app/modules/documents-folder/documents-folder.page";
import { SurveysListPage } from "src/app/modules/surveys-list-page/surveys-list-page.page";
import { SurveyPage } from "src/app/modules/survey/survey.page";
import { NotificationsComponent } from "src/app/modules/notifications/notifications.component";
import { TermsOfUseComponent } from "src/app/components/terms-of-use/terms-of-use.component";
import { PrivacyTermsComponent } from "src/app/components/privacy-terms/privacy-terms.component";
import { InteractivityPage } from "src/app/modules/interactivity/interactivity.page";
import { FeedNewsPage } from "src/app/modules/feed-news/feed-news.page";
import { FeedCommentComponent } from "src/app/components/feed-comment/feed-comment.component";
import { ChatListComponent } from "src/app/components/chat-list/chat-list.component";
import { ChatPage } from "src/app/modules/chat/chat.page";
import { ChatHistoryPage } from "src/app/modules/chat/chat-history/chat-history.page";
import { TermsNPrivacyComponent } from "src/app/components/terms-n-privacy/terms-n-privacy.component";
import { MapsListPage } from "src/app/modules/maps-list/maps-list.page";
import { MapDetailPage } from "src/app/modules/map-detail/map-detail.page";
import { RankingPage } from "src/app/modules/ranking/ranking.page";
import { SelfCheckinPage } from "src/app/modules/self-checkin/self-checkin.page";
import { PageBoothPage } from 'src/app/modules/page-booth/page-booth.page'
import { PublicLoginComponent } from "src/app/components/public-login/public-login.component";
import { PublicRegisterComponent } from "src/app/components/public-register/public-register.component";
import { CustomPagesPage } from "src/app/modules/custom-pages/custom-pages.page"
import { QuizListPage } from "src/app/modules/quiz-list-page/quiz-list-page.page";
import { QuizPage } from "src/app/modules/quiz/quiz.page";
import { NotifysBadgesComponent } from "src/app/components/notifys-badges/notifys-badges.component";
import { AskQuestionListPage } from "src/app/modules/ask-question-list/ask-question-list.page";
import { WordCloudListPage } from "src/app/modules/word-cloud-list/word-cloud-list.page";
import { WordCloudPage } from "src/app/modules/word-cloud/word-cloud.page";
import { LightboxImgComponent } from "src/app/components/lightbox-img/lightbox-img.component";
import { LoginpPage } from "src/app/loginp/loginp.page";
import { GamificationPage } from 'src/app/modules/gamification/gamification.page';
import { LoginWithCodeConfirmComponent } from "src/app/components/login-with-code-confirm/login-with-code-confirm.component";
import { LoginWithoutCodeConfirmComponent } from "src/app/components/login-without-code-confirm/login-without-code-confirm.component";
import { LoginWithShortcodeComponent } from "src/app/components/login-with-shortcode/login-with-shortcode.component";
import { FilterByComponent } from "src/app/components/filter-by/filter-by.component";
import { HotsiteComponent } from "src/app/hotsite/hotsite.component";

export class PathComponent {
    static start = SplashPage;
    static select_login = SelectLoginPage;
    static login = LoginPage;
    static loginp = LoginpPage;
    static admin_events = AdminEventsPage;
    static client_events = ClientEventsPage;
    static user_events = UserEventsPage;
    static personal_page = PersonalPagePage;
    static profile_edit = ProfileEditPage;
    static public_events = PublicEventsPage;

    // MODULES
    static event = EventPage;
    static widget = WidgetsPage;
    static attendees = AttendeesPage;
    static speakers = SpeakersPage;
    static schedule = SchedulePage;
    static schedule_detail = ScheduleDetailPage;
    static location = LocationPage;

    static profile = ProfilePage;

    static gallery = GalleryPage;
    static gallery_folder = GalleryFolderPage;
    static documents_folder = DocumentsFolderPage;
    static checkin = CheckinPage;
    static checkin_detail = CheckinDetailPage;

    static interactivity = InteractivityPage;
    static surveys_list = SurveysListPage;
    static survey = SurveyPage;
    static quiz_list = QuizListPage;
    static quiz = QuizPage;
    static ask_question_list = AskQuestionListPage;
    static word_cloud_list = WordCloudListPage;
    static word_cloud = WordCloudPage;
    static notifications = NotificationsComponent;
    static terms_of_use = TermsOfUseComponent;
    static privacy_terms = PrivacyTermsComponent;
    static feed_news = FeedNewsPage;
    static feed_comments = FeedCommentComponent;
    static chat_list = ChatListComponent;
    static chat = ChatPage;
    static chat_history = ChatHistoryPage;
    static terms_n_privacy = TermsNPrivacyComponent;
    static maps_list = MapsListPage;
    static map_detail = MapDetailPage;
    static ranking = RankingPage;
    static self_checkin = SelfCheckinPage;
    static booth_page = PageBoothPage
    static public_login = PublicLoginComponent;
    static public_register = PublicRegisterComponent;
    static custom_pages = CustomPagesPage
    static notifys_component = NotifysBadgesComponent;
    static lightbox_img = LightboxImgComponent
    static gaming = GamificationPage;
    static login_with_code_confirm = LoginWithCodeConfirmComponent;
    static login_without_code_confirm = LoginWithoutCodeConfirmComponent;
    static login_with_shortcode = LoginWithShortcodeComponent;
    static filter_by = FilterByComponent;
    static hotsite = HotsiteComponent;
    // LOADING PAGE
    // static loading = LoadingPage;

    // ERROR PAGE
    // static error = ErrorPage;
}
