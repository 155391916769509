import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, docData, query, where } from '@angular/fire/firestore';
import { firstValueFrom, takeUntil } from 'rxjs';
import { TypeModule } from 'src/app/enums/type-module';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';

@Injectable({
    providedIn: 'root'
})
export class DaoInteractivityService {

    constructor(
        private firestore: Firestore,
        private Slogout: LogoutService
    ) { }

    /**
     * Get 
     * @param eventId 
     * @param onResolve 
     */
    getInteractivityModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.INTERACTIVITY));

        collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject)).subscribe((docs) => {
            if (docs.length > 1) { console.log(`>>> there is more than one interactivity module`) }
            onResolve(docs[0]);
        });
    }

    getSurveyModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.SURVEY));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getQuizModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.QUIZ));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            })
    }

    getTrainingModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.TRAINING));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            });
    }


    getAskQuestionModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.ASK_QUESTION));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            });
    }

    getWordCloudModule(eventId: string, onResolve) {
        const ref = collection(this.firestore, `events/${eventId}/modules`);
        const refQ = query(ref, where('type', '==', TypeModule.WORDCLOUD));

        firstValueFrom(collectionData(refQ))
            .then((snapshot) => {
                onResolve(snapshot);
            })
            .catch((e) => {
                onResolve(e);
            });
    }
}
