import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { BackButtonComponent } from 'src/app/components/back-button/back-button.component';

@NgModule({
    declarations: [BackButtonComponent],
    imports: [
        IonicModule,
        CommonModule,
    ],
    providers: [],
    exports: [BackButtonComponent]
})
export class BackButtonModule { }
