import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { AlertController, Platform, MenuController, ModalController, ToastController } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CeuAttendee } from 'src/app/models/ceu-attendee';
import { DaoCheckinService } from 'src/app/providers/db/dao-checkin.service';
import { GlobalService } from 'src/app/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { TypeVisionCheckin } from 'src/app/enums/type-vision-checkin';
import { PathComponent } from 'src/app/models/path/path-components';
import { BarcodeScannerService } from 'src/app/providers/barcode-scanner/barcode-scanner.service';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-checkin-detail',
    templateUrl: './checkin-detail.page.html',
    styleUrls: ['./checkin-detail.page.scss'],
    providers: [DaoCheckinService]
})

export class CheckinDetailPage implements OnInit, OnDestroy {
    public module = null

    checkinId: string;
    symplaCheckin: boolean = false;
    moduleId: string;
    eventId: string;
    attendees: Array<CeuAttendee> = [];
    attendeesPresent: number = 0;
    attendeesAway: number = 0;
    loader: boolean = true;
    scanning: boolean = false;
    attendeeChange: CeuAttendee;
    public subscription;
    // http
    public headers;
    public requestOptions;
    backBtn: boolean = true;
    searchOpen: boolean = false;
    searchText: string = '';
    cancelTxt: string = null;
    enableChangeOrder: boolean = false // controls the changeOrder function
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    typeOrder: string = null;
    typeVisionListing: TypeVisionCheckin;
    groups = {};

    firstAccessRealtime: boolean = true;
    allAttendeesList: Array<CeuAttendee> = [];
    isMobile: boolean = false;
    showOrderList = false;
    subscriptions: Subscription[] = [];
    language: string;

    constructor(
        private route: ActivatedRoute,
        private DaoCheckin: DaoCheckinService,
        public alertController: AlertController,
        public platform: Platform,
        private router: Router,
        public global: GlobalService,
        private events: Events,
        private menuCtrl: MenuController,
        private STranslate: TranslateService,
        private zone: NgZone,
        private modalCtrl: ModalController,
        public scan: BarcodeScannerService,
        private SEventData: EventDataService,
        private toast: ToastController
    ) {
        this.menuCtrl.enable(true);
        const params = this.route.snapshot.params;
        this.checkinId = params.checkinId;
        if (this.checkinId == "sympla_checkin") {
            this.symplaCheckin = true;
        }
        this.moduleId = params.moduleId;
        this.eventId = params.eventId;
        this.loadColors();

        this.language = this.SEventData.getLanguage();

        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.zone.run(() => {
                this.menuBadge = this.global.notificationBadge;
            })
        });

        this.loadModule();
        this.getAttendees();
        // this.events.subscribe('languageUpdate', () => {
        //     this.zone.run(() => {
        //         this.loadModule();
        //         this.getAttendees();
        //     });
        // });

        this.cancelTxt = this.STranslate.instant('global.buttons.cancel');
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        });
        this.subscriptions.push(subscription);
    }


    ngOnInit() {
        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        this.loadModule();
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    ionViewDidEnter() {
        this.subscription = this.platform.backButton.subscribe(() => {
            this.router.navigate([`/events/${this.eventId}/checkin-details/${this.moduleId}/${this.checkinId}`])
        });

        this.DaoCheckin.getCheckin(this.checkinId, this.moduleId, (data) => {
            this.enableChangeOrder = false
            this.typeOrder = data['typeOrder'];
            this.typeVisionListing = (data.typeVisionListing != undefined) ? data.typeVisionListing : TypeVisionCheckin.GLOBAL_VISION;
            this.groups = (data.groups != undefined) ? data.groups : {};
            this.getAttendees();
        })
    }


    /**
     *load the module.
     */
    loadModule() {
        this.DaoCheckin.getModule(this.moduleId, (module) => {
            this.module = module;
        });
    }

    /**
     * carries the participants. 
     */
    getAttendees() {
        this.DaoCheckin.getCheckinAttendees(
            this.eventId,
            this.moduleId,
            this.checkinId,
            (val) => { return val }
        ).then((data) => {
            this.attendees = this.arrangesAttendees(data)
            this.allAttendeesList = this.attendees
            
            const count = this.countOccurrences(this.allAttendeesList);
            this.attendeesPresent = count[0];
            this.attendeesAway = count[1];
            
            this.enableChangeOrder = true
            this.loader = false
        })
    }

    /** *
    *
    * arranges data from participants.
      @param attendees 
    * @return attendees  
    */
    arrangesAttendees(data) {
        this.attendees = []
        for (const attendee of data) {
            var letters = attendee.name.split('');
            var letter = letters[0].toUpperCase();
            attendee.letter = letter;
            attendee.principal_title = attendee.title[this.SEventData.getLanguage()];
            this.attendees.push(attendee)
        }

        return this.attendees
    }

    /** 
     * Count the number of listed attendees that did and didnt made the check 
     * in
     * @return and array with the amount counted, i.e. index 0: present, index 
     * 1: absent
    */
    countOccurrences(attendees: Array<any>) {
        const cont = [0, 0]
        for (const attendee of attendees) {
            if (attendee.checkinStatus) {
                cont[0]++
            } else {
                cont[1]++
            }
        }
        return cont
    }

    /**
     * opens a modal asked about the status change.
     **/
    async confirmAttendeeChangeStatus(attendee: CeuAttendee) {
        if (this.symplaCheckin) return;
        
        this.attendeeChange = attendee;
        const alert = await this.alertController.create({
            header: this.STranslate.instant('global.alerts.change_status'),
            message: this.STranslate.instant('global.alerts.change_status_confirm_msg') + ' ' + attendee.name + '?',
            buttons: [
                {
                    text: this.STranslate.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.changeAttendeeStatus();
                    },
                },
                {
                    text: this.STranslate.instant('global.buttons.no'),
                    handler: (_) => {
                    }
                }
            ]
        });

        await alert.present();
    }

    /**
     * Change attendee status.
     */
    changeAttendeeStatus() {
        let status = this.attendeeChange.checkinStatus ? false : true

        this.DaoCheckin.changeAttendeeStatus(
            status, 
            this.checkinId, 
            this.moduleId, 
            this.attendeeChange.uid, 
            this.attendeeChange.moduleId, 
            this.eventId
        ).then(() => {
            this.successManualAlert(this.attendeeChange.name);
        }).catch(err => {
            this.errorManualAlert(this.attendeeChange.name);
        })
    }

    changeOrder() {
        if (this.typeOrder !== 'asc')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderAsc()
        if (this.typeOrder !== 'desc')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderDesc()
        if (this.typeOrder !== 'present')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderPresent()
        if (this.typeOrder !== 'away')
            this.DaoCheckin.closeRefLoadCheckinGlobalOrderAway()
        if (this.typeOrder !== 'asc')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAsc()
        if (this.typeOrder !== 'desc')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderDesc()
        if (this.typeOrder !== 'present')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderPresent()
        if (this.typeOrder !== 'away')
            this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAway()

        this.getAttendees()
    }

    /**
     * returns the first letter of the participant's name.
     */
    attendeeHeader(attendee, i, attendees) {
        if (i == 0) {
            return attendee.letter;
        } else if (i != 0 && attendee.letter != attendees[i - 1].letter) {
            return attendee.letter;
        }
    }

    /**
     * 
     */
    openQr(): void {
        this.scanning = true;
        this.scan.scanSingleQRCode().then((result) => {
            if (this.symplaCheckin) {
                this.checkinSympla(result)
            } else {
                this.findAttendeeExist(result).then((exist) => {
                    if (exist['status'] === false) {
                        // user not found in this event
                        this.userNotFoundAlert();
                    } else {
                        // user found, change status to true (present);
                        this.changeUserCheckinStatus(exist['user']);
                    }
                });
            }
        }).catch(err => {
            this.scanning = false;
            this.genericErrAlert();
        })
    }

    checkinSympla(code: string) {
        this.DaoCheckin.checkinSympla(this.eventId, code)
            .then(() => {
                this.getAttendees();
                this.successAlert()
            })
            .catch(err => {
                console.error(err);
                this.genericErrAlert();
            }).finally(() => { this.scanning = false; })
    }

    /**
     * Find Attendee Exist In Event.
    */
    findAttendeeExist(uid: string) {
        return new Promise((resolve) => {
            this.DaoCheckin.findAttendeeExistInEvent(this.eventId, uid, (exist) => {
                if (exist.status == false) {
                    resolve({
                        user: null,
                        status: false
                    });
                } else {
                    resolve({
                        user: exist.user,
                        status: true
                    });
                }
            });
        });
    }


    /**
     * Find Attendee with "identifier" Exist In Event.
    */
    findAttendeeExistWithIdentifier(identifier: string) {
        return new Promise((resolve) => {
            this.DaoCheckin.findAttendeeExistInEventWithIdentifier(this.eventId, identifier, (exist) => {
                if (exist.status == false) {
                    resolve({
                        user: null,
                        status: false
                    });
                } else {
                    resolve({
                        user: exist.user,
                        status: true
                    });
                }
            });
        });
    }

    /**
    * Change attendee status.
    */
    changeUserCheckinStatus(user) {
        this.DaoCheckin.changeAttendeeStatus(
            true, 
            this.checkinId, 
            this.moduleId, 
            user.uid, 
            user.moduleId, 
            this.eventId
        ).then(() => {
            this.successAlert();
        }).catch(err => {
            this.userNotFoundAlert();
        }).finally(() => { this.scanning = false; })
    }

    /**
     * emit an alert with a generic error message
     */
    async genericErrAlert() {
        const toast = await this.toast.create({
            header: this.STranslate.instant('global.alerts.error'),
            message: this.STranslate.instant('global.alerts.generic_error'),
        });
        toast.position = "top";
        toast.duration = 1000;
        await toast.present();
    }

    /**
   * shows the success alert on changing status of participants.
   */

    async successAlert() {
        const toast = await this.toast.create({ 
            header: this.STranslate.instant('global.alerts.confirmed_presence')
        });
        toast.position = "top";
        toast.duration = 1000;
        await toast.present();
    }

    /**
    * shows the success alert on changing status of participants.
    */
    async successManualAlert(username: string) {
        const alert = await this.alertController.create({
            header: this.STranslate.instant('global.alerts.all_right'),
            message: this.STranslate.instant('global.alerts.the_status_changed') + ' ' + username + ' ' + this.STranslate.instant('global.alerts.status_changed_successfully'),
            buttons: [
                {
                    text: this.STranslate.instant('global.buttons.ok'),
                    handler: (_) => {
                        //
                        // this.reloadAttendeesWithoutLoader();
                    },
                }
            ]
        });

        await alert.present();
    }


    async errorManualAlert(username: string) {
        const alert = await this.alertController.create({
            header: this.STranslate.instant('global.alerts.error'),
            message: this.STranslate.instant('global.alerts.not_possible_change_status') + ' ' + username + ' ' + this.STranslate.instant('global.alerts.please_tryagain'),
            buttons: [
                {
                    text: this.STranslate.instant('global.buttons.ok'),
                    handler: (_) => {
                        //
                    },
                }
            ]
        });

        await alert.present();
    }


    /**
    * Display the user alert not found in this event.
    */
    async userNotFoundAlert() {
        const alert = await this.alertController.create({
            header: this.STranslate.instant('global.alerts.error'),
            message: this.STranslate.instant('global.alerts.attendee_not_found'),
            buttons: [
                {
                    text: this.STranslate.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.STranslate.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                    }
                }
            ]
        });

        await alert.present();
    }

    async invalidQrAlert() {
        const alert = await this.alertController.create({
            header: this.STranslate.instant('global.alerts.error_scanning'),
            message: this.STranslate.instant('global.alerts.invalid_qrcode'),
            buttons: [
                {
                    text: this.STranslate.instant('global.buttons.yes'),
                    handler: (_) => {
                        this.openQr();
                    },
                },
                {
                    text: this.STranslate.instant('global.buttons.no'),
                    handler: (_) => {
                        //
                    }
                }
            ]
        });

        await alert.present();
    }

    searchBar(ev) {
        if (ev.target.value.length >= 1) {
            let value = ev.target.value.toLowerCase();
            this.attendees = [];
            this.allAttendeesList.filter(item => {
                if (item.name.toLowerCase().includes(value)) {
                    this.attendees.push(item);
                }
            })
        } else {
            this.attendees = this.allAttendeesList;
        }
    }

    ionViewWillLeave() {
        this.subscription.unsubscribe()

        this.DaoCheckin.closeRefLoadCheckinGlobalOrderAsc()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderDesc()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderPresent()
        this.DaoCheckin.closeRefLoadCheckinGlobalOrderAway()

        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAsc()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderDesc()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderPresent()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionOrderAway()

        this.DaoCheckin.closeRefGetModule()
        this.DaoCheckin.closeRefLoadCheckinGlobalAllAttendees()
        this.DaoCheckin.closeRefLoadCheckinGroupVisionAllAttendees()
        this.DaoCheckin.closeRefGetCheckin()
    }

    headerHeightFn(item, index) {
        return (40);
    }

    itemHeightFn(item, index) {
        return (85);
    }

    async showOrderBy() {
        if (this.isMobile) {
            const modal = await this.modalCtrl.create({
                component: PathComponent.filter_by,
                componentProps: {
                    mode: this.isMobile ? 'mobile' : 'desktop',
                    activeChoice: this.typeOrder,
                    comp: 'checkin'
                },
                cssClass: 'modal-order-by'
            });

            modal.onDidDismiss().then(ret => {
                this.typeOrder = ret.data.choice
                this.changeOrder()
            })
            return await modal.present();
        } else {
            this.showOrderList = !this.showOrderList;
        }
    }

    newFilterByValue(value: string) {
        console.log(value)
        if (value !== this.typeOrder) {
            this.typeOrder = value;
            this.showOrderList = false;
            this.changeOrder()

        }
    }
}
