import { Injectable } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { LogoutService } from "src/app/shared/services/logout/logout.service";
import { Firestore, collection, collectionSnapshots } from "@angular/fire/firestore";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DaoCustomTranslationsService {
  constructor(
    private firestore: Firestore,
    private Slogout: LogoutService,
    private translateService: TranslateService,
  ) {}

  getCustomTranslations(eventId: string) {
    const ref = collection(this.firestore, `events/${eventId}/custom-translations`);

    return collectionSnapshots(ref).pipe(takeUntil(this.Slogout.logoutSubject))
  }

  loadCustomTranslations(eventId: string) {
    this.getCustomTranslations(eventId).subscribe((snapshot) => {
      const customTranslations = snapshot.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        return acc;
      }, {});

      const customTranslationsFormatted = this.formatTranslations(customTranslations);

      for (let lang in customTranslationsFormatted) {
        this.translateService.setTranslation(lang, customTranslationsFormatted[lang], true);
      }
    })
  }

  formatTranslations(input: Object): Object {
    const result: Object = {};
  
    for (const [lang, translations] of Object.entries(input)) {
      if (Object.keys(translations).length === 0) {
        result[lang] = {}; // there is no translations for this language
        continue;
      }
  
      result[lang] = this.customTranslationsMapper(translations);
    }
  
    return result;
  }
  
  customTranslationsMapper(raw: Object): Object {
    const translationsMapped: Object = {};
  
    for (const [key, value] of Object.entries(raw)) {
      const keys = key.split('.');
      let currentLevel = translationsMapped;
  
      for (let i = 0; i < keys.length; i++) {
        const currentKey = keys[i];
        if (!currentLevel[currentKey]) {
          currentLevel[currentKey] = (i === keys.length - 1) ? value : {};
        }
        currentLevel = currentLevel[currentKey];
      }
    }
  
    return translationsMapped;
  }
}
