import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GlobalService, AnalyticsService } from "src/app/shared/services";
import { 
    Firestore, 
    doc,
    docData,
    collection,
    query,
    orderBy,
    collectionData,
    QueryConstraint,
    limit,
    startAfter, 
} from '@angular/fire/firestore';
import {
	MenuController,
	ModalController,
	Platform
} from "@ionic/angular";
import { Events } from 'src/app/shared/services/global/events.service';
import { TypeTracking } from "src/app/enums/type-tracking";
import { PathComponent } from "src/app/models/path/path-components";
import { EventColors } from "src/app/models/event-colors";
import { Subscription } from "rxjs";
import { UserDataService } from "src/app/shared/services/user-data/user-data.service";
import { EventDataService } from "src/app/shared/services/eventData/event-data.service";
import { ModuleDataService } from "src/app/shared/services/module-data/module-data.service";
import { DaoAttendeesService } from "src/app/providers/db/dao-attendees.service";

@Component({
	selector: "app-ranking",
	templateUrl: "./ranking.page.html",
	styleUrls: ["./ranking.page.scss"]
})
export class RankingPage implements OnInit, OnDestroy {
	isLoading: boolean = true;
	module = null;
	moduleId: string = null;
	event = null
	eventId: string = null;
	eventColors: EventColors = new EventColors();
	myUser = null;
	isMobile: boolean = false;
    subscriptions: Subscription[] = [];
    language: string;
	mergeInteractivityPoints: boolean = false;
	showOnlyAttendeeWithPoints: boolean = false;
	attendees = [];
	attendeesToShow = [];

	constructor(
		private route: ActivatedRoute,
		public global: GlobalService,
		private firestore: Firestore,
		private events: Events,
		private menuCtrl: MenuController,
		private SAnalytics: AnalyticsService,
		private modalCtrl: ModalController,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
		private platform: Platform,
		private moduleDataService: ModuleDataService,
		private attendeeService: DaoAttendeesService,
	) {
        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.moduleId = params.moduleId;

		this.language = this.SEventData.getLanguage();
        this.loadColors();
	}

	/**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
            document.body.style.setProperty(`--background-clr`, this.eventColors.menu_color);
		    document.body.style.setProperty(`--myUser-color`, this.eventColors.menu_text_color);
        });
        this.subscriptions.push(subscription);
    }

	ngOnInit() {
		if (this.platform.is('ios') || this.platform.is('android')) {
			this.isMobile = true;
		}

		this.getModule();
		this.fetchAttendees();
	}

	ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe; });
    }

	getModule() {
		this.subscriptions.push(this.moduleDataService.getSingleModuleListener(this.moduleId, this.eventId)
		.subscribe(module => {
			this.module = module;
			this.mergeInteractivityPoints = module.mergeInteractivityPoints ? module.mergeInteractivityPoints : false;
			this.showOnlyAttendeeWithPoints = module.showOnlyAttendeeWithPoints ? module.showOnlyAttendeeWithPoints : false;

			this.manageAttendeesToShow();
		}))
	}

	fetchAttendees() {
		const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
        const refQ = query(ref, orderBy("queryName", "asc"));

        const subscription = collectionData(refQ).subscribe((attendees) => {
			this.attendees = this.sortAttendeesByPoints(attendees);

			for (let i = 0; i < this.attendees.length; i++) {
				this.attendees[i].position = i+1;
				if (this.attendees[i].uid == this.SUserData.userId) 
					this.myUser = this.attendees[i]; 
			}

			this.manageAttendeesToShow();
			this.isLoading = false;
		});
        
        this.subscriptions.push(subscription);
	}

	manageAttendeesToShow() {
		if (this.showOnlyAttendeeWithPoints)
			this.attendeesToShow = this.attendeesWithPoints();
		else 
			this.attendeesToShow = this.sortAttendeesByPoints(this.attendees);;
	  }
	
	attendeesWithPoints() {
		return this.attendees.filter(attendee => this.getAttendeeEngagementPoints(attendee) > 0 || this.mergeInteractivityPoints && attendee.points > 0);
	}
	
	sortAttendeesByPoints(attendees) {
		if (!attendees || attendees.length <= 0) return [];

		return attendees.sort((a, b) => { // sorting by engagement points
			const aPoints = this.getAttendeeEngagementPoints(a) + (this.mergeInteractivityPoints ? a.points : 0);
			const bPoints = this.getAttendeeEngagementPoints(b) + (this.mergeInteractivityPoints ? b.points : 0);
			return aPoints > bPoints ? -1 : 1;
		})
	}
	
	getAttendeeEngagementPoints(attendee) {
		if (!attendee.engagementPoints) return 0;
		return Object.values<number>(attendee.engagementPoints).reduce((acc: number, cur: number) => acc + cur, 0);
	}

	// get attendees and points
	// start() {
    //     const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
    //     const refQ = query(ref, orderBy("queryName", "asc"));

    //     const subscription = collectionData(refQ).subscribe((allAttendees) => {
	// 		this.allAttendeesList = [];
	// 		allAttendees = allAttendees.sort(function (a, b) {
	// 			if (a.points === b.points) {
	// 				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
	// 			} else {
	// 				return a.points < b.points ? 1 : -1;
	// 			}
	// 		});
	// 		for (let i = 0; i < allAttendees.length; i++) {
	// 			let attendee = allAttendees[i];
	// 			let position = i + 1;
	// 			attendee.position = position;
	// 			if (attendee.uid == this.SUserData.userId) {
	// 				this.myUser = attendee;
	// 			} else {
	// 				this.allAttendeesList.push(attendee);
	// 			}
	// 		}
	// 	});
        
    //     this.subscriptions.push(subscription);
	// 	this.getFirstAttendees();
	// }

	// getFirstAttendees() {
    //     const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
    //     const qc: QueryConstraint[] = [limit(100), orderBy("points", "desc")]
    //     const refQ = query(ref, ...qc);

    //     const subscription = collectionData(refQ).subscribe((data: any) => {
	// 		data = data.sort(function (a, b) {
	// 			if (a.points === b.points) {
	// 				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0;
	// 			} else {
	// 				return a.points < b.points ? 1 : -1;
	// 			}
	// 		});
	// 		if (data.length > 0) {
	// 			this.lastAttendees = data[data.length - 1];
	// 		}
	// 		this.attendees = [];
	// 		for (let i = 0; i < data.length; i++) {
	// 			let attendee = data[i];
	// 			let position = i + 1;
	// 			attendee.position = position;
	// 			if (attendee.uid !== this.SUserData.userId)
	// 				this.attendees.push(attendee);
	// 			else this.myUser = Object.assign({}, attendee);
	// 		}
	// 		this.loader = false;
	// 	});

    //     this.subscriptions.push(subscription);
	// }

	// getMoreAttendees(event) {
    //     const ref = collection(this.firestore, `events/${this.eventId}/attendees`);
    //     const qc: QueryConstraint[] = [startAfter(this.lastAttendees.name), limit(100), orderBy("points", "desc")]
    //     const refQ = query(ref, ...qc);

    //     const subscription = collectionData(refQ).subscribe((data: any) => {
	// 		data = data.sort(function (a, b) {
	// 			if (a["points"] < b["points"]) {
	// 				return 1;
	// 			}
	// 			if (a["points"] > b["points"]) {
	// 				return -1;
	// 			}
	// 			return 0;
	// 		});
	// 		if (data.length > 0) {
	// 			this.lastAttendees = data[data.length - 1];
	// 		}

	// 		for (let i = 0; i < data.length; i++) {
	// 			let attendee = data[i];
	// 			let position = i + 1;
	// 			attendee.position = position;
	// 			this.attendees.push(attendee);
	// 		}
	// 		this.loader = false;
	// 		if (data.length > 0) {
	// 			this.virtualScroll.checkEnd();
	// 			event.target.complete();
	// 		} else {
	// 			event.target.disabled = true;
	// 		}
	// 	});

    //     this.subscriptions.push(subscription);
	// }

	// searchBar(ev) {
	// 	if (ev.target.value.length >= 1) {
	// 		let value = ev.target.value.toLowerCase();
	// 		this.attendees = [];
	// 		this.allAttendeesList.filter((item) => {
	// 			if (item.name.toLowerCase().includes(value)) {
	// 				this.attendees.push(item);
	// 			}
	// 		});
	// 	} else {
	// 		this.getFirstAttendees();
	// 	}
	// }

	// resitFilter() {
	// 	this.attendees = [...this.allAttendeesList];
	// }

	// async showOrderBy() {
	// 	if (this.isMobile) {
	// 		const modal = await this.modalCtrl.create({
	// 			component: PathComponent.filter_by,
	// 			componentProps: {
	// 				mode: this.isMobile ? "mobile" : "desktop",
	// 				activeChoice: this.filterby
	// 			},
	// 			cssClass: "modal-order-by"
	// 		});

	// 		modal
	// 			.onDidDismiss()
	// 			.then((ret) => (this.filterby = ret.data.choice));
	// 		return await modal.present();
	// 	} else {
	// 		this.showOrderList = !this.showOrderList;
	// 	}
	// }

	// newFilterByValue(value: string) {
	// 	if (value !== this.filterby) {
	// 		this.filterby = value;
	// 		this.showOrderList = false;
	// 	}
	// }
}
