/// <reference types="@types/googlemaps" />
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DaoEventsService } from 'src/app/providers/db/dao-events.service';
import { MenuController, Platform } from '@ionic/angular';
import { Events } from 'src/app/shared/services/global/events.service';
import { GlobalService, AnalyticsService } from 'src/app/shared/services';
import { CeuEvent } from '../../models/ceu-event';
import { EventFieldsVisibility } from 'src/app/models/event-fields-visibility';
import { InAppBrowserService } from 'src/app/providers/in-app-browser/in-app-browser.service';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';

declare let google: any;

@Component({
    selector: 'app-event',
    templateUrl: './event.page.html',
    styleUrls: ['./event.page.scss'],
})
export class EventPage implements OnInit, OnDestroy {
    public eventId: string = null;
    public module = null

    event: CeuEvent = null;
    eventAddress;
    description: any = null;
    language: string = null;
    isMobile: boolean = false;

    dayStartDate: string = null; //print the event start day
    dayEndDate: string = null; //print the event end day

    monthStartDate: string = null; //print the event start month
    monthEndDate: string = null; //print the event end month

    yearStartDate: string = null;
    yearEndDate: string = null;

    loader: boolean = true;
    @ViewChild('map') mapElement;
    map: any;
    mapDisplay: boolean = false;

    backBtn: boolean = true;
    eventColors: EventColors = new EventColors();
    menuBadge: number = 0;
    allowButtonsHeader: boolean = false;

    subscriptions: Subscription[] = [];

    constructor(
        private route: ActivatedRoute,
        private menuCtrl: MenuController,
        public global: GlobalService,
        private events: Events,
        private SAnalytics: AnalyticsService,
        private dbEvent: DaoEventsService,
        private inAppBrowser: InAppBrowserService,
        private dt: DateTimeService,
        public SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.menuCtrl.enable(true);

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        // this.loadColors();
        this.language = this.SEventData.getLanguage();
        this.menuBadge = this.global.notificationBadge;
        this.events.subscribe('menuBadge', () => {
            this.menuBadge = this.global.notificationBadge;
        });
    }

    // /**
    //  * @description get event colors
    //  * @param eventId 
    //  */
    // loadColors(eventId: string = this.eventId) {
    //     this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
    //         this.eventColors = eventData.colors;
    //     })
    // }

    async ngOnInit() {
        this.module = (await this.dbEvent.getModuleEvent(this.eventId));
        this.loadEvent();
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        this.SAnalytics.moduleAccessEvent(this.eventId);

        if (this.global.previousPage == 'container') {
            this.backBtn = false;
        } else {
            this.backBtn = true;
        }
        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.zone.run(() => {
        //         this.allowButtonsHeader = true;
        //     })
        // }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }

    loadEvent() {
        // get data event
        const subscription = this.SEventData.getEventDataListener(this.eventId).subscribe((data: any) => {
            this.event = data;

            if (this.event['eventFields'] === undefined) {
                this.event['eventFields'] = new EventFieldsVisibility();
            }

            this.description = this.event.description[data.language];
            this.eventColors = data.colors;

            // Dates
            const eventSDate: Date = new Date(this.event.startDate);
            const eventEDate: Date = new Date(this.event.endDate);
            
            const doubleDigitDay = (day: number) => {
                return (day < 10) ? `0${day}` : day.toString();
            }

            const getMonthName = (date: Date) => {
                return date.toLocaleString(this.dt.deviceLocale, { month: "long"})
            }

            this.dayStartDate = doubleDigitDay(eventSDate.getDay());
            this.monthStartDate = getMonthName(eventSDate);
            this.yearStartDate = eventSDate.getFullYear().toString();

            this.dayEndDate = doubleDigitDay(eventEDate.getDay());
            this.monthEndDate = getMonthName(eventEDate);
            this.yearEndDate = eventEDate.getFullYear().toString();

            if (data.placeAddress) {
                // this.initMap(data.placeAddress);
            }
            else {
                console.log(`no map`)
            }
        });

        this.subscriptions.push(subscription);

        this.loader = false;
    }

    async initMap(address) {
        // setTimeout(async () => {
            let map;
            let latLng = new google.maps.LatLng(0, 0);
            map = await new google.maps.Map(this.mapElement.nativeElement, {
                center: latLng,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });
            this.searchAddress(map, address);
        // }, 2000);
    }


    searchAddress(map, address) {
        const geoCoder = new google.maps.Geocoder();
        let myResult = null;
        geoCoder.geocode({ 'address': address }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                myResult = results[0].geometry.location; // LatLng

                new google.maps.Marker({
                    position: myResult,
                    map: map,
                    title: 'Localização!'
                });

                map.setCenter(myResult);
                map.setZoom(16);
                this.mapDisplay = true;
            }
        })
    }

    openLink(url: string) {
        this.inAppBrowser.openLink(url);
    }
}
