import { Injectable } from "@angular/core";
import { Observable, of, forkJoin, firstValueFrom } from "rxjs";
import { 
    DocumentData,
    Firestore, 
    collection, 
    collectionData, 
    doc, 
    docData, 
    limit, 
    orderBy, 
    query, 
    runTransaction, 
    setDoc, 
    updateDoc, 
    where, 
    writeBatch,
    arrayUnion,
    serverTimestamp
 } from "@angular/fire/firestore";
import { map, share, startWith, switchMap, take, takeUntil } from "rxjs/operators";
import { Participant } from "src/app/models/participant";
import { CeuModule } from "src/app/models/ceu-module";
import { LogoutService } from "../logout/logout.service";
import { Auth, User, user } from "@angular/fire/auth";
import { UserDataService } from "../user-data/user-data.service";
import { DateTimeService } from "src/app/providers/date-time/date-time.service";

@Injectable({
	providedIn: "root"
})
export class GroupDiscussionsService {
    private userChats: DocumentData[] = null;
    public unreadChats: number = 0;
    private onChat: string = null;

	constructor(
        private firestore: Firestore,
        private auth: Auth,
        private SUserData: UserDataService,
        private Slogout: LogoutService,
        private SDateTime: DateTimeService
    ) { }

    /**
     * Stores the current chat id the user opened, so the incoming 
     * messagens from that chat wont be included in the chats notification 
     * badge 
     * @param chatId 
     */
    public enterChat(chatId: string) {
        this.onChat = chatId;
    }
    
    public leaveChat() {
        this.onChat = null;
    }

	/**
	 * Get discussions groups for specified session
	 * @param eventId
	 * @param sessionId
	 */
	getGroupsForEventForSession(eventId: string, sessionId: string): Observable<DocumentData[]> {
        const ref = collection(this.firestore, `groupDiscussions`);
        const refQ = query(ref, ...[where("disabled", "==", false), where("eventId", "==", eventId)]);

        return collectionData(refQ).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((groups) => {
                const gr = groups.filter((grp) => {
                    return grp.link_sessions == "all" ||
                        grp.sessions.filter((sess) => sess.uid == sessionId).length > 0
                        ? true
                        : false;
                });
                return gr.filter(
                    (gr) =>
                        gr.visibility ||
                        (!gr.visibility && gr.groups.some((x) => Object.keys(this.SUserData.userData.groups).includes(x))) // TODO: remove the usage of property SUserData.userData
                );
            })
        );
	}

	groupDiscussions(eventId: string, moduleId: string): Observable<DocumentData[]> {
        return user(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((u: User) => {
                const ref = collection(this.firestore, `groupDiscussions`);
                const refQ = query(ref, ...[
                    where("eventId", "==", eventId),
					where("moduleId", "==", moduleId),
					where("disabled", "==", false)
                ]);

                return collectionData(refQ, { idField: "id" }).pipe(
                    takeUntil(this.Slogout.logoutSubject),
                    map((gps: DocumentData[]) => {
                        return gps.filter((gr) =>
                                gr.visibility ||
                                (!gr.visibility &&
                                gr.groups.some((x) => Object.keys(this.SUserData.userData.groups).includes(x))) // TODO: remove the usage of property SUserData.userData
                        );
                        //  return gps.filter((gp: GroupDiscussion) => {
                        //         const includes = gp.participants
                        //             .map((p) => p.uid)
                        //             .includes(u.uid);

                        //         return gp.visibility || includes;
                        //     });
                    })
                )
            })
        )
	}

	getUserId(): Promise<string> {
        return firstValueFrom(user(this.auth).pipe(map((u: User) => { return u.uid; })));
	}

	userId(): Observable<string> {
        return user(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((u: User) => { return u.uid; })
        )
	}

	currentUser(): Observable<any> {
        return user(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((u: User) => {
                const ref = doc(this.firestore, `users/${u.uid}`);
                return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
            })
        );
	}

	images(eventId: string, groupId: string) {
        const ref = collection(this.firestore, `events/${eventId}/chats/${groupId}/messages`);
        const refQ = query(ref, where("message_picture", ">", ""));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	messagesAfterLastAccess(eventId: string, groupId: string, timestamp: number) {
        const ref = collection(this.firestore, `events/${eventId}/chats/${groupId}/messages`);
        const refQ = query(ref, where("send_at", ">", timestamp ? timestamp : 0));

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	badgeNotifications(eventId: string, groupId: string): Observable<number> {
		return this.userId().pipe(
			switchMap((u) => {
				return this.chat(eventId, groupId).pipe(
					switchMap((c) => {
						return this.messagesAfterLastAccess(
							eventId,
							groupId,
							c.last_access && c.last_access[u] ? c.last_access[u] : null
						).pipe(map((m) => m.length));
					})
				);
			})
		);
	}

	async getOrCreateChat(eventId: string, userId1: string, userId2: string): Promise<string> {
		const chatId = `${userId1}-${userId2}`;

        const ref1 = collection(this.firestore, `events/${eventId}/chats`);
        const refQ = query(ref1, ...[
            where("uid", "in", [`${userId1}-${userId2}`, `${userId2}-${userId1}`]),
			where("disabled", "==", false)
        ]);

        const document = await firstValueFrom(collectionData(refQ));

		if (document && document.length > 0) {
			return document[0].uid;
		}

        const ref2 = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        await setDoc(ref2, {
            uid: chatId,
            members: [userId1, userId2],
            disabled: false,
            visibility: true
        });

		return chatId;
	}

	currentParticipant(): Observable<Participant> {
        return user(this.auth).pipe(
            takeUntil(this.Slogout.logoutSubject),
            switchMap((u: User) => {
                const ref = doc(this.firestore, `users/${u.uid}`);
                return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
            }),
			map((u) => {
				return new Participant(u.uid, u.name, u.email, u.emailRecovery, false, u.photoUrl);
			})
        );
	}

	groupDiscussion(groupId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `groupDiscussions/${groupId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	module(moduleId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `modules/${moduleId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	event(eventId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `events/${eventId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	chat(eventId: string, chatId: string): Observable<DocumentData> {
        const ref = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        return docData(ref).pipe(takeUntil(this.Slogout.logoutSubject));
	}

	messages(eventId: string, chatId: string) {
        const ref = collection(this.firestore, `events/${eventId}/chats/${chatId}/messages`);
        const refQ = query(ref, ...[orderBy("send_at", "desc"), limit(100)])

        return collectionData(refQ).pipe(takeUntil(this.Slogout.logoutSubject));
	}

    public getChatsListener(eventId: string) {
        const chats$ = (this.userChats == null) ? 
            this.getChatsSharedObs(eventId) : 
            this.getChatsSharedObs(eventId).pipe(startWith(this.userChats));
        
        return chats$;
    }

    // public fetchChats(eventId: string) {
    //     return this.getChatsListener(eventId).pipe(takeUntil()).subscribe();
    // }

	private getChatsSharedObs(eventId: string): Observable<any> {
        if (!this.SUserData.userId) return of([]);

        const ref = collection(this.firestore, `events/${eventId}/chats`);
        const refQ = query(ref, ...[
            where("members", "array-contains", this.SUserData.userId),
            where(`visibility`, `==`, true),
            where(`disabled`, `==`, false),
            orderBy("lastMessage.send_at", "desc")
        ]);

        return collectionData(refQ).pipe(
            share(),
            takeUntil(this.Slogout.logoutSubject),
            switchMap((chats) => {
                if (!chats || chats.length == 0) return of([]);

                const lastMessageUserArrayObs: Observable<any>[] = []; // Array of observables of user for getting their data
                const userAlreadyCheck: string[] = []; // Already added user
                lastMessageUserArrayObs.push(of(chats)); // Add in first chats for further operations

                let count = 0;
                for (let i = 0; i < chats.length; i++) {
                    // Check if chat is a group or normal conv 1 to 1
                    if (chats[i].group && !userAlreadyCheck.includes(chats[i].lastMessage.from_user)) {
                        const ref1 = doc(this.firestore, `users/${chats[i].lastMessage.from_user}`);
                        let newUserObs = docData(ref1).pipe(take(1));

                        lastMessageUserArrayObs.push(newUserObs);
                        userAlreadyCheck.push(chats[i].lastMessage.from_user);
                    } else {
                        // If chat is normal conv 1 to 1 check members for getting other party uid and get his data
                        const otherUids = this.getOtherUsersIds(chats[i].members);
                        if (
                            otherUids.length == 1 &&
                            !userAlreadyCheck.includes(otherUids[0])
                        ) {
                            const ref2 = doc(this.firestore, `users/${otherUids[0]}`);
                            let newUserObs = docData(ref2).pipe(take(1));

                            lastMessageUserArrayObs.push(newUserObs);
                            userAlreadyCheck.push(otherUids[0]);
                        }
                    }

                    if (
                        this.onChat != chats[i].uid &&
                        chats[i].last_access[this.SUserData.userId] &&
                        chats[i].lastMessage.from_user != this.SUserData.userId &&
                        chats[i].lastMessage.send_at > chats[i].last_access[this.SUserData.userId]
                    ) {
                        count++;
                    }
                }
                this.unreadChats = count;

                return forkJoin(lastMessageUserArrayObs).pipe(
                    map((results: any) => {
                        let chatsToPush = [];
                        // In results get we have all chats on index 0 of array and all user data in subsequent index
                        if (results && results.length == 1) {
                            return results[0];
                        } else if (results && results.length > 1) {
                            let chats: any[] = results[0];

                            // For each chats we check all user and link the corresponding one
                            for (let c = 0; c < chats.length; c++) {
                                let chat = chats[c];
                                let allOk = 0;
                                for (let i = 1; i < results.length; i++) {
                                    let user = results[i];

                                    // Check for existing user
                                    if (user && chat.members.includes(user.uid)) {
                                        allOk++;
                                    }

                                    if (
                                        chat.group &&
                                        chat.lastMessage &&
                                        chat.lastMessage.from_user == user.uid
                                    ) { // If chat a group link data of last message user to the chat
                                        chat.lastMessageUser = user;
                                    } else if (!chat.group) {
                                        // If chat a normal conv 1 to 1 link other party data
                                        const otherUids = this.getOtherUsersIds(chat.members);
                                        if (
                                            otherUids.length == 1 &&
                                            user &&
                                            otherUids[0] == user.uid
                                        ) {
                                            chat.lastMessageUser = user;
                                        }
                                    }
                                }

                                if (allOk >= 1) {
                                    chatsToPush.push(chat);
                                }
                            }
                            return chatsToPush;
                        } else {
                            return [];
                        }
                    })
                );
            })
        )
	}

    /**
     * @description get an array of user ids an returns another array with all
     * the ids that diverge from the current users id
     * @param members array of user ids
     * @returns 
     */
    public getOtherUsersIds(members: string[]) {
        if (!members) {
            return [];
        }
        const i = members.indexOf(this.SUserData.userId);
        const arr = [...members];
        if (i > -1) {
            arr.splice(i, 1);
        }
        return arr;
    }

	getChatMembers(eventId: string, chatId: string): Promise<string[]> {
        const ref = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        const docData$ = docData(ref).pipe(
			map((c: any) => c.members)
        );

        return firstValueFrom(docData$);
	}

	public updateLastAccess(eventId: string, chatId: string) {
        const uid = this.SUserData.userId;
        if (!uid) { 
            return Promise.reject("SUserData.userId has invalid value")
        }
        
        const ref = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        
        const time = this.SDateTime.getTimestamp(this.SDateTime.dbTime());
        const data = {};
        data[`last_access.${uid}`] = time;

        return updateDoc(ref, data);
	}

	async addMessage(eventId: string, chatId: string, message: any, group: boolean) {
		const batch = writeBatch(this.firestore);
		
        const chatRef = doc(this.firestore, `events/${eventId}/chats/${chatId}`);
        const messagesRef = collection(this.firestore, `events/${eventId}/chats/${chatId}/messages`);
        const messageRef = doc(messagesRef);


		batch.set(messageRef, { ...message });
		batch.update(chatRef, { lastMessage: { ...message } });

		if (group) {
            const gdRef = doc(this.firestore, `groupDiscussions/${chatId}`);
			const activeParticipants = arrayUnion(message.from_user);

			batch.update(gdRef, {
				activeParticipants: activeParticipants,
				lastMessage: { ...message }
			});
		}

		return batch.commit();
	}

	async updateMutedParticipants(groupId: string, uid: string) {
		const gdRef = doc(this.firestore, `groupDiscussions/${groupId}`);
        return runTransaction(this.firestore, async (t) => {
			const sfDoc = await t.get(gdRef);
			const muted = sfDoc.data().mutedParticipants || [];

			return t.update(gdRef, {
				mutedParticipants: muted.includes(uid) ? muted.filter((u) => u !== uid) : [...muted, uid]
			});
		});
	}

	async updateLastMessage(
		content: string,
		sender: any,
		eventId: string,
		chatId: string,
		type: string
	) {
		const gdRef = doc(this.firestore, `groupDiscussions/${chatId}`);
		const activeParticipants = arrayUnion(sender.uid);
        
		let newMessage = {
			sender: { ...sender },
			eventId,
			chatId,
			content,
			type,
			sentAt: serverTimestamp()
		};
        
        return updateDoc(gdRef, {
			activeParticipants: activeParticipants,
			lastMessage: { ...newMessage }
		});
	}

	getGroupModule(eventId: string, moduleId: string) {
        const ref = doc(this.firestore, `events/${eventId}/modules/${moduleId}`);
        return docData(ref).pipe(
            takeUntil(this.Slogout.logoutSubject),
            map((doc) => {
                return doc as CeuModule;
            })
        )
	}
}
