import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFilterPipe } from '../pipes/search-filter/search-filter';
import { SafeHtmlPipe } from '../pipes/safe-html/safe-html';
import { SortListPipe } from '../pipes/sort-list/sort-list';
import { PathComponent } from '../models/path/path-components';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DaoEventsService } from '../providers/db/dao-events.service';
import { RegexProvider } from '../providers/regex/regex.service';
import { FormatDatePipe } from '../pipes/format-date/format-date.pipe';
import { VideoPipe } from '../pipes/video/video.pipe';
import { BackButtonModule } from '../components/back-button/back-button.module';
import { DocumentsComponent } from '../content/reusable_components/interactivity/documents/documents.component';

@NgModule({
    declarations: [
        SearchFilterPipe,
        SafeHtmlPipe,
        SortListPipe,
        PathComponent.notifys_component,
        FormatDatePipe,
        PathComponent.filter_by,
        VideoPipe,
        DocumentsComponent
    ],
    imports: [
        IonicModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        CommonModule,
        TranslateModule.forChild(),
        BackButtonModule
    ],
    exports: [
        SearchFilterPipe,
        SafeHtmlPipe,
        SortListPipe,
        PathComponent.notifys_component,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FormatDatePipe,
        PathComponent.filter_by,
        VideoPipe,
        BackButtonModule,
        DocumentsComponent
    ],
    providers: [
        DaoEventsService, 
        RegexProvider
    ]
})
export class SharedModule { }
