/// <reference types="@types/googlemaps" />

import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/shared/services';
import { Events } from 'src/app/shared/services/global/events.service';
import { DaoMapsService } from 'src/app/providers/db/dao-maps.service';
import { EventColors } from 'src/app/models/event-colors';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
declare let google: any;

@Component({
    selector: 'app-map-detail',
    templateUrl: './map-detail.page.html',
    styleUrls: ['./map-detail.page.scss'],
    providers: [DaoMapsService]
})
export class MapDetailPage implements OnInit, OnDestroy {
    @ViewChild('map') mapElement;
    map: any;
    eventColors: EventColors = new EventColors();
    eventId: string = null;
    moduleId: string = null;
    mapId: string = null;
    mapName: string = null;
    mapAddress: string = null;
    menuBadge: number = 0;
    loader: boolean = true;
    allowButtonsHeader: boolean = false;
    subscriptions: Subscription[] = [];
    isMobile: boolean = false;
    
    constructor(
        private route: ActivatedRoute,
        public global: GlobalService,
        private events: Events,
        private dbMaps: DaoMapsService,
        private router: Router,
        private zone: NgZone,
        private SEventData: EventDataService,
        private platform: Platform,
    ) {
        this.route.params.subscribe((params) => {
            this.eventId = params.eventId;
            // this.eventId = this.route.parent.params['_value']['eventId'];
            // localStorage.setItem('eventId', this.eventId);
            this.moduleId = params.moduleId;
            // this.moduleId = this.route.snapshot.params['moduleId'];
            this.mapId = params.mapId;
            // this.mapId = this.route.snapshot.params['mapId'];
            this.loadColors();
            // this.events.subscribe('loadColors', () => {
            //     this.loadColors();
            // });
            this.menuBadge = this.global.notificationBadge;
            this.events.subscribe('menuBadge', () => {
                this.zone.run(() => {
                    this.menuBadge = this.global.notificationBadge;
                })
            });
        })
    }

    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;
        })
        this.subscriptions.push(subscription);
    }

    ngOnInit() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.isMobile = true;
        }

        // if (this.router.url == this.SEventData.eventData.homePage) {
        //     this.zone.run(() => {
        //         this.allowButtonsHeader = true;
        //     })
        // }
        this.dbMaps.getMap(this.moduleId, this.mapId, (result) => {
            if (result !== null && result !== undefined) {
                this.mapAddress = result.address;
                this.mapName = result.name;
                this.initMap(this.mapAddress);
            } else {

            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => { sub.unsubscribe(); })
    }

    // start map
    async initMap(address) {
        setTimeout(async () => {
            let map;
            let latLng = new google.maps.LatLng(0, 0);
            map = await new google.maps.Map(this.mapElement.nativeElement, {
                center: latLng,
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
            });
            this.searchAddress(map, address);
            this.loader = false;
        }, 2000);
    }

    // search map address
    searchAddress(map, address) {
        const geoCoder = new google.maps.Geocoder();
        let myResult = null;
        geoCoder.geocode({ 'address': address }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                myResult = results[0].geometry.location; // LatLng

                new google.maps.Marker({
                    position: myResult,
                    map: map,
                    title: 'Localização!'
                });

                map.setCenter(myResult);
                map.setZoom(16);
            } else {
                console.log('not load map')
            }
        })
    }

}
