import { SchedulesActionsTypes, SchedulesActionsUnion } from '../actions/schedules.actions';
import { ISchedulesState } from '../interfaces/schedules.interfaces';

export const initialState: ISchedulesState = {
    scheduleSessions: null
}

export function schedulesReducer(state = initialState, action: SchedulesActionsUnion): ISchedulesState {
    switch (action.type) {
        case SchedulesActionsTypes.GetScheduleSessions: {
            return ({
                ...state,
                scheduleSessions: action.payload
            })
        }

        default: {
            return (state);
        }
    }
}