export class EventColors {
    menu_color: string;
    menu_text_color: string;
    menu_text_color2: string;
    active_menu_item: string;
    title_color: string;
    text_content_color: string;
    link_color: string;
    btn_text_color: string;
    bg_content_color: string;
    bg_general_color: string;
    img_bg: string;

    constructor(
        menu_color: string = null,
        menu_text_color: string = null,
        menu_text_color2: string = null,
        active_menu_item: string = null,
        title_color: string = null,
        text_content_color: string = null,
        link_color: string = null,
        btn_text_color: string = null,
        bg_content_color: string = null,
        bg_general_color: string = null,
        img_bg: string = null
    ) {
        this.menu_color = menu_color; 
        this.menu_text_color = menu_text_color;
        this.menu_text_color2 = menu_text_color2;
        this.active_menu_item = active_menu_item;
        this.title_color = title_color;
        this.text_content_color = text_content_color;
        this.link_color= link_color;
        this.btn_text_color = btn_text_color;
        this.bg_content_color = bg_content_color;
        this.bg_general_color = bg_general_color;
        this.img_bg = img_bg;
    }
}