export class Post {
    uid: string
    moduleId: string
    eventId: string

    img: any = {} // image url stores in firebase storage. name and url
    likes: any = {}
    totalLikes: number = 0;
    comments: any = {};
    totalComments: number = 0;
    description: string
    date: number | string //date of creation
    creator: any //creator of the post. (Can be client, god, supergod or participant)
    fixedTop: number //defines whether the post should be at the top of the feed. (0 for true and 1 for false)
    active: boolean

    constructor(
        uid: string, 
        moduleId: string, 
        eventId: string, 
        img: any, 
        description: string, 
        creator: any, 
        fixedTop: number, 
        active: boolean,
    ) {
        this.uid = uid;
        this.moduleId = moduleId;
        this.eventId = eventId;
        this.img = img;
        this.description = description;
        this.creator = creator;
        this.fixedTop = fixedTop;
        this.active = active;
        
        this.likes = {};
        this.totalLikes = 0;
        this.comments = {};
        this.totalComments = 0;
    }
}
