import { InteractivityActionsTypes, InteractivityActionsUnion } from '../actions/interactivity.actions';
import { IInteractivityState } from '../interfaces/interactivity.interfaces';

export const initialState: IInteractivityState = {
    questionsInteractivity: null,
    surveysInteractivity: null,
    quizsInteractivity: null,
    speakersInteractivity: null,
    documentsInteractivity: null,
    feedbacksInteractivity: null,
    discussionsGroupsInteractivity: null
}

export function interactivityReducer(state = initialState, action: InteractivityActionsUnion): IInteractivityState {
    switch (action.type) {
        case InteractivityActionsTypes.GetQuestions: {
            return ({
                ...state,
                questionsInteractivity: {
                    questionModule: action.payload.questionModule,
                    questions: action.payload.questions,
                    anonymousQuestions: action.payload.anonymousQuestions
                }
            })
        }

        case InteractivityActionsTypes.GetSurveys: {
            return ({
                ...state,
                surveysInteractivity: {
                    surveyModule: action.payload.surveyModule,
                    surveys: action.payload.surveys
                }
            })
        }

        case InteractivityActionsTypes.GetQuizs: {
            return ({
                ...state,
                quizsInteractivity: {
                    quizModule: action.payload.quizModule,
                    quizs: action.payload.quizs
                }
            })
        }

        case InteractivityActionsTypes.GetSpeakers: {
            return ({
                ...state,
                speakersInteractivity: {
                    speakers: action.payload.speakers
                }
            })
        }

        case InteractivityActionsTypes.GetDocuments: {
            return ({
                ...state,
                documentsInteractivity: {
                    documents: action.payload.documents
                }
            })
        }

        case InteractivityActionsTypes.GetFeedbacks: {
            return ({
                ...state,
                feedbacksInteractivity: {
                    feedbacks: action.payload.feedbacks,
                    feedbackModule: action.payload.feedbackModule
                }
            })
        }

        case InteractivityActionsTypes.GetDiscussionsGroups: {
            return ({
                ...state,
                discussionsGroupsInteractivity: {
                    discussionsGroups: action.payload.discussionsGroups
                }
            })
        }

        default: {
            return (state);
        }
    }
}