import { DocumentData } from '@angular/fire/firestore';
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Platform, NavController } from "@ionic/angular";
import { DaoScheduleService } from "src/app/providers/db/dao-schedule.service";
import { DaoEventsService } from "src/app/providers/db/dao-events.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
	GlobalService,
	UtilityService,
	PublicService,
	AnalyticsService,
	IntegrationsService
} from "src/app/shared/services";
import { DaoPersonalAgendaService } from "src/app/providers/db/dao-personal-agenda.service";
import { DaoSurveyService } from "src/app/providers/db/dao-survey.service";
import { Survey } from "src/app/models/survey";
import { DaoSessionFeedbackService } from "src/app/providers/db/dao-session-feedback.service";
import { sessionFeedback } from "src/app/models/sessionFeedback";
import { DaoAskQuestionService } from "src/app/providers/db/dao-ask-queston.service";
import { DaoQuizService } from "src/app/providers/db/dao-quiz.service";
import { Quiz } from "src/app/models/quiz";
import { DaoTrainingService } from "src/app/providers/db/dao-training.service";
import { Training } from "src/app/models/training";
import { Observable, Subscription } from "rxjs";
import { AskQuestion } from "src/app/models/ask-question";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/shared/reducers";
import {
	GetQuestions,
	GetQuizs,
	GetSurveys,
	GetSpeakers,
	GetDocuments,
	GetFeedbacks
} from "src/app/shared/actions/interactivity.actions";
import { DaoModulesService } from "src/app/providers/db/dao-modules.service";
import { sessionIsLastPrev } from "src/app/shared/selectors/schedules.selectors";
import { take } from "rxjs/operators";
import { GroupDiscussionsService } from "src/app/shared/services/group-discussions/group-discussions.service";
import * as _ from "lodash";
import { IIntegration } from "src/app/shared/interfaces/integrations.interface";
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from 'src/app/providers/date-time/date-time.service';
import { EventColors } from 'src/app/models/event-colors';
import { ModuleDataService } from 'src/app/shared/services/module-data/module-data.service';
import { TypeModule } from 'src/app/enums/type-module';
import { UserDataService } from 'src/app/shared/services/user-data/user-data.service';
import { EventDataService } from 'src/app/shared/services/eventData/event-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

type ReactionOptions = 'like' | 'love' | 'rocket' | 'wow' | 'congratulation' | 'amazing';

// interface VideoPlayer {
// 	type: 'youtube' | 'vimeo' | 'iframe';
	// url: SafeResourceUrl;
// }

@Component({
	selector: "app-schedule-detail",
	templateUrl: "./schedule-detail.page.html",
	styleUrls: ["./schedule-detail.page.scss"]
})
export class ScheduleDetailPage implements OnInit, OnDestroy {
	// Video player
	@ViewChild("videoComponent") videoComponent: any;

	subscriptions: Subscription[] = [];
	groupsSubscription: Subscription;
	moduleAgenda: any = null;
	isMobile: boolean = false;

	// parameters
	eventId: string = null;
	moduleId: string = null;
	sessionId: string = null;

	event: any;
	module: any;

	moduleInteractivity: any = null;

	userId: string = null;
	isUserLoggedIn: boolean = false;
	// userType: number = null;
	isSessionSpeaker: boolean = false;
	date: string = "";
	startTime: string = "";
	endTime: string = "";
	locations = [];
	tracks = [];
	speakers: any[] = [];
	documents: any[] = [];
	customFields: any[] = [];
	title: string = "";
	description: string = "";
	videoPlayer: {
        type: 'youtube' | 'vimeo' | 'iframe';
        url: SafeResourceUrl,
        id: string  
    } = null;
	reactions;

    askQuestionVisibility: boolean;

	// survey
	surveyModule: any = null;
	surveys: Array<Survey> = [];

	// quiz
	quizs: Array<Quiz> = [];
	quizModule: any = null;

	trainings: Array<Training> = [];
	public trainingModule = null;
	trainingIcon: string = null;
	trainingIconFamily: string = null;
	trainingModuleId: string = null;

	questions: AskQuestion[] = [];
	questionModule: any = null;

	sessionFeedbackModule: string = null;

	session: DocumentData;
	feedbacks: Array<sessionFeedback> = [];

	// attributes used for the personal agenda module
	qtdAttendees: number = null; //save the number of registered participants in the session.
	habiliedPersonal: boolean = null; //saves the value that verifies that the module is ready to be used in the personal calendar.
	habilitedLimit: boolean = null; //saves the value that checks whether the option to limit the number of sessions added in the personal calendar.
	statePersonalAgenda: boolean = null; //saves the value if the session is part of the user's personal calendar. (yes = true and no = false)

	loadingSend: Array<boolean>;
	loader: boolean = true;

	eventColors: EventColors = new EventColors();
	menuBadge: number = 0;

	language: string;

	fullWidth: boolean = true;

	// Prev and next buttons
	allowNextPrevBtn: boolean = false;
	prevBtn: boolean = false;
	nextBtn: boolean = false;

	allowDiscussionsGroups: boolean = false;
	groupDiscussionName: any;

	urlVisio: string = "";
	onSession: boolean = false;

	playerCurrentTime: number = 0;
	paused: boolean = false;
	videoPlayerActivated: boolean = false;

	integration: IIntegration;

	//isIos: boolean = false;

	subscribeSessionBtn: string;
	unsubscribeSessionBtn: string;
	constructor(
		private store: Store<AppState>,
		private dbSchedule: DaoScheduleService,
		private dbPersonal: DaoPersonalAgendaService,
		private route: ActivatedRoute,
		private router: Router,
		private dbEvent: DaoEventsService,
		public SGlobal: GlobalService,
		private DaoSurvey: DaoSurveyService,
		private DaoQuiz: DaoQuizService,
		private DaoTraining: DaoTrainingService,
		private daoAskQuestion: DaoAskQuestionService,
		private daoFeedback: DaoSessionFeedbackService,
		private SModule: DaoModulesService,
		private SGroupsDiscussions: GroupDiscussionsService,
		private SPublic: PublicService,
		private platform: Platform,
		private SUtility: UtilityService,
		private navCtrl: NavController,
		private SAnalytics: AnalyticsService,
		private SIntegration: IntegrationsService,
		private translateService: TranslateService,
		private dt: DateTimeService,
        private md: ModuleDataService,
        private SUserData: UserDataService,
        private SEventData: EventDataService,
		public sanitizer: DomSanitizer,
	) {
		// this.resetInteractivity();

		// this.menuBadge = this.SGlobal.notificationBadge;
		// this.events.subscribe("menuBadge", () => {
		// 	this.menuBadge = this.SGlobal.notificationBadge;
		// });

		// receives the parameters
		// this.route.params.subscribe((params) => {
		// 	this.eventId = params.eventId;
		// 	this.sessionId = params.sessionId;
		// 	this.moduleId = params.moduleId;

            // commented
			// localStorage.setItem("eventId", this.eventId);

			// if (this.router.getCurrentNavigation().extras.state != undefined) {
			// 	if (this.router.getCurrentNavigation().extras.state.sessionFeedbackModule != null) {
			// 		this.sessionFeedbackModule = this.router.getCurrentNavigation().extras.state.sessionFeedbackModule;
			// 		localStorage.setItem("feedbackModuleId", this.sessionFeedbackModule);
			// 		this.getFeedbacks();
			// 	} else {
			// 		localStorage.setItem("feedbackModuleId", null);
			// 		this.getFeedbacks();
			// 	}
			// } else {
			// 	this.sessionFeedbackModule = localStorage.getItem("feedbackModuleId");
			// 	this.getFeedbacks();
			// }

			//this.menuCtrl.enable(true);
		// });

        const params = this.route.snapshot.params;
        this.eventId = params.eventId;
        this.sessionId = params.sessionId;
        this.moduleId = params.moduleId;

        this.loadColors();
        this.language = this.SEventData.getLanguage();
	}

	async ngOnInit() {
        this.isMobile = this.platform.is("mobile");
		this.isUserLoggedIn = this.SUserData.userLogged();

		const sessionData = this.dbSchedule.getSessionData(this.eventId, this.moduleId, this.sessionId);
		this.subscriptions.push(
			sessionData.subscribe(([session, schedule]) => {
				this.setupSession(session, schedule);
			})
		);
        this.initReactions();

		this.userId = this.SUserData.userId;
		// this.userType = this.SUserData.userData.type;

        const visibility = await this.SEventData.getEventDataSnapshot(this.eventId);
		if (!this.userId && visibility) {
			this.userId = await this.SPublic.createOrGetPublicUserId();
		}

        this.initSession();
		this.SIntegration.getIntegration(this.eventId);

        this.getQuiz();
        this.getFeedbacks();
	}

    /**
     * 
     * @param session firebase firestore document
     * @param schedule firebase firestore document
     */
    setupSession(session: DocumentData, schedule: DocumentData) {
        // title
		this.title = session.name[this.language];
        this.askQuestionVisibility = session.askQuestion;
        if (this.askQuestionVisibility) {
            this.getAskQuestion();
            !this.isMobile && this.changeSessionTab('ask_question');
        }

        // date time
        this.date = this.dt.getDate(session.date, schedule.timezoneType);
        this.startTime = this.dt.getConvertedTime(session.startTime, schedule.timezoneType);
        this.endTime = (typeof session.endTime == "string" && session.endTime.length > 0) ? 
            this.dt.getConvertedTime(session.endTime, schedule.timezoneType) : 
            "--:--";
        
        // tracks
		this.tracks = [];
        for (const uid in session.tracks) {
            this.tracks.push(session.tracks[uid]);
        }

        // description
        this.description = session.descriptions[this.language].trim();
				
        // check if it is youtube or vimeo 
        if (
            session.videoPlayer &&
            (
                !this.videoPlayer ||
                session.videoPlayer.id != this.videoPlayer.id
            )
        ) {
            if (session.videoPlayer.url.includes("/watch?v=")) {
                session.videoPlayer.url = this.sanitizer
                    .bypassSecurityTrustResourceUrl(session.videoPlayer.url.replace('/watch?v=', '/embed/'));
            } else if (session.videoPlayer.url.includes("//youtu.be/")) {
                session.videoPlayer.url = this.sanitizer
                    .bypassSecurityTrustResourceUrl(session.videoPlayer.url.replace('//youtu.be/', '//www.youtube.com/embed/'));
            } else if (session.videoPlayer.url.includes("//vimeo.com/")) {
                session.videoPlayer.url = this.sanitizer
                    .bypassSecurityTrustResourceUrl(session.videoPlayer.url.replace('//vimeo.com/', '//player.vimeo.com/video/'));
            } else {
                session.videoPlayer.url = this.sanitizer
                    .bypassSecurityTrustResourceUrl(session.videoPlayer.url);
            }

            this.videoPlayer = session.videoPlayer;
            this.fetchReactions();
        } 
        // else {
        //     console.log(`session.videoPlayer 2: ${JSON.stringify(session.videoPlayer)}`);
        //     this.videoPlayer = null;
        // }

        // locations
        this.locations = [];

		for (const uid in session.locations) {
			this.locations.push(session.locations[uid]);
		}

		// Sort locations by the order field.
		this.locations.sort(function (a, b) {
			return a.order - b.order;
		});

        // speakers
        this.speakers = [];

        for (const uid in session.speakers) {
			let speakerData = this.getSpeakerPrincipalTitle(
				session.speakers[uid].title,
				session.speakers[uid].description
			);
			session.speakers[uid].principal_title = speakerData.title;
			session.speakers[uid].principal_description = speakerData.description;

			this.speakers.push(session.speakers[uid]);

			if (this.userId == uid) {
				this.isSessionSpeaker = true;
			}
		}

        // Ordering speakers by name.
		this.speakers = this.speakers.sort(function (a, b) {
			return a.queryName < b.queryName ? -1 : a.queryName > b.queryName ? 1 : 0;
		});

        // Dispatch speakers list on store
		this.store.dispatch(
			new GetSpeakers({
				speakers: _.cloneDeep(this.speakers)
			})
		);

        // documents
        this.documents = [];

		for (const uid in session.documents) {
			this.documents.push(session.documents[uid]);
		}

        // Dispatch document list on store
		this.store.dispatch(
			new GetDocuments({
				documents: this.documents
			})
		);

        // Custom fields
		let attendeeCustomFields: any[] = []; 

		// tslint:disable-next-line: forin
		for (const uid in session.customFields) {
			attendeeCustomFields.push(session.customFields[uid])
		}

        // if(this.userId){
		// 	const ref = this.afs
		// 		.collection('events')
		// 		.doc(this.eventId)
		// 		.collection('attendees')
		// 		.doc(this.userId);
	
		// 		ref.get().subscribe(attendee => {
		// 			this.customFields = [];
		// 			if (attendee.exists) {
		// 				const customs = attendee.data().customFields;
		// 				if (customs) {
		// 					customs.forEach(e => {
		// 						if (attendeeCustomFields.some(a => a.uid === e.uid)) {
		// 							this.customFields.push(e);
		// 						}
		// 					})
		// 				}
		// 				console.log(this.customFields)
		// 			}
		// 		})
		// }
        
		// values of the enabledPersonal and enabledLimit attributes
		this.habiliedPersonal = schedule.habiliedPersonal;
		this.habilitedLimit = schedule.habilitedLimit;

		this.subscribeSessionBtn = (schedule.subscribeSessionBtn) ? 
			schedule.subscribeSessionBtn : 
			this.translateService.instant('pages.schedule_detail.favorite');
			// this.translateService.instant('global.texts.addschedule');

		this.unsubscribeSessionBtn = (schedule.unsubscribeSessionBtn) ? 
			schedule.unsubscribeSessionBtn : 
			this.translateService.instant('global.texts.removeschedule');

		this.session = session;

        // if (!this.isMobile) {
        //     if (this.askQuestionVisibility) {
        //         this.changeSessionTab('ask_question');
        //     } else if (this.quizs.length > 0) {
        //         this.changeSessionTab('quiz');
        //     }
        // }
        this.loader = false;
    }

    /**
     * @description attribute initial value to the "reactions" property
     */
    initReactions(): void {
        const reactionsModel = {
            counter: 0,
            userUids: {},
        }
        this.reactions = {
            like: reactionsModel,
            love: reactionsModel,
            rocket: reactionsModel,
            wow: reactionsModel,
            congratulation: reactionsModel,
            amazing: reactionsModel
        };
    }

    // commented
	// ngAfterViewInit(){
    //     //console.log("Win");
    // }

    // commented
	// ionViewWillEnter() {
	// 	this.SVideo.publish({ floating: false });
	// 	this.onSession = true;
	// 	//this.subscriptions.forEach((sub) => sub.unsubscribe());
	// 	this.subscriptions.push(
	// 		this.store.select(getIntegrationsState).subscribe((integration) => {
	// 			this.integration = integration;
	// 		})
	// 	);
	// 	this.getEvent();
	// 	this.initSession();
	// 	this.allowNextPrevBtn = !(this.route.snapshot.paramMap.get("source") === "schedule-personal");
	// }

	// Video player functions
	// publish(t: number) {
	// 	if (t) {
	// 		// only if the video has already been played
	// 		this.SVideo.publish({
	// 			floating: true,
	// 			start: Math.round(t),
	// 			src: this.session.videoPlayer.url,
	// 			type: this.session.videoPlayer.type
	// 		});
	// 	}
	// }

	// onExpanded(value: boolean) {
	// 	console.log(value);
	// }

	/**
	 * Init session
	 */
	async initSession() {
		// Tracks analytics
		this.SAnalytics.accessToSession(this.eventId, this.moduleId, this.sessionId);

		//this.loadSession();
		if (this.userId !== "" && this.userId !== null && typeof this.userId !== "undefined") {
			this.checkAttendeeOfTheSession();
		}
	}

	// leavingSessionForPlayer() {
	// 	let el;

	// 	if (this.session && this.session.videoPlayer && this.session.videoPlayer.type && this.session.videoPlayer.url) {
	// 		if (
	// 			this.session.videoPlayer.type == "vimeo" &&
	// 			this.videoComponent !== undefined &&
	// 			this.videoComponent.videoPlayer
	// 		) {
	// 			el = this.videoComponent.videoPlayer.nativeElement;
	// 			// Vimeo
	// 			const player = new Player(el);

	// 			player.getCurrentTime().then((t: number) => {
	// 				this.store.dispatch(new UpdateStart(t));
	// 			});
	// 			player.pause();
	// 		} else if (this.session.videoPlayer.type != "youtube") {
	// 			el = this.videoComponent.nativeElement;
	// 			// HTML5 video
	// 			el.pause();
	// 			this.store.dispatch(new UpdateStart(el.currentTime));
	// 		}
	// 	}

	// 	this.store.dispatch(new UpdateInSession(false));
	// }

	/**
	 * Get event data
	 */
	getEvent() {
		this.subscriptions.push(
			this.dbEvent.getEvent(this.eventId).subscribe((event) => {
				this.event = event;
				if (this.event.availableModules.module_group_discussion) {
					this.getGroupsAndUpdateInteractivity();
				}
			})
		);

		this.subscriptions.push(
			this.SModule.getModule(this.moduleId).subscribe((moduleEvent) => {
				this.module = moduleEvent;
				if (this.module.allowNextPrevBtn) {
					this.allowNextPrevBtn = true;

					this.store
						.select(sessionIsLastPrev(), this.sessionId)
						.pipe(take(1))
						.subscribe((response) => {
							if (response.status == "error") {
								this.allowNextPrevBtn = false;
								this.prevBtn = false;
								this.nextBtn = false;
							} else {
								this.prevBtn = response.first ? false : true;
								this.nextBtn = response.last ? false : true;
							}
						});
				} else {
					this.allowNextPrevBtn = false;
				}
				// if(this.route.snapshot.paramMap.get('source') === 'schedule-personal'){
				//     this.allowNextPrevBtn = false;
				// }
			})
		);
	}

	/**
	 * Get groups and update interactivity
	 */
	getGroupsAndUpdateInteractivity() {
		if (this.groupsSubscription && !this.groupsSubscription.closed) {
			this.groupsSubscription.unsubscribe();
		}
		this.groupsSubscription = this.SGroupsDiscussions.getGroupsForEventForSession(
			this.eventId,
			this.sessionId
		).subscribe((grps) => {
			if (grps && grps.length > 0) {
				this.allowDiscussionsGroups = true;
				this.subscriptions.push(
					this.SGroupsDiscussions.getGroupModule(this.eventId, grps[0].moduleId).subscribe((grpModule) => {
						if (grpModule && grpModule.name) {
							this.groupDiscussionName = grpModule.name;
						}
						// this.checkInteractivity();
					})
				);
			} else {
				this.allowDiscussionsGroups = false;
				// this.checkInteractivity();
			}
		});
	}

	/**
	 * Go next or previous session
	 * @param type
	 */
	// goNextOrPrevSession(type) {
	// 	this.store
	// 		.select(type == "prev" ? getPrevSession() : getNextSession(), this.sessionId)
	// 		.pipe(take(1))
	// 		.subscribe((response) => {
	// 			this.store.dispatch(new GetVideoPlayer(null));
	// 			this.sessionId = response.session.uid;
	// 			this.subscriptions.forEach((sub) => sub.unsubscribe());
	// 			this.getFeedbacks();
	// 			this.getEvent();
	// 			// this.getGroupsAndUpdateInteractivity();
	// 			this.store
	// 				.select(sessionIsLastPrev(), this.sessionId)
	// 				.pipe(take(1))
	// 				.subscribe((response) => {
	// 					if (response.status == "error") {
	// 						this.prevBtn = false;
	// 						this.nextBtn = false;
	// 					} else {
	// 						this.prevBtn = response.first ? false : true;
	// 						this.nextBtn = response.last ? false : true;
	// 					}
	// 				});
	// 			this.initSession();
	// 			this.zoomService.shutdownZoom();
	// 			this.location.replaceState(`/event/${this.eventId}/schedule-detail/${this.moduleId}/${this.sessionId}`);
	// 			// if (response && response.session && response.session.uid) {
	// 			//     this.joinedVisio = false;
	// 			//     this.router.navigateByUrl(`/event/${this.eventId}/schedule-detail/${this.moduleId}/${response.session.uid}`);
	// 			// }
	// 		});
	// }



    /**
     * @description get event colors
     * @param eventId 
     */
    loadColors(eventId: string = this.eventId) {
        const subscription = this.SEventData.getEventDataListener(eventId).subscribe((eventData) => {
            this.eventColors = eventData.colors;

            document.body.style.setProperty('--menu-color', this.eventColors.menu_color);
		    document.body.style.setProperty('--menu_text_color', this.eventColors.menu_text_color);

            // if (eventData.availableModules.module_group_discussion) {
            //     this.getGroupsAndUpdateInteractivity();
            // }
        });
        this.subscriptions.push(subscription);
    }

	/**
	 * Loading session
	 */
	// async loadSession() {
	// 	this.subscriptions.push(
	// 		this.dbSchedule.getSessionModule(this.eventId, this.moduleId, this.sessionId).subscribe((session) => {
	// 			this.session = this.setTheSession(session);

	// 			this.loadVideoPlayer();
	// 			this.getInfoModule();
	// 			this.getSessionAttendees();

	// 			this.getSurveys();
	// 			this.getQuiz();
	// 			this.getTraining();

	// 			if (this.session.askQuestion) {
	// 				this.getAskQuestion();
	// 			} else {
	// 				this.store.dispatch(
	// 					new GetQuestions({
	// 						questionModule: null,
	// 						questions: [],
	// 						anonymousQuestions: false
	// 					})
	// 				);
	// 			}
	// 			setTimeout(() => {
	// 				this.loader = false;
	// 			}, 1000);
	// 		})
	// 	);
	// }

	// loadVideoPlayer() {
	// 	if (this.session && this.session.videoPlayer && this.session.videoPlayer.type && this.session.videoPlayer.url) {
	// 		this.videoPlayerActivated = true;
	// 		this.store
	// 			.select(getVideoPlayerState)
	// 			.pipe(take(1))
	// 			.subscribe((videoPlayerState) => {
	// 				this.store.dispatch(
	// 					new GetVideoPlayer({
	// 						src: this.session.videoPlayer.url,
	// 						type: this.session.videoPlayer.type,
	// 						start:
	// 							videoPlayerState && videoPlayerState.sessionId === this.sessionId
	// 								? videoPlayerState.start
	// 								: 0,
	// 						sessionId: this.session.uid,
	// 						inSession: true,
	// 						state:
	// 							videoPlayerState && videoPlayerState.sessionId === this.sessionId
	// 								? videoPlayerState.state
	// 								: -1
	// 					})
	// 				);
	// 			});
	// 	} else {
	// 		this.videoPlayerActivated = false;
	// 		this.store.dispatch(new GetVideoPlayer(null));
	// 	}
	// }

	// getUrlVisio() {
	// 	let userGroups =
	// 		this.SUserData.userLogged() && this.SUserData.userData.groups && Object.keys(this.SUserData.userData.groups).length > 0
	// 			? Object.keys(this.SUserData.userData.groups)
	// 			: null;
	// 	let visioGroupsHost =
	// 		this.session.visio &&
	// 		this.session.visio.groupsVisioHost &&
	// 		Object.keys(this.session.visio.groupsVisioHost).length > 0
	// 			? Object.keys(this.session.visio.groupsVisioHost)
	// 			: null;

	// 	if (visioGroupsHost && userGroups && visioGroupsHost.some((uid) => userGroups.includes(uid))) {
	// 		return this.session.visio.hostRoomUrl;
	// 	} else {
	// 		return this.session.visio.roomUrl;
	// 	}
	// }

	// getUserType() {
	// 	let userGroups =
	// 		this.SUserData.userLogged() && this.SUserData.userData.groups && Object.keys(this.SUserData.userData.groups).length > 0
	// 			? Object.keys(this.SUserData.userData.groups)
	// 			: null;
	// 	let visioGroupsHost =
	// 		this.session.visio &&
	// 		this.session.visio.groupsVisioHost &&
	// 		Object.keys(this.session.visio.groupsVisioHost).length > 0
	// 			? Object.keys(this.session.visio.groupsVisioHost)
	// 			: null;

	// 	if (visioGroupsHost && userGroups && visioGroupsHost.some((uid) => userGroups.includes(uid))) {
	// 		return 1;
	// 	} else {
	// 		return 0;
	// 	}
	// }

	getZoomIntegration() {
		if (this.integration && this.session.visio && this.session.visio.type && this.session.visio.type == "zoom") {
			return this.integration.zoom.find((inte) => inte.id == this.session.visio.zoomIntegrationId);
		} else {
			return null;
		}
	}

	/**
	 * Check interactivity for full width or not
	 */
	checkInteractivity() {
		if (
			(this.surveys && this.surveys.length > 0) ||
			(this.questionModule && this.session.askQuestion) ||
			(this.quizs && this.quizs.length > 0) ||
			// || (this.speakers && this.speakers.length > 0)
			// || (this.documents && this.documents.length > 0)
			// || (this.feedbacks && this.feedbacks.length > 0)
			this.allowDiscussionsGroups
		) {
			this.fullWidth = false;
		} else {
			this.fullWidth = true;
		}
	}

	/*
	 * Checks whether the session is part of the user's personal calendar
	 */
	checkAttendeeOfTheSession() {
		this.dbSchedule
			.checkAttendeeOfTheSession(this.moduleId, this.sessionId, this.userId)
			.subscribe((status: boolean) => {
				this.statePersonalAgenda = status;
			});
	}

	/*
	 * Loads session participants
	 */
	getSessionAttendees() {
		this.dbSchedule.getSessionAttendees(this.moduleId, this.sessionId, (attendees) => {
			this.qtdAttendees = attendees.length;
		});
	}

	/**
	 * Reset interactivity
	 */
	resetInteractivity() {
		this.store.dispatch(
			new GetQuestions({
				questionModule: null,
				questions: [],
				anonymousQuestions: false
			})
		);

		this.store.dispatch(
			new GetSurveys({
				surveyModule: null,
				surveys: []
			})
		);

		this.store.dispatch(
			new GetQuizs({
				quizModule: null,
				quizs: []
			})
		);

		this.store.dispatch(
			new GetSpeakers({
				speakers: []
			})
		);

		this.store.dispatch(
			new GetDocuments({
				documents: []
			})
		);

		this.store.dispatch(
			new GetFeedbacks({
				feedbacks: [],
				feedbackModule: null
			})
		);
	}

	ionViewWillLeave() {
		// this.leavingSessionForPlayer();
		// this.SWhereby.stopVisio.next(true);
		// this.onSession = false;
		// // Stopping iframe youtube video on navigating need to add ?enablejsapi=1 on end of video link on backoffice
		// let listaFrames = document.getElementsByTagName("iframe");
		// for (var index = 0; index < listaFrames.length; index++) {
		// 	let iframe = listaFrames[index].contentWindow;
		// 	iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', "*");
		// }
		// this.resetInteractivity();
		// this.dbSchedule.closeRefGetSessionModule();
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get surveys for event
	 */
	getSurveys() {
		if (this.userId !== null || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
			this.subscriptions.push(
				this.DaoSurvey.getSurveyModule(this.eventId).subscribe((modules) => {
					if (modules.length > 0) {
						this.surveyModule = modules[0];

						this.subscriptions.push(
							this.DaoSurvey.getSurveysSession(
								this.eventId,
								this.surveyModule.uid,
								this.moduleId,
								this.sessionId,
								this.userId
							).subscribe((surveys) => {
								this.surveys = [];
								this.surveys = surveys;

								this.checkInteractivity();
								this.store.dispatch(
									new GetSurveys({
										surveyModule: this.surveyModule,
										surveys: this.surveys
									})
								);
							})
						);
					} else {
						this.checkInteractivity();
						this.store.dispatch(
							new GetSurveys({
								surveyModule: null,
								surveys: []
							})
						);
					}
				})
			);
		}
	}

	/**
	 * Get quizs for event
	 */
	getQuiz() {
		if (this.userId !== null || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
			this.subscriptions.push(
				this.DaoQuiz.getQuizModule(this.eventId).subscribe((modules) => {
					if (modules.length > 0) {
						this.quizModule = modules[0];
                        console.log(`quizModule: ${JSON.stringify(this.quizModule)}`)

						this.subscriptions.push(
							this.DaoQuiz.getQuizsSession(
								this.eventId,
								this.quizModule.uid,
								this.moduleId,
								this.sessionId,
								this.userId
							).subscribe((quizs) => {
								this.quizs = quizs;

								this.checkInteractivity();
								this.store.dispatch(
									new GetQuizs({
										quizModule: this.quizModule,
										quizs: this.quizs
									})
								);
							})
						);
					} else {
						this.checkInteractivity();
						this.store.dispatch(
							new GetQuizs({
								quizModule: null,
								quizs: []
							})
						);
					}
				})
			);
		}
	}

	getTraining() {
		if (this.userId !== null || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) {
			this.DaoTraining.getTrainingModule(this.eventId, (modules) => {
				if (modules.length > 0) {
					this.trainingModule = modules[0];
					this.trainingIcon = modules[0].icon;
					this.trainingIconFamily = modules[0].iconFamily;
					this.trainingModuleId = modules[0].uid;

					this.DaoTraining.getTrainingsSession(
						this.eventId,
						this.trainingModuleId,
						this.moduleId,
						this.sessionId,
						this.userId,
						(trainings) => {
							this.trainings = [];
							this.trainings = trainings;
						}
					);
				}
			});
		}
	}

	/**
	 * Get asked questions
	 */
	getAskQuestion() {
		this.subscriptions.push(
			this.daoAskQuestion.getAskQuestionModule(this.eventId).subscribe((modules) => {
				if (modules.length > 0) {
					this.questionModule = modules[0];
					this.subscriptions.push(
						this.daoAskQuestion
							.getQuestions(this.eventId, this.session.moduleId, this.sessionId, this.userId)
							.subscribe((questions) => {
								if (questions !== null) {
									questions = questions.sort(function (a, b) {
										if (a.totalVotes < b.totalVotes) {
											return 1;
										}
										if (a.totalVotes > b.totalVotes) {
											return -1;
										}
										// a must be equal to b
										return 0;
									});

									this.questions = [];
									this.questions = questions;
								} else {
									this.questions = [];
								}
								this.checkInteractivity();
								this.store.dispatch(
									new GetQuestions({
										questionModule: this.questionModule,
										questions: this.questions,
										anonymousQuestions: this.session.anonymous
									})
								);
							})
					);
				} else {
					this.checkInteractivity();
					this.store.dispatch(
						new GetQuestions({
							questionModule: null,
							questions: [],
							anonymousQuestions: false
						})
					);
				}
			})
		);
	}

	/**
	 * Get feedbacks
	 */
	getFeedbacks() {
        this.md.getModuleId(this.eventId, TypeModule.SESSION_FEEDBACK).then((moduleId) => {
            this.sessionFeedbackModule = moduleId;
            this.daoFeedback.getFeedbacks(this.sessionFeedbackModule).subscribe(async (feedbacks) => {
                this.feedbacks = [];

                if (feedbacks && feedbacks.length > 0) {
                    this.loadingSend = new Array(feedbacks.length).fill(false);

                    for (let feedback of feedbacks) {
                        if (feedback.type === "AllSessions") {
                            if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
                                this.feedbacks.push(feedback);
                                this.loader = false;
                            }
                        } else if (feedback.type === "ScheduleModule") {
                            if (this.moduleId === feedback.module_id) {
                                if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
                                    this.feedbacks.push(feedback);
                                    this.loader = false;
                                }
                            }
                        } else if (feedback.type === "SessionTrack") {
                            // case this session be inside a track with permission, display feedback
                            await this.daoFeedback.checkSpecificTrackFeedback(
                                feedback.module_id,
                                this.sessionId,
                                feedback.references,
                                (tracks: Array<boolean>) => {
                                    for (const track of tracks) {
                                        if (track) {
                                            if (
                                                this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1
                                            ) {
                                                this.feedbacks.push(feedback);
                                                this.loader = false;
                                            }
                                            break;
                                        }
                                    }
                                }
                            );
                        } else if (feedback.type === "SpecificSession") {
                            for (let uid of feedback.references) {
                                if (uid === this.sessionId) {
                                    if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
                                        this.feedbacks.push(feedback);
                                        this.loader = false;
                                    }
                                    break;
                                }
                            }
                        } else if (feedback.type === "SpecificGroup") {
                            await this.daoFeedback.listgroupsOfAttendee(
                                this.eventId,
                                this.userId,
                                (listGroupsAttendee) => {
                                    const references: any = feedback.references;
                                    let findGroup = false;
                                    for (let uidGroup of references) {
                                        for (const uidGroupUser of listGroupsAttendee) {
                                            //if the user is part of the group
                                            if (uidGroup == uidGroupUser && findGroup == false) {
                                                findGroup = true;
                                                if (
                                                    this.SUtility.checkIndexExists(this.feedbacks, feedback) ==
                                                    -1
                                                ) {
                                                    this.feedbacks.push(feedback);
                                                    this.loader = false;
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }
                            );
                        }
                    }

                    setTimeout(() => {
                        this.loader = false;
                        this.store.dispatch(
                            new GetFeedbacks({
                                feedbacks: this.feedbacks,
                                feedbackModule: this.sessionFeedbackModule
                            })
                        );
                    }, 300);
                } else {
                    this.loader = false;
                    this.store.dispatch(
                        new GetFeedbacks({
                            feedbacks: [],
                            feedbackModule: null
                        })
                    );
                }
            })
        })


		// this.daoInteractivity.getInteractivityModule(this.eventId, (moduleInteractivity) => {
		// 	this.moduleInteractivity = moduleInteractivity;

		// 	if (
		// 		(this.SGlobal.user || (this.moduleInteractivity && this.moduleInteractivity.answerOffline)) &&
		// 		this.sessionFeedbackModule !== null &&
		// 		this.sessionFeedbackModule !== undefined
		// 	) {
		// 		this.subscriptions.push(
					// this.daoFeedback.getFeedbacks(this.sessionFeedbackModule).subscribe(async (feedbacks) => {
		// 				this.feedbacks = [];

		// 				if (feedbacks && feedbacks.length > 0) {
		// 					this.loadingSend = new Array(feedbacks.length).fill(false);

		// 					for (let feedback of feedbacks) {
		// 						if (feedback.type === "AllSessions") {
		// 							if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
		// 								this.feedbacks.push(feedback);
		// 								this.loader = false;
		// 							}
		// 						} else if (feedback.type === "ScheduleModule") {
		// 							if (this.moduleId === feedback.module_id) {
		// 								if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
		// 									this.feedbacks.push(feedback);
		// 									this.loader = false;
		// 								}
		// 							}
		// 						} else if (feedback.type === "SessionTrack") {
		// 							// case this session be inside a track with permission, display feedback
		// 							await this.daoFeedback.checkSpecificTrackFeedback(
		// 								feedback.module_id,
		// 								this.sessionId,
		// 								feedback.references,
		// 								(tracks: Array<boolean>) => {
		// 									for (const track of tracks) {
		// 										if (track) {
		// 											if (
		// 												this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1
		// 											) {
		// 												this.feedbacks.push(feedback);
		// 												this.loader = false;
		// 											}
		// 											break;
		// 										}
		// 									}
		// 								}
		// 							);
		// 						} else if (feedback.type === "SpecificSession") {
		// 							for (let uid of feedback.references) {
		// 								if (uid === this.sessionId) {
		// 									if (this.SUtility.checkIndexExists(this.feedbacks, feedback) == -1) {
		// 										this.feedbacks.push(feedback);
		// 										this.loader = false;
		// 									}
		// 									break;
		// 								}
		// 							}
		// 						} else if (feedback.type === "SpecificGroup") {
		// 							await this.daoFeedback.listgroupsOfAttendee(
		// 								this.eventId,
		// 								this.userId,
		// 								(listGroupsAttendee) => {
		// 									const references: any = feedback.references;
		// 									let findGroup = false;
		// 									for (let uidGroup of references) {
		// 										for (const uidGroupUser of listGroupsAttendee) {
		// 											//if the user is part of the group
		// 											if (uidGroup == uidGroupUser && findGroup == false) {
		// 												findGroup = true;
		// 												if (
		// 													this.SUtility.checkIndexExists(this.feedbacks, feedback) ==
		// 													-1
		// 												) {
		// 													this.feedbacks.push(feedback);
		// 													this.loader = false;
		// 												}
		// 												break;
		// 											}
		// 										}
		// 									}
		// 								}
		// 							);
		// 						}
		// 					}

		// 					setTimeout(() => {
		// 						this.loader = false;
		// 						this.store.dispatch(
		// 							new GetFeedbacks({
		// 								feedbacks: this.feedbacks,
		// 								feedbackModule: this.sessionFeedbackModule
		// 							})
		// 						);
		// 					}, 300);
		// 				} else {
		// 					this.loader = false;
		// 					this.store.dispatch(
		// 						new GetFeedbacks({
		// 							feedbacks: [],
		// 							feedbackModule: null
		// 						})
		// 					);
		// 				}
		// 			})
		// 		);
		// 	} else {
		// 		this.loader = false;
		// 		this.store.dispatch(
		// 			new GetFeedbacks({
		// 				feedbacks: [],
		// 				feedbackModule: null
		// 			})
		// 		);
		// 	}
		// });
	}

	// /*
	//  * Add session to participant's personal calendar
	//  */
	// addSession() {
	// 	if (this.SGlobal.userLogged()) {
	// 		this.dbPersonal.addSession(this.session);
	// 	}
	// }

	// /*
	//  * Removes the session from the participant's personal calendar
	//  */
	// removeSession() {
	// 	const sessionId = this.session.uid;
	// 	if (this.SGlobal.userLogged()) {
	// 		this.dbPersonal.deleteSession(this.eventId, this.moduleId, sessionId);
	// 	}
	// }
	// addRemoveSession(type) {
	// 	if (this.statePersonalAgenda) {
	// 		this.removeSession();
	// 	} else {
	// 		this.addSession();
	// 	}
	// }

	/**
	 * Open training list session
	 */
	openTrainingsListSession() {
		this.router.navigate([
			`/event/${this.eventId}/training-list-session/${this.trainingModuleId}/${this.moduleId}/${this.sessionId}`
		]);
	}

	/**
	 * Get speaker principal title
	 * @param title
	 * @param description
	 */
	getSpeakerPrincipalTitle(title, description) {
		return {
			title: title[this.language],
			description: description[this.language]
		};
	}

	navigateBack() {
		this.navCtrl.back();
	}

	// onScroll(ev) {
	// 	if(!this.isMobile){
	// 		if (ev.detail.scrollTop > 67) {
	// 			document.getElementById(`${this.sessionId}-col-detail`).style.top = 56 + "px";
	// 		} else {
	// 			document.getElementById(`${this.sessionId}-col-detail`).style.top = 125 - ev.detail.scrollTop + "px";
	// 		}
	// 	}
	// }

	// segSelected: string = 'info';
	// segChange(event) {
	// 	console.log('event', event.target.value);
	// 	this.segSelected = event.target.value;
	// }
    sessionTab: string = 'info';
    changeSessionTab(tab: string) {
		this.sessionTab = tab;		
		// switch (tab) {
		// 	case "quiz":
		// 		// this.getQuiz();
		// 		break;
		// 	case "ask_question":
		// 		this.getAskQuestion();
		// 		break;			
		// }
        //colocar condicional >>> proibir nav se a tab não estiver disponivel para essa sessão/agenda
    }
	
	// Favorite 
	fav() {
		if (this.SUserData.userLogged()) {
			if (this.statePersonalAgenda) {
				
				// removes the session from the participant's personal calendar
				this.dbPersonal.deleteSession(this.eventId, this.moduleId, this.session.uid);
			} 
			else {
				// add session to participant's personal calendar
				this.dbPersonal.addSession(this.session);
			}
		}
	}

    fetchReactions$: Observable<DocumentData> = null;
    /**
     * @description if no observable for schedule session reactions data is 
     * available initialize the fetchReactions$ property with one, then make a 
     * subscription to it. This method was designed to be called inside a 
     * subscriber method that receives the session data, but was intended to 
     * only "work" once, so only one observable for reactions and a 
     * subscription to it will be made.
     * 
     * obs.: note that this method has no params, but is dependent of the value
     * of "videoPlayer" property .
     */
    fetchReactions(): void {
        if (this.fetchReactions$ != null) { return; }

        this.fetchReactions$ = this.dbSchedule.getReactionsListener(this.eventId, this.moduleId, this.sessionId);    
        this.subscriptions.push(
            this.fetchReactions$.subscribe((reactions) => {
                if (
                    this.videoPlayer && 
                    this.videoPlayer.id &&
                    reactions[this.videoPlayer.id]
                ) {
                    this.reactions = reactions[this.videoPlayer.id];
                } else {
                    console.error(`videoPlayer.id ${this.videoPlayer.id} is invalid`)
                }
            })
        );
    }


	addOrRemoveReaction(reaction: ReactionOptions) {
		// if (!this.isUserLoggedIn) 
		// 	return;
        
		if (!!this.reactions[reaction].userUids[this.userId]) {
            delete this.reactions[reaction].userUids[this.userId];
			this.reactions[reaction].counter--;

            this.dbSchedule.updateReactions(
                this.eventId,
                this.moduleId,
                this.sessionId,
                this.videoPlayer.id,
                this.reactions,
            );
		}
		else {
            this.SUserData.getUserDataSnapshot(this.eventId).then((userData) => {
                const params = {
                    eventId: this.eventId,
                    userId: userData.uid,
                    userType: userData.type
                }
                this.reactions[reaction].userUids[this.userId] = this.SUserData
                    .userRef(params);
                
                this.reactions[reaction].counter++;
            }).catch((error) => {
                console.error(error);
                this.reactions[reaction].userUids[this.userId] = this.SUserData
                    .userRef();
                
                this.reactions[reaction].counter++;
            }).finally(() => {
                this.dbSchedule.updateReactions(
                    this.eventId,
                    this.moduleId,
                    this.sessionId,
                    this.videoPlayer.id,
                    this.reactions,
                );
            })
		}
	}
}
